import React, { useEffect, useState } from "react";
import { Button, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import {
  getCourseDetail_WebService,
  submitCourseQuestions,
} from "../core/_request";
import { courseData } from "./core/models";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import Iframe from "react-iframe";

const CourseQuestions = () => {
  const [value, setValue] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [data, setData] = useState();
  let { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const courseId = courseData.find((o) => o.id == id).id;
  const [isVideoViewed, setIsVideoViewed] = useState(false);
  const onClick = (data) => {
    var promises = [];
    data?.data?.stepIds?.map((item, index) => {
      console.log("dataprops", data?.data);
      const body = {
        stepId: item.id,
        userId: currentUser?.UserId,
        selectedPoints: selectedOptions
          .filter((o) => o.pointId == item.id)
          .map((o) => o.id),
      };

      promises.push(
        submitCourseQuestions(body).then((resp) => {
          console.log("resp", resp);
        })
      );
      Promise.all(promises).then((_) =>
        navigate(`/dashboard/program/program-loading/${courseId}`)
      );
    });
  };

  useEffect(() => {
    const body = {
      userId: currentUser?.UserId,
      courseId: courseId,
    };
    getCourseDetail_WebService(body).then((resp) => {
      setData(resp.Data);
      var viewoLength =
        data?.data?.stepIds &&
        data?.data?.stepIds?.filter((o) => o.type == "video").length;
      if (viewoLength == 0) setIsVideoViewed(true);
    });
  }, []);

  const handleChange = (val) => {
    setValue(val);
  };

  const onOptionClick = (item, pointId) => {
    if (selectedOptions.find((o) => o.id == item.id))
      setSelectedOptions([...selectedOptions.filter((o) => o.id != item.id)]);
    else
      setSelectedOptions([
        ...selectedOptions,
        { pointId: pointId, id: item.id },
      ]);
  };

  return (
    <div className="mx-5">
      <div className="py-5">
        <p>
          Answer these question so that we can curate a personalized course for
          you
        </p>
      </div>
      {!isVideoViewed && (
        <div>
          {data?.data?.stepIds &&
            data?.data?.stepIds
              ?.filter((o) => o.type == "video")
              .map(
                (video, i) =>
                  video?.contentIds &&
                  video?.contentIds.map((item) => (
                    <>
                      <div className="col-md-5 py-3 px-0">
                        <p>{item?.title}</p>
                      </div>
                      <Iframe
                        styles={{ border: "1px solid black" }}
                        className="coursePreviewVideo my-2"
                        src={item?.video?.player_embed_url}
                        width="100%"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        title="Course"
                      ></Iframe>
                    </>
                  ))
              )}
          <div>
            <Button
              className="rounded-5 border-0 px-4"
              onClick={() => setIsVideoViewed(true)}
              style={{
                background:
                  "linear-gradient(94.39deg, #FF9C00 0.89%, #FFD255 100%)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                color: "#fff",
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {isVideoViewed && (
        <div>
          {data?.data?.stepIds &&
            data?.data?.stepIds
              ?.filter((o) => o.type == "points")
              .map((point, i) => (
                <div
                  className="row py-3 align-items-center"
                  style={{
                    boxShadow:
                      "0px -1px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div className="col-md-5 py-3 px-0">
                    <p>{point?.description}</p>
                  </div>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={value}
                    onChange={handleChange}
                    className="row col-md-7 gap-3 justify-content-around"
                  >
                    {point?.points &&
                      point?.points.map((item, index) => (
                        <ToggleButton
                          onClick={() => onOptionClick(item, point.id)}
                          id={`tbg-btn-${i}${index}`}
                          value={item.id}
                          className="col-md-3 rounded-5"
                          variant="outline-success"
                        >
                          {item?.name}
                        </ToggleButton>
                      ))}
                  </ToggleButtonGroup>
                </div>
              ))}
          <div className="d-flex my-5 mx-3 gap-4 justify-content-end">
            <div>
              <Button
                className="rounded-5 bg-white"
                style={{ border: "1px solid #FFA300", color: "#FFA300" }}
              >
                Dashboard
              </Button>
            </div>
            <div>
              <Button
                className="rounded-5 border-0 px-4"
                onClick={() => onClick(data)}
                style={{
                  background:
                    "linear-gradient(94.39deg, #FF9C00 0.89%, #FFD255 100%)",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  color: "#fff",
                }}
              >
                Finish
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseQuestions;
