import React, { useState, useEffect, useRef, useContext } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { AppContext } from "../../../../context";

function SessionTranscript({ sessionStatus }) {
  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);
  let transcriptmessages =
    expertDashboardData?.session_data?.session_transcript?.messages || [];
  let transcriptoverall =
    expertDashboardData?.session_data?.session_transcript?.overall || "";

  const [textMessage, setTextMessage] = useState(transcriptmessages);
  const [overall, setoverall] = useState(transcriptoverall);
  const scrollableContainerRef = useRef();

  useEffect(() => {
    setTextMessage(transcriptmessages);
    setoverall(expertDashboardData?.session_data?.session_transcript?.overall);
  }, [transcriptmessages, transcriptoverall]);


  const handleSearch = (event) => {
    const searchTerm = event.target.value;
  };
  function formatTimestampTo12Hour(timestamp) {
    const formattedTime = new Date(timestamp).toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  }

  function likeDislike(chatIndex, reaction, type) {
    const updatedMessage = textMessage.map((item, index) => {
      if (chatIndex === index) {
        if (reaction === "like") {
          return {
            ...item,
            text_like: "like",
          };
        } else {
          return {
            ...item,
            text_like: "dislike",
          };
        }
      }
      return item;
    });
    if (type !== "overall") {
      setExpertDashboardData((prevData) => ({
        ...prevData,
        session_data: {
          ...prevData.session_data,
          session_transcript: {
            ...prevData.session_data.session_transcript,
            messages: updatedMessage,
          },
        },
      }));
    } else {
      setExpertDashboardData((prevData) => ({
        ...prevData,
        session_data: {
          ...prevData.session_data,
          session_transcript: {
            ...prevData.session_data.session_transcript,
            overall: reaction,
          },
        },
      }));
    }
  }
  return (
    <div>
      {!sessionStatus && (
        <div className=" d-flex align-items-center justify-content-between p-2 shadow-sm">
          <div
            className="p-1 gap-2"
            style={{ backgroundColor: "rgb(249, 245, 255)" }}
          >
            <span>Overall, did you find this transcript useful?</span>
            <button
              onClick={() => likeDislike(0, "like", "overall")}
              className=" border-0 mx-2"
              style={{
                backgroundColor: "rgb(249, 245, 255)",
                color: "#73589b",
              }}
              disabled={expertDashboardData?.session_data?.submitted}
            >
              {overall !== "" && overall === "like" ? (
                <ThumbUpAltIcon style={{ fontSize: "30px" }} />
              ) : (
                <ThumbUpAltIcon style={{ fontSize: "30px", color: "grey" }} />
              )}
            </button>
            <button
              disabled={expertDashboardData?.session_data?.submitted}
              onClick={() => likeDislike(0, "dislike", "overall")}
              className=" border-0"
              style={{
                backgroundColor: "rgb(249, 245, 255)",
                color: "#73589b",
              }}
            >
              {overall !== "" && overall === "dislike" ? (
                <ThumbDownAltIcon style={{ fontSize: "30px" }} />
              ) : (
                <ThumbDownAltIcon style={{ fontSize: "30px", color: "grey" }} />
              )}
            </button>
          </div>
          {/* hidden for developmen purpose*/}
          <div className="d-none gap-3 align-items-center">
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              onChange={handleSearch}
            />
            <button
              style={{
                border: "none",
                borderRadius: "5px",
                background: "#FFF",
                color: "#73589b",
              }}
            >
              <OndemandVideoOutlinedIcon style={{ fontSize: "30px" }} />
            </button>

            <button
              style={{
                border: "none",
                borderRadius: "5px",
                background: "#FFF",
                color: "#73589b",
              }}
            >
              <DownloadForOfflineIcon style={{ fontSize: "30px" }} />
            </button>
            <button
              style={{
                border: "none",
                borderRadius: "5px",
                background: "#FFF",
                color: "#73589b",
              }}
            >
              <LocalPrintshopIcon style={{ fontSize: "30px" }} />
            </button>
          </div>
        </div>
      )}

      <div
        ref={scrollableContainerRef}
        className="SessionTranscript--ScrollBar"
        style={{ height: "50vh", overflow: "auto" }}
      >
        {textMessage &&
          textMessage.map((message, index) =>
            message.speaker_type === "expert" ? (
              <div key={index} className="d-flex">
                <div style={{ maxWidth: "70%" }}>
                  <div className="d-flex align-items-center gap-2 p-3">
                    <img
                      width={"24px"}
                      className="rounded-circle"
                      src={message.image_url}
                    />
                    <h6 className="m-0">{message.speaker_name}</h6>
                    <p className="text-muted m-0">
                      {formatTimestampTo12Hour(message.timestamp)}
                    </p>
                  </div>
                  <div
                    className=""
                    style={{
                      backgroundColor: "rgb(249, 245, 255)",
                      borderRadius: "0px 20px 20px 20px",
                    }}
                  >
                    <p className="p-3  ">{message.text}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div key={index} className="d-flex flex-row-reverse ">
                <div style={{ maxWidth: "70%", marginRight: "8px" }}>
                  <div className="d-flex align-items-center flex-row-reverse gap-2 p-3">
                    <img
                      width={"24px"}
                      className="rounded-circle"
                      src={message.image_url}
                    />
                    <h6 className="m-0">{message.speaker_name}</h6>
                    <p className="text-muted m-0">
                      {formatTimestampTo12Hour(message.timestamp)}
                    </p>
                  </div>
                  <div
                    className=""
                    style={{
                      backgroundColor: "#73589b",
                      color: "white",
                      borderRadius: "20px 0px 20px 20px",
                    }}
                  >
                    <p className="p-3 ">{message.text}</p>
                  </div>
                </div>
              </div>
            )
          )}
      </div>
    </div>
  );
}

export default SessionTranscript;
