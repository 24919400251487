import React from "react";
import { Button, Dropdown, Card } from "react-bootstrap";
import "./ContactCardResponse.css";
import { useNavigate } from "react-router-dom";

const ContactCardResponse = ({
  id,
  name,
  image,
  profession,
  expInYears,
  languages,
  url,
  banner
}) => {
  const navigate = useNavigate();

  return (
    <div
      id={id}
      style={{ width: "100%", maxWidth: "340px" }}
      className="ContactCard"
      onClick={() => {
        navigate(`${url}`);
      }}
    >
      <div className="ContactCard--Content">
        <Card.Img variant="top" src={image} />
        <Card.Body>
          {
            banner && <img alt="" src={banner} />
          }

          <div className="ContactCard--desc">
            <Card.Title>{name}</Card.Title>
            {profession !== undefined && (
              <div>
                <p>{profession}</p>
              </div>
            )}

            {expInYears !== undefined && (
              <div>
                <p>{expInYears} years of experience</p>
              </div>
            )}

            {languages !== null &&
              languages !== "" &&
              languages !== undefined &&
              languages.length > 0 && (
                <div>
                  <p>{languages}</p>
                </div>
              )}

            <div className="bookExpert--Btn">Book now</div>
          </div>
        </Card.Body>
      </div>
    </div>
  );
};

export default ContactCardResponse;
