import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Container, Modal } from 'react-bootstrap'
import AddIcon from '@mui/icons-material/Add';
import { GetMySubscription, SubscriptionFaq } from './core/request';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';

const MySubscription = () => {

    const [show, setShow] = useState(false);
    const [mySubscriptionData, setMySubscriptionData] = useState({});
    const [faqData, setFaqData] = useState({});
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const OnClickCancel = () => {
        navigate('/cancel-subscription')
    }

    var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    var body = { "user_id": user_id }
    useEffect(() => {
        GetMySubscription(body).then(resp => {
            if (resp.ResponseCode === 200) {
                setMySubscriptionData(resp.data)
            } else {
                enqueueSnackbar(resp?.ResponseMessage, { variant: "error" });
            }
        })

        SubscriptionFaq(body).then(resp => {
            if (resp.ResponseCode === 200) {
                setFaqData(resp.data)
            } else {
                // alert(resp.ResponseMessage)
            }
        })
    }, [])

    const onUpgradeButton = () => {
        navigate('/subscription-upgrade')
    }

    const noSubscription = () => {
        navigate('/plans')
    }

    return (
        <Container>
            <Helmet>
                <title>My Subscription - UNITED WE CARE</title>
            </Helmet>
            <div className='d-block mx-auto my-5'>
                <h1 className='font-inter fs-2 lh-lg'>My Subscription</h1>
                {mySubscriptionData?.subscription_id &&
                    <div className='rounded-3 my-2' style={{ border: "1px solid #1C1B1F1F" }}>
                        <div className='d-flex align-items-center justify-content-between rounded-top px-3 py-4' style={{ background: "#C4E5F9" }}>
                            <h6 className='font-inter fs-5 font-w500'>{mySubscriptionData?.subscription_name}</h6>
                            <Button
                                className='rounded-5 font-inter fs-6 text-black border-1'
                                style={{ background: "#C4E5F9", borderColor: "#21231E" }}
                                onClick={OnClickCancel}>
                                Cancel Subscription
                            </Button>
                        </div>
                        <div className='d-md-flex align-items-center justify-content-between px-3'>
                            <div className='row align-items-center py-4 col-md-10'>
                                <div className='col-6 col-md-3'>
                                    <p className='font-inter fs-6 lh-lg'>Start Date</p>
                                    <h6 className='font-inter fs-4 font-w700 lh-lg'>{mySubscriptionData.start_date}</h6>
                                </div>
                                <div className='col-6 col-md-3'>
                                    <p className='font-inter fs-6 lh-lg'>Renewal Date</p>
                                    <h6 className='font-inter fs-4 font-w700 lh-lg'>{mySubscriptionData.billing_date}</h6>
                                </div>
                                <div className='col-6 col-md-3'>
                                    <p className='font-inter fs-6 lh-lg'>Billing</p>
                                    <h6 className='font-inter fs-4 font-w700 lh-lg'>{mySubscriptionData.billing_type} <Link className='font-inter font-w400 fs-6 text-decoration-none text-black'>(Cancel)</Link></h6>
                                </div>
                                <div className='col-6 col-md-3'>
                                    <p className='font-inter fs-6 lh-lg'>Sessions Left</p>
                                    <h6 className='font-inter fs-4 font-w700 lh-lg'>{mySubscriptionData.sessions_left}</h6>
                                </div>
                            </div>
                            {mySubscriptionData?.upgrade_button &&
                                <Button
                                    className='border-0 rounded-5 font-inter text-black px-3 py-3 col-md-2 col-6 d-block mx-auto mb-3 mb-md-0'
                                    style={{ background: "#9FE770" }}
                                    onClick={handleShow}
                                >
                                    Upgrade
                                </Button>
                            }

                            <Modal className='subscriptionModal' show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='d-flex flex-column align-items-center gap-1'>
                                        <img src='/assets/img/autopay.png' alt='' />
                                        <h6 className='font-inter font-w700 fs-5 text-center lh-lg'>
                                            Are you sure you want to proceed?
                                        </h6>
                                        <p className='font-inter fs-6 text-center lh-lg'>
                                            Autopay saves you the hassle of manual recurring payments so that you can access our services uninterrupted.
                                        </p>
                                    </div>
                                    <Button
                                        className='border-0 rounded-5 font-inter text-black px-3 py-3 col-9 my-3 d-block mx-auto'
                                        style={{ background: "#9FE770" }}
                                        onClick={onUpgradeButton}
                                    >
                                        Confirm
                                    </Button>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>

                    ||
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <h6 className='font-inter fs-4 font-w500 lh-lg text-center'>
                            No Subscription Found!
                        </h6>
                        <Button className='border-0 col-3 font-inter fs-5' onClick={noSubscription} style={{ background: "#1B8BCF" }}>Buy a Plan</Button>
                    </div>
                }
                <hr className='my-4' style={{ borderTopWidth: "2px" }} />
                <div>
                    <h2 className='font-inter fs-2 lh-lg'>FAQs</h2>
                    {faqData?.faqs?.map((data, index) =>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1a-content"
                                id={index}
                                className='py-3'
                            >
                                <Typography variant='h3' className='fs-5'>{data?.heading_text}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p className='font-inter fs-6' dangerouslySetInnerHTML={{ __html: data?.body_text }}></p>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </div>
            </div>
        </Container>
    )
}

export default MySubscription