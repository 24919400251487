import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./CourseCard.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CourseCard = (props) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const onClickCourse = () => {
    navigate(`/program/${props?.courseData?.name}/${props?.courseData?.id}`);
  };

  const onCLickCopy = async () => {
    try {
      await navigator?.clipboard?.writeText(props?.courseData?.website_url.replace("us.unitedwecare.com", "my.unitedwecare.com"));

      setOpen(true);
    } catch (err) {
      // console.log(err)
    }
  };

  const onClickWhatsApp = () => {
    window.open(
      encodeURI(`whatsapp://send?text=${props?.courseData?.website_url.replace("us.unitedwecare.com", "my.unitedwecare.com")}`),
    );
  };

  const onClickWhatsAppOnWeb = () => {
    window.open(
      encodeURI(`https://wa.me?text=${props?.courseData?.website_url.replace("us.unitedwecare.com", "my.unitedwecare.com")}`),
    );
  }


  return (
    <>
      <div
        className="rounded-4 px-3 py-3 d-flex flex-column justify-content-between gap-3 CourseListing--Card"
        style={{ border: "1px solid #dadada", height: "100%" }}
      >
        <Link
          onClick={onClickCourse}
          className="text-decoration-none d-flex flex-column gap-1"
        >
          <img
            loading="lazy"
            src={props?.courseData?.website_thumbnail_url}
            alt=""
            className="rounded-3"
            style={{ aspectRatio: "16/9", objectFit: "fill" }}
          />
          <h2
            className="font-w600 font-inter fs-4 my-2 my-md-0 CourseListing--Card__Title"
            style={{ color: "#101828", lineHeight: "32px" }}
          >
            {props?.courseData?.name}
          </h2>
        </Link>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex align-items-center justify-content-between gap-1">
            <div
              className="rounded-5 d-flex align-items-center gap-1 justify-content-center px-2 py-1"
              style={{ background: "rgba(159, 214, 139, 0.10)" }}
            >
              <img src="assets/img/tabler_clock-filled.png" alt="" />
              <p
                className="font-inter fs-6 font-w500"
                style={{ color: "#4C9234", whiteSpace: 'nowrap' }}
              >
                {props?.courseData?.duration}
              </p>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <Link
                onClick={onCLickCopy}
                className="rounded-5 d-flex align-items-center gap-1 justify-content-center px-2 py-1 text-decoration-none"
                style={{
                  border: "1px solid #D0D5DD",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                }}
              >
                <img src="assets/img/copy.png" alt="" />
                <p
                  className="font-inter font-w600 fs-6"
                  style={{ color: "#344054", whiteSpace: 'nowrap' }}
                >
                  Copy link
                </p>
              </Link>
              <div
                className="rounded-circle d-flex align-items-center justify-content-center"
                style={{
                  border: "1px solid #D0D5DD",
                  height: "35px",
                  width: "35px",
                  cursor: "pointer",
                }}
              >
                <a
                  className="d-none d-md-block"
                  style={{ cursor: "pointer" }}
                  onClick={onClickWhatsAppOnWeb}
                >
                  <img
                    src="/assets/img/whatsapp.png"
                    alt=""
                    style={{ height: "25px", width: "25px" }}
                  />
                </a>
                <a
                  className="d-block d-md-none"
                  style={{ cursor: "pointer" }}
                  onClick={onClickWhatsApp}
                >
                  <img
                    src="/assets/img/whatsapp.png"
                    alt=""
                    style={{ height: "25px", width: "25px" }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12">
            {(props?.courseData?.price === 0 && (
              <Button
                onClick={onClickCourse}
                className="d-flex align-items-center gap-3 mt-2 justify-content-center border-0 rounded-3 col-12 text-white text-capitalize"
                style={{ background: "var(--primary-color)", padding: "0.75rem" }}
              >
                <img src="assets/img/unlock.png" alt="" />
                Start for FREE
              </Button>
            )) || (
                <Button
                  onClick={onClickCourse}
                  className="d-flex align-items-center gap-3 mt-2 justify-content-center border-0 rounded-3 col-12 text-white text-capitalize"
                  style={{ background: "var(--primary-color)", padding: "0.75rem" }}
                >
                  <img src="assets/img/lock.png" alt="" />
                  Subscribe to Enroll
                </Button>
              )}
          </div>
        </div>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={action}
      >
        <Alert severity="success">Link copied successfully!</Alert>
      </Snackbar>
    </>

  );
};

export default CourseCard;
