import axios from "axios";
import { useAuth } from "../../../auth";
import jwtDecode from "jwt-decode";
import { Config } from "../../../core/_request";

export const GetPrescriptionsSentByUser = (expertID, userID) => {
  return axios
    .get(
      `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/prescriptions-from-user?expert_id=${expertID}&user_id=${userID}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
