import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../auth";
import { useContext } from "react";
import { AppContext } from "../../context";
import { getCountryCode } from "../core/commonFun";

export const WhyTrustUs = ({ type }) => {
  const { currentUser } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const navigate = useNavigate();
  const [isCountryIndia, setIsCountryIndia] = useState();

  var countryCode = getCountryCode();
  console.log("countryCode", countryCode);
  useEffect(() => {
    if (countryCode === "IN") {
      setIsCountryIndia(true);
    } else {
      setIsCountryIndia(false);
    }
  }, []);

  const onClickSubscribe = () => {
    if (!currentUser) {
      if (type === "Platinum") {
        sessionStorage.setItem("subscriptionID", 5);
      } else if (type === "Premium") {
        if (isCountryIndia) {
          sessionStorage.setItem("subscriptionID", 7);
        } else {
          sessionStorage.setItem("subscriptionID", 9);
        }
      } else if (type === "Super") {
        if (isCountryIndia) {
          sessionStorage.setItem("subscriptionID", 6);
        } else {
          sessionStorage.setItem("subscriptionID", 10);
        }
      }
      localStorage.setItem("retrunurl", `/subscription-order-summary`);
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    } else {
      if (type === "Platinum") {
        sessionStorage.setItem("subscriptionID", 5);
      } else if (type === "Premium") {
        if (isCountryIndia) {
          sessionStorage.setItem("subscriptionID", 7);
        } else {
          sessionStorage.setItem("subscriptionID", 9);
        }
      } else if (type === "Super") {
        if (isCountryIndia) {
          sessionStorage.setItem("subscriptionID", 6);
        } else {
          sessionStorage.setItem("subscriptionID", 10);
        }
      }
    }
  };

  return (
    <div className="WhyTrustUs--Area">
      <div className="WhyTrustUs--Top">
        <div className="Desc--Area">
          <h4>
            Your <span>Trust</span> Matters to us
          </h4>
          <br />
          <p>
            <ul>
              <li>
                Enjoy <b>The UWC Experience,</b> where your well-being is our
                priority.
              </li>
              <li>
                Experience wellness your way with diverse offerings and flexible
                options.
              </li>
              <li>
                What you see is what you pay. No hidden costs, no surprises
              </li>
            </ul>
          </p>
        </div>

        <div className="Images--Area">
          <img src="/assets/img/planDetails/Picture1.png" />
          <img src="/assets/img/planDetails/Picture2.png" />
          <img src="/assets/img/planDetails/Picture3.png" />
        </div>
      </div>
      <div className="WhyTrustUs--Bottom">
        <div className="Desc--Area">
          <h4>10M+</h4>
          <h6 className="me-md-5">
            Global Conversations with <br />
            Stella. Bridging Boundaries, One Conversation at a Time!
          </h6>
          <button onClick={() => onClickSubscribe()}>
            Join them and start
          </button>
        </div>
        <picture>
          <source
            media="(min-width:465px)"
            srcset="/assets/img/planDetails/TrustBG.png"
          />
          <img src="/assets/img/planDetails/TrustBGAlt.png" />
        </picture>
        <img src="/assets/img/planDetails/globalMap.png" className="WorldMap" />
      </div>
    </div>
  );
};
