import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//Icons
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import moment from "moment";

//Upload
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../firebaseConfig'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const languageList = [
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
  { value: "Bengali", label: "Bengali" },
  { value: "Japanese", label: "Japanese" },
  { value: "Korean", label: "Korean" },
];

const EditProfile = ({
  instituteID,
  data: {
    address = "",
    city = "",
    country_id = "",
    country_phone_code = "",
    dob = "",
    email = "",
    email_verified = false,
    first_name = "",
    middle_name = "",
    last_name = "",
    gender_id = "",
    id_proof_link = "",
    id_proof_type_id = "",
    image_link = "",
    landmark = "",
    mobile = "",
    state_id = "",
    timezone = "",
    zip = "",
  } = {},
}) => {
  const [gender, setGender] = useState(0);
  const [selectedDays, setSelectedDays] = useState([]);
  const [country, setCountry] = useState(country_id);
  const [firstName, setFirstName] = useState(first_name);
  const [middleName, setMiddleName] = useState(middle_name);
  const [lastName, setLastName] = useState(last_name);
  const [mobileNumber, setMobileNumber] = useState();

  const [addressDetails, setAddressDetails] = useState(address);
  const [landmarkDetails, setLandmarkDetails] = useState(landmark);
  const [cityName, setCityName] = useState(city);
  const [stateID, setStateID] = useState(0);
  const [zipCode, setZipCode] = useState(0);

  const [countryList, setCountryList] = useState([]);
  const [proofIDList, setproofIDList] = useState([]);
  const [proofName, setProofName] = useState();

  const [stateList, setStateList] = useState([]);
  const [countryCallingCode, setCountryCallingCode] = useState("");

  const [profilePicture, setProfilePicture] = useState(image_link);
  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const defaultImage = "/assets/img/default-profile.jpg";

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const userID = JSON.parse(localStorage.getItem("profile")).UserId;

  //When values are poplulated
  useEffect(() => {
    setFirstName(first_name);
    setMiddleName(middle_name);
    setLastName(last_name);
    setAddressDetails(address);
    setProfilePicture(image_link);
    setMobileNumber(mobile);
    setStateID(state_id);
    setCityName(city);
    setZipCode(zip);
  }, [first_name]);

  //Countries List
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://us.uwc.world/new-uwc/country", {
          method: "GET",
          headers,
        });

        const postResponse = await response.json();
        setCountryList(postResponse.data.countries);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCountries();
  }, [country]);

  //States List
  useEffect(() => {
    const fetchStates = async () => {
      try {
        let countryItem = countryList?.filter(
          (item) => item.id === country_id
        );
        let countryCode = countryItem[0]?.code;

        const response = await fetch(
          `https://us.uwc.world/new-uwc/state/${countryCode}`,
          {
            method: "GET",
            headers,
          }
        );

        const postResponse = await response.json();
        const statesArray = await postResponse.data?.states;

        setStateList(statesArray);
      } catch (error) {
        console.error(error);
      }
    };

    fetchStates();
  }, [countryList]);

  //Country Calling Code
  useEffect(() => {
    const fetchCountryCallingCode = async () => {
      try {
        let countryItem = countryList?.filter((item) => item.id === country_id);
        let countryCode = countryItem[0]?.code;

        const response = await fetch(
          `https://us.uwc.world/new-uwc/country/call-code/${countryCode}`,
          {
            method: "GET",
            headers,
          }
        );

        const postResponse = await response.json();
        const phoneCode = await postResponse?.data?.phone_code;

        setCountryCallingCode(phoneCode);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCountryCallingCode();
  }, []);

  //ID Proof
  useEffect(() => {
    const fetchIDs = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/registration/id-proofs`,
          {
            method: "GET",
            headers,
          }
        );

        const postResponse = await response.json();

        setproofIDList(postResponse?.data.id_proofs);
      } catch (error) {
        console.error(error);
      }
    };

    fetchIDs();
  }, []);

  useEffect(() => {
    const proofItem = proofIDList?.filter(
      (item) => item.id == id_proof_type_id
    );
    setProofName(proofItem[0]?.name);
  }, [proofIDList]);


  //Firebase login
  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        console.log("SIGNED IN")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }, [])
  const handleSelectlanguages = (event) => {
    setSelectedDays(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  //Profile Picture
  const handleChangeProfilePic = async (event) => {

    try {
      let file = Array.from(event.target.files)[0];

      if (
        file?.type === "image/png" ||
        file?.type === "image/jpg" ||
        file?.type === "image/jpeg"
      ) {
        const dirName = moment().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          (error) => {
            console.error("Upload error:", error);
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);

              setProfilePicture(url)
            } catch (error) {
              console.error("Error getting download URL:", error);
            }
          }
        );
      } else {
        console.error("Incorrect file format.");
      }
    } catch (error) {
      console.error("Error handling file upload:", error);
    }


  };

  const handleDeleteProfilePic = () => {
    setProfilePicture(defaultImage);
  };
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleMiddleNameChange = (e) => {
    setMiddleName(e.target.value);
  };
  const handleLasttNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleAddress = (e) => {
    setAddressDetails(e.target.value);
  };

  const handleMobileChange = (e) => {

    const { value } = e.target;
    const isBackspace = e.nativeEvent.inputType === "deleteContentBackward";

    if (isBackspace) {
      setMobileNumber(value);
    } else {
      setMobileNumber(`${value}`);
    }
  };

  const handleStateChange = (e) => {
    setStateID(e.target.value);
  };

  const handleCityChange = (e) => {
    setCityName(e.target.value);
  };

  const handleZipChange = (e) => {
    setZipCode(e.target.value);
  };

  const handleUpdateProfile = async () => {
    const payload = {
      uwc_user_id: userID,
      email: email,
      mobile: mobileNumber,
      image_link: profilePicture == defaultImage ? "" : profilePicture,
      address: addressDetails,
      state_id: stateID,
      city: cityName,
      zip: zipCode,
      country_phone_code: countryCallingCode,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/update-profile-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const reponseCode = await postResponse.code;

      if (postResponse.code == 200) {
        setSnackbarMessage(postResponse?.data?.message);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="Content--Section">
        <div className="mt-4 d-flex align-items-center gap-3">
          <img
            src={profilePicture}
            width={120}
            height={120}
            style={{ borderRadius: "20px", objectFit: "cover" }}
          />

          {
            instituteID !== "93" && (
              <div>
                <Button
                  component="label"
                  htmlFor="profile-pic-upload"
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#414141",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    textTransform: "capitalize",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "200px",
                  }}
                >
                  <input
                    id="profile-pic-upload"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleChangeProfilePic}
                  />
                  <ImageOutlinedIcon />
                  Change Profile Pic
                </Button>
                <Button
                  onClick={handleDeleteProfilePic}
                  sx={{
                    backgroundColor: "#D2293D1A",
                    color: "#D2293D",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    textTransform: "capitalize",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "200px",
                    marginTop: "20px",
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                  Delete Profile Pic
                </Button>
              </div>
            )
          }

        </div>
      </div>
      <div className="Content--Section">
        <h6>Personal Details</h6>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={4}>
            <TextField
              id="firstname"
              label="First Name"
              value={firstName}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="middlename"
              label="Middle Name (Optional)"
              variant="outlined"
              fullWidth
              value={middleName}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="lastname"
              label="Last Name"
              variant="outlined"
              fullWidth
              value={lastName}
              disabled
            />
          </Grid>

          {/* Gender */}
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select
                fullWidth
                labelId="gender-select-label"
                id="gender-select"
                value={gender_id}
                label="Gender"
                disabled
              >
                <MenuItem value={0}>Male</MenuItem>
                <MenuItem value={1}>Female</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Email */}
          <Grid item xs={4}>
            <TextField
              id="email"
              label=""
              value={email}
              variant="outlined"
              fullWidth
              disabled
              //defaultValue={""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {email_verified && (
                      <DoneOutlinedIcon sx={{ color: "#55A51D" }} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* DOB */}
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(dob)}
                label="Date of birth"
                sx={{ width: "100%", paddingTop: "0" }}
                disabled
              />
            </LocalizationProvider>
          </Grid>

          {/* Language */}
          
        </Grid>
      </div>

      {/* ID Proof */}
      <div className="Content--Section">
        <h6>ID Proof Submitted</h6>
        <div className="UploadedFiles--Section">
          <TextField
            fullWidth
            id="uploaded-basic"
            variant="outlined"
            value={proofName}
            className="mt-2"
            disabled={instituteID == "93" ? true : false}
          />
        </div>
      </div>

      <div className="Content--Section">
        <h6>Address Details</h6>

        {/* Address */}
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={4}>
            <TextField
              id="address"
              label="Address"
              variant="outlined"
              fullWidth
              value={addressDetails}
              onChange={handleAddress}
              disabled={instituteID == "93" ? true : false}
            />
          </Grid>

          {/* Landmark */}
          <Grid item xs={4}>
            <TextField
              id="landmark"
              label="Landmark (Optional)"
              variant="outlined"
              value={landmarkDetails}
              disabled={instituteID == "93" ? true : false}
              fullWidth
            />
          </Grid>

          {/* Country */}
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="country-select-label">Country</InputLabel>
              <Select
                fullWidth
                labelId="country-select-label"
                id="country-select"
                value={country_id || ""}
                label="country"
                disabled
              >
                {countryList?.map((country) => {
                  return (
                    <MenuItem key={country.code} value={country.id}>
                      {country.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {/* State */}
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="state-select-label">State</InputLabel>
              <Select
                fullWidth
                labelId="state-select-label"
                disabled={instituteID == "93" ? true : false}
                id="state-select"
                value={stateID || ""}
                label="state"
                onChange={handleStateChange}
              >
                {stateList?.map((item) => {
                  return (
                    <MenuItem key={item.code} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {/* City */}
          <Grid item xs={4}>
            <TextField
              id="city"
              label="City"
              value={cityName}
              disabled={instituteID == "93" ? true : false}
              onChange={handleCityChange}
              defaultValue=""
              variant="outlined"
              fullWidth
            />
          </Grid>

          {/* Timezone */}
          <Grid item xs={4}>
            <TextField
              id="timezone"
              label="Timezone"
              value={timezone}
              disabled={instituteID == "93" ? true : false}
              defaultValue=""
              variant="outlined"
              fullWidth
            />
          </Grid>

          {/* Zipcode */}
          <Grid item xs={4}>
            <TextField
              id="zipcode"
              label="Zip Code"
              value={zipCode}
              onChange={handleZipChange}
              disabled={instituteID == "93" ? true : false}
              defaultValue=""
              variant="outlined"
              inputProps={{ inputMode: "numeric" }}
              fullWidth
            />
          </Grid>

          {/* Mobile */}
          <Grid item xs={4}>
            <TextField
              id="mobile"
              label="Mobile (Optional)"
              variant="outlined"
              value={mobileNumber}
              defaultValue={""}
              onChange={handleMobileChange}
              disabled={instituteID == "93" ? true : false}
              InputProps={{
                inputMode: "numeric",
                startAdornment: (
                  <InputAdornment position="start">
                    {countryCallingCode || "+91"}
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
        </Grid>


        {
          instituteID !== "93" && (
            <Button
              variant="contained"
              disableElevation
              onClick={handleUpdateProfile}
              className="PrimaryCTA"
              sx={{ marginLeft: "auto", display: "block", marginTop: "30px" }}
            >
              Update Profile
            </Button>
          )
        }

      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditProfile;
