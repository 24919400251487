import { Helmet } from 'react-helmet';
import MyAppointments from '../../components/user/MyAppointments';

const MyAppointmentsDetailsPage = () => {
    return (
        <>
            <Helmet>
                <title>
                    View Your Scheduled Appointments | United We Care Dashboard
                </title>
            </Helmet>
            <MyAppointments />
        </>
    )
}

export default MyAppointmentsDetailsPage;