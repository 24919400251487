import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";

const Video = ({ item }) => {
  console.log("itemitem", item);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { currentUser } = useAuth();
  let userId = currentUser?.UserId;
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const timestamp = item?.createDateIST;

  const dateObject = new Date(timestamp);

  const day = String(dateObject.getDate()).padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateObject.getMonth()];
  const year = dateObject.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  console.log(formattedDate);

  return (
    <div
      className="videoListing--module position-relative gap-2"
      style={{ borderBottom: "1px solid #E4E4E4", background: "#FFF" }}
      onClick={() => navigate(`/community/feed/video/${item?.id}`)}
    >
      <img src={item?.smallThumbnailUrl} alt="" className="w-100" />
      <div className="my-2 mx-1 d-flex flex-column justify-content-between gap-3">
        <div className="d-flex align-items-center justify-content-between">
          <h6>{item?.title}</h6>
          <a onClick={handleMenuClick}>
            <MoreVertIcon />
          </a>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {userId !== item?.data?.user?.userId ? (
              <MenuItem onClick={handleClose}>Report</MenuItem>
            ) : (
              <MenuItem onClick={handleClose}>Delete</MenuItem>
            )}
          </Menu>
        </div>
        <div className="d-flex justify-content-between">
          <p>{formattedDate}</p>
          <div className="clubDiv">{item?.club?.name}</div>
        </div>
      </div>
      <span className="video-duration">{item?.duration}</span>
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="video-playBtn"
      >
        <rect y="0.799805" width="36" height="36" rx="18" fill="#73589B" />
        <path
          d="M23.5996 18.9996C23.5999 19.1406 23.5643 19.2794 23.4962 19.4023C23.4281 19.5252 23.3299 19.6281 23.211 19.7011L15.8432 24.2773C15.719 24.3546 15.5768 24.3967 15.4311 24.3995C15.2855 24.4022 15.1418 24.3654 15.0148 24.2929C14.8891 24.2215 14.7844 24.1174 14.7114 23.9913C14.6384 23.8652 14.5998 23.7216 14.5996 23.5754V14.4238C14.5998 14.2776 14.6384 14.134 14.7114 14.0079C14.7844 13.8818 14.8891 13.7777 15.0148 13.7063C15.1418 13.6338 15.2855 13.597 15.4311 13.5998C15.5768 13.6025 15.719 13.6447 15.8432 13.7219L23.211 18.2982C23.3299 18.3711 23.4281 18.474 23.4962 18.5969C23.5643 18.7199 23.5999 18.8586 23.5996 18.9996Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default Video;
