import { Routes, Route } from 'react-router-dom';
import MyAppointmentPage from './MyAppointmentPage';
import MyAppointmentDetailsPage from './MyAppointmentDetailsPage';
import CourseRouting from './Course';
import UserDashboardChat from '../../components/user/UserDashboardChat';

const DashRouting = () => {
    return (
        <Routes>
            <Route path='/program/*' element={<CourseRouting />} />
            <Route path='my-appointments' element={<MyAppointmentPage />} />
            <Route path='my-appointments/:id' element={<MyAppointmentDetailsPage />} />
            <Route path='chat/:id' element={<UserDashboardChat />} />
        </Routes>
    )
}

export default DashRouting;