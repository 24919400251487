import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useAuth } from "../../auth";
import { useNavigate, useParams } from "react-router";
import { courseData } from "./core/models";
import {
  get_course_details_web_by_Id,
  Course_Page_Tracking,
  CreateTransactionService,
  OODOCoursePaymentService,
  UpdateTransactionV2Service,
  UseCouponCodeService,
  Course_CreateTr_Stripe,
  updatePhoneNumber,
} from "../core/_request";
import { getCountryCode, getCurrencyCode } from "../core/commonFun";
import { useContext } from "react";
import { AppContext } from "../../context";
import StripeCheckout from "react-stripe-checkout";
import { useEffect } from "react";
import { useSnackbar } from "notistack";

function loadRazorpayScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const CourseOrderPreview = () => {
  const [couponloading, setCouponloading] = useState(false);
  const [couponCode, setCouponCode] = useState();
  const [mainAmountAfterCoupone, setMainAmountAfterCoupone] = useState();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  let { id } = useParams();
  const data = courseData.find((o) => o.id == id);
  const countryCode = getCountryCode();
  const [number, setNumber] = useState();
  const [course_details, setCourse_details] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    get_course_details_web_by_Id(id).then((resp) => {
      setCourse_details(resp?.data);
    });

    if (!currentUser) {
      localStorage.setItem("retrunurl", `/program/program-order-preview/${id}`);
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      var body = {
        Course_Id: id,
        Course_Name: course_details?.name,
        Thumbnail_Image: course_details?.video?.small_thumbnail_url,
        Course_Duration: course_details?.duration,
        Language: course_details?.languages,
      };
      Course_Page_Tracking(body).then((resp) => { });
    }
  }, [currentUser]);

  const LoginPopup = () => {
    // setShowLloginPopup(true);
    const currentUrl = encodeURIComponent(window.location.href);
    window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
  };

  const checkout = () => {
    if (!currentUser) {
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    } else {
      if (countryCode == "IN") {
        var requestt = { userId: currentUser?.UserId, courseId: data?.id };
        OODOCoursePaymentService(requestt).then((_) =>
          navigate("/program/payment-status/success")
        );
      } else {
        displayRazorpay(course_details?.usd_price, data);
      }
    }
  };

  const displayRazorpay = async (amount, Package) => {
    debugger;
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      enqueueSnackbar("Razorpay SDK failed to load. Are you online?", {
        variant: "error",
      });
      return;
    }

    var trequest = { Type: 1, RefId: Package.id, Amount: amount };
    console.log("trequest", trequest);
    var Tr = await CreateTransactionService(trequest);
    console.log("TRRRRR", Tr);
    if (Tr.ResponseCode == 401) {
      logout();
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    }
    if (Tr.ResponseCode !== 200) {
      enqueueSnackbar(Tr.ResponseMessage, { variant: "error" });
      return;
    }

    const options = {
      key: "rzp_live_Gft79xyY3ppWNz",
      amount: amount * 100,
      currency: countryCode == "IN" ? "INR" : "USD",
      name: currentUser?.Name,
      description: Package.name,
      handler: async function (response) {
        var uBody = {
          TxnId: Tr.data.TxnId,
          PaymentId: response.razorpay_payment_id,
        };
        UpdateTransactionV2Service(uBody).then((uresp) => {
          if (uresp.ResponseCode === 200) {
            var requestt = { userId: currentUser.UserId, courseId: Package.id };
            OODOCoursePaymentService(requestt).then((_) =>
              navigate("/dashboard/program/my-programs")
            );
          } else {
            enqueueSnackbar(uresp.ResponseMessage, { variant: "error" });
          }
        });
      },
      modal: {
        ondismiss: function () {
          navigate("/program/payment-status/failed");
        },
      },
      prefill: {
        name: currentUser?.Name,
        email: currentUser?.Emailid,
        contact: number ?? currentUser?.MobileNo,
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const Stripe_Payment = (token) => {
    onChangeNumber();
    Course_CreateTr_Stripe(
      JSON.stringify({
        stripeToken: token.id,
        amount:
          countryCode === "IN"
            ? course_details?.inr_price
            : course_details?.usd_price,
        currency: countryCode === "IN" ? "INR" : "USD",
        Type: 1,
        RefId: data?.id,
      })
    ).then((resp) => {
      console.log("Course_CreateTr_Stripe resp", resp);
      if (resp.ResponseCode === 200) {
        var requestt = { userId: currentUser?.UserId, courseId: data?.id };
        OODOCoursePaymentService(requestt).then((_) =>
          navigate("/dashboard/program/my-programs")
        );
      } else {
        navigate("/program/payment-status/failed");
      }
    });
  };

  const onChangeNumber = () => {
    var body = {
      MobileNo: number,
    };
    updatePhoneNumber(body).then((resp) => {
      if (resp.data == "") {
      }
    });
  };

  return (
    <div
      className="d-block mx-auto"
      style={{
        backgroundSize: "100% 300px",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url("/assets/img/gray_bg.png")`,
      }}
    >
      <h5 className="font-inter fs-4 font-w600 text-center py-4">
        Complete you Order
      </h5>
      <div
        className="col-md-7 h-75 bg-white mx-auto px-2 px-md-5 py-3 rounded-2"
        style={{ background: "#fff", border: "1px solid #E2E2E1" }}
      >
        <div
          className="rounded-3 py-3 my-3 px-3 d-flex flex-column gap-3 "
          style={{ border: "1px solid #C4E5F9", background: "#EEF9FF" }}
        >
          <p className="font-inter font-w600 fs-5 text-black">{data?.name}</p>
          <div className="d-flex">
            <div
              className="d-flex gap-2 align-items-center justify-content-start"
              style={{ marginRight: 30 }}
            >
              <img src="/assets/img/hourglass_blue_icon.png" alt="" />
              <p
                className="font-inter font-w500 fs-6"
                style={{ color: "#0E0F0C" }}
              >
                {data?.courseDuration}
              </p>
            </div>
            <div className="d-flex gap-2 align-items-center justify-content-start">
              <img src="/assets/img/language_translation_blue.png" alt="" />
              <p
                className="font-inter font-w500 fs-6 lh-lg"
                style={{ color: "#0E0F0C" }}
              >
                {data?.language}
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-md-5">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <p className="font-inter font-w500 text-black fs-5 col-4">
                Fee Details
              </p>
              <hr className=" col-7" />
            </div>
            <div className="align-self-end">
              <p
                className="font-inter fs-6 d-flex justify-content-between"
                style={{ color: "#494747" }}
              >
                Program Fees :
                <span className="font-inter fs-6 text-black font-w500">
                  {" "}
                  {(countryCode === "IN" && (
                    <> ₹ {course_details?.inr_price} </>
                  )) || <> $ {course_details?.usd_price} </>}{" "}
                </span>
              </p>
              <p
                className="font-inter fs-6 d-flex justify-content-between"
                style={{ color: "#494747" }}
              >
                Final Amount :{" "}
                <span className="font-inter fs-6 text-black font-w500">
                  {(countryCode === "IN" && (
                    <>₹ {course_details?.inr_price}</>
                  )) || <>$ {course_details?.usd_price}</>}
                </span>
              </p>
            </div>
            <div
              className=" d-flex justify-content-between justify-content-md-between rounded-2 my-3"
              style={{ border: "1px solid #D9D9D9", borderRight: "none" }}
            >
              <input
                className="rounded-2 border-0"
                type="text"
                placeholder="Discount / Referral code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                style={{ height: "45px", paddingLeft: 7 }}
              />
              <Button
                className="font-inter fs-6 text-black border-0 px-md-4 py-2"
                style={{ background: "#C4E5F9" }}
              >
                Apply
              </Button>
            </div>
          </div>
          <div className="col-md-5">
            <div className="d-flex">
              <p className="font-inter font-w500 text-black fs-5 col-4">
                Bill To
              </p>
              <hr className=" col-8" />
            </div>
            <div className="align-self-end">
              <p
                className="font-inter fs-6 d-flex justify-content-between"
                style={{ color: "#494747" }}
              >
                Name :
                <span className="font-inter fs-6 text-black font-w500">
                  {currentUser?.Name}
                </span>
              </p>
              <p
                className="font-inter fs-6 d-flex justify-content-between"
                style={{ color: "#494747" }}
              >
                Email :
                <span className="font-inter fs-6 text-black font-w500">
                  {currentUser?.Emailid}
                </span>
              </p>
            </div>
            <div
              className=" d-flex justify-content-between justify-content-md-between my-2 rounded-2"
              style={{ border: "1px solid #D9D9D9" }}
            >
              <input
                className="rounded-2 border-0"
                type="tel"
                placeholder={currentUser?.MobileNo ?? "Phone No."}
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                style={{ height: "45px", width: "80%", paddingLeft: 7 }}
              />
              <Button
                className="font-inter fs-6 text-black border-0"
                style={{
                  background: "#fff",
                  borderRightWidth: 1,
                  borderRightColor: "#D9D9D9",
                }}
              >
                Mobile
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center py-3 px-4 gap-3">
        {(!currentUser && (
          <Button
            className="px-4 col-12 col-md-1 w-auto rounded-5"
            style={{ background: "#9FE770", border: "none", color: "#000" }}
            onClick={LoginPopup}
          >
            Make Payment
          </Button>
        )) ||
          (countryCode === "IN" && (
            <Button
              className="px-4 col-12 col-md-1 w-auto rounded-5"
              style={{ background: "#9FE770", border: "none", color: "#000" }}
              onClick={checkout}
            >
              Make Payment
            </Button>
          )) || (
            <StripeCheckout
              token={Stripe_Payment}
              stripeKey="pk_live_51KjhlAKti5B1FKZIDKf4UDgvU8Wug8B8zEJ2Tu2CRmil6w4wzGe9oAAX1uW1E0LFZMfur20TCubjuAWvtDRrXKxb00KYCoOflT"
              name={currentUser?.Name}
              description={data?.name}
              image="https://app.uwc.world/Content/adminlogo.png"
              ComponentClass="div"
              panelLabel="Pay"
              amount={
                (countryCode === "IN"
                  ? course_details?.inr_price
                  : course_details?.usd_price) * 100
              }
              currency={countryCode === "IN" ? "INR" : "USD"}
              email={currentUser?.Emailid}
              locale="auto"
              allowRememberMe
            >
              <Button
                className="px-4 col-12 col-md-1 w-auto rounded-5"
                style={{ background: "#9FE770", border: "none", color: "#000" }}
              >
                Make Payment
              </Button>
            </StripeCheckout>
          )}
      </div>
    </div>
  );
};

export default CourseOrderPreview;
