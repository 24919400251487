import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import StopIcon from "@mui/icons-material/Stop";
import CloseIcon from '@mui/icons-material/Close';

const WallPaper = styled('div')({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'hidden',
    background: '#fff',
    borderRadius: '10px',
});

const Widget = styled('div')(({ theme }) => ({
    padding: 16,
    borderRadius: 16,
    width: 373,
    maxWidth: '100%',
    margin: 'auto',
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#D9C4E3',
    backdropFilter: 'blur(40px)',
}));

const CoverImage = styled('div')({
    width: 100,
    height: 100,
    objectFit: 'cover',
    overflow: 'hidden',
    flexShrink: 0,
    borderRadius: 8,
    backgroundColor: 'rgba(0,0,0,0.08)',
    '& > img': {
        width: '100%',
        height: '100%'
    },
});

const TinyText = styled(Typography)({
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: 0.2,
    color: '#73589B'
});

const formatTime = (seconds) => {
    const pad = (num, size) => num.toString().padStart(size, '0');
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${pad(minutes, 2)}:${pad(remainingSeconds, 2)}`;
};

export default function PodcastPopup({ data, onClose }) {
    console.log("data", data);
    const audioRef = React.useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(50);
    const [seekPosition, setSeekPosition] = useState(0);
    const [duration, setDuration] = useState(0);
    const mainIconColor = '#000';
    const lightIconColor = 'rgba(0,0,0,0.4)';

    useEffect(() => {
        const audio = audioRef.current;

        if (audio) {
            const setAudioData = () => {
                setDuration(audio.duration); // Set duration as number
                setSeekPosition(audio.currentTime);
            };

            const updateSeekPosition = () => {
                setSeekPosition(audio.currentTime);
            };

            audio.addEventListener("loadedmetadata", setAudioData);
            audio.addEventListener("timeupdate", updateSeekPosition);

            // Return a cleanup function that removes the event listeners
            return () => {
                if (audio) { // Check if audio is not null
                    audio.removeEventListener("loadedmetadata", setAudioData);
                    audio.removeEventListener("timeupdate", updateSeekPosition);
                }
            };
        }
    }, []);

    const togglePlayPause = () => {
        if (audioRef.current.paused) {
            audioRef.current.play();
            setIsPlaying(true);
        } else {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const stopAudio = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
    };

    const handleVolumeChange = (event, newValue) => {
        setVolume(newValue);
        audioRef.current.volume = newValue / 100;
    };

    const handleMuteToggle = () => {
        // Improved mute toggle logic
        if (volume === 0) {
            setVolume(50); // Reset to 50% if previously muted
        } else {
            setVolume(0); // Mute
        }
        audioRef.current.volume = volume === 0 ? 0.5 : 0; // Toggle volume
    };

    const handleVolumeUp = () => {
        // Improved volume up logic
        const newVolume = volume < 100 ? 100 : volume; // Increase to 100 if not already
        setVolume(newVolume);
        audioRef.current.volume = newVolume / 100;
    };

    const handleSeekChange = (event, newValue) => {
        setSeekPosition(newValue);
        audioRef.current.currentTime = newValue;
    };

    const close = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onClose();
    };

    return (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <audio
                ref={audioRef}
                src={data?.audioUrl}
            // src={'data?.audioUrl'}
            />
            <Widget>
                {/* <IconButton onClick={close} style={{ color: "#73589b", position: "absolute", top: "5px", right: "10px" }}><CloseIcon /></IconButton> */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CoverImage>
                        <img
                            alt="can't win - Chilling Sunday"
                            src={data?.defaultImageUrl}
                        />
                    </CoverImage>
                    <Box sx={{ ml: 1.5, minWidth: 0 }}>
                        <Typography noWrap>
                            <b>{data?.title}</b>
                        </Typography>
                        <Typography noWrap letterSpacing={-0.25}>
                            {data?.description}
                        </Typography>
                    </Box>
                </Box>
                <Slider
                    value={seekPosition}
                    max={duration} // Use raw duration for calculations
                    onChange={(e, newValue) => {
                        setSeekPosition(newValue); // Update current time as number
                        audioRef.current.currentTime = newValue;
                    }}
                    aria-labelledby="continuous-slider"
                    min={0}
                    step={1}
                    sx={{
                        color: 'rgba(0,0,0,0.87)',
                        height: 4,
                        '& .MuiSlider-thumb': {
                            width: 8,
                            height: 8,
                            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                            '&::before': {
                                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                            },
                            '&:hover, &.Mui-focusVisible': {
                                boxShadow: `0px 0px 0px 8px 'rgb(0 0 0 / 16%)'
                                    }`,
                            },
                            '&.Mui-active': {
                                width: 20,
                                height: 20,
                            },
                        },
                        '& .MuiSlider-rail': {
                            opacity: 0.28,
                        },
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: -2,
                    }}
                >
                    <TinyText>{formatTime(seekPosition)}</TinyText>
                    <TinyText>-{formatTime(duration)}</TinyText>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: -1,
                    }}
                >
                    {/* <IconButton onClick={stopAudio}>
                        <StopIcon sx={{ fontSize: '1.5rem' }} htmlColor={'#000'} />
                    </IconButton> */}
                    <IconButton
                        onClick={togglePlayPause}
                    >
                        {
                            !isPlaying ? (
                                <PlayArrowRounded
                                    sx={{ fontSize: '2.5rem' }}
                                    htmlColor={'#000'}
                                />
                            ) : (
                                <PauseRounded sx={{ fontSize: '2.5rem' }} htmlColor={'#000'} />
                            )
                        }
                    </IconButton>
                </Box>
                <Stack spacing={1} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <VolumeDownRounded htmlColor={lightIconColor} onClick={handleMuteToggle} />
                    <Slider
                        value={volume}
                        onChange={handleVolumeChange}
                        aria-labelledby="continuous-slider"
                        min={0}
                        max={100}
                        step={1}
                        sx={{
                            color: 'rgba(0,0,0,0.87)',
                            '& .MuiSlider-track': {
                                border: 'none',
                            },
                            '& .MuiSlider-thumb': {
                                width: 24,
                                height: 24,
                                backgroundColor: '#fff',
                                '&::before': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                },
                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                    boxShadow: 'none',
                                },
                            },
                        }}
                    />
                    <VolumeUpRounded htmlColor={lightIconColor} onClick={handleVolumeUp} />
                </Stack>
            </Widget>
            <WallPaper />
        </Box>
    );
}