import React, { useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppContext } from "../../../context";
import FollowUpTable from "./FollowUpTable";
import AutogeneratedReviewBar from "../component/AutogeneratedReviewBar";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
// Mock data for Lab Test Autocomplete field
const labTests = [{ label: "Test 1" }, { label: "Test 2" }]; // Replace with actual data
const followUpDate = [{ label: "1" }, { label: "2" }, { label: "3" }]; // Replace with actual data

const SessionLabAdviceTable = ({
  isWebsocketDataRecieved,
  aiGenerated,
  genTest,
  sessionStatus,
}) => {
  const [tableRows, setTableRows] = useState([
    {
      test_name: "",
      sample_requirement: "",
      storage_transport_temperature: "",
      acceptable_alternative_specimen: "",
      other_requirements: "",
      causes_for_rejection: "",
      time_to_result: "",
    },
  ]);
  const [advice, setadvice] = useState([]);
  const [adviceView, setadviceView] = useState(false);
  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);
  const [generated, setGenerated] = useState(false);

  useEffect(() => {
    setadvice(genTest[0].prescribed_lab_test);
  }, [genTest]);

  useEffect(() => {
    if (isWebsocketDataRecieved) {
      setTableRows(expertDashboardData?.session_data?.lab_test);
    }
  }, [isWebsocketDataRecieved]);

  useEffect(() => {
    setGenerated(aiGenerated);
  }, [aiGenerated]);

  const handleInputChange = (rowIndex, field, value) => {
    setTableRows((prevRows) => {
      const updatedRows = prevRows.map((row, index) =>
        index === rowIndex ? { ...row, [field]: value } : row
      );

      setExpertDashboardData((prevData) => ({
        ...prevData,
        session_data: {
          ...prevData.session_data,
          lab_test: updatedRows,
        },
      }));

      return updatedRows;
    });
  };

  const addNewRow = () => {
    const newRow = {
      test_name: "",
      sample_requirement: "",
      storage_transport_temperature: "",
      acceptable_alternative_specimen: "",
      other_requirements: "",
      causes_for_rejection: "",
      time_to_result: "",
    };
    setTableRows([...tableRows, newRow]);
    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        lab_test: [...tableRows, newRow],
      },
    }));
  };

  const handleDeleteRow = (rowIndex) => {
    const updatedRows = tableRows.filter((_, index) => index !== rowIndex);
    setTableRows(updatedRows);
    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        lab_test: updatedRows,
      },
    }));
  };

  const onTickClick = () => {
    setGenerated(false);
  };

  const onCrossClick = () => {
    setTableRows([{ test_name: "", comments: "" }]);
    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        lab_test: [],
      },
    }));
  };

  return (
    <div className="SessionLabAdvice--Table p-0 ">
      <div
        style={{ border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}
        className=" p-3"
      >
        {generated && !sessionStatus && (
          <div className="my-2">
            <span
              style={{
                color: "#305FB8",
                width: "fit-content",
                border: "1px solid",
                backgroundColor: "rgba(48, 95, 184,0.2)",
              }}
              className="d-flex fw-bold px-3 gap-2 align-items-center   rounded-5 p-1"
            >
              <img
                alt=""
                src="/assets/img/icons/sparkle_blue.svg"
                width={16}
                className=""
                color="#305FB8"
              />
              <span>AI Generated</span>
            </span>
          </div>
        )}
        {!generated ? (
          <div className="Table-span-3 d-flex align-items-center">
            {isWebsocketDataRecieved ? (
              <button
                onClick={() => setGenerated(true)}
                className=" border-0 mx-1"
                style={{ backgroundColor: "white" }}
                disabled={expertDashboardData?.session_data?.submitted}
              >
                <AutoFixHighIcon
                  sx={{ color: "#6C46A0", width: "30px", height: "30px" }}
                />
              </button>
            ) : (
              <div
                style={{ marginRight: "8px", marginLeft: "5px" }}
                className="d-flex align-items-center justify-content-center my-3"
              >
                <img
                  alt=""
                  src="/assets/img/icons/sparkling.png"
                  width={15}
                  className="animate-pulse"
                />
              </div>
            )}
            <h6 className="">Lab Advice </h6>
          </div>
        ) : (
          <h6 className="">Lab Advice </h6>
        )}
        <div
          style={{ gridTemplateColumns: "repeat(7, minmax(0, 1fr))" }}
          className="Table--Body gap-3 mt-2"
        >
          <div className="fw-semibold">Lab Test</div>
          <div className=" fw-semibold">Sample Requirement</div>
          <div className=" fw-semibold">Storage Temperature</div>
          <div className=" fw-semibold">Alternative Specimen</div>
          <div className="fw-semibold">Other Requirements</div>
          <div className=" fw-semibold">Causes for Rrejection</div>
          <div className="fw-semibold">Time to result</div>
        </div>

        {tableRows &&
          tableRows.map((row, index) => {
            return (
              <div
                style={{ gridTemplateColumns: "repeat(7, minmax(0, 1fr))" }}
                className="Table--Body gap-2 mt-2"
                key={index}
              >
                <div className="Table-span-1">
                  <TextField
                    fullWidth
                    value={row.test_name}
                    onChange={(e) =>
                      handleInputChange(index, "test_name", e.target.value)
                    }
                    label=""
                    disabled={expertDashboardData?.session_data?.submitted}
                  />
                </div>
                <div className="Table-span-1  ">
                  <TextField
                    fullWidth
                    value={row.sample_requirement}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "sample_requirement",
                        e.target.value
                      )
                    }
                    label=""
                    disabled={expertDashboardData?.session_data?.submitted}
                  />
                </div>
                <div className="Table-span-1 ">
                  <TextField
                    fullWidth
                    value={row.storage_transport_temperature}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "storage_transport_temperature",
                        e.target.value
                      )
                    }
                    label=""
                    disabled={expertDashboardData?.session_data?.submitted}
                  />
                </div>
                <div className="Table-span-1">
                  <TextField
                    fullWidth
                    value={row.acceptable_alternative_specimen}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "acceptable_alternative_specimen",
                        e.target.value
                      )
                    }
                    label=""
                    disabled={expertDashboardData?.session_data?.submitted}
                  />
                </div>
                <div className="Table-span-1">
                  <TextField
                    fullWidth
                    value={row.other_requirements}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "other_requirements",
                        e.target.value
                      )
                    }
                    label=""
                    disabled={expertDashboardData?.session_data?.submitted}
                  />
                </div>
                <div className="Table-span-1">
                  <TextField
                    fullWidth
                    value={row.causes_for_rejection}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "causes_for_rejection",
                        e.target.value
                      )
                    }
                    label=""
                    disabled={expertDashboardData?.session_data?.submitted}
                  />
                </div>
                <div className="Table-span-1">
                  <div className="d-flex align-items-center">
                    <TextField
                      fullWidth
                      value={row.time_to_result}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "time_to_result",
                          e.target.value
                        )
                      }
                      label=""
                      disabled={expertDashboardData?.session_data?.submitted}
                    />
                    {!expertDashboardData?.session_data?.submitted && (
                      <Button
                        sx={{ minWidth: "max-content" }}
                        onClick={() => handleDeleteRow(index)}
                      >
                        <DeleteIcon className="text-danger" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

        {!generated && (
          <Button
            onClick={addNewRow}
            sx={{ marginTop: "20px", width: "120px" }}
            className="PrimaryCTA"
          >
            Add New
          </Button>
        )}
        {!expertDashboardData?.session_data?.submitted && generated && (
          <AutogeneratedReviewBar
            tick={() => onTickClick()}
            cross={() => onCrossClick()}
          />
        )}
      </div>
      <FollowUpTable sessionStatus={sessionStatus} aiGenerated={generated} />
    </div>
  );
};

export default SessionLabAdviceTable;
