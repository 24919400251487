import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import CourseTabItem from "../../tabs/clients/CourseTabItem";
import ScreenerTabItem from "../../tabs/clients/ScreenerTabItem";
import AppointmentTabItem from "../../tabs/clients/AppointmentTabItem";
import NoteTabItem from "../../tabs/clients/NoteTabItem";
import PrescriptionTabItem from "../../tabs/clients/PrescriptionTabItem";

import { getSessionNotes, getSessionNotesById } from "../../APIS/clientAPIS";

import moment from "moment";
import dayjs from "dayjs";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import TextField from "@mui/material/TextField";

//Loader spinner
import CircularProgress from "@mui/material/CircularProgress";

//Accordion

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useAuth } from "../../../../auth";
import jwtDecode from "jwt-decode";
import AddAppointmentModal from "../../modals/AddAppointmentModal";

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import SessionNoteTabItem from "../../tabs/clients/SessionNoteTabItem";

import { getToken } from "../../APIS/clientAPIS";
import { Skeleton } from "@mui/material";
import { GetAppointment_Documents_Files_Sent_User } from "../../../user/core/_request";
import { GetPrescriptionsSentByUser } from "../../APIS/commonAPIS";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ClientDetailsView = ({
  clientName,
  onScreenerView,
  clientID,
  clientDetails,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [clientViewDetails, setClientViewDetails] = useState({});
  const [checkClientID, setCheckClientID] = useState(clientID);
  const [clientAppointmentsList, setClientAppointmentsList] = useState([]);
  const [clientNotes, setClientNotes] = useState([]);
  const [clientSessionNotes, setClientSessionNotes] = useState([]);
  const [clientPrescriptions, setClientPrescriptions] = useState([]);
  const [clientScreeners, setClientScreeners] = useState([]);
  const [mergedClientScreeners, setMergedClientScreeners] = useState([]);
  const [userUploadedPrescriptions, setUserUploadedPrescriptions] = useState(
    []
  );
  const [clientScreenersQNA, setClientScreenersQNA] = useState([]);
  const [clientCourses, setClientCourses] = useState([]);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openSessionNotesModal, setOpenSessionNotesModal] = useState(false);
  const [note, setNote] = useState("");
  const [chatClientList, setChatClientList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [bearerToken, setBearerToken] = useState(localStorage.getItem("token"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isNoteAdded, setIsNoteAdded] = useState(null);

  const [selectedPrescriptionTab, setSelectedPrescriptionTab] =
    useState("Expert");

  const [sessionNoteView, setSessionNoteView] = useState({});
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const { currentUser, userType, logout } = useAuth();
  const navigate = useNavigate();
  const searchBarParams = useSearchParams();

  const { search } = useLocation();
  const tabIndexFromURL = new URLSearchParams(search).get("tabIndex");

  useEffect(() => {
    setSelectedTab(+tabIndexFromURL);
  }, []);

  useEffect(() => {
    setCheckClientID(clientID);
    setClientViewDetails(clientDetails);
  }, [clientID]);

  //Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  //Check if CLient ID
  const getClientID = () => {
    if (
      (checkClientID === "" ||
        checkClientID === null ||
        checkClientID === undefined) &&
      window.location.href.split("?")[1].includes("id")
    ) {
      const queryParam = new URLSearchParams(search).get("id");
      setCheckClientID(queryParam);
    } else {
      setCheckClientID(clientID);
    }
  };

  useEffect(() => {
    getClientID();
  }, []);

  // Define the sorting function
  const sortByDate = (a, b) => {
    let dateA = a.score_date || a.create_date;
    let dateB = b.score_date || b.create_date;
    dateA = new Date(dateA);
    dateB = new Date(dateB);
    return dateB - dateA;
  };

  //Fetch Client Screeners
  useEffect(() => {
    if (!checkClientID) {
      return;
    }

    getUserUploadedPrescriptions();
    postClientScreenersData();
  }, [checkClientID]);

  useEffect(() => {
    if (!checkClientID) {
      return;
    }

    const payload = {
      expert_id: currentUser?.UserId,
      user_id: Number(checkClientID),
    };

    const postClientAppointmentsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { appointments } = await postResponse.data;

        setClientAppointmentsList(appointments);
        setLoading(false);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientAppointmentsData();
  }, [checkClientID]);

  //Fetch Client Notes
  useEffect(() => {
    if (!checkClientID) {
      return;
    }

    const payload = {
      jsonrpc: "2.0",
      params: {
        creator_id: +currentUser?.UserId,
        assign_id: +checkClientID,
      },
    };

    const postClientNotesData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/uwc_notes/list`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const token = await postResponse.data;
        const decodedToken = jwtDecode(token);

        setClientNotes(decodedToken?.notes);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientNotesData();
  }, [checkClientID]);

  //Fetch Client Prescriptions
  useEffect(() => {
    if (!checkClientID) {
      return;
    }
    let sessionBearerToken = sessionStorage.getItem("token");

    const prescriptionHeaders = {
      Authorization: `Bearer ${sessionBearerToken}`,
      "Content-Type": "application/json",
      deviceid: "123",
      devicetype: "web",
      lat: "1",
      long: "1",
    };

    const postClientNotesData = async (retryCount = 0) => {
      const MAX_RETRY = 3; // Maximum number of retries
      try {
        const response = await fetch(
          `https://app.uwc.world/api/appointment/GetAppointment_Documents_Files_Sent_Expert?AppointmentId=0&ToUserId=${checkClientID}`,
          {
            method: "GET",
            headers: prescriptionHeaders,
          }
        );
        const postResponse = await response.json();

        if (postResponse?.ResponseCode == 401 && retryCount < MAX_RETRY) {
          getToken(currentUser?.Emailid).then((res) => {
            setTimeout(() => postClientNotesData(retryCount + 1), 0);
          });
        }

        if (postResponse?.ResponseCode == 200) {
          const token = postResponse?.data;
          const decodedToken = jwtDecode(token);

          setClientPrescriptions(decodedToken);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientNotesData(0);
  }, [chatClientList]);

  //Fetch Client Courses
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }
    const payload = {
      userId: checkClientID,
    };

    const postClientNotesData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/community/course/active_courses`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { courses } = await postResponse.data;

        setClientCourses(courses);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientNotesData();
    setLoading(false);
  }, [checkClientID]);

  //Fetch Client SessionNotes
  useEffect(() => {
    const fetchSessionNotes = async () => {
      try {
        const sessionData = await getSessionNotes(
          currentUser.UserId,
          checkClientID
        );

        setClientSessionNotes(sessionData?.notes);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching sessionNotes:", error);
      }
    };

    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }

    if (checkClientID !== undefined) {
      fetchSessionNotes();
    }
  }, [checkClientID]);

  const getUserUploadedPrescriptions = async () => {
    try {
      const response = await GetPrescriptionsSentByUser(
        currentUser?.UserId,
        checkClientID
      );

      //console.log("getUserUploadedPrescriptions", response);

      if (+response.ResponseCode === 200) {
        setUserUploadedPrescriptions(response?.data);
      }
    } catch (error) {}
  };

  const postClientScreenersData = async () => {
    try {
      const screenerBody = {
        expert_id: currentUser?.UserId,
        user_id: checkClientID,
      };

      const response = await fetch(
        `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/assignments`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(screenerBody),
        }
      );
      const postResponse = await response.json();

      const { assignments } = await postResponse.data;
      const { worksheets } = await postResponse.data;

      setClientScreeners(assignments);
      setClientScreenersQNA(worksheets);

      const modifiedWorksheets = worksheets.map((worksheet) => ({
        ...worksheet,
        score_date: worksheet.create_date,
        type: "QNA",
      }));

      const modifiedAssignments = assignments.map((worksheet) => ({
        ...worksheet,
        type: "Options",
      }));

      const mergedClientScreeners = [
        ...modifiedAssignments,
        ...modifiedWorksheets,
      ];
      const sortedMergedClientScreeners =
        mergedClientScreeners.sort(sortByDate);

      setMergedClientScreeners(sortedMergedClientScreeners);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewScreener = (title, id, type) => {
    onScreenerView(title, id, type);
  };

  //Notes
  const handleNotesOpen = () => {
    setOpenNotesModal(true);
  };

  const handleNotesClose = () => {
    setOpenNotesModal(false);
  };

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  //Send Note
  const handlePostNote = async () => {
    setIsNoteAdded(false);

    const payload = {
      jsonrpc: "2.0",
      params: {
        name: note,
        content: note,
        creator_id: currentUser?.UserId,
        assign_id: +clientDetails?.id,
        is_private: "True",
      },
    };

    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/uwc_notes/create`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse?.ResponseCode;

      if (message == 200) {
        setNote("");
        setOpenNotesModal(false);
        setSnackbarMessage("Note sent successfully!");
        setOpenSnackbar(true);
        setIsNoteAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Redirect to chat
  const handleChat = () => {
    navigate(`/expert/chat?clientID=${clientID}`);
  };

  ///Session Notes Region Start

  const handleSessionNotesClose = () => {
    setOpenSessionNotesModal(false);
  };

  const fetchSessionNotesById = async (id) => {
    try {
      const sessionDataID = await getSessionNotesById(currentUser?.UserId, id);

      setSessionNoteView(sessionDataID?.notes);

      if (sessionDataID?.notes !== undefined) {
        setOpenSessionNotesModal(true);
      } else {
        enqueueSnackbar("Oops! Looks like this Record doesn't exist!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching session notes by ID:", error);
    }
  };

  const sortedClientSessionNotes = clientSessionNotes?.sort(function (a, b) {
    var dateA = new Date(a.date);
    var dateB = new Date(b.date);

    return dateB - dateA;
  });

  ///Session Notes Region End

  const handlePrescriptionTab = (tab) => {
    setSelectedPrescriptionTab(tab);
  };

  // console.log("loading", loading);
  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item xs={8}>
          <div
            style={{
              background: "#fff",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              className="ClientDetails--Tabs"
            >
              <Tab label="Appointments" />
              <Tab label="Notes" />
              <Tab label="Prescriptions" />
              <Tab label="Screeners" />
              <Tab label="Courses" />
              <Tab label="Session Notes" />
            </Tabs>

            <CustomTabPanel value={selectedTab} index={0}>
              <div className="Tab--Panel Appointments--Panel">
                {/* Appointment */}
                {isLoading
                  ? Array.from(new Array(10)).map((_, index) => (
                      <div
                        style={{
                          width: "100%",
                          height: "70px",
                          backgroundColor: "rgb(0,0,0,0.07)",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        className="d-flex flex-column gap-2"
                      >
                        <Skeleton
                          variant="rounded"
                          width={75}
                          height={25}
                          sx={{ borderRadius: "20px" }}
                        />
                        <div className="d-flex flex-row gap-3">
                          <Skeleton
                            variant="rounded"
                            width="5vw"
                            height={20}
                            sx={{ borderRadius: "5px" }}
                          />
                          <Skeleton
                            variant="rounded"
                            width="5vw"
                            height={20}
                            sx={{ borderRadius: "5px" }}
                          />
                          <Skeleton
                            variant="rounded"
                            width="5vw"
                            height={20}
                            sx={{ borderRadius: "5px" }}
                          />
                        </div>
                      </div>
                    ))
                  : clientAppointmentsList?.map((item) => (
                      <AppointmentTabItem
                        key={item.ID}
                        status={item.Status}
                        date={item.AppDate}
                        time={item.AppTime}
                        duration={item.duration}
                      />
                    ))}
              </div>
            </CustomTabPanel>

            {/* Notes Panel */}
            <CustomTabPanel value={selectedTab} index={1}>
              <div className="Tab--Panel Notes--Panel">
                {loading ? (
                  <div className="NotesItem my-2">
                    {Array.from(new Array(10)).map((_, index) => (
                      <div className="mb-3">
                        <Skeleton variant="rounded" width="50%" height={20} />
                        <p>
                          <Skeleton variant="rounded" width="30%" height={20} />
                        </p>
                      </div>
                    ))}
                  </div>
                ) : clientNotes.length > 0 ? (
                  clientNotes?.map((note) => (
                    <NoteTabItem
                      key={note.id}
                      date={note.create_date}
                      name={note.name}
                      content={note.content}
                    />
                  ))
                ) : (
                  <p style={{ fontWeight: "500", textAlign: "center" }}>
                    There are no notes attached with {clientViewDetails?.name}.
                  </p>
                )}
              </div>
            </CustomTabPanel>

            {/* Prescriptions panel */}
            <CustomTabPanel value={selectedTab} index={2}>
              <div className="Tab--Panel Prescriptions--Panel">
                <div className="Prescriptions--Panel__Tabs d-flex align-items-center">
                  <button
                    className={`${
                      selectedPrescriptionTab === "Expert" ? "Selected" : null
                    }`}
                    onClick={() => handlePrescriptionTab("Expert")}
                  >
                    Expert Uploaded
                  </button>
                  <button
                    className={`${
                      selectedPrescriptionTab === "User" ? "Selected" : null
                    }`}
                    onClick={() => handlePrescriptionTab("User")}
                  >
                    User Uploaded
                  </button>
                </div>

                {selectedPrescriptionTab === "Expert" ? (
                  <div className="">
                    {loading ? (
                      Array.from(new Array(10)).map((_, index) => (
                        <div className="d-flex flex-column gap-2">
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <Skeleton
                              variant="rounded"
                              width="30%"
                              height={18}
                              sx={{ borderRadius: "10px" }}
                            />

                            <Skeleton
                              variant="circular"
                              width={22}
                              height={15}
                            />
                          </div>

                          <Skeleton
                            variant="rounded"
                            width="20%"
                            height={18}
                            sx={{ borderRadius: "10px" }}
                          />
                          <Skeleton
                            variant="rounded"
                            width="20%"
                            height={18}
                            sx={{ borderRadius: "10px" }}
                          />
                        </div>
                      ))
                    ) : clientPrescriptions.length > 0 ? (
                      <div>
                        {clientPrescriptions?.map((item) => (
                          <PrescriptionTabItem
                            key={item.Id}
                            title={item.Title}
                            note={item.Note}
                            date={item.CreatedDateTime}
                            linkedFile={item.DocsFile}
                          />
                        ))}
                      </div>
                    ) : (
                      <p style={{ fontWeight: "500", textAlign: "center" }}>
                        There are no prescription notes attached with&nbsp;
                        {clientViewDetails?.name}.
                      </p>
                    )}
                  </div>
                ) : (
                  <div>
                    {loading ? (
                      Array.from(new Array(10)).map((_, index) => (
                        <div className="d-flex flex-column gap-2">
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <Skeleton
                              variant="rounded"
                              width="30%"
                              height={18}
                              sx={{ borderRadius: "10px" }}
                            />

                            <Skeleton
                              variant="circular"
                              width={22}
                              height={15}
                            />
                          </div>

                          <Skeleton
                            variant="rounded"
                            width="20%"
                            height={18}
                            sx={{ borderRadius: "10px" }}
                          />
                          <Skeleton
                            variant="rounded"
                            width="20%"
                            height={18}
                            sx={{ borderRadius: "10px" }}
                          />
                        </div>
                      ))
                    ) : userUploadedPrescriptions?.length > 0 ? (
                      userUploadedPrescriptions?.map((item) => (
                        <PrescriptionTabItem
                          key={item.Id}
                          title={item.Title}
                          note={item.Note}
                          date={item.CreatedDateTime}
                          linkedFile={item.DocsFile}
                        />
                      ))
                    ) : (
                      <p style={{ fontWeight: "500", textAlign: "center" }}>
                        There are no prescription uploaded by&nbsp;
                        {clientViewDetails?.name}.
                      </p>
                    )}
                  </div>
                )}
              </div>
            </CustomTabPanel>

            {/* Screeners Panel */}
            <CustomTabPanel value={selectedTab} index={3}>
              <div className="Tab--Panel Screeners--Panel">
                {loading ? (
                  Array.from(new Array(10)).map((_, index) => (
                    <div
                      style={{
                        width: "100%",
                        height: "70px",
                        backgroundColor: "rgb(0,0,0,0.07)",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      className="d-flex justify-content-between"
                    >
                      <div className="d-flex flex-column gap-2">
                        <Skeleton
                          variant="rounded"
                          height={18}
                          width="20vw"
                          sx={{ borderRadius: "10px" }}
                        />
                        <Skeleton
                          variant="rounded"
                          height={18}
                          width="15vw"
                          sx={{ borderRadius: "10px" }}
                        />
                      </div>

                      <div className="CTA--Container d-flex gap-2 align-items-center">
                        <Skeleton
                          variant="rounded"
                          height={38}
                          width={86}
                          sx={{ borderRadius: "20px" }}
                        />
                      </div>
                    </div>
                  ))
                ) : mergedClientScreeners.length > 0 ? (
                  mergedClientScreeners?.map((item) => (
                    <ScreenerTabItem
                      type={item.type}
                      key={item.id}
                      id={item.id}
                      title={item.name}
                      submittedOn={dayjs(item.score_date).format("DD MMM YYYY")}
                      onViewClick={handleViewScreener}
                    />
                  ))
                ) : (
                  <p style={{ fontWeight: "500", textAlign: "center" }}>
                    {clientViewDetails?.name} has not taken any screeners.
                    Please take this up in your session.
                  </p>
                )}
              </div>
            </CustomTabPanel>

            {/* Courses Panel */}
            <CustomTabPanel value={selectedTab} index={4}>
              {loading ? (
                Array.from(new Array(10)).map((_, index) => (
                  <>
                    <div
                      className="d-flex flex-row gap-2 align-items-center"
                      style={{
                        // backgroundColor: "rgb(0,0,0,0.09)",
                        borderRadius: "10px",
                        padding: "5px",
                      }}
                    >
                      <Skeleton
                        width={122}
                        height={69}
                        sx={{ borderRadius: "10px" }}
                      />

                      <div className="d-flex flex-column gap-2">
                        <Skeleton
                          variant="rounded"
                          width="15vw"
                          height={20}
                          sx={{ borderRadius: "5px" }}
                        />
                        <Skeleton
                          variant="rounded"
                          width="10vw"
                          height={20}
                          sx={{ borderRadius: "5px" }}
                        />
                      </div>
                    </div>
                    <hr />
                  </>
                ))
              ) : (
                <div className="Tab--Panel Courses--Panel">
                  {clientCourses?.map((item) => (
                    <CourseTabItem
                      key={item.id}
                      title={item.name}
                      status={item.status}
                      img={item.website_thumbnail_url}
                    />
                  ))}
                </div>
              )}
            </CustomTabPanel>

            {/* Session Notes Panel */}
            <CustomTabPanel value={selectedTab} index={5}>
              <div className="Tab--Panel SessionNotes--Panel">
                {loading ? (
                  Array.from(new Array(10)).map((_, index) => (
                    <div
                      style={{
                        width: "100%",
                        height: "70px",
                        backgroundColor: "rgb(0,0,0,0.07)",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      className="d-flex justify-content-between"
                    >
                      <div className="d-flex flex-column gap-2">
                        <Skeleton
                          variant="rounded"
                          height={18}
                          width="20vw"
                          sx={{ borderRadius: "10px" }}
                        />
                        <Skeleton
                          variant="rounded"
                          height={18}
                          width="15vw"
                          sx={{ borderRadius: "10px" }}
                        />
                      </div>

                      <div className="CTA--Container d-flex gap-2 align-items-center">
                        <Skeleton
                          variant="rounded"
                          height={38}
                          width={86}
                          sx={{ borderRadius: "20px" }}
                        />
                      </div>
                    </div>
                  ))
                ) : sortedClientSessionNotes.length > 0 ? (
                  sortedClientSessionNotes?.map((item) => (
                    <SessionNoteTabItem
                      onView={fetchSessionNotesById}
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      date={item.date}
                      time={item.time}
                    />
                  ))
                ) : (
                  <p style={{ fontWeight: "500", textAlign: "center" }}>
                    {clientViewDetails?.name} has no session notes attached.
                  </p>
                )}
              </div>
            </CustomTabPanel>
          </div>
        </Grid>

        {isLoading ? (
          <Grid item xs={4}>
            <div
              className="ClientDetailsGrid--Right"
              style={{
                background: "#fff",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <Skeleton variant="circular" width={110} height={110} />
              <Skeleton variant="text" width="30%" height={20} />

              <div className="d-flex align-items-center gap-2 ClientActions--Container">
                <Skeleton
                  variant="rounded "
                  width={168}
                  height={38}
                  sx={{ borderRadius: "20px" }}
                />
                <Skeleton
                  variant="rounded "
                  width={168}
                  height={38}
                  sx={{ borderRadius: "20px" }}
                />
              </div>

              <div className="d-flex align-items-center gap-2 CTA--Container">
                <Skeleton
                  variant="rounded"
                  width={335}
                  height={50}
                  sx={{ borderRadius: "10px" }}
                />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item xs={4}>
            <div
              className="ClientDetailsGrid--Right"
              style={{
                background: "#fff",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <img
                src={clientDetails.image}
                width={110}
                height={110}
                style={{ backgroundColor: "rgb(0,0,0,0.07)" }}
              />
              <h6>{clientDetails.name}</h6>
              <span>{clientDetails.country}</span>

              <div className="d-flex align-items-center gap-2 ClientActions--Container">
                <Button onClick={handleChat}>
                  <ChatOutlinedIcon />
                  Chat
                </Button>

                <Button onClick={handleNotesOpen}>
                  <TextSnippetOutlinedIcon />
                  Add Notes
                </Button>
              </div>

              <div className="d-flex align-items-center gap-2 CTA--Container">
                <AddAppointmentModal clientDetails={clientViewDetails} />
              </div>

              <span
                className="d-none"
                style={{ color: "#6A6C6A", fontSize: "14px" }}
              >
                Last Active :{" "}
                <b style={{ color: "#000" }}>{clientDetails.last_active}</b>
              </span>
            </div>
          </Grid>
        )}
      </Grid>

      {/* Notes Dialog */}
      <Dialog
        className="Dialog Notes--Dialog"
        open={openNotesModal}
        onClose={handleNotesClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isNoteAdded !== null && !isNoteAdded && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientDetails.image}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientDetails.name}</h6>
              {/* <span>
                {formattedDate} | {appointmentTime} | {clientType}
              </span> */}
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Write Something"
            multiline
            value={note}
            rows={4}
            fullWidth
            onChange={(e) => handleNote(e)}
            sx={{ marginTop: "20px" }}
          />

          <Button
            className="PrimaryCTA"
            variant="contained"
            disableElevation
            sx={{ margin: "30px auto 20px auto" }}
            onClick={handlePostNote}
            disabled={note == "" ? true : false}
          >
            Add Note
          </Button>
        </DialogContent>
      </Dialog>

      {/* Session Note Details Dialog */}
      <Dialog
        className="Dialog SessionNotesView--Dialog"
        open={openSessionNotesModal}
        onClose={handleSessionNotesClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <DialogTitle>{sessionNoteView?.name}</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div>
            <h6>
              <b>Client Details</b>
            </h6>
            <div className="sessionNoteView--ClientDetails">
              <div className="d-flex align-items-center gap-2">
                <p>Client’s Contact No:</p>
                <span>{sessionNoteView?.client_mobile_no}</span>
              </div>

              <div className="d-flex align-items-center gap-2">
                <p>Client’s DOB:</p>
                <span>{sessionNoteView?.client_dob}</span>
              </div>

              <div className="d-flex align-items-center gap-2">
                <p>Client’s Address:</p>
                <span>{sessionNoteView?.client_address}</span>
              </div>

              <div className="mt-5">
                <h6>
                  <b>Note Details</b>
                </h6>
                {sessionNoteView?.responses?.map((item, i) => {
                  return (
                    <Accordion
                      key={item.id}
                      className="sessionNoteView--Accordion mt-3"
                      elevation={0}
                      sx={{ background: "#E5F5FF" }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {item.question_text}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {item.response_text && item.response_text[0]}
                          {item.sub_questions && (
                            <ul className="mb-3">
                              {item?.sub_questions?.map((question) => {
                                return (
                                  <li key={question.id}>
                                    {question.question_text}:{" "}
                                    {question.response_text[0]}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ClientDetailsView;
