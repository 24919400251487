import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import Grid from "@mui/material/Grid";
import WorksheetCard from "./cards/WorksheetCard";
import ArticleCard from "./cards/ArticleCard";
import NewArticle from "./component/NewArticle";
import ComingSoon from "./component/ComingSoon";

//Helpers
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from 'react-router-dom';

const ExpertArticlesDB = () => {
  const [newArticle, setNewArticle] = useState(false);
  const [articlesList, setArticlesList] = useState([]);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if user is logged in
  // useEffect(() => {
   
  //   const isLoggedIn = isUserLoggedIn();

  //   if (!isLoggedIn) {
  //     window.location.href = "https://www.unitedwecare.com";
  //   }
  // }, [location, navigate]);

  useEffect(() => {
    const payload = {
      user_id: 1,
    };

    const postArticlesData = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/expert/dashboard/articles`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const data = await postResponse.data?.articles;

        setArticlesList(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postArticlesData();
  }, []);

  return <ComingSoon />;
};

export default ExpertArticlesDB;
