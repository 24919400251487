import React, { useState, useContext } from "react";
import "../../styles/courses/Details.css";
import { AppContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import {
  OODOCourseAccessRequestService,
  OODOCoursePaymentService,
} from "../core/_request";
import CircularProgress from "@mui/material/CircularProgress";
import { Skeleton } from "@mui/material";
import SubscriptionAlertDialougBox from "./SubscriptionAlertDialougBox";

const Details = (props) => {
  const { courseData, isPaid, loading } = props;
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [openPaymetAlert, setOpenPaymetAlert] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleStartSubscription = () => {
    setIsButtonClicked(true);
    if (!currentUser) {
      localStorage.setItem(
        "retrunurl",
        `/program/${props?.courseData?.course_title}/${props?.courseId}`
      );
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    } else {
      if (courseData?.enrolled) {
        navigate(
          `/program-internal/${courseData?.course_data_id}/${courseData?.course_title}`
        );
        let linksData = [
          {
            name: "Home",
            uri: `/program-internal/${courseData?.course_data_id}/${courseData?.course_title}`,
          },
          {
            name: "My Journal",
            uri: `/program-internal/module/my-journal/:id`,
          },
        ];
        sessionStorage.setItem(
          "courseInternalLinks",
          JSON.stringify(linksData)
        );
      } else if (
        courseData?.inr_price === 0.0 ||
        courseData?.course_type === "free"
      ) {
        let request = {
          userId: currentUser?.UserId,
          courseId: props?.courseId,
        };
        OODOCoursePaymentService(request).then((res) => {
          navigate(
            `/program-internal/${res?.data.data.id}/${courseData?.course_title}`
          );
          let linksData = [
            {
              name: "Home",
              uri: `/program-internal/${res?.data?.data?.id}/${courseData?.course_title}`,
            },
            {
              name: "My Journal",
              uri: `/program-internal/module/my-journal/:id`,
            },
          ];
          sessionStorage.setItem(
            "courseInternalLinks",
            JSON.stringify(linksData)
          );
        });
      } else if (courseData?.course_type === "premium") {
        // localStorage.setItem(
        //   "retrunurl",
        //   `/program/${props?.courseData?.course_title}/${props?.courseId}`
        // );
        // navigate("/plans");
        setOpenPaymetAlert(true);
      }
    }
  };

  return (
    <section className="details-section">
      {loading ? (
        <div className="details-images-left gap-4">
          <Skeleton
            variant="circular"
            width={160}
            height={160}
            className="img1"
          />
          <Skeleton
            variant="circular"
            width={180}
            height={180}
            className="img2"
          />
          <Skeleton
            variant="circular"
            width={200}
            height={200}
            className="img1"
          />
        </div>
      ) : (
        <div className="details-images-left d-none d-md-flex col-md-3">
          <img
            src="/assets/img/course_new/Ellipse405.png"
            alt=""
            width={200}
            height={200}
            className="img1"
          />
          <br />
          <img
            src="/assets/img/course_new/Ellipse406.png"
            alt=""
            width={220}
            height={220}
            className="img2"
          />
          <br />
          <img
            src="/assets/img/course_new/Ellipse407.png"
            alt=""
            width={240}
            height={240}
            className="img1"
          />
        </div>
      )}

      {loading ? (
        <>
          {/* desktop */}
          <div className="details-main d-none d-md-block">
            <h1 className="details-heading d-flex flex-column justify-content-center align-items-center gap-2">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="100%"
                height={50}
              />
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="70%"
                height={50}
              />
            </h1>

            <div className="" style={{ margin: "40px 0 20px 130px" }}>
              <div style={{ margin: "20px  0" }}>
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="40vw"
                  height={50}
                />
              </div>
              <div style={{ margin: "20px  0" }}>
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="40vw"
                  height={50}
                />
              </div>
              <div style={{ margin: "20px  0" }}>
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="40vw"
                  height={50}
                />
              </div>
              <div style={{ margin: "20px  0" }}>
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="40vw"
                  height={50}
                />
              </div>
            </div>

            <div
              className="d-flex flex-column justify-content-center align-items-center gap-2"
              style={{ marginLeft: "100px" }}
            >
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="50%"
                height={70}
              />
            </div>
          </div>
          {/* mobile */}
          <div className="details-main d-block d-md-none">
            <h1 className="details-heading d-flex flex-column justify-content-center align-items-center gap-2">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="100%"
                height={40}
              />
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="70%"
                height={40}
              />
            </h1>

            <div className="" style={{ marginTop: "30px", marginLeft: "20px" }}>
              <div style={{ margin: "20px  0" }}>
                <Skeleton
                  variant="text"
                  sx={{ borderRadius: "10px" }}
                  width="90%"
                  height={40}
                />
              </div>
              <div style={{ margin: "20px  0" }}>
                <Skeleton
                  variant="text"
                  sx={{ borderRadius: "10px" }}
                  width="90%"
                  height={40}
                />
              </div>
              <div style={{ margin: "20px  0" }}>
                <Skeleton
                  variant="text"
                  sx={{ borderRadius: "10px" }}
                  width="90%"
                  height={40}
                />
              </div>
              <div style={{ margin: "20px  0" }}>
                <Skeleton
                  variant="text"
                  sx={{ borderRadius: "10px" }}
                  width="90%"
                  height={40}
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center gap-2 mt-4">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px", marginTop: "15px" }}
                width="50%"
                height={40}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="details-main col-md-6">
          <h1 className="details-heading">
            Uncover the
            <span className="text-highlight"> SAFE HAVEN </span>
            for your mental health
          </h1>

          <div className="details-list">
            <ul>
              <li className="details-list-items">
                <span className="text-highlight">Carefully crafted </span>
                programs rigorously tested in controlled environments for proven
                effectiveness
              </li>
              <li className="details-list-items">
                <span className="text-highlight">
                  24*7 AI powered wellness coach, STELLA,{" "}
                </span>{" "}
                to assist you in times of distress in your preferred language
              </li>
              <li className="details-list-items">
                <span className="text-highlight">Best in class </span> global
                therapists & coaches with diverse wisdom
              </li>
              <li className="details-list-items">
                <span className="text-highlight">Clinically proven </span>{" "}
                assessments & curated content to enhance your emotional
                well-being
              </li>
            </ul>
          </div>

          <button
            className="details-btn"
            onClick={handleStartSubscription}
            disabled={isButtonClicked}
          >
            {isButtonClicked && <CircularProgress color="inherit" />}
            {props.isSubscribed ? "Continue" : isPaid ? "Subscribe" : "Start"}
          </button>
        </div>
      )}

      {loading ? (
        <div className="details-images-right gap-4">
          <Skeleton
            variant="circular"
            width={200}
            height={200}
            className="img3"
          />
          <Skeleton
            variant="circular"
            width={160}
            height={160}
            className="img4"
          />
          <Skeleton
            variant="circular"
            width={180}
            height={180}
            className="img3"
          />
        </div>
      ) : (
        <div className="details-images-right d-none d-md-flex col-md-3">
          <img
            src="/assets/img/course_new/Ellipse408.png"
            alt=""
            width={240}
            height={240}
            className="img3"
          />
          <br />
          <img
            src="/assets/img/course_new/Ellipse409.png"
            alt=""
            width={200}
            height={200}
            className="img4"
          />
          <br />
          <img
            src="/assets/img/course_new/Ellipse410.png"
            alt=""
            width={220}
            height={220}
            className="img3"
          />
        </div>
      )}

      <SubscriptionAlertDialougBox
        description="Thank you for showing interest in our program! It is currently available exclusively for our Enterprise users. If your employer is already partnered with us, you may have access through your company's plan—please check with your HR department. We'll notify you as soon as it becomes available for individual users like you."
        courseData={courseData}
        userId={currentUser?.UserId}
        isOpen={openPaymetAlert}
        setOpenPaymetAlert={(item) => setOpenPaymetAlert(item)}
        setIsButtonClicked={(item) => setIsButtonClicked(item)}
      />
    </section>
  );
};

export default Details;
