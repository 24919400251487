import React, { useContext, useEffect } from 'react'
import Sidebar from '../dashboard/Components/Dashboard/Sidebar'
import Category from './Components/Category'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../auth'
import { AppContext } from '../../context'

const CategoryDetail = () => {
    const categoryId = useParams();
    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    useEffect(() => {
        if (!currentUser) {
            // setShowLloginPopup(true);
            const currentUrl = encodeURIComponent(window.location.href);
            window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
        }
    }, []);
    return (
        <div className='d-flex'>
            <div className='col-2 d-none d-md-block'>
                <Sidebar />
            </div>
            <div className='col-12 col-md-10'>
                <Category categoryId={categoryId} />
            </div>
        </div>
    )
}

export default CategoryDetail