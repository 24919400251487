import React, { useEffect, useState, useContext } from "react";
//SpeechRecognition
import {  useAudioRecorder } from "react-audio-voice-recorder";
import { AppContext } from "../../../context";
import { getTranscript, getTranslation } from "../APIS/clientAPIS";
import StopIcon from "@mui/icons-material/Stop";

function AutogeneratedReviewBar({ tick, cross }) {
  const [isRecording, setIsRecording] = useState(false);

  const [audioRequestData, setAudioRequestData] = useState(null);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const recorderControls = useAudioRecorder();
  const { expertDashboardData } = useContext(AppContext);

  useEffect(() => {
    const sendAudioData = async () => {
      const base64String = await convertBlobToBase64(
        recorderControls.recordingBlob
      );
      getTranscript(
        expertDashboardData.session_data.appointment_details.appointment_id,
        expertDashboardData.session_data.appointment_details.user_id,
        expertDashboardData.session_data.appointment_details.expert_id,
        expertDashboardData.session_data.appointment_details.appointment_date,
        expertDashboardData.session_data.appointment_details.booking_date,
        base64String
      ).then((res) => {
        getTranslation(
          expertDashboardData.session_data.appointment_details.appointment_id,
        expertDashboardData.session_data.appointment_details.user_id,
        expertDashboardData.session_data.appointment_details.expert_id,
        expertDashboardData.session_data.appointment_details.appointment_date,
        expertDashboardData.session_data.appointment_details.booking_date,
        res.transcript
        );
      });
    };
    if (
      recorderControls.recordingBlob !== undefined &&
      recorderControls.recordingBlob !== null
    ) {
      sendAudioData();
    }
  }, [recorderControls.recordingBlob]);


  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split("data:audio/webm;codecs=opus;base64,")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleRecordingStart = () => {
    recorderControls.startRecording();
    setIsRecording(true);
  };

  const handleRecordingStop = async () => {
    recorderControls.stopRecording();
    setIsRecording(false);
    setIsRecordingStopped(true);
  };

  return (
    <div className="p-2 d-flex  align-items-center">
      <div
        style={{ backgroundColor: "white" }}
        className="p-1 my-2 rounded-2 d-inline mx-2"
      >
        <button
          className=" border-0 mx-1"
          style={{ backgroundColor: "white" }}
          onClick={tick}
        >
          <img src="/assets/img/elements/typcn_tick.png" />
        </button>
        <button className=" border-0 mx-1" style={{ backgroundColor: "white" }}>
          {isRecording ? (
            <button className="border-0 mx-1" onClick={handleRecordingStop}>
              <StopIcon style={{ color: "#777777", border: "none" }} />
            </button>
          ) : (
            <img
              src="/assets/img/elements/Mic.png"
              onClick={handleRecordingStart}
            />
          )}
        </button>
        <button
          className=" border-0 mx-1"
          style={{ backgroundColor: "white" }}
          onClick={cross}
        >
          <img src="/assets/img/elements/carbon_close-filled.png" />
        </button>
      </div>
      <div
        className=" d-none p-2"
        style={{ backgroundColor: "rgb(249, 245, 255)" }}
      >
        <span>Did we do a better job this time?</span>
        <button className=" border-0 mx-1" style={{ backgroundColor: "white" }}>
          <span
            style={{ backgroundColor: "white" }}
            className="fw-bold p-1 mx-2"
          >
            Better
          </span>
        </button>
        <button className=" border-0 mx-1" style={{ backgroundColor: "white" }}>
          <span style={{ backgroundColor: "white" }} className="fw-bold  p-1">
            Worse
          </span>
        </button>
        <button className=" border-0 mx-1" style={{ backgroundColor: "white" }}>
          <span
            style={{ backgroundColor: "white" }}
            className="fw-bold  p-1 mx-2"
          >
            Same
          </span>
        </button>
      </div>
    </div>
  );
}

export default AutogeneratedReviewBar;
