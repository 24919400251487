import React, { useEffect, useState } from "react";
import MoodBar from "./MoodBar";
import ProgressBar from "@ramonak/react-progress-bar";
import "../styles/Home.css";
import Sidebar from "./Sidebar";
import { addBookmarkEntry, getCourseContent } from "../_request";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import SidebarMobile from "./SidebarMobile";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Helmet } from "react-helmet";
import { Skeleton } from "@mui/material";

const Home = () => {
  const [courseContent, setCourseContent] = useState();
  const [totalModule, setTotalModule] = useState();
  const [viewedModule, setViewedModule] = useState();
  const [percentageViewedContent, setPercentageViewedContent] = useState();
  const [bookmarkDataStatus, setBookmarkDataStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { id, urlTitle } = useParams();

  useEffect(() => {
    var body = { course_id: id };
    getCourseContent(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCourseContent(resp?.data);
        setIsLoading(false);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, [bookmarkDataStatus]);

  const handleClickOnModule = (item) => {
    navigate(`/program-internal/program-view/${item.id}`);
  };

  useEffect(() => {
    let total = 0;
    let viewed = 0;

    courseContent?.levels?.forEach((level) => {
      total += level?.total;
      viewed += level?.viewed;
    });

    setTotalModule(total);
    setViewedModule(viewed);

    const percentage = Math.round((viewed / total) * 100);

    setPercentageViewedContent(percentage);
  }, [courseContent]);

  const onClickWhatsApp = () => {
    window.open(
      encodeURI(
        `whatsapp://send?text=${courseContent?.course_url.replace(
          "us.unitedwecare.com",
          "my.unitedwecare.com"
        )}`
      )
    );
  };

  const onClickWhatsAppOnWeb = () => {
    window.open(
      encodeURI(
        `https://wa.me?text=${courseContent?.course_url.replace(
          "us.unitedwecare.com",
          "my.unitedwecare.com"
        )}`
      )
    );
  };

  const handleBookmarkClick = () => {
    var body = { course_data_id: courseContent?.course_id };
    addBookmarkEntry(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setBookmarkDataStatus(resp?.data);
      }
    });
  };

  const moodAddCallback = () => {
    //console.log("Mood Added!");
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Share on WhatsApp
    </Tooltip>
  );

  const renderCopyTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Copy the Link
    </Tooltip>
  );

  return (
    <section style={{ display: "flex", overflowX: "hidden", width: "100%" }}>
      <Helmet>
        <title>Program | Home</title>
      </Helmet>
      {isLoading ? (
        <div className="d-flex">
          <section className="home-sidebar">
            <Skeleton variant="rectangular" width={300} height={1080} />
          </section>

          <section className="home-section">
            <div clasName="home-container">
              <div className="home-upper-section">
                <div className="home-heading">
                  <Skeleton
                    variant="rounded"
                    width="20%"
                    height={40}
                    className="rounded"
                  />
                </div>
                <div className="home-sub-heading">
                  <Skeleton
                    variant="rounded"
                    width="40%"
                    height={40}
                    className="rounded"
                  />
                  <div className="d-flex gap-3">
                    <Skeleton
                      variant="rounded"
                      width={25}
                      height={25}
                      className="rounded-circle"
                    />
                    <Skeleton
                      variant="rounded"
                      width={25}
                      height={25}
                      className="rounded-circle"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Mood Monitor */}
            <Skeleton
              variant="rounded"
              width="100%"
              height={272}
              className="rounded-3 my-4"
            />

            {/* your progress */}
            <section className="my-2">
              <Skeleton
                variant="rounded"
                width="20%"
                height={40}
                className="rounded-3 my-2"
              />
              <Skeleton
                variant="rounded"
                width="40%"
                height={120}
                className="rounded-3 mb-2"
              />
            </section>

            <Skeleton
              variant="rounded"
              width="100%"
              height={350}
              className="rounded-3 my-4"
            />

            {/* module section */}
            <section className="modulesss">
              <Skeleton
                variant="rounded"
                width="20%"
                height={40}
                className="rounded-3 my-2"
              />

              <div className="module-container">
                {Array.from(new Array(4)).map((i) => (
                  <>
                    <Skeleton
                      variant="rounded"
                      width={257}
                      height={357}
                      className="rounded-4"
                    />
                  </>
                ))}
              </div>
            </section>
          </section>
        </div>
      ) : (
        <>
          <section className="home-sidebar">
            <Sidebar id={id} urlTitle={urlTitle} />
          </section>
          <SidebarMobile id={id} urlTitle={urlTitle} />
          <section className="home-section">
            <div clasName="home-container">
              <div className="home-upper-section">
                <div className="home-heading">
                  <p className="home-heading1">
                    PROGRAM • {courseContent?.duration}
                  </p>
                </div>
                <div className="home-sub-heading">
                  <p className="home-heading2">{courseContent?.title}</p>
                  <div className="home-btns">
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <img
                        src="/assets/img/course_internal_journey/home/home-wp.png"
                        alt=""
                        loading="lazy"
                        className="d-none d-md-block"
                        onClick={onClickWhatsAppOnWeb}
                        style={{ cursor: "pointer" }}
                      />
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <img
                        src="/assets/img/course_internal_journey/home/home-wp.png"
                        alt=""
                        loading="lazy"
                        className="d-block d-md-none"
                        onClick={onClickWhatsApp}
                        style={{ cursor: "pointer" }}
                      />
                    </OverlayTrigger>
                    <div
                      onClick={handleBookmarkClick}
                      className="rounded-circle p-1 d-flex align-items-center justify-content-center"
                      style={{ cursor: "pointer" }}
                    >
                      {courseContent?.bookmarked ? (
                        <BookmarkIcon />
                      ) : (
                        <BookmarkBorderOutlinedIcon />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Mood Monitor */}
            <section className="home--mood__monitor">
              <MoodBar
                moodCallback={moodAddCallback}
                courseID={courseContent?.course_id}
              />
            </section>

            {/* your progress */}
            <section className="progress-section">
              <div className="progress-heading">Your Progress</div>
              <div className="progress-container">
                <div className="progress-sub">Modules</div>
                <div className="progress-text">
                  {percentageViewedContent}% completed
                </div>
                <div className="progress-bar">
                  <ProgressBar
                    completed={percentageViewedContent}
                    bgColor="#51AC10"
                    customLabel=" "
                  />
                </div>
              </div>
            </section>

            <section className="video-section">
              <ReactPlayer
                url={courseContent?.preview_video?.video?.player_embed_url}
                controls={true}
              ></ReactPlayer>
            </section>

            {/* module section */}
            <section className="modulesss">
              <div>
                <div className="module-main-heading">All Modules</div>
                <div className="module-sub">
                  {viewedModule == 0
                    ? `${totalModule} sections`
                    : `${viewedModule} of ${totalModule} sections completed`}
                </div>
                <div className="module-container">
                  {courseContent?.levels?.map((item, i) => (
                    <div
                      className="module--item"
                      onClick={() => handleClickOnModule(item)}
                    >
                      <img
                        src={item.image_url}
                        className="module-img"
                        alt=""
                        loading="lazy"
                      />
                      <div className="module-text d-flex justify-content-between align-items-center gap-1 py-2">
                        <div>
                          <p className="module--heading">{item.name} </p>
                          <p className="module-sub-heading mt-1">
                            {item?.viewed == 0
                              ? `${item?.total} sections`
                              : `${item?.viewed} of ${item?.total} sections viewed`}
                          </p>
                          <div
                            className="d-flex align-items-center gap-2 text-white mt-3"
                            style={{ fontSize: "0.95rem" }}
                          >
                            {item?.viewed == 0
                              ? "Start"
                              : item?.viewed == item?.total
                              ? "Completed"
                              : "Continue"}
                            {item?.viewed !== item?.total && (
                              <KeyboardArrowRightIcon
                                style={{ fontSize: "1.2rem", marginTop: "3px" }}
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          {item.viewed > 0 && item.viewed < item.total && (
                            <img
                              src="/assets/img/course_internal_journey/keep-going.png"
                              alt=""
                              width={40}
                              height={40}
                              style={{ minWidth: "40px", minHeight: "40px" }}
                            />
                          )}
                        </div>
                        {item.viewed == item.total && (
                          <img
                            src="/assets/img/course_internal_journey/completed.png"
                            alt=""
                            style={{
                              padding: "8px",
                              minWidth: "40px",
                              minHeight: "40px",
                            }}
                            width={40}
                            height={40}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </section>
        </>
      )}
    </section>
  );
};

export default Home;
