import React from "react";
import ".././Plans.css";
import { useAuth } from "../../../auth";
import { useContext } from "react";
import { AppContext } from "../../../context";
import { Link, useNavigate } from "react-router-dom";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { FormControl, MenuItem, Select } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useState } from "react";
import { useRef } from "react";

const dropdownArray = [
  {
    id: "care",
    name: "Care",
  },
  {
    id: "platinum",
    name: "Platinum",
  },
  {
    id: "preminum",
    name: "Premium",
  },
  {
    id: "super",
    name: "Super",
  },
];

const ComparePlans = () => {
  const { currentUser } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [option1, setOption1] = useState("care");
  const [option2, setOption2] = useState("preminum");
  const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];

  const onFree = () => {
    if (!currentUser) {
      localStorage.setItem("retrunurl", "/");
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    } else {
      window.open(`https://stella.unitedwecare.com/?token=${authToken}`);
      navigate("/");
    }
  };

  const onPaid = (item) => {
    sessionStorage.setItem("subscriptionID", item);
    if (!currentUser) {
      localStorage.setItem("retrunurl", `/subscription-order-summary`);
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    } else {
      navigate(`/subscription-order-summary`);
    }
  };

  const handleChangeOption1 = (event) => {
    setOption1(event.target.value);
  };

  const handleChangeOption2 = (event) => {
    setOption2(event.target.value);
  };

  const comparisonData = [
    {
      feature: "",
      toolTip: "",
      plans: {
        care: (
          <div className="d-flex flex-column align-items-center">
            <h3
              className="font-roboto font-w700 text-black"
              style={{ fontSize: "14px" }}
            >
              No Cost
            </h3>
            <button
              className="rounded-2 px-2 py-1 border-0"
              style={{ background: "var(--primary-color)", color: "white" }}
              onClick={onFree}
            >
              Start Now
            </button>
          </div>
        ),
        platinum: (
          <div className="d-flex flex-column align-items-center">
            <h3
              className="font-roboto font-w700 text-black"
              style={{ fontSize: "14px" }}
            >
              INR 9999
              <span
                className="font-w500"
                style={{ color: "#737373", fontSize: "11px" }}
              >
                {" "}
                /month
              </span>
            </h3>
            <button
              className="rounded-2 px-2 py-1 border-0"
              style={{ background: "var(--primary-color)", color: "white" }}
              onClick={() => onPaid(5)}
            >
              Subscribe
            </button>
            <button
              className="font-roboto font-w500 bg-transparent border-0"
              style={{ fontSize: "10px", textDecoration: "underline" }}
              onClick={() => onLearnMore(5)}
            >
              Learn more
            </button>
          </div>
        ),
        preminum: (
          <div className="d-flex flex-column align-items-center">
            <h3
              className="font-roboto font-w700 text-black"
              style={{ fontSize: "14px" }}
            >
              INR 999
              <span
                className="font-w500"
                style={{ color: "#737373", fontSize: "11px" }}
              >
                {" "}
                /month
              </span>
            </h3>
            <button
              className="rounded-2 px-2 py-1 border-0"
              style={{ background: "var(--primary-color)", color: "white" }}
              onClick={() => onPaid(7)}
            >
              Subscribe
            </button>
            <button
              className="font-roboto font-w500 bg-transparent border-0"
              style={{ fontSize: "10px", textDecoration: "underline" }}
              onClick={() => onLearnMore(7)}
            >
              Learn more
            </button>
          </div>
        ),
        super: (
          <div className="d-flex flex-column align-items-center">
            <h3
              className="font-roboto font-w700 text-black"
              style={{ fontSize: "14px" }}
            >
              INR 2999
              <span
                className="font-w500"
                style={{ color: "#737373", fontSize: "11px" }}
              >
                {" "}
                /month
              </span>
            </h3>
            <button
              className="rounded-2 px-2 py-1 border-0"
              style={{ background: "var(--primary-color)", color: "white" }}
              onClick={() => onPaid(6)}
            >
              Subscribe
            </button>
            <button
              className="font-roboto font-w500 bg-transparent border-0"
              style={{ fontSize: "10px", textDecoration: "underline" }}
              onClick={() => onLearnMore(6)}
            >
              Learn more
            </button>
          </div>
        ),
      },
    },
    {
      feature: (
        <div
          className="d-flex align-items-center gap-2 font-roboto font-w700"
          style={{ fontSize: "14px" }}
        >
          <OverlayTrigger
            trigger={["click", "hover", "focus"]}
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                Your ultimate AI coach on the journey to achieve Mental
                Wellbeing
              </Tooltip>
            }
          >
            <ErrorOutlineIcon fontSize="10px" />
          </OverlayTrigger>
          Stella
        </div>
      ),
      plans: {
        care: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        platinum: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        preminum: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        super: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        default: "-",
      },
    },
    {
      feature: (
        <div
          className="d-flex align-items-center gap-2 font-roboto font-w700"
          style={{ fontSize: "14px" }}
        >
          <OverlayTrigger
            trigger={["click", "hover", "focus"]}
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                Clinically approved, self-administered assessments to help
                discover underlying concerns
              </Tooltip>
            }
          >
            <ErrorOutlineIcon fontSize="10px" />
          </OverlayTrigger>
          Assessment with stella
        </div>
      ),
      plans: {
        care: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        platinum: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        preminum: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        super: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        default: "-",
      },
    },
    {
      feature: (
        <div
          className="d-flex align-items-center gap-2 font-roboto font-w700"
          style={{ fontSize: "14px" }}
        >
          <OverlayTrigger
            trigger={["click", "hover", "focus"]}
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                A diverse range of resources in the form of audio, video, and
                blogs
              </Tooltip>
            }
          >
            <ErrorOutlineIcon fontSize="10px" />
          </OverlayTrigger>
          Exclusive self care content
        </div>
      ),
      plans: {
        care: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        platinum: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        preminum: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        super: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        default: "-",
      },
    },
    {
      feature: (
        <div
          className="d-flex align-items-center gap-2 font-roboto font-w700"
          style={{ fontSize: "14px" }}
        >
          <OverlayTrigger
            trigger={["click", "hover", "focus"]}
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                Access to top-tier courses to elevate your mental health and
                well-being.
              </Tooltip>
            }
          >
            <ErrorOutlineIcon fontSize="10px" />
          </OverlayTrigger>
          Premium Courses
        </div>
      ),
      plans: {
        care: "Basic Wellness Programs",
        platinum: "40+ exclusive courses",
        preminum: "40+ exclusive courses",
        super: "40+ exclusive courses",
        default: "-",
      },
    },
    {
      feature: (
        <div
          className="d-flex align-items-center gap-2 font-roboto font-w700"
          style={{ fontSize: "14px" }}
        >
          <OverlayTrigger
            trigger={["click", "hover", "focus"]}
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                Tailored to your preferences, with top certified professionals
              </Tooltip>
            }
          >
            <ErrorOutlineIcon fontSize="10px" />
          </OverlayTrigger>
          Personalised Sessions
        </div>
      ),
      plans: {
        care: "-",
        platinum: "Unlimited 1-on-1 sessions",
        preminum: "-",
        super: "3 hours live sessions",
        default: "-",
      },
    },
    {
      feature: (
        <div
          className="d-flex align-items-center gap-2 font-roboto font-w700"
          style={{ fontSize: "14px" }}
        >
          <OverlayTrigger
            trigger={["click", "hover", "focus"]}
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                Tailored to your preferences, with top certified professionals
              </Tooltip>
            }
          >
            <ErrorOutlineIcon fontSize="10px" />
          </OverlayTrigger>
          Personalised Care Plans
        </div>
      ),
      plans: {
        care: "-",
        platinum: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        preminum: "-",
        super: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1567_3714"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path d="M16 0H0V16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1567_3714)">
              <path
                d="M7.99312 15.7323C12.2638 15.7323 15.7264 12.2703 15.7264 7.99896C15.7264 3.7283 12.2638 0.265625 7.99312 0.265625C3.72179 0.265625 0.259766 3.7283 0.259766 7.99896C0.259766 12.2703 3.72179 15.7323 7.99312 15.7323Z"
                fill="#76A83A"
              />
              <path
                d="M6.46956 11.2062L4.65621 9.43482C4.61688 9.39682 4.58554 9.35149 4.56421 9.30082C4.54288 9.25016 4.53223 9.19616 4.53223 9.14149C4.53223 9.08682 4.54288 9.03283 4.56421 8.98216C4.58554 8.93216 4.61688 8.88616 4.65621 8.84816L5.25757 8.26149C5.33824 8.18349 5.44623 8.13949 5.55823 8.13949C5.67023 8.13949 5.77822 8.18349 5.85889 8.26149L6.93823 9.31683L9.96623 4.71415C10.0282 4.62149 10.1249 4.55615 10.2342 4.53282C10.3435 4.50949 10.4576 4.53016 10.5529 4.58949L11.2682 5.03882C11.3149 5.06749 11.3556 5.10549 11.3876 5.15016C11.4196 5.19483 11.4416 5.24549 11.4536 5.29882C11.4656 5.35282 11.4669 5.40815 11.4569 5.46215C11.4469 5.51615 11.4262 5.56749 11.3962 5.61349L7.81156 11.0648C7.73956 11.1715 7.64491 11.2615 7.53491 11.3275C7.42491 11.3942 7.30088 11.4355 7.17354 11.4488C7.04554 11.4622 6.91623 11.4475 6.79423 11.4055C6.6729 11.3635 6.56156 11.2955 6.46956 11.2062Z"
                fill="white"
              />
            </g>
          </svg>
        ),
        default: "-",
      },
    },
  ];

  const onLearnMore = (item) => {
    debugger;
    if (item === 5) {
      navigate("/platinum-care-plan", { replace: true });
    } else if (item === 6) {
      navigate("/super-care-plan", { replace: true });
    } else if (item === 7) {
      navigate("/premium-care-plan", { replace: true });
    }
  };

  return (
    <div>
      {/* <!------ Compare-plan-area (desktop-display) ------> */}
      <div class="compare-plan-area mobile-display-none desktop-display">
        <div className="container">
          <h2 className="heading">
            <span className="diff-clr">Compare</span> our plans
          </h2>
          <p className="mobile-display-none desktop-display">
            Plans which help you save money everyday
          </p>
          <div className="compare-plan-inner">
            <img src="assets/img/comparePlans_1.png" alt="" />
            <div
              className="compare-plan-inner-desktop py-3"
              style={{ background: "#F2EEFF" }}
            >
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column justify-content-center gap-2 col-4 px-3">
                  <h3 className="">Plan Pricing</h3>
                </div>
                <div className="d-flex align-items-baseline justify-content-between col-8 px-2">
                  <div className="d-flex flex-column align-items-center justify-content-center gap-3 col-3">
                    <h3>No Cost</h3>
                    <button className="px-2 py-1" onClick={onFree}>
                      Start Now
                    </button>
                    {/* <p>{" "}</p> */}
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center gap-3 col-3">
                    <h3>
                      INR 999
                      <span
                        style={{
                          color: "#737373",
                          fontFamily: "Roboto",
                          fontSize: "23px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20px",
                        }}
                      >
                        {" "}
                        /month
                      </span>
                    </h3>
                    <button className="px-2 py-1" onClick={() => onPaid(7)}>
                      Subscribe
                    </button>
                    <button
                      className="compare-plan-learnMore-link bg-transparent"
                      onClick={() => onLearnMore(7)}
                    >
                      Learn more
                    </button>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center gap-3 col-3">
                    <h3>
                      INR 2999
                      <span
                        style={{
                          color: "#737373",
                          fontFamily: "Roboto",
                          fontSize: "23px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20px",
                        }}
                      >
                        /month
                      </span>
                    </h3>
                    <button className="px-2 py-1" onClick={() => onPaid(6)}>
                      Subscribe
                    </button>
                    <button
                      className="compare-plan-learnMore-link bg-transparent"
                      onClick={() => onLearnMore(6)}
                    >
                      Learn more
                    </button>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center gap-3 col-3">
                    <h3>
                      INR 9999
                      <span
                        style={{
                          color: "#737373",
                          fontFamily: "Roboto",
                          fontSize: "23px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20px",
                        }}
                      >
                        /month
                      </span>
                    </h3>
                    <button className="px-2 py-1" onClick={() => onPaid(5)}>
                      Subscribe
                    </button>
                    <button
                      className="compare-plan-learnMore-link bg-transparent"
                      onClick={() => onLearnMore(5)}
                    >
                      Learn more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!------ Compare-plan-area (mobile-display) ------> */}
      <div
        className="compare-plan-area mobile-display desktop-display-none"
        style={{ background: "#fff" }}
      >
        <div className="">
          <h2 className="heading">
            <span className="diff-clr">Compare</span> our plans
          </h2>
          <Table
            hover
            style={{ width: "100vw", padding: "0 5px", background: "#fff" }}
          >
            <thead>
              <tr>
                <th style={{ verticalAlign: "middle" }}>
                  <h3
                    className="font-roboto text-black font-w700 lh-1"
                    style={{ fontSize: "16px" }}
                  >
                    Choose
                  </h3>
                </th>
                <th>
                  <FormControl sx={{ width: 100 }} size="small">
                    <Select
                      value={option1}
                      onChange={handleChangeOption1}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {dropdownArray?.map((option, index) => (
                        <MenuItem value={option?.id}>{option?.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </th>
                <th>
                  <FormControl sx={{ width: 100 }} size="small">
                    <Select
                      value={option2}
                      onChange={handleChangeOption2}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {dropdownArray?.map((option, index) => (
                        <MenuItem value={option?.id}>{option?.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </th>
              </tr>
            </thead>
            <tbody>
              {comparisonData.map((data) => (
                <tr className="bg-white">
                  <td className="text-start" style={{ verticalAlign: "top" }}>
                    <div className="d-flex align-items-center gap-2 font-roboto">
                      {data?.feature}
                    </div>
                  </td>
                  <td
                    className="text-center font-roboto"
                    style={{ fontSize: "12px", verticalAlign: "top" }}
                  >
                    {data.plans[option1]}
                  </td>
                  <td
                    className="text-center font-roboto"
                    style={{ fontSize: "12px", verticalAlign: "top" }}
                  >
                    {data.plans[option2]}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ComparePlans;
