import React from "react";
import { Link, useNavigate } from "react-router-dom";

const AssessmentCard = ({
  id,
  questionCount,
  title,
  usersCount,
  author,
  backgroundImageUrl,
  stellaKey,
}) => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];


  return (
    <div className="AssessmentCard">
      <div
        className="d-flex flex-column"
        style={{ minHeight: "200px", height: "100%" }}
      >
        <div
          className="d-flex align-items-start py-3 px-3 gap-1"
          style={{
            border: "1px solid #73589b40",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            flex: 1,
          }}
        >
          <div className="" style={{ color: "rgb(102, 112, 133)" }}>
            <h3 className="font-inter font-w600 fs-5 lh-base AssessmentCard--title">
              {title}
            </h3>
            <p className="font-inter lh-base AssessmentCard--question">
              {questionCount} question(s)
            </p>
            <b className="font-inter lh-base AssessmentCard--author mt-1">
              {author}
            </b>
          </div>
        </div>

        <button
          id="professional_book_now"
          className="d-flex border-0 font-inter py-2 text-center AssessmentCard--Button"
          style={{
            textAlign: "center",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            justifyContent: "center",
          }}
          onClick={() => {
            window.open(`https://stella.unitedwecare.com/?token=${authToken}&message=${stellaKey}`);
          }}
          fdprocessedid="zsk37f"
        >
          <div className="book-now-a-professional text-decoration-none fs-6 text-white text-center py-1 d-flex align-items-center gap-2">
            <span style={{ lineHeight: "120%" }}>Start</span>
            <svg
              width="20"
              height="18"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.23769 3.76387L3.1488 2.66535M10.0444 3.76387L11.1332 2.66535M3.14806 10.7246L4.23695 9.62535M7.14065 2.29942V0.833496M2.7851 6.69498H1.33398M11.6118 11.0824L14.4369 9.96683C14.5051 9.93955 14.5635 9.89248 14.6047 9.83168C14.6459 9.77088 14.6679 9.69914 14.6679 9.62572C14.6679 9.5523 14.6459 9.48056 14.6047 9.41976C14.5635 9.35896 14.5051 9.31188 14.4369 9.28461L7.64806 6.60683C7.58197 6.58125 7.50985 6.5755 7.44054 6.59028C7.37123 6.60505 7.30773 6.63971 7.25782 6.69002C7.2079 6.74033 7.17374 6.8041 7.15951 6.87352C7.14528 6.94295 7.15159 7.01501 7.17769 7.0809L9.83102 13.9335C9.95102 14.2446 10.3866 14.2446 10.5066 13.9335L11.6118 11.0824Z"
                stroke="white"
                strokeWidth="1.11111"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};

export default AssessmentCard;
