import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import VideoModal from "../../BookingJourney/Component/VideoModal";

const VideoCard = ({ item }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const open = Boolean(anchorEl);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  console.log("data?.createDateIST", data)
  const timestamp = data?.createDate;
  const dateObject = new Date(timestamp);
  const day = String(dateObject.getDate()).padStart(2, '0');
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[dateObject.getMonth()];
  const year = dateObject.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': '76d802e426a2cb28f3760c8c8f669983f67ed775'
          },
          body: JSON.stringify({
            postId: item?._id
          })
        };

        const response = await fetch('https://odoo.unitedwecare.ca/new-uwc/view/public/post', requestOptions);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();
        setData(jsonData?.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [item]);


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleVideoModalOpen = () => {
    setVideoModalOpen(true); // Open the modal
  };

  const handleVideoModalClose = () => {
    setVideoModalOpen(false); // Close the modal
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="Search--videoCard position-relative gap-2" style={{ cursor: 'pointer' }} onClick={handleVideoModalOpen}>
      <div style={{ position: "relative", display: "inline-block" }}>
        <img
          src={item?.thumbnail_url || item?.small_thumbnail_url || "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2FThumbnail.png?alt=media"}
          alt=""
          className="w-100"
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#73589B",
            zIndex: "100",
            background: "white",
            width: "60px",
            height: "60px",
          }}
          className=" p-2 rounded-circle"
        >
          <img
            src="/assets/img/play-video.png"
            alt=""
            style={{ width: "50px", height: "auto", aspectRatio: "1/1" }}
          />
        </div>
      </div>
      <div className="my-auto mx-1 d-flex flex-column justify-content-between gap-3">
        <div className="">
          <h6>{item?.title}</h6>
          <div className="d-flex flex-wrap-reverse gap-2 align-items-center justify-content-between">
            <div className="d-flex gap-2 jusify-content-between align-items-center lh-lg">
              {item?.club_id &&
                <div className="clubDiv">
                  <p style={{ color: "#fff", fontSize: "10px" }}>
                    {item?.club_id}
                  </p>
                </div>
              }
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="12"
                viewBox="0 0 2 12"
                fill="none"
              >
                <path
                  d="M1 11V1"
                  stroke="#D0D5DD"
                  strokeWidth="0.92"
                  strokeLinecap="round"
                />
              </svg>
              <p className="fw-semibold text-decoration-none">{item?.duration} min </p>
            </div>
            {formattedDate === null || formattedDate === NaN ?
              null
              :
              <p>
                {formattedDate}
              </p>
            }
          </div>
        </div>
      </div>

      <VideoModal
        showModal={videoModalOpen}
        closeModal={handleVideoModalClose}
        data={data}
      />
    </div>
  );
};

export default VideoCard;