import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../../auth';
import './VideoCard.css'
// import VideoModal from './VideoModal';
// import Modal from 'react-bootstrap/Modal';
// import ReactPlayer from "react-player";
import SharePopup from '../../Community/Components/SharePopup';

const BlogCard = ({ item }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalShow, setModalShow] = useState(false);
    const { navigate } = useNavigate();
    // const [videoModal, setVideoModal] = useState(false);
    const open = Boolean(anchorEl);
    // const { currentUser } = useAuth();
    // let userId = currentUser?.UserId
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShare = () => {
        setModalShow(true)
        handleClose();
    }

    const handleBlogClick = () => {
        window.open(item?.article_url, '_blank');
    };

    // const handleVideoModalClose = () => {
    //     setVideoModal(false); // Close the modal
    // };

    const timestamp = item?.createDateIST;

    // Parse the timestamp using the Date object
    const dateObject = new Date(timestamp);

    // Get the date components
    const day = String(dateObject.getDate()).padStart(2, '0');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[dateObject.getMonth()];
    const year = dateObject.getFullYear();
    // Create the formatted date string
    const formattedDate = `${day} ${month} ${year}`;

    return (
        <div className='BlogCard--module position-relative gap-2 justify-content-between'>
            <img src={item?.coverImageUrl || item?.smallThumbnailUrl} alt='' className='w-100' onClick={handleBlogClick} />
            <div className='my-1 mx-2 d-flex flex-column justify-content-between gap-3'>
                <div className='d-flex align-items-center justify-content-between'>
                    <h6 onClick={handleBlogClick} className="title-ellipsis" title={item?.title} style={{ cursor: 'pointer' }}>
                        {item?.title}
                    </h6>
                    <a onClick={handleMenuClick}>
                        <MoreVertIcon />
                    </a>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {/* {userId !== item?.data?.user?.userId ?
                            <MenuItem onClick={handleClose}>Report</MenuItem>
                            :
                            <MenuItem onClick={handleClose}>Delete</MenuItem>
                        } */}
                        <MenuItem onClick={handleShare} style={{ width: "140px" }} className='d-flex gap-3'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.59 12L15 7.41V9.87L14.14 10C9.83 10.61 6.91 12.87 5.24 16.33C7.56 14.69 10.44 13.9 14 13.9H15V16.59M13 14.92C8.53 15.13 5.33 16.74 3 20C4 15 7 10 14 9V5L21 12L14 19V14.9C13.67 14.9 13.34 14.91 13 14.92Z" fill="black" />
                            </svg>
                            Share
                        </MenuItem>
                    </Menu>
                </div>
                <div className='d-flex justify-content-between'>
                    <p>{formattedDate}</p>
                    <div className='clubDiv'>{item?.category?.name}</div>
                </div>
            </div>
            {item?.duration &&
                <span className='video-duration'>
                    {item?.duration}
                </span>
            }
            <img src='/assets/img/kareify/Play1.png' alt='' className='video-playBtn' onClick={handleBlogClick} />
            {/* <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className='video-playBtn'>
                <g filter="url(#filter0_b_3454_1239)">
                    <circle cx="24" cy="24" r="24" fill="white" />
                </g>
                <path d="M18.5454 14.1812L33.8181 23.9993L18.5454 33.8175V14.1812Z" stroke="#73589B" stroke-width="2.18" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <filter id="filter0_b_3454_1239" x="-10" y="-10" width="68" height="68" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3454_1239" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3454_1239" result="shape" />
                    </filter>
                </defs>
            </svg> */}

            {/* <VideoModal
                showModal={videoModal}
                closeModal={handleVideoModalClose}
                data={item}
            /> */}

            <SharePopup
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div >
    )
}

export default BlogCard