import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './Community.css'
import Post from './Components/Post'
import ClubCard from './Components/ClubCard'
import CreateComponent from './Components/CreateComponent';
import Rightbar from './Components/Rightbar';
import { Odoo_community_feed, getAllClubs, getCategoryList } from './_request';
import { useAuth } from '../../auth';
import { useSnackbar } from 'notistack';

const LandingPage = () => {
    const [select, setSelect] = useState("Feed");
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [postData, setPostData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [categoryList, setCategoryList] = useState();
    const [clubList, setClubList] = useState();
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const PAGE_SIZE = 10;
    const [page, setPage] = useState(0);

    const id = currentUser?.UserId;

    const { enqueueSnackbar } = useSnackbar();

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        getCategoryList(id).then(resp => {
            if (resp?.ResponseCode === 200) {
                setCategoryList(resp?.data)
            } else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        })

        getAllClubs(id).then(resp => {
            if (resp?.ResponseCode === 200) {
                setClubList(resp?.data)
            } else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        })

        fetchData_Post()
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop ===
                document.documentElement.offsetHeight
            ) {
                loadMoreData();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [postData, isLoading, page]);

    function fetchData_Post() {
        let body = {
            posttype: "post",
            page: page,
            count: PAGE_SIZE,
        };
        Odoo_community_feed(body).then((resp) => {
            setPostData(prevData => [...prevData, ...resp?.feed]);
            setPage(page + 1);
            setIsLoading(false);
        });
    }

    const loadMoreData = () => {
        if (isLoading) return;
        setIsLoading(true);
        fetchData_Post(page);
    };

    return (
        <div className='feed--container'>
            <div className='d-flex gap-3 selection--tab'>
                <div className={`${select === "Feed" ? "selectedTab" : "nonSelected"}`} onClick={() => setSelect('Feed')}>
                    Feed
                </div>
                <div className={`${select === "Clubs" ? "selectedTab" : "nonSelected"}`} onClick={() => setSelect('Clubs')}>
                    Clubs
                </div>
            </div>
            {
                select === "Feed" &&
                <div className='d-flex w-100'>
                    <div className='col-md-9 col-12'>
                        <div className='feed-container d-flex align-items-center justify-content-center px-3'>
                            <div className='feedDiv d-flex justify-content-md-around gap-5 gap-md-3 ps-md-0 col-md-8'>
                                <div className='d-flex d-md-none flex-column align-items-center w-100 gap-2' onClick={() => setDrawerOpen(true)}>
                                    <img src='/assets/img/community/addIcon.png' alt='' />
                                    <p className='text-center'>Upload</p>
                                </div>
                                {
                                    categoryList?.category?.map((item, index) =>
                                        <div key={index} className='d-flex flex-column align-items-center w-100 gap-2' style={{ cursor: "pointer" }} onClick={() => navigate(`/community/category/${item?.id}`)}>
                                            <img src={item?.imageUrl} alt='' />
                                            <p className='text-center'>{item?.name}</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <CreateComponent
                            open={drawerOpen}
                            onClose={toggleDrawer}
                        />
                        {postData && postData?.map((item, index) =>
                            <div className='my-3 mx-md-auto py-md-3 col-12 col-md-8'>
                                <Post item={item} key={index} />
                            </div>
                        )}
                    </div>
                    <div className='col-3 d-none d-md-block'>
                        <Rightbar />
                    </div>
                </div>
            }

            {
                select === "Clubs" &&
                <div className='d-flex flex-column flex-md-row flex-md-wrap col-12 h-100'>
                    {
                        clubList?.clubs?.map((item, index) =>
                            <div className='col-lg-4 col-md-6 col-sm-6 p-md-3 p-2' key={index}>
                                <ClubCard clubData={item} />
                            </div>
                        )
                    }
                </div>
            }
        </div>
    )
}

export default LandingPage