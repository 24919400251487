import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardCourse from './DashboardCourse';
import DashboardMyWorksheetTab from '../../../components/course/DashboardMyWorksheetTab'
import DashboardMyCoursesTab from '../../../components/course/DashboardMyCoursesTab'
import DashboardAppointmentHistoryTab from '../../../components/course/DashboardAppointmentHistoryTab';
import CourseQuestions from '../../../components/course/CourseQuestions';
import CoursePreview from '../../../components/course/CoursePreview';
import CourseBuffer from '../../../components/course/CourseBuffer';
import { Helmet } from 'react-helmet';

const DashRouting = () => {
    return (
        <Routes>
            <Route element={<DashboardCourse />}>
                <Route path='/appointments-history' element={<DashboardAppointmentHistoryTab />} />
                <Route path='/my-worksheets' element={<DashboardMyWorksheetTab />} />

                <Route path='/' element={<Navigate replace to="my-programs" />} />
            </Route>
            <Route path='program-preview/:id' element={<CoursePreview />} />
            <Route path='program-questions/:id' element={<CourseQuestions />} />
            <Route path='program-loading/:id' element={<CourseBuffer />} />
            <Route path='/my-programs' element={<DashboardMyCoursesTab />} />
        </Routes>
    )
}

export default DashRouting;