import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

const Blogs = ({ item }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className='videoListing--module position-relative' style={{ borderBottom: "1px solid #E4E4E4", background: "#FFF" }} onClick={() => navigate(`/community/feed/blog/${item?.id}`)}>
      <img src={item?.smallThumbnailUrl} alt='' className='w-100' />
      <div className='my-2 mx-1'>
        <div className='d-flex justify-content-between'>
          <div className='clubDiv my-2'>{item?.club?.name}</div>
          <a onClick={handleMenuClick}>
            <MoreVertIcon />
          </a>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>Report</MenuItem>
            <MenuItem onClick={handleClose}>Delete</MenuItem>
          </Menu>
        </div>
        <div className='d-flex justify-content-between'>
          <h6>{item?.title}</h6>
        </div>
      </div>
      <span className='article-duration'>
        {item?.duration}
      </span>
    </div >
  )
}

export default Blogs