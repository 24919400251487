import React, { useState } from "react";
import { useAuth } from "../../auth";
import { useNavigate } from "react-router";
import { getCurrencyCode } from "../core/commonFun";
import { useContext } from "react";
import { AppContext } from "../../context";
import { Skeleton } from "@mui/material";
import { handleBrokenImage } from "../../lib/utils";

const DashboardDetailCard = (props) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { data, loading } = props;
  const [sdata, setSdata] = useState();
  const CurrencyCode = getCurrencyCode();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);

  const onBookNow = (data) => {
    const currentUrl = encodeURIComponent(window.location.href);
    currentUser
      ? navigate(`/book-an-appointment/${data?.Id}`)
      // : setShowLloginPopup(true);
      : window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
  };

  return (
    data && (
      <div
        className="bg-white d-flex flex-column align-items-center justify-content-around px-2 py-3"
        style={{
          borderRadius: "12px",
          boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)",
        }}
      >
        <div className="row my-3 align-items-center justify-content-around">
          <div className="my-3 d-flex align-items-center justify-content-around col-xs-6 col-md-12">
            <img
              src={data?.ProfileImage}
              onError={(e) => {
                const newLink = handleBrokenImage(data?.ProfileImage);
                e.target.src = newLink;
              }}
              style={{
                height: 200,
                width: 200,
                borderRadius: "50%",
                backgroundColor: "rgb(0,0,0,0.9)",
              }}
              alt=""
            />
          </div>
          <div className="d-flex flex-column my-1 col-xs-6 col-md-12">
            <h6 className="text-center">{data?.Name}</h6>
            <p className="text-center fs-6">{data?.RegisterYourselfAs}</p>
            <p className="text-center fs-6">
              Years of Experience : {data?.Experience} yrs
            </p>
          </div>
        </div>
        <hr className="w-100" />
        {sdata && (
          <div className="d-flex flex-row justify-content-between w-100">
            <p className="fs-6">Followers {sdata?.followersCount}</p>
            <p className="fs-6">Following {sdata?.followingCount}</p>
          </div>
        )}

        <button
          onClick={() => onBookNow(data)}
          className="px-5 border-0"
          style={{
            background: "var(--primary-color)",
            borderRadius: "40px",
            height: "48px",
            color: "#fff",
          }}
        >
          Book Now
        </button>
      </div>
    )
  );
};

export default DashboardDetailCard;
