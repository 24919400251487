import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useNavigate } from "react-router-dom";
//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

//ICONS
import MoreVertIcon from '@mui/icons-material/MoreVert';

dayjs.extend(localizedFormat);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UpcomingAppointmentCard = ({
  id,
  name,
  img,
  type,
  time,
  date,
  status,
  joinURL,
  onReschedule,
  onAddNote,
  onPrescription,
  onCancel,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLocked, setIsLocked] = useState(false);
  const [isMatchingConditions, setIsMatchingConditions] = useState(false);
  const open = Boolean(anchorEl);

  const navigate = useNavigate()

  useEffect(() => {
    const currentDate = dayjs();
    const appointmentDateTime = dayjs(`${date} ${time}`, "DD MMM YYYY hh:mm A");

    const isToday = appointmentDateTime.isSame(currentDate, "day");
    const isWithinRange = currentDate.isBetween(
      appointmentDateTime.subtract(10, "minutes"),
      appointmentDateTime.add(30, "minutes"),
      null,
      "[)"
    );

    const diffInHours = appointmentDateTime.diff(currentDate, "hours");

    const isWithin48Hours = diffInHours <= 48;

    setIsMatchingConditions(isToday && isWithinRange);
    setIsLocked(isWithin48Hours);
  }, []);

  const checkTomorrow =
    dayjs(`${date} ${time}`, "DD MMM YYYY hh:mm A").format("DD/MM/YYYY") ==
    dayjs().add(1, "day").format("DD/MM/YYYY")
      ? true
      : false;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <>
      <div
        className={`d-flex flex-column align-items-center px-2 gap-2 UpcomingAppointmentCard  upcomingAppointment`}
      >
        <div
          className={`d-flex align-items-start justify-content-between p-3 rounded-2 col-10`}
        >
          {isLocked ? (
            <img src="/assets/img/lock_red_icon.png" alt="" />
          ) : (
            <img src="/assets/img/unlock_white_icon.png" alt="" />
          )}

          <div className="d-flex flex-column align-items-center justify-content-center">
            <img
              src={img}
              alt=""
              width={45}
              height={45}
              style={{ borderRadius: "50%" }}
            />
            <p className="font-inter font-w600 fs-6 text-black text-center name">
              {name}
            </p>
            <p
              className="font-inter AppointmentType"
              style={{ color: "#6A6C6A" }}
            >
              {type}
            </p>
          </div>
          <Button
            sx={{ minWidth: 0, padding: 0 }}
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon style={{ color: "#73589b", fontSize: '1.2rem'}} />
          </Button>
        </div>
        {(status == "Ongoing" || status == "Upcoming") && isMatchingConditions ? (
          <p className="font-inter font-w600 fs-6 AppointmentTime">{time}</p>
        ) : checkTomorrow ? (
          <p className="font-inter font-w600 fs-6 AppointmentTime">Tomorrow</p>
        ) : (
          <p className="font-inter font-w600 fs-6 AppointmentTime">{date}</p>
        )}
      </div>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="DarkDropdown"
      >
        <MenuItem sx={{ fontSize: "14px", color: "#FFF" }} onClick={onAddNote}>
          Add Note
        </MenuItem>
        <Divider />
        <MenuItem sx={{ fontSize: "14px" }} onClick={onReschedule}>
          Reschedule
        </MenuItem>
        <Divider />
        <MenuItem sx={{ fontSize: "14px", color: "#FFF" }} onClick={() => {
          navigate(`/expert/clients?client=${name}&id=${id}&tabIndex=4`)
        }}>
          Courses undertaken
        </MenuItem>
        <Divider />
        {!isLocked && (
          <MenuItem
            sx={{ fontSize: "14px", color: "#f57e8c !important" }}
            onClick={onCancel}
          >
            Cancel Appointment
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default UpcomingAppointmentCard;
