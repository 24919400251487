import { UserDetailsService } from "../components/core/_request";

export const useAuth = () => ({
  currentUser: localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : null,
  isLogedIn: async () => {
    const Authorization = localStorage.getItem("Authorization");
    if (Authorization) {
      var UserDetails = await UserDetailsService().then((resp) => resp.data);
      return UserDetails?.ResponseCode == 200 ?? false;
    }
    return false;
  },
  userType: {
    EXPERT: 3,
    ENDUSER: 4,
  },
  setCurrentUser: (profile) => {
    localStorage.setItem("profile", JSON.stringify(profile));
    window.dataLayer.push({
      'event': 'U_login',
      'user_id': profile?.UserId
    })
  },
  login: (data) => {
    localStorage.setItem(
      "Authorization",
      data.usertoken
    );
    let now = new Date();
    let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
    let firstName = data?.userdetails?.Name.split(" ").slice(0, 1).join("");
    document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
  },
  logout: () => {
    localStorage.removeItem("Authorization");
    localStorage.removeItem("profile");
    let now = new Date();
    let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
    document.cookie = `userType=${null};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
    document.cookie = `displayName=${null};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
  },
  updateMobileNo: (mobileNumber) => {
    var profileJSON = localStorage.getItem("profile");
    var profile = JSON.parse(profileJSON);
    profile.MobileNo = mobileNumber;
    var updatedProfileJSON = JSON.stringify(profile);
    localStorage.setItem("profile", updatedProfileJSON);
  },
});
