import React from "react";
import "../../styles/courses/Testimonials.css";

const TestimonialCard = (props) => {
  return (
    <section className="testimonials--card">
      <div className="card">
        <div className="card-data">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="47"
            height="39"
            viewBox="0 0 47 39"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.1061 0.46572C7.07568 6.88759 1.5037 14.3612 0.388768 22.8905C-1.3469 36.1658 10.5617 42.6634 16.5959 36.8258C22.6301 30.9869 19.0136 23.577 14.6444 21.5506C10.2753 19.5255 7.60318 20.2306 8.0694 17.523C8.53562 14.8167 14.751 7.3112 20.2124 3.81609C20.4059 3.65203 20.5294 3.42054 20.5576 3.16885C20.5859 2.91715 20.5167 2.66421 20.3643 2.4616L19.0136 0.710059C18.4275 -0.0495174 17.8653 -0.0203035 17.1061 0.464391V0.46572ZM43.5154 0.46572C33.485 6.88759 27.9117 14.3625 26.7981 22.8905C25.0624 36.1658 36.971 42.6634 43.0052 36.8258C49.0394 30.9869 45.4229 23.577 41.0524 21.5506C36.6833 19.5255 34.0112 20.2306 34.4774 17.523C34.9436 14.8167 41.1603 7.3112 46.6217 3.81609C46.8151 3.65184 46.9383 3.42027 46.9662 3.16858C46.9942 2.91689 46.9248 2.66405 46.7723 2.4616L45.4215 0.710059C44.8354 -0.0495174 44.2733 -0.0203035 43.5154 0.464391V0.46572Z"
              fill="#73589B"
            />
          </svg>
          <div className="card-heading mt-3">{props?.item?.title}</div>
          <div className="card-desc">{props?.item?.description}</div>
        </div>

        <div className="card-user">
          <div className="card-userImage">
            <img src={props?.item?.profile_url} />
          </div>
          <div className="card-userData">
            <p className="card-userName">{props?.item?.user_name}</p>
            <p className="card-userCourse">{props?.item?.location}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialCard;
