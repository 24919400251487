import React from "react";
import Sidebar from "./components/Sidebar";
import Home from "./components/Home";
import { Helmet } from 'react-helmet';

const CourseInternal = () => {
  return (
    <>
      <Helmet>
        <title>Course</title>
      </Helmet>
      <div style={{ display: "flex", overflow: "hidden", width: "100%" }}>
        <Home />
      </div>
    </>

  );
};

export default CourseInternal;
