import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { useAuth } from "../auth";
import StaticStellaContactCard from "../components/stella/StaticStellaContactCard";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Stella.css";
import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";

function StellaTestingJourney() {
  const stsic_alternamte_journey = new URLSearchParams(
    new URL(window.location.href).search
  ).get("journey");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [stellaDummyJsonData, setStellaDummyJsonData] = useState([]);
  const [stellaQuestionToAsk, setStellaQuestionToAsk] = useState([]);
  const [userResponse, setUserResponse] = useState("");
  const [stellaJsonQuestion, setStellaJsonQuestion] = useState([]);
  const [responseMessage, setresponseMessage] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [scoreToSHhow, setScoreToShow] = useState(0);
  const [stellaResponseToSendToServer, setStellaResponseToSendToServer] =
    useState([]);
  const [recommendationText, setRecommendationText] = useState(" ");
  const [
    recommendationSessionAndTutorial,
    setRecommendationSessionAndTutorial,
  ] = useState();
  const [showAssesmentScore, setShowAssesmentScore] = useState(false);
  const [sendScore, setSendScore] = useState(false);
  const { currentUser } = useAuth();
  const chatHistoryRef = useRef(null);
  const [completedTask, setCompletedTask] = useState(false);
  const [scrollDisable, setScrollDisable] = useState(true);
  const [loadingQuestion, setLoadingQuestion] = useState(true);
  const [showInputBox, setShowInputBox] = useState(false);
  const [loadingJsonData, setLoadingJsondata] = useState(false);
  //Audio States
  const [stellaChatHistory, setStellaChatHistory] = useState([]);
  const [responseButton, setResponseButton] = useState("");

  useEffect(() => {
    async function fetchStellaDummyJsonData() {
      setLoadingJsondata(true);
      try {
        const response = await fetch(
          `https://notifications.uwc.world/static-stella-journey/${stsic_alternamte_journey}`
        );
        // Check if the request was successful (status code in the range 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        // If successful, parse the response JSON
        const data = await response.json();
        setStellaDummyJsonData(data);
        setLoadingJsondata(false);
      } catch (error) {
        setLoadingJsondata(false);
        // Handle any errors that occurred during the fetch
        console.error("Error fetching data:", error.message);
      }
    }
    if (stsic_alternamte_journey !== undefined) {
      fetchStellaDummyJsonData();
    }
  }, []);

  useEffect(() => {
    if (stellaDummyJsonData && stellaDummyJsonData.questions) {
      setStellaJsonQuestion(stellaDummyJsonData.questions);
      setCurrentQuestionIndex(0); // Reset to the 0th index when new data is loaded
    }
  }, [stellaDummyJsonData]);

  useEffect(() => {
    if (stellaJsonQuestion.length > 0) {
      const questionId_to_display =
        stellaJsonQuestion[currentQuestionIndex]?.question_id;
      if (questionId_to_display !== undefined) {
        setStellaQuestionToAsk([]);
        setTimeout(() => {
          setLoadingQuestion(false);
          setStellaQuestionToAsk(
            stellaJsonQuestion.find(
              (item) =>
                Number(item.question_id) === Number(questionId_to_display)
            )
          );
        }, 2000);
        setLoadingQuestion(true);
      }
      if (
        stellaJsonQuestion[stellaJsonQuestion.length - 1]?.question_id ===
        stellaQuestionToAsk?.question_id
      ) {
        setSendScore(true);
        setStellaQuestionToAsk([]);
        setCompletedTask(true);
        setShowAssesmentScore(true);
        setScrollDisable(false);
      } else if (
        stellaQuestionToAsk?.options !== undefined &&
        stellaQuestionToAsk?.send_response === true
      ) {
        setCompletedTask(true);
      } else {
        setCompletedTask(false);
      }
    }
  }, [currentQuestionIndex, stellaJsonQuestion]);

  useEffect(() => {
    if (stellaQuestionToAsk.question_type === "Long-Text") {
      if (responseMessage !== " ") {
        const questionObject = {
          question: stellaQuestionToAsk?.question,
          response: responseMessage === " " ? responseButton : responseMessage,
        };
        setStellaResponseToSendToServer((prevArray) => [
          ...prevArray,
          questionObject,
        ]);
        const chatHistoryObject = {
          question: stellaQuestionToAsk,
          response: responseMessage === " " ? responseButton : responseMessage,
        };
        setStellaChatHistory((prevArray) => [...prevArray, chatHistoryObject]);
        setresponseMessage(" ");
      }
      setShowInputBox(true);
    } else {
      if (responseButton && responseButton !== " ") {
        const questionObject = {
          question: stellaQuestionToAsk?.question,
          response: responseButton?.text,
          value: responseButton.score,
          question_id: stellaQuestionToAsk?.question_id,
        };
        setStellaResponseToSendToServer((prevArray) => [
          ...prevArray,
          questionObject,
        ]);
        const chatHistoryObject = {
          question: stellaQuestionToAsk,
          response: responseMessage === " " ? responseButton : responseMessage,
        };
        setStellaChatHistory((prevArray) => [...prevArray, chatHistoryObject]);
        setresponseMessage(" ");
      }
    }
  }, [responseMessage, responseButton]);

  const handleMultiSelectButton = (data) => {
    setResponseButton(data);
  };

  useEffect(() => {
    if (responseMessage === " ") {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setUserResponse("");
    }
  }, [responseMessage, responseButton]);

  useEffect(() => {
    if (
      stellaJsonQuestion[stellaJsonQuestion.length - 1]?.question_id >=
      stellaQuestionToAsk?.question_id
    ) {
      let total_score = 0;
      stellaChatHistory.forEach((item) => {
        if (item?.question?.add_score === true) {
          setTotalScore((total_score = total_score + item.response.score));
        }
      });
    }
    getRecommendationText(totalScore);
  }, [stellaChatHistory]);

  const setToLocalStorage = async (recordId) => {
    try {
      await localStorage.setItem("record_id", recordId.toString());
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const setrRemoveLocalStorage = async (recordId) => {
    try {
      await localStorage.removeItem("record_id");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const responseDataPostHandler = async (responseData) => {
    const payload = {
      journey: stsic_alternamte_journey,
      total_score: `${stellaDummyJsonData?.total_score}`,
      result_text: recommendationText,
      response: responseData?.slice(1),
      score: `${sendScore ? totalScore : 0}`,
      record_id:
        localStorage.getItem("record_id") !== null &&
        localStorage.getItem("record_id") !== undefined
          ? parseInt(localStorage.getItem("record_id"))
          : -1,
    };

    fetch("https://notifications.uwc.world/wtw-journey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    return fetch(
      "https://odoo.unitedwecare.ca/new-uwc/uwc-test/uwc-temp-test-data",
      {
        method: "POST",
        headers: {
          "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
          "Content-Type": "application/json",
          Cookie: "session_id=22e0685c96076f7c16e805b500bcebee1ff3e35d",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json(); // Parse the JSON response
        } else {
          throw new Error("API call failed!");
        }
      })
      .then((data) => {
        // Assuming the structure of the response is like { data: { data: { record_id: 'your_value' } } }
        const recordId = data?.data?.record_id;
        if (recordId) {
          setToLocalStorage(recordId);
        } else {
          throw new Error("Record ID not found in the response!");
        }

        return data; // You may return the response data if needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // To sow the Total Score for

  useEffect(() => {
    if (stsic_alternamte_journey === "happiness_and_wellbeing") {
      setScoreToShow(Math.round(totalScore / 29));
    } else {
      setScoreToShow(totalScore);
    }
  }, [totalScore]);

  const responseDataPostHandlerCallInsideThen = (responseData) => {
    console.log("Your total score is:", totalScore);
    const payload = {
      journey: stsic_alternamte_journey,
      total_score: `${stellaDummyJsonData?.total_score}`,
      result_text: recommendationText,
      response: responseData?.slice(1),
      score: `${sendScore ? totalScore : 0}`,
      record_id:
        localStorage.getItem("record_id") !== null &&
        localStorage.getItem("record_id") !== undefined
          ? parseInt(localStorage.getItem("record_id"))
          : -1,
    };

    return fetch(
      "https://odoo.unitedwecare.ca/new-uwc/uwc-test/uwc-temp-test-data",
      {
        method: "POST",
        headers: {
          "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
          "Content-Type": "application/json",
          Cookie: "session_id=22e0685c96076f7c16e805b500bcebee1ff3e35d",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json(); // Parse the JSON response
        } else {
          throw new Error("API call failed!");
        }
      })
      .then((data) => {
        // Assuming the structure of the response is like { data: { data: { record_id: 'your_value' } } }
        const recordId = data?.data?.record_id;
        if (recordId) {
          setToLocalStorage(recordId);
        } else {
          throw new Error("Record ID not found in the response!");
        }

        return data; // You may return the response data if needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (stellaDummyJsonData?.recommendation) {
      const expert_recomendation = stellaDummyJsonData?.recommendation.find(
        ({ lower, upper }) => totalScore >= lower && totalScore <= upper
      );
      setRecommendationSessionAndTutorial(
        expert_recomendation
          ? expert_recomendation.expert_suggestion
          : "No matching recommendation found."
      );
    }
  }, [stellaDummyJsonData, totalScore]);

  function getRecommendationText(total_score) {
    if (stellaDummyJsonData?.recommendation) {
      const matchedRecommendation = stellaDummyJsonData?.recommendation.find(
        ({ lower, upper }) => total_score >= lower && total_score <= upper
      );
      setRecommendationText(
        matchedRecommendation
          ? matchedRecommendation.text
          : "No matching recommendation found."
      );
    } else {
      return "Recommendation array is not defined.";
    }
  }

  useEffect(() => {
    let time_afterCall;
    if (completedTask === true && sendScore === false) {
      time_afterCall =
        Math.floor(Math.random() === 0 ? 1 : Math.random() * 3) * 1000;
      // Initial
      responseDataPostHandler(stellaResponseToSendToServer)
        .then((response) => {
          if (response.ResponseCode === 200) {
          } else {
            setTimeout(() => {
              responseDataPostHandlerCallInsideThen(
                stellaResponseToSendToServer
              );
            }, time_afterCall);
          }
        })
        .catch((error) => {
          throw new Error(`Error in first API call: ${error.message}`);
        });
    } else if (completedTask === true && sendScore === true) {
      time_afterCall =
        Math.floor(Math.random() === 0 ? 1 : Math.random() * 10) * 1000;
      responseDataPostHandler(stellaResponseToSendToServer)
        .then((response) => {
          if (response.ResponseCode === 200) {
            setrRemoveLocalStorage();
          } else {
            setTimeout(() => {
              responseDataPostHandlerCallInsideThen(
                stellaResponseToSendToServer
              );
            }, time_afterCall);
          }
        })
        .catch((error) => {
          throw new Error(`Error in first API call: ${error.message}`);
        });
    }

    setCompletedTask(false);
  }, [completedTask]);

  const scrollChatToBottom = () => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  };

  //Initially body scroll Y
  useEffect(() => {
    document.body.style.overflowY = "scroll";
  }, []);

  const handleSendButton = () => {
    setresponseMessage(userResponse);
    if (currentUser == undefined) {
    } else {
      if (userResponse !== "") {
        onEnterClicked();
        setUserResponse("");
      }
    }
  };

  const onEnterClicked = () => {
    setresponseMessage(userResponse);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && userResponse !== "") {
      onEnterClicked();
      setUserResponse("");
    }
  };

  const handleTextChange = (e) => {
    setUserResponse(e.target.value);
  };

  useEffect(() => {
    if (scrollDisable === true) {
      scrollChatToBottom();
    }
  }, [stellaChatHistory, stellaQuestionToAsk]);

  useEffect(() => {
    const responseDataPostHandleronReload = async () => {
      const payload = {
        journey: stsic_alternamte_journey,
        total_score: `${stellaDummyJsonData?.total_score}`,
        result_text: recommendationText,
        response: stellaResponseToSendToServer?.slice(1),
        score: `${sendScore ? totalScore : 0}`,
        record_id:
          localStorage.getItem("record_id") !== null &&
          localStorage.getItem("record_id") !== undefined
            ? parseInt(localStorage.getItem("record_id"))
            : -1,
      };

      fetch("https://notifications.uwc.world/wtw-journey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      return fetch(
        "https://odoo.unitedwecare.ca/new-uwc/uwc-test/uwc-temp-test-data",
        {
          method: "POST",
          headers: {
            "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
            "Content-Type": "application/json",
            Cookie: "session_id=22e0685c96076f7c16e805b500bcebee1ff3e35d",
          },
          body: JSON.stringify(payload),
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json(); // Parse the JSON response
          } else {
            throw new Error("API call failed!");
          }
        })
        .then((data) => {
          // Assuming the structure of the response is like { data: { data: { record_id: 'your_value' } } }
          const recordId = data?.data?.record_id;
          if (recordId) {
            setToLocalStorageOnReload(recordId);
          } else {
            throw new Error("Record ID not found in the response!");
          }

          return data; // You may return the response data if needed
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    const setToLocalStorageOnReload = async (recordId) => {
      try {
        await localStorage.setItem("record_id", recordId.toString());
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      if (
        localStorage.getItem("record_id") !== null &&
        localStorage.getItem("record_id") !== undefined
      ) {
        responseDataPostHandleronReload();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup: Remove the event listener when the component is unmounted
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    stsic_alternamte_journey,
    stellaDummyJsonData,
    stellaResponseToSendToServer,
    totalScore,
  ]);

  return (
    <>
      <Helmet>
        <title>Talk to Stella | United We Care</title>
      </Helmet>
      {window.scrollTo(0, 0)}
      <div className="min-h-screen stella-screen mb-5 pb-5">
        <div className="chat-box">
          <div className="chat-box--header">
            <Button className="StellaAvatar--Selector" sx={{ minWidth: "max" }}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Fstella%2Ffaces%2F1.png?alt=media"
                width={60}
                height={60}
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                  objectPosition: "center top",
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M11.9984 22.0914C17.3004 22.0914 21.5984 17.7933 21.5984 12.4914C21.5984 7.18942 17.3004 2.89136 11.9984 2.89136C6.6965 2.89136 2.39844 7.18942 2.39844 12.4914C2.39844 17.7933 6.6965 22.0914 11.9984 22.0914Z"
                  fill="#541CF5"
                  stroke="white"
                  strokeWidth="1.90119"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 8.49146V16.4915"
                  stroke="white"
                  strokeWidth="1.02981"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 12.4917H16"
                  stroke="white"
                  strokeWidth="1.02981"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>

            <div className="chat-box--logo">
              <p>Hi I am Stella</p>
            </div>
          </div>

          <div className="chat-box--content">
            <div className="chat-content--buttonArea px-3 d-flex align-items-center gap-2 bg-white">
              <div className="chat-box--logo  d-flex align-items-center gap-3">
                <span className="pingContainer">
                  <span className="animate-ping"></span>
                  <span className="pingDot"></span>
                </span>
                <p>LIVE Chat</p>
              </div>
            </div>

            <div ref={chatHistoryRef} className="chat-content--chats">
              <div className="response-area">
                <div className="avatar">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Fstella%2Ffaces%2F1.png?alt=media"
                    alt="My Avatar"
                  />
                </div>
                <div style={{ width: "100%" }} className="space-y-5">
                  {!loadingJsonData && stellaDummyJsonData.length !== 0 && (
                    <div className={`response server-response`}>
                      {stellaDummyJsonData?.expert_info}
                    </div>
                  )}
                  {!loadingJsonData && stellaDummyJsonData.length === 0 && (
                    <div className={`response server-response`}>
                      Hope you are having a good day!
                    </div>
                  )}
                  {stellaChatHistory &&
                    stellaChatHistory?.slice(1).map((item, index) => (
                      <div key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <div className={`response server-response`}>
                              {item?.question?.question}
                              {item?.question?.options?.map((button) => (
                                <div
                                  key={button?.button_id}
                                  className="response-buttons"
                                >
                                  <div className="button--item">
                                    {button?.text}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Grid>
                          <Grid item xs={12} align="right">
                            <div
                              className="response my-response"
                              style={{
                                marginRight: "50px",
                                textAlign: "start",
                              }}
                            >
                              {/* {item?.response} */}
                              {item?.question?.question_type === "Long-Text"
                                ? item.response
                                : item.response?.text}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ))}

                  {stellaJsonQuestion[stellaJsonQuestion.length - 1]
                    ?.question_id >= stellaQuestionToAsk?.question_id && (
                    <>
                      {[stellaQuestionToAsk]?.map((item) => (
                        <div
                          className={`response server-response`}
                          key={item?.id}
                        >
                          {item?.question}
                          {item?.options?.map((button) => (
                            <div
                              className="response-buttons"
                              key={button?.button_id}
                              onClick={() => handleMultiSelectButton(button)}
                            >
                              <div className="button--item">{button?.text}</div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </>
                  )}

                  {showAssesmentScore && (
                    <div>
                      <div className={`response server-response`}>
                        Your{" "}
                        {`${stellaDummyJsonData?.journey_info} total score is ${scoreToSHhow}`}
                      </div>
                      <div
                        style={{ marginTop: "15px" }}
                        className={`response server-response`}
                      >
                        {`${recommendationText}`}
                      </div>
                      <div
                        style={{ marginTop: "15px" }}
                        className={`response server-response`}
                      >
                        <p>Here are some recommendations.</p>
                      </div>
                      <div>
                        {recommendationSessionAndTutorial.map((item, index) => (
                          <div className={`response server-response my-2`}>
                            <StaticStellaContactCard
                              key={item.index}
                              id={item.index}
                              type={item.type}
                              name={`${item.title}`}
                              image={`${item.exp_image}`}
                              profession={`${item.profession}`}
                              expInYears={`${item.experience}`}
                              buttonText={`${item.buttonText}`}
                              languages={`${item.language}`}
                              url={item.session_link}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {loadingQuestion && (
                <div id="wave-animation" style={{ marginLeft: "50px" }}>
                  <div id="loading-bubble" className="grey">
                    <div className="spinner">
                      <div className="bounce1"></div>
                      <div className="bounce2"></div>
                      <div className="bounce3"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* {showInput && ( */}
            {showInputBox && (
              <div className="chat-content--input">
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    value={userResponse}
                    placeholder="Start Typing..."
                    onChange={(e) => handleTextChange(e)}
                    onKeyDown={handleKeyPress}
                    disabled={
                      stellaQuestionToAsk?.question_type === "single_select"
                    }
                  />
                  <button
                    className="send-input--button"
                    onClick={handleSendButton}
                    disabled={userResponse === ""}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default StellaTestingJourney;
