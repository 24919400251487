import React, { useEffect, useState } from 'react'
import './Plans.css'
import "./PlanDetailsPage.css" 
import PlanDetailsReviewSlide from '../planDetails/PlanDetailsReviewSlide';
import HeroArea from './Components/HeroArea';
import PersonalDataArea from './Components/PersonalDataArea';
import PickUpPlans from './Components/PickUpPlans';
import ComparePlans from './Components/ComparePlans';
import TrustArea from './Components/TrustArea';
import ReasonArea from './Components/ReasonArea';
import ReviewArea from './Components/ReviewArea';
import FaqArea from './Components/FaqArea';
import { Helmet } from 'react-helmet';
import { getCountryCode } from '../core/commonFun';
import ComparePlanOutsideIndia from './Components/ComparePlanOutsideIndia';



const NewPlansPage1 = () => {

    const [isCountryIndia, setIsCountryIndia] = useState();

    var countryCode = getCountryCode();
    useEffect(() => {
        if (countryCode === "IN") {
            setIsCountryIndia(true)
        } else {
            setIsCountryIndia(false)
        }
    }, []);

    return (
        <div className="main bg-md-white new-plans">
            <Helmet>
                <script src="//code.tidio.co/y84keavfozv8pkn1ar3c4h0cofpeq7le.js" async></script>
                <title>
                    Choose Your Mental Wellness Plan | United We Care Subscription
                </title>
            </Helmet>
            <HeroArea />
            <PersonalDataArea />
            <PickUpPlans />
            {isCountryIndia ?
                <ComparePlans /> :
                <ComparePlanOutsideIndia />
            }
            <TrustArea />
            <ReasonArea />
            <ReviewArea />
            <FaqArea />
            <div style={{ clear: 'both', marginBottom: "-10px" }}>
                <PlanDetailsReviewSlide />
            </div>
        </div >
    )
}

export default NewPlansPage1;