import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CreatePost from './CreatePost';
import CreateVideo from './CreateVideo';
import CreateBlog from './CreateBlog';
import CreatePodcast from './CreatePodcast';

const Create = (props) => {
    const renderCreateForm = () => {
        switch (props?.type) {
            case 'Post':
                return <CreatePost closeModal={props?.onHide} />;
            case 'Video':
                return <CreateVideo closeModal={props?.onHide} />;
            case 'Blog':
                return <CreateBlog closeModal={props?.onHide} />;
            case 'Podcast':
                return <CreatePodcast closeModal={props?.onHide} />;
            default:
                return null;
        }
    };
    return (
        <Modal
            {...props}
            size={props?.type === "Blog" ? 'xl' : ''}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={`createModal create-${props?.type === 'Blog' ? 'Blog' : 'Other'}`}
            autoFocus={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props?.type}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderCreateForm()}
            </Modal.Body>
        </Modal>
    )
}

export default Create