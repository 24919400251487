import axios from "axios";
import { Config } from "../../core/_request";
import jwtDecode from "jwt-decode";

export const setMoodData = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/user/mood-tracker/add`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const getCourseContent = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/course/v2/content`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const getMoodData = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/user/mood-tracker/summary`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const removeMoodTrackerRecord = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/user/mood-tracker/delete`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const courseInternalContentView = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/course/content/view`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const addEntrytoJournal = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/journal/add-entry`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const editEntrytoJournal = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/journal/edit-entry`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const deleteEntrytoJournal = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/journal/delete-entry`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const addPointToCheckSheet = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/checksheet/add-point`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const submitQuizAnswers = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/quiz/submit`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const editPointToCheckSheet = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/checksheet/edit-point`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const deletePointToCheckSheet = async (body) => {
  const resp = await axios.post(
    `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/checksheet/delete-point`,
    body,
    Config()
  );
  const resp_1 = resp.data;
  return resp_1;
};

export const addBookmarkEntry = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/bookmark/add`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const listOfBookmarksEntry = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/bookmark/list`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const changeDailyPracticeItemStatus = async (body) => {
  const resp = await axios.post(
    `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v3/community/checksheet/change-status`,
    body,
    Config()
  );

  return resp.data;
};
