export const courseData = [
    {
        "id": 22,
        "urlTitle": "advanced-program-for-sleep-disorders",
        "name": "Advanced Program for Sleep Disorders",
        "price": 12999,
        "USD_price": 199,
        "description": "Are you tired of feeling exhausted and irritable every day due to insomnia? Is it affecting your work, personal relationships, and overall quality of life? Don't let sleep disorders take control of your life. Take charge with our expertly designed self-paced program that addresses every aspect of insomnia and teaches you effective strategies to overcome it. With a comprehensive approach that includes personalized consultation sessions, practical tasks, and helpful resources, our program will guide you towards a healthier and more restful sleep. Invest in yourself and start the journey towards a better, more energized life today.",
        "src": "/assets/img/advanced-program-for-sleep-disorders.png",
        "courseDuration": "21 days",
        "language": "English",
        "rating": "4.5",
        "video": {
            "id": 1664,
            "name": "Advanced Program for Sleep Disorders Preview Video",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2FContent%2FNew%20Videos%2FThumbnail%2Fsleep_advanced.png?alt=media&token=f4a5783a-df41-4fb5-9606-db0b73f4de95",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/797022642?h=3f135b2717&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"1920\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"SLEEP WELLNESS ADVANCED PROGRAM  COURSE PREVIEW.mp4\"></iframe>",
                "link": "https://vimeo.com/797022642/3f135b2717",
                "player_embed_url": "https://player.vimeo.com/video/797022642?h=3f135b2717",
                "uri": "/videos/797022642"
            },
            "type": "video"
        },
        "whyShouldTake": "Our course takes a different approach to treating sleep problems. We understand the risks and side-effects of medication, which is why we provide a multidimensional therapeutic approach that involves yoga, meditation, nutrition, hydration regulation, and other behavioral modifications to help you achieve restful sleep without relying on pills."
    },
    {
        "id": 23,
        "urlTitle": "first-time-mom-wellness-program",
        "name": "First Time Mom Wellness Program",
        "price": 11999,
        "USD_price": 190,
        "description": "As a new mother, it can be difficult to balance taking care of yourself while also caring for your newborn. But neglecting your own emotional, nutritional, and physical needs can have serious consequences. That's why we've designed a program specifically for new mothers, with guidance from a life coach who understands the challenges firsthand. Our team of experts have curated a program to help you bring back and maintain wellness in your life, so you can be the best caregiver possible for your baby without feeling worn down.",
        "src": "/assets/img/first-time-mom-wellness-program.png",
        "courseDuration": "6 weeks",
        "language": "English",
        "rating": "4.9",
        "video": {
            "id": 1671,
            "name": "First time mom wellness program preview",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2FContent%2FNew%20Videos%2FThumbnail%2Ffirst_mom.png?alt=media&token=311f04ba-b98e-4c28-86c7-eabd644d8fff",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/802641266?h=401ed0c471&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"1920\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"First time mom wellness program\"></iframe>",
                "link": "https://vimeo.com/802641266/401ed0c471",
                "player_embed_url": "https://player.vimeo.com/video/802641266?h=401ed0c471",
                "uri": "/videos/802641266"
            },
            "type": "video"
        },
        "whyShouldTake": "Our wellness program for new mothers takes a unique approach that goes beyond traditional counseling. With a multidimensional approach, we help you overcome the physical and emotional changes your body goes through after childbirth. Over six weeks, our experts will provide consultations on nutrition, lifestyle changes, and art, dance, and music therapy, along with resources and discussions tailored to new mothers. By prioritizing your own wellness, you'll be better equipped to care for your baby."
    },
    {
        "id": 1,
        "urlTitle": "coping-with-anxiety",
        "name": "Overcoming GAD [Generalised Anxiety Disorder]",
        "price": 2499,
        "USD_price": 39,
        "description": "Do you frequently feel restless and find it difficult to focus your thoughts or concentrate on the work at hand? Do you worry constantly and go through your day with a certain level of nervousness? These are very clear symptoms of generalised anxiety disorder. While at this stage it may just be affecting your sleep patterns and making you feel fatigued through the day, if not dealt with effectively, it can lead to severe mental health and physical problems. This self-paced course has been designed in association with our experts to guide you through the process of overcoming this condition and proceed towards a healthier and happier life.",
        "src": "/assets/img/Coping-with-Anxiety.png",
        "courseDuration": "Self Paced",
        "language": "English",
        "rating": "5.0",
        "video": {
            "id": 1267,
            "name": "OverComing GAD [Generalised Anxiety Disorder]",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fsmall%20banner%2Fvideo%2FAnxiety%20Course%20Preview.png?alt=media&token=f0b234ba-0a55-448b-8835-efe0d784fa1b",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/750299456?h=a176929e40&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"1920\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Meditation Course Preview.mp4\"></iframe>",
                "link": "https://vimeo.com/750299456/a176929e40",
                "player_embed_url": "https://player.vimeo.com/video/818300430?h=296810cb17",
                "uri": "/videos/750299456"
            },
            "type": "video"
        },
        "whyShouldTake": "The modules of this self-paced course uses a multidisciplinary approach, including breathing exercises, meditation and relaxation training, alongside art and music therapy sessions to assist you in overcoming anxiety in daily life. These therapeutic measures can be easily incorporated into your daily routine and will continue enjoying the fruits of the program even after its completion. As the first step to the course, we have an assessment which will take into consideration your exact mental state (triggers and symptoms) to customize the combination of course modules to perfectly suit your individual needs."
    },
    {
        "id": 2,
        "urlTitle": "healing-with-meditation",
        "name": "Healing With Meditation",
        "price": 1999,
        "USD_price": 29,
        "description": "Meditation is popular due to its proven ability to improve mental and physical health, increase self-awareness, and foster a deeper connection to the world. Starting with a guided course or practitioner can provide a strong foundation for beginners to find their approach. If you are new to meditation and are not sure if you need to work with a meditation practitioner then you can get started on this journey with this self paced course. This will allow you to experience the benefits of meditation through carefully curated guided meditations to help you get started on this beautiful journey.",
        "src": "/assets/img/Healing-with-Meditation.png",
        "courseDuration": "Self Paced",
        "language": "English",
        "rating": "4.9",
        "video": {
            "id": 1264,
            "name": "Meditation Course Preview",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fsmall%20banner%2Fvideo%2FMeditation%20Course%20Preview.png?alt=media&token=f1a36c46-0827-4f68-abe0-7a968d1292ea",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/750299456?h=a176929e40&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"100%\" height=\"100%\" frameborder=\"2\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Meditation Course Preview.mp4\"></iframe>",
                "link": "https://vimeo.com/750299456/a176929e40",
                "player_embed_url": "https://player.vimeo.com/video/750299456?h=a176929e40",
                "uri": "/videos/750299456"
            },
            "type": "video"
        },
        "whyShouldTake": "If you are fairly new to the idea of meditation or have never experienced it first hand then you could get this starter pack to get initiated into the world of meditation. Through this course you will be able to experience the benefits of meditation as you practise 8 different meditation techniques through our guided exercises."
    },
    {
        "id": 3,
        "urlTitle": "get-started-with-mindfulness",
        "name": "Get Started With Mindfulness",
        "price": 1999,
        "USD_price": 29,
        "description": "Mindfulness is the art of being present in the moment, and through regular practice, you can access its numerous benefits. This course will guide you through the process of developing mindfulness skills, tapping into your inner workings, and improving the quality of your life. Join us on this journey to start experiencing the beauty of mindfulness.",
        "src": "/assets/img/Get-started-with-Mindfulness.png",
        "courseDuration": "Self Paced",
        "language": "English",
        "rating": "4.6",
        "video": {
            "id": 1265,
            "name": "Mindfulness Course Preview",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fsmall%20banner%2Fvideo%2FMindfulness%20Course%20Preview.png?alt=media&token=318c1a55-7c8e-4182-b355-b6c007001405",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/750301564?h=d881d4cf59&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"500\" height=\"500\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Mindfulness Course preview.mp4\"></iframe>",
                "link": "https://vimeo.com/750301564/d881d4cf59",
                "player_embed_url": "https://player.vimeo.com/video/750301564?h=d881d4cf59",
                "uri": "/videos/750301564"
            },
            "type": "video"
        },
        "whyShouldTake": "Our mindfulness starter pack is designed to help you begin your mindfulness practice with ease. The explainer videos are a great way to understand the basic concepts of mindfulness and how it can benefit you in your daily life. The guided audio exercises are designed to help you practice mindfulness in different situations and aspects of your life. With regular practice, you can expect to see improvements in your overall well-being, including reduced stress, improved focus, and better emotional regulation."
    },
   
    {
        "id": 20,
        "urlTitle": "sleep-wellness",
        "name": "Sleep Better Basic Program",
        "price": 7500,
        "USD_price": 120,
        "description": "This course offers expert guidance to overcome sleep challenges that are affecting your personal and professional life. Poor sleep can lead to a range of health conditions such as hypertension, obesity, heart attack, stroke, and depression. The course helps identify underlying stressors that may be causing disturbed sleep patterns and offers practical solutions to improve sleep quality. With a focus on proper diet, hydration, and managing anxiety and restlessness, the course aims to promote sleep wellness for optimal physical and mental health.",
        "src": "/assets/img/Sleep-Wellness.png",
        "courseDuration": "21 days",
        "language": "English",
        "rating": "4.7",
        "video": {
            "id": 1545,
            "name": "Sleep Course Preview",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2FContent%2FNew%20Videos%2FThumbnail%2Fsleep_basic.png?alt=media&token=cc0371ba-5709-4f8d-8032-08c49eaf679c",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/794043463?h=8224f110e1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"1920\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Sleep Course Preview.mp4\"></iframe>",
                "link": "https://vimeo.com/794043463/8224f110e1",
                "player_embed_url": "https://player.vimeo.com/video/794043463?h=8224f110e1",
                "uri": "/videos/794043463"
            },
            "type": "video"
        },
        "whyShouldTake": "Revitalize your sleep patterns and overcome sleep deprivation with this comprehensive program. With a multidisciplinary approach that includes breathing exercises, mindfulness practice, live yoga and music therapy sessions, you'll experience better quality sleep. Bedtime stories, guided meditations, sleep trackers, and more resources are included to help maintain healthy sleep habits. Plus, personalized consultations with experts provide tailored support for specific sleep-related issues."
    },
    {
        "id": 5,
        "urlTitle": "adhd-beginners-course",
        "name": "Managing ADHD Beginner Course",
        "price": 7500,
        "USD_price": 120,
        "description": "Is your child's hyperactivity and impulsiveness causing concern? Do they struggle to focus and have outbursts in public? Our 45-day course includes consultations with a psychologist and parenting coach, plus exercises to develop emotional, behavioural, and social skills. Learn to understand your child, engage with them, and manage ADHD as a family.",
        "src": "/assets/img/ADHD.png",
        "courseDuration": "5 WEEKS",
        "language": "English",
        "rating": "5.0",
        "video": {
            "id": 1263,
            "name": "ADHD Course Preview",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fsmall%20banner%2Fvideo%2FADHD%20Course%20Preview.png?alt=media&token=eb76648e-99a4-41dd-94a4-d23e4f98f31a",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/750291013?h=838af128e2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"\" height=\"\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"ADHD Course Preview.mp4\"></iframe>",
                "link": "https://vimeo.com/750291013/838af128e2",
                "player_embed_url": "https://player.vimeo.com/video/750291013?h=838af128e2",
                "uri": "/videos/750291013"
            },
            "type": "video"
        },
        "whyShouldTake": "Our course is for children aged 6-17 who have been diagnosed with ADHD or exhibit symptoms such as excessive movement, talking, fidgeting, inability to concentrate or follow instructions, and bouts of anger or anxiety. With consultations from a psychologist and parenting coach, our course also provides lifetime access to exercises targeting all ADHD problem areas for the well-being of both you and your child."
    },
    {
        "id": 17,
        "urlTitle": "managing-hyperactive-kids",
        "name": "Managing Hyperactive Kids Program",
        "price": 7500,
        "USD_price": 120,
        "description": "Is your child's boundless energy overwhelming you? Struggling to control their activity levels and make them sit still? Our 6-week course is designed for both parents and children, providing specialized care, effective tools and parenting skills to better manage hyperactivity. Don't let your child's energy put them at risk and drain you emotionally and physically. Take control with our comprehensive plan.",
        "src": "/assets/img/Managing-Hyperactive-kids.png",
        "courseDuration": "6 weeks",
        "language": "English",
        "rating": "4.9",
        "video": {
            "id": 1426,
            "name": "Managing hyperactive kids preview",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fsmall%20banner%2Fvideo%2Fmanaging_hyperactive_kids_thumbnail.png?alt=media&token=342f3ae1-cc4f-4aad-b1a9-589d91cab866",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/783532823?h=7935ff3594&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"1920\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Managing hyperactive kids course preview.mp4\"></iframe>",
                "link": "https://vimeo.com/783532823/7935ff3594",
                "player_embed_url": "https://player.vimeo.com/video/783532823?h=7935ff3594",
                "uri": "/videos/783532823"
            },
            "type": "video"
        },
        "whyShouldTake": "This course has two variants, customised specifically for 2-3 year olds and 4-6 year olds. Alongside multiple consultation sessions with psychologists, it includes focus building activities, calming exercises and alternative therapies through art & movement. The course begins with a temperament testing exercise and provides you with tracker worksheets which you and your child could use to understand their behavioural patterns better."
    },
    {
        "id": 24,
        "urlTitle": "conflict-management-in-relationships",
        "name": "Conflict Management in Relationships (Basic)",
        "price": 2999,
        "USD_price": 49,
        "description": "Relationships should bring comfort and upliftment, but conflicts are inevitable. Our course focuses on personality assessment, pattern and trigger identification, mindfulness practice, and emotional regulation to healthify conflict management and resolution in relationships. With this toolkit, conflicts can become healthy discussions and revive your relationship.",
        "src": "/assets/img/Conflict_Management_Program.png",
        "courseDuration": "Self-paced",
        "language": "English",
        "rating": "4.5",
        "video": {
            "id": 1734,
            "name": "Conflict Management Program",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2FContent%2FNew%20Videos%2FThumbnail%2FConflict%20Management%20Program.png?alt=media&token=77502c26-bb97-4cab-9133-da7914580172",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/807856399?h=69b32ec6fb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"1920\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Conflict management in relationships Course Preview.mp4\"></iframe>",
                "link": "https://vimeo.com/807856399/69b32ec6fb",
                "player_embed_url": "https://player.vimeo.com/video/807856399?h=69b32ec6fb",
                "uri": "/videos/807856399"
            },
            "type": "video"
        },
        "whyShouldTake": "Our course takes you on an introspective journey to work on your inward and outward responses. Efficient conflict resolution requires awareness of your personality and behaviours, openness to work on them, and consideration of alternate perspectives. Through explainers, activity videos, worksheets, quizzes, and questionnaires, our course provides the most effective conflict management toolkit. Emotion-intensive modules aim to build a long-term atmosphere of conflict resolution among partners."
    },
    {
        "id": 26,
        "urlTitle": "anger-management",
        "name": "Anger Management (Basic)",
        "price": 2999,
        "USD_price": 49,
        "description": "Uncontrolled anger can have disastrous effects on your life, but it's not too late to take control. Our course, designed by experts, offers effective strategies and techniques to help you channel your anger in healthy ways. Through a multidimensional approach, we provide resources and tools to help you understand your triggers, process and resolve them, and maintain emotional balance. With our help, you can learn to manage your anger and become a calmer, more empowered version of yourself.",
        "src": "/assets/img/ANGER MANAGEMENT PROGRAM@2x.png",
        "courseDuration": "Self-paced",
        "language": "English",
        "rating": "4.7",
        "video": {
            "id": 1766,
            "name": "Anger Management - Course Preview",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2FContent%2FNew%20Videos%2FThumbnail%2FANGER%20MANAGEMENT%20PROGRA%20Preview.png?alt=media&token=b0cf77ca-a998-4979-ae5b-6aededa3ea76",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/811415200?h=c3a6349305&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"1920\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Anger Management Basic Course Preview.mp4\"></iframe>",
                "link": "https://vimeo.com/811415200/c3a6349305",
                "player_embed_url": "https://player.vimeo.com/video/811415200?h=c3a6349305",
                "uri": "/videos/811415200"
            },
            "type": "video"
        },
        "whyShouldTake": "This course offers a comprehensive approach to anger management, aimed at helping you achieve lasting change in your emotional regulation. By addressing the root causes of your anger and providing you with practical tools and techniques, this program empowers you to navigate challenging situations with greater ease and resilience. With a focus on self-awareness, self-regulation, and effective communication, you will learn to channel your anger in healthy and productive ways, leading to a more peaceful and fulfilling life."
    },
    {
        "id": 27,
        "urlTitle": "healing-from-heartbreak",
        "name": "Healing from Heartbreak",
        "price": 1999,
        "USD_price": 29,
        "description": "To overcome heavy emotions, it's best to find ways to lighten the load. Heartbreak can be as devastating as losing a loved one, but this course can help you cope efficiently without consuming all your time. It covers the emotional, physical, and mental effects of heartbreak and how to overcome it. The course will guide you to nourish your well-being and cope with difficult times.",
        "src": "/assets/img/healing-from-heartbreak.jpg",
        "courseDuration": "Self-paced",
        "language": "English",
        "rating": "4.7",
        "video": {
            "id": 1794,
            "name": "Healing from Heartbreak Preview",
            "small_thumbnail_url": "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2FContent%2FNew%20Videos%2FThumbnail%2FHFB_Course%20Preview%20Video.png?alt=media&token=a0fb8190-7fd1-4794-8717-f81626429627",
            "video": {
                "embed_html": "<iframe src=\"https://player.vimeo.com/video/814522485?h=f98770b01f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" width=\"1920\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Heartbreaks and healing Course Preview\"></iframe>",
                "link": "https://vimeo.com/814522485/f98770b01f",
                "player_embed_url": "https://player.vimeo.com/video/814522485?h=f98770b01f",
                "uri": "/videos/814522485"
            },
            "type": "video"
        },
        "whyShouldTake": "If you're struggling with a breakup, whether it's your first or you've been through it before and can't seem to get over it, this course is for you. It will help you identify your patterns and how you process separation grief. Through explainer videos, you'll learn about the science behind heartbreak, attachment styles, real-life recovery stories, and exercises to help you gain an informed perspective on your experiences."
    },
]

