import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const SharePopup = ({ show, onHide }) => {
    const [copySuccess, setCopySuccess] = useState(false);
    const link = window.location.href;
    const handleShare = (platform) => {
        console.log(`Sharing link on ${platform}: ${link}`);

        if (platform == 'Facebook') {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, '_blank');
        } else if (platform == 'Twitter') {
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`, '_blank');
        } else if (platform == 'WhatsApp') {
            window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(window.location.href)}`, '_blank');
        }
    };

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(link);
            setCopySuccess(true);
        } catch (error) {
            console.error('Failed to copy:', error);
            setCopySuccess(false);
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='Share-PopUp'
        >
            <Modal.Header>
                <Modal.Title>Share Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column gap-3 w-100 mb-3 linkContainer'>
                    <TextField disabled value={link} className='w-100' />
                    <Button className='w-100' onClick={handleCopyLink}>{copySuccess ? 'Copied!' : 'Copy Link'}</Button>
                </div>
                <div className='d-flex align-items-center w-100 gap-2 horizontal--line'>
                    <hr />
                    <p>Or Share With</p>
                    <hr />
                </div>
                <div className='d-flex align-items-center justify-content-center gap-3 shareButtonContainer my-4'>
                    <Button onClick={() => handleShare('Facebook')}><FacebookIcon sx={{ color: '#3b5998' }} /></Button>
                    <Button onClick={() => handleShare('Twitter')}><TwitterIcon sx={{ color: '#1DA1F2' }} /></Button>
                    <Button onClick={() => handleShare('WhatsApp')}><WhatsAppIcon sx={{ color: '#128c7e' }} /></Button>
                    <Button onClick={() => handleShare('WhatsApp')}><InstagramIcon sx={{ color: '#fbad50' }} /></Button>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SharePopup