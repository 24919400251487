import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { OODOCourseAccessRequestService } from "../core/_request";
import { CircularProgress } from "@mui/material";


const SubscriptionAlertDialougBox = ({
  description,
  setOpenPaymetAlert,
  setIsButtonClicked,
  isOpen,
  courseData,
  userId,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClosePaymentModal = () => {
    setIsLoading(true);
    try {
      let request = {
        userId: userId,
        courseId: courseData?.course_id,
      };
      OODOCourseAccessRequestService(request).then((res) => {
        console.log("RES", res);
        setIsLoading(false);
        setOpenPaymetAlert(false);
        setIsButtonClicked(false);
        let linksData = [
          {
            name: "Home",
            uri: `/program-internal/${res?.data?.data?.id}/${courseData?.course_title}`,
          },
          {
            name: "My Journal",
            uri: `/program-internal/module/my-journal/:id`,
          },
        ];
        sessionStorage.setItem(
          "courseInternalLinks",
          JSON.stringify(linksData)
        );
      });
    } catch (error) {
      setIsLoading(false);
      setOpenPaymetAlert(false);
      setIsButtonClicked(false);
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ backgroundColor: "#f5f1fe", padding: "16px" }}>
          <p className="course_popup_conform_text">{description}</p>
          <button
            className="course_popup_conform_button"
            onClick={handleClosePaymentModal}
          >
            {isLoading ? (
              <CircularProgress
                color="inherit"
                style={{ width: "25px", height: "25px" }}
              />
            ) : (
              "OK"
            )}
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default SubscriptionAlertDialougBox;
