import React, { useEffect, useState } from 'react'
import SearchHeader from './Components/SearchHeader'
import SearchResult from './Components/SearchResult'
import { useLocation, useNavigate } from 'react-router-dom';
import { getSearchedResult } from './api/_request';

const Search = () => {
    const [inputValue, setInputValue] = useState('');
    const [searchResultText, setSearchResultText] = useState();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectOption, setSelectOption] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location?.search);
        const query = queryParams?.get('query');
        console.log("query", queryParams?.get('query'));
        if (query) {
            setSearchQuery(query);
            setInputValue(query)
        }
    }, [location?.search]);

    const fetchSearchData = () => {
        if (inputValue) {
            getSearchedResult(inputValue).then((resp) => {
                setLoading(true);
                if (resp?.code == 200) {
                    if (resp?.data) {
                        setData(resp?.data)
                        console.log("data", resp?.data);
                        setLoading(true);
                    }
                } else {
                    console.log(resp?.message)
                    setLoading(true);
                }
            })
        }
    }

    useEffect(() => {
        fetchSearchData();
    }, [searchQuery]);

    return (
        <div style={{ backgroundColor: "#F5F5F5" }}>
            {
                <div>
                    <SearchHeader
                        setInputValue={setInputValue}
                        inputValues={inputValue}
                        fetchSearchData={fetchSearchData}
                        setSearchResultText={setSearchResultText}
                        setSelectOption={setSelectOption}
                        setSearchQuery={setSearchQuery}
                        searchQuery={searchQuery}
                        selectOption={selectOption}
                    />
                </div>
            }
            {data &&
                <SearchResult SearchedData={data} searchResultText={searchResultText} selectOption={selectOption} searchQuery={searchQuery} />
                ||
                <div className="w-100 d-flex flex-column align-items-center justify-content-center noResultOnSearch gap-3">
                    <img src='/assets/img/kareify/Out_Of_Stock.png' alt='No Result' />
                    <p style={{ textDecoration: 'none' }}>No results related to ‘{searchQuery}’</p>
                </div>
            }
        </div>
    )
}

export default Search