import React, { useContext, useEffect } from 'react'
import Sidebar from '../dashboard/Components/Dashboard/Sidebar'
import LandingPage from './LandingPage'
import { useAuth } from '../../auth';
import { AppContext } from '../../context';

const Community = () => {
    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    useEffect(() => {
        if (!currentUser) {
            // setShowLloginPopup(true);
            const currentUrl = encodeURIComponent(window.location.href);
            window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
        }
    }, []);
    return (
        <div className='d-flex community--main'>
            <div className='col-2 d-none d-md-block'>
                <Sidebar />
            </div>
            <div className='col-md-10 col-12'>
                <LandingPage />
            </div>
        </div>
    )
}

export default Community