import { useState } from "react";
import "../styles/Sidebar.css";
import Drawer from "@mui/material/Drawer";
import { useNavigate, useParams } from "react-router-dom";

const SidebarMobile = () => {
  const navigate = useNavigate();
  const { id, urlTitle } = useParams();
  let homeLink = "";
  let linksData = JSON.parse(sessionStorage.getItem("courseInternalLinks"));
  if (linksData !== undefined && linksData !== null) {
    homeLink = linksData.filter((item) => item.name == "Home");
  } else {
    if (
      id !== null &&
      id !== undefined &&
      id !== "" &&
      urlTitle !== null &&
      urlTitle !== undefined &&
      urlTitle !== ""
    ) {
      let linksData = [
        { name: "Home", uri: `/program-internal/${id}/${urlTitle}` },
        { name: "My Journal", uri: `/program-internal/module/my-journal/:id` },
      ];
      sessionStorage.setItem("courseInternalLinks", JSON.stringify(linksData));
      homeLink = [{ name: "Home", uri: `/program-internal/${id}/${urlTitle}` }];
    }
  }
  const [openDrawer, setOpenDrawer] = useState(false);
  const toolsData = [
    {
      img: "/assets/img/course_internal_journey/sidebar/sidebar-mood-monitor.png",
      text: "Mood Monitor",
      link: "/program-internal/module/mood-monitor",
    },
    {
      img: "/assets/img/course_internal_journey/sidebar/my-library.png",
      text: "My Library",
      link: "/program-internal/library",
    },
  ];
  return (
    <>
      <section className="sidebar--mobile align-item-center bg-white">
        <button onClick={() => {
          navigate(homeLink[0].uri)
        }}>Home</button>
        <div></div>
        <button onClick={() => setOpenDrawer(true)}>Tools</button>
      </section>

      <Drawer
        anchor={"bottom"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        className="navbar--tools"
      >
        <div className="navbar--tools__container">
          <div></div>
          {toolsData?.map((item, i) => (
            <div
              key={i}
              className="sidebar-li"
              onClick={() => {
                navigate(item.link);
              }}
            >
              <img
                src={item.img}
                className="sidebar-img"
                alt=""
                loading="lazy"
              />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default SidebarMobile;
