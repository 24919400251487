import React, { useEffect, useState } from 'react'
import ClubCard from './ClubCard'
import Button from '@mui/material/Button';
import Post from './Post'
import Video from './Video'
import Blogs from './Blogs'
import { useAuth } from '../../../auth'
import { getAllClubsByCategory, getTypePostsWithUserIdByCategory } from '../_request'
import { useSnackbar } from 'notistack';

const tabs = [
    {
        tabId: 1,
        name: "Post",
        keyword: 'post'
    },
    {
        tabId: 2,
        name: "Blogs",
        keyword: 'article'
    },
    {
        tabId: 3,
        name: "Videos",
        keyword: 'video'
    },
    {
        tabId: 4,
        name: "Podcasts",
        keyword: 'audio'
    },
]

const Category = ({ categoryId }) => {
    const catId = categoryId?.id
    const [select, setSelect] = useState(0);
    const [clubList, setClubList] = useState();
    const [postData, setPostData] = useState();
    const { currentUser } = useAuth();
    const userId = currentUser?.UserId;
    const { enqueueSnackbar } = useSnackbar();

    const onTabChange = (item) => {
        const postType = item?.keyword;
        setSelect(item?.tabId)
        getTypePostsWithUserIdByCategory({ userId, catId, postType }).then(resp => {
            if (resp?.ResponseCode === 200) {
                setPostData(resp?.data)
            } else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        })
    }

    useEffect(() => {
        getAllClubsByCategory({ userId, catId }).then(resp => {
            if (resp?.ResponseCode === 200) {
                setClubList(resp?.data)
            } else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        })
    }, []);

    return (
        <div className='categoryDetail-section'>
            <div>
                <div className='module-bg-image'>
                    <img src="/assets/img/community/mother_child.jpg" alt='' />
                </div>
                <div className='module-container d-flex flex-row p-md-4 p-2'>
                    <div className='col-12 col-md-8 pe-md-3'>
                        <div className='mt-3 mt-md-0'>
                            <h3>{clubList?.category?.name}</h3>
                            {clubList?.category?.description && <p>{clubList?.category?.description}</p>}
                        </div>
                        <div className='tabs-section d-flex align-items-center gap-3 my-4 overflow-auto'>
                            <div className={`${select == 0 ? "tabs-container-active" : "tabs-container"}`} onClick={() => setSelect(0)}>
                                Clubs
                            </div>
                            {
                                tabs?.map((item, index) =>
                                    <div key={index} className={`${select == index + 1 ? "tabs-container-active" : "tabs-container"}`} onClick={() => onTabChange(item)}>
                                        {item?.name}
                                    </div>
                                )
                            }
                        </div>
                        {
                            select === 0 &&
                            <div className='d-flex flex-column'>
                                {
                                    clubList?.clubs?.map((item, index) =>
                                        <div className='col-12 col-md-6 p-2 p-md-3 '>
                                            <ClubCard clubData={item} />
                                        </div>
                                    )
                                }
                            </div>
                        }
                        {
                            select === 1 &&
                            <div className='d-flex flex-column'>
                                {postData?.feed?.map((item, index) =>
                                    <div className='my-3 py-md-3 col-12 col-md-9'>
                                        <Post item={item} />
                                    </div>
                                )}
                            </div>
                        }
                        {
                            select === 2 &&
                            <div className='row'>
                                {postData?.feed?.map((item, index) =>
                                    <div className='col-6 p-3'>
                                        <Blogs item={item?.data} />
                                    </div>
                                )}
                            </div>
                        }
                        {
                            select === 3 &&
                            <div className='row'>
                                {postData?.feed?.map((item, index) =>
                                    <div className='col-6 p-3'>
                                        <Video item={item?.data} />
                                    </div>
                                )}
                            </div>
                        }
                        {
                            select === 4 &&
                            <div className='row'>
                                {postData?.feed?.map((item, index) =>
                                    <div className='col-6 p-3'>
                                        <Video item={item?.data} />
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    <div className='col-md-4 d-none d-md-block suggested-clubs'>
                        <h3>Suggested Clubs</h3>
                        <hr />
                        <div className='clubs-card d-flex align-items-center justify-content-between m-2'>
                            <div className='d-flex align-items-center gap-3'>
                                <img src='/assets/img/community/DietNutrition.png' alt='' />
                                <p>Emotional Wellness</p>
                            </div>
                            <Button variant='contained'>Join</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Category