import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom'

const TransactionFailed = () => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate("/plans")
        }, 15000);
        return () => clearTimeout()
    }, []);

    return (
        <div className='d-flex flex-column justify-content-center align-items-center gap-4 my-5' style={{}}>
            <Helmet>
                <title>Payment Failed - United We Care</title>
            </Helmet>
            <img src='/assets/img/failed.png' alt='' />
            <h1 className='font-helvetica font-w700 fs-1' style={{ color: "#F80808" }}>Transaction Failed</h1>
            <p className='font-helvetica fs-5 text-black col-9 col-md-6 text-center'>
                Apologies, but we couldn't process your payment at this time. Consider using an alternative payment method if needed. For assistance, <a href='https://www.unitedwecare.com/contact/' className='text-black'>contact our support</a> or <Link to='/' className='text-black'>return to homepage.</Link>
            </p>
            <p className='font-helvetica fs-5 text-black text-center'>We appreciate your understanding.</p>
        </div>
    )
}

export default TransactionFailed