import axios from "axios";
import { Config } from "../../core/_request";
import jwtDecode from "jwt-decode";

export const getCourseDetailsV2 = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v2/community/course/detail`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};
