import React, { useEffect, useState } from 'react'
import Create from './Create';
import Drawer from '@mui/material/Drawer';
import { getCommentsOnPost } from '../_request';
import { useAuth } from '../../../auth';
import CloseIcon from '@mui/icons-material/Close';
import AddCommentIcon from '@mui/icons-material/AddComment';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HeadsetIcon from '@mui/icons-material/Headset';

const uploadData = [
    {
        title: "Create New Post",
        img: <AddCommentIcon />,
        type: 'Post'
    },
    {
        title: "Upload New Video",
        img: <VideoLibraryIcon />,
        type: 'Video'
    },
    {
        title: "Write New Blogs",
        img: <AssignmentIcon />,
        type: 'Blog'
    },
    {
        title: "Upload New Podcast",
        img: <HeadsetIcon />,
        type: 'Podcast'
    },
]

const CreateComponent = (props) => {
    const [selected, setSelected] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('');

    const handleCreateClick = (index, title) => {
        props?.onClose()
        setSelected(index);
        setSelectedTitle(title);
        setModalShow(true);
    };

    const closeModal = () => {
        setModalShow(!modalShow);
    }

    return (
        <Drawer
            anchor='bottom'
            className='drawerContainer'
            open={props?.show}
            onClose={props?.toggleDrawer}
            {...props}
        >
            <div className='rightSide-createContainer'>
                <div className='d-flex align-items-center justify-content-between'>
                    <h5>Upload your Post</h5>
                    <CloseIcon onClick={() => props?.onClose()} />
                </div>

                <hr />
                {uploadData?.map((item, index) =>
                    <div key={index} className={`${selected === index ? 'create-active' : 'create'}`}
                        onClick={() => handleCreateClick(index, item?.type)}
                    >
                        {item?.img}
                        <h6>{item?.title}</h6>
                    </div>
                )}
                <Create
                    show={modalShow}
                    onHide={closeModal}
                    type={selectedTitle}
                />
            </div>
        </Drawer>
    )
}

export default CreateComponent