import { useState } from "react";
import moment from "moment";

const NoteTabItem = ({ date, name, content }) => {
  return (
    <div className="NotesItem">
      <h6>Added on {moment(date).format("DD MMM YYYY")}</h6>
      <p>{content}</p>
    </div>
  );
};

export default NoteTabItem;
