import React from "react";
import Today_picks from "./Today_picks";
import Sidebar from "../Dashboard/Sidebar";
import All_programs from "./All_programs";
import Explore_according from "./Explore_according";
function My_programs() {
  return (
    <>
      <main>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2 d-none d-lg-inline">
                <Sidebar />
              </div>

              <div
                className="col-lg-10 px-lg-5 py-md-3 ps-md-3 pt-md-3 pb-0 m-0"
                style={{
                  backgroundColor: "#F4F4F4",
                  borderRadius: "8px",
                  height: "",
                  overflowY: "",
                }}
              >
                <Today_picks />
                <Explore_according />
                <All_programs />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default My_programs;
