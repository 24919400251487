import React, { useEffect, useState } from 'react'
import "./PlanDetailsPage.css"
import PlanDetailsLanding from "../planDetails/PlanDetailsLanding"
import PlanDetailsStats from '../planDetails/PlanDetailsStats'
import PlanDetailsReviewSlide from '../planDetails/PlanDetailsReviewSlide'
import ThreeImgBackground from '../planDetails/ThreeImgBackground'
import { WhyTrustUs } from '../planDetails/WhyTrustUs'
import WhyPlan from '../planDetails/WhyPlan'
import { Helmet } from 'react-helmet'
import { getCountryCode } from '../core/commonFun'
import PlanDetailsLandingOutsideIndia from '../planDetails/PlanDetailsLandingOutsideIndia'

const PlanDetailsPage = ({ type }) => {
    const [isCountryIndia, setIsCountryIndia] = useState();

    var countryCode = getCountryCode();
    console.log("countryCode", countryCode)
    useEffect(() => {
        if (countryCode === "IN") {
            setIsCountryIndia(true)
        } else {
            setIsCountryIndia(false)
        }
    }, []);

    return (
        <div>
            <Helmet>
                <script src="//code.tidio.co/y84keavfozv8pkn1ar3c4h0cofpeq7le.js" async></script>
            </Helmet>
            {
                isCountryIndia ?
                <PlanDetailsLanding type={type} />
                :
                <PlanDetailsLandingOutsideIndia type={type}/>
            }
            <PlanDetailsStats />
            <WhyPlan type={type} />
            <WhyTrustUs type={type} />
            <ThreeImgBackground type={type} />
            <PlanDetailsReviewSlide />
        </div>
    )
}

export default PlanDetailsPage