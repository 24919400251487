import React, { useEffect, useState } from "react";
import DetailPageCard from "./DetailPageCard";
import DetailPageTabs from "./DetailPageTabs";
import { getExpertbyId_WebService } from "./core/_request";
import { getCountryCode } from "./core/commonFun";
import { Helmet } from "react-helmet";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import { useNavigate } from 'react-router-dom'
import './ExpertFilterDrawer.css'

const ExpertDetails = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true)
  const countryCode = getCountryCode();
  const navigate = useNavigate();

  useEffect(() => {
    const { id } = props;
    getExpertbyId_WebService(id).then((resp) => {
      var data = resp?.data;
      if (countryCode == "IN")
        data["countryAmount"] = data.Hourly_Consultaion_Fee_amount;
      else data["countryAmount"] = data.USD_Amount;
      setData(data);
      setLoading(false)
    });
  }, []);

  //console.log('expert details', data)
  return (
    (data && (
      data?.IsAdminApproval === false ? (
        <div className="container my-3 my-md-5">
          <div className="d-flex align-items-center flex-wrap-reverse justify-content-md-between justify-content-center py-md-5 w-100">
            <div className="col-md-6 d-flex flex-column justify-content-between gap-1 gap-md-4 align-items-center align-items-md-start">
              <h5
                className="text-center text-md-start notFound-heading"
                style={{
                  color: "#101828",
                  fontFamily: "Roboto",
                  fontSize: "60px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "66px",
                  margin: 0
                }}>We're Sorry!</h5>
              <p
                className="text-center text-md-start notFound-text font-roboto"
                style={{
                  color: "#737373",
                  // fontFamily: "Roboto !important",
                  fontSize: "26px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "36px",
                  letterSpacing: "-0.26px",
                  fontFamily: 'Roboto'
                }}>
                The expert you're looking for is no longer available on our platform. We're committed to connecting you with the guidance and support you need. Please explore other best-in-class certified professionals available on our platform. Thank you for choosing us to support your wellness journey.
              </p>
              <button
                onClick={() => navigate('/')}
                className="border-0 px-md-4 py-md-3 py-2 mt-3 notFound-btn col-12 col-md-6 mb-5"
                style={{
                  borderRadius: "12px",
                  background: "#73589B",
                  color: "#fff",
                  fontFamily: "Roboto",
                  fontSize: "28px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "32px",
                }}
              >Explore Other Experts</button>
            </div>
            <div className="col-md-6">
              <img src="/assets/img/kareify/image(2).png" className="mx-auto d-block notFound-img" alt="" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Helmet>
            <title>
              {data?.Name + ":" + " Experienced Psychologist for Personalized Consultation | United We Care"}
            </title>
          </Helmet>
          <div>
            <div className="expertDetail--container px-0 py-md-3">
            {
              loading ? (<Skeleton variant="text" width="50%" height="40px" className="mx-auto" />) : (
                <p className="fs-2 text-black text-center pb-5 py-4 px-3 font-w600 lh-base font-inter d-none d-md-block">
                {data?.RegisterYourselfAs}
              </p>
              )
            }
              
              <div className="row col-12 justify-content-between align-content-center align-items-md-start w-100 mx-0 position-relative">
                <div className="col-md-4 my-1 mx-auto px-0 px-md-2">
                  <DetailPageCard data={data} loading={loading}/>
                </div>
                <div className="col-md-8 p-0 m-0 my-1">
                  <DetailPageTabs data={data} loading={loading}/>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    ))
  ) 
};

export default ExpertDetails;