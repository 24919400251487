import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useState } from "react";
import AccessTimeIcon from "../../../assets/icons/clock-icon.svg";

import SessionComplete from "../modals/SessionCompleted";
import NotCompleted from "../modals/NotCompleted";
import Snacknbar from "./Snackbar";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined"

//Icons
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";

import "./cards.css";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context";

const AllSessions = ({
  session,
  unMarkedSession,
  openModal,
  handleOpenModal,
  handleCloseModal,
  openModal1,
  handleOpenModal1,
  handleCloseModal1,
  loading,
}) => {
  const [appointment_id, setAppointmentId] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("success");

  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  const handleOpenSnackbar = () => {
    setOpen(true);
  };

  return (
    <div>
      <Snacknbar
        message={message}
        open={open}
        status={status}
        handleClose={handleCloseSnackbar}
      ></Snacknbar>
      <p
        style={{
          color: "black",
          fontSize: "16px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          marginLeft: "50px",
          paddingTop: "30px",
        }}
      >
        {" "}
        {session === "in-complete" ? "Marked Sessions" : "Unmarked Sessions"}
      </p>

      <div className="d-flex flex-wrap   mt-3 mx-5 custom-font custom-card1 ">
        {!loading && unMarkedSession &&
          unMarkedSession.length > 0 &&
          unMarkedSession.map((data, index) => (
            <div key={index} className="col-md-6 col-lg-3   ">
              <div className="card mb-3 custom-font custom-card  ">
                <div className="card-body ">
                  <div className="d-flex justify-content justify-spaceBetween align-items-center mb-3">
                    <img
                      src={data.client_pofile_picture}
                      alt="User"
                      className="img-fluid rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5
                        className="card-title"
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        {data.client_name}
                      </h5>

                      <p
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "10px",
                          color: "grey",
                          marginLeft: "10px",
                          marginTop: "-8px",
                        }}
                      >
                        Follow-up
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-2 px-1 ">
                    <div
                      style={{
                        FontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <CalendarMonthIcon
                        style={{
                          color: "#73589b",
                          height: "22px",
                          whiteSpace: "nowrap",
                        }}
                      />
                      {data.start_date}
                    </div>
                    <div
                      style={{
                        marginRight: "5px",
                        FontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                    > 
                      <AvTimerOutlinedIcon style={{color: "#73589b"}} />
                      {/* <img
                        src={AccessTimeIcon}
                        alt=""
                        style={{
                          height: "20px",
                          whiteSpace: "nowrap",
                          paddingRight: "5px",
                          color: "#73589b",
                        }}
                      /> */}
                      {data.start_time}
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-4">
                    <button
                      style={{
                        fontWeight: 600,
                        borderRadius: "30px",
                        height: "34px",
                        flex: 1,
                      }}
                      onClick={() => {
                        handleOpenModal();
                        setAppointmentId(data.appointment_id);
                      }}
                      className={`btn btn-outline-success btn-sm text-success`}
                    >
                      <DoneIcon />
                    </button>

                    <button
                      style={{
                        fontWeight: 600,
                        borderRadius: "30px",
                        height: "34px",
                        flex: 1,
                      }}
                      onClick={() => {
                        handleOpenModal1();
                        setAppointmentId(data.appointment_id);
                      }}
                      className="btn btn-outline-danger btn-sm text-danger"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  {/* Session Dashboard Uncomment later */}
                  {/* <button
                    className="d-flex align-items-center gap-2 px-2 mt-2 py-1"
                    style={{
                      border: "1px solid #E0E0E0",
                      backgroundColor: "#F5F5F5",
                      fontStyle: "normal",
                      fontWeight: 600,
                      borderRadius: "30px",
                      height: "34px",
                      width: "100%",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      navigate(
                        `/expert/sessionmanagement?view=sessionDashboard&clientID=${data.client_id}&appointmentID=${data.appointment_id}`
                      );
                      setExpertDashboardData((prevData) => ({
                        ...prevData,
                        clientID: data.client_id,
                        clientName: data.client_name,
                        appointmentID: data.appointment_id,
                        appointmentDate: data.start_time_ts_ist,
                        bookingDate: data.booking_time_ts_ist,
                        consultationDate: data.start_date,
                      }));
                    }}
                  >
                    <SpaceDashboardOutlinedIcon />
                    <span>Session Dashboard</span>
                  </button> */}
                </div>
              </div>
            </div>
          ))}
        {!loading && unMarkedSession && unMarkedSession.length === 0 && (
          <div className="NoDataAvailable">
            <img src="/assets/img/no-appointment.png" />
            <p>
              No Sessions Found
            </p>
          </div>
        )}

        <NotCompleted
          status={"write"}
          appointment_id={appointment_id}
          open={openModal1}
          handleClose={handleCloseModal1}
          setMessage={setMessage}
          handleOpenSnackbar={handleOpenSnackbar}
          setStatus={setStatus}
        />
        <SessionComplete
          status={"write"}
          appointment_id={appointment_id}
          open={openModal}
          handleClose={handleCloseModal}
          setMessage={setMessage}
          handleOpenSnackbar={handleOpenSnackbar}
          setStatus={setStatus}
        />
      </div>
    </div>
  );
};

export default AllSessions;
