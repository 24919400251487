import { useState, useEffect } from "react";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Button from "@mui/material/Button";

//Grid
import Grid from "@mui/material/Grid";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

import CalendarViewAppointmentCard from "../cards/CalendarViewAppointmentCard";

import isUserLoggedIn from "../helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../auth";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const localizer = momentLocalizer(moment);

const AppointmentCalendarView = () => {
  const [calendarView, setCalendarView] = useState("month");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [activeDate, setActiveDate] = useState(moment().format("YYYY-MM-DD"));
  const [appointmentList, setAppointmentList] = useState([]);
  const [allAppointmentList, setAllAppointmentList] = useState([]);
  const [hasAppointmentData, setHasAppointmentData] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  let userID;
  const { currentUser, userType, logout } = useAuth();

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  //Appointments List
  useEffect(() => {
    const payload = {
      expert_id: currentUser?.UserId,
      date: activeDate,
    };

    const postAppointmentsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();
        const { appointments } = postResponse?.data;

        setAppointmentList(appointments);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postAppointmentsData();
  }, [activeDate]);

  //All Appointments List
  useEffect(() => {
    const payload = {
      expert_id: currentUser?.UserId,
    };

    const postAppointmentsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();
        const { appointments } = postResponse?.data;

        setAllAppointmentList(appointments);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postAppointmentsData();
  }, []);

  const currentMonth = moment().month(selectedMonth).format("MMMM");
  const startOfMonth = moment().month(selectedMonth).startOf("month");
  const endOfMonth = moment().month(selectedMonth).endOf("month");
  const startDate = startOfMonth.clone().startOf("week");
  const endDate = endOfMonth.clone().endOf("week");
  const today = moment();

  const dates = [];

  const currentDate = startDate.clone();
  while (currentDate.isSameOrBefore(endDate, "day")) {
    dates.push(currentDate.clone());
    currentDate.add(1, "day");
  }

  const events = allAppointmentList
    ?.filter((appointment) => appointment.Status !== "Cancelled")
    ?.map((appointment) => ({
      id: appointment.ID,
      title: appointment.Name,
      start: moment(
        `${appointment.AppDate} ${appointment.AppTime}`,
        "DD MMM YYYY hh:mm A"
      ).toDate(),
      end: moment(
        `${appointment.AppDate} ${appointment.AppTime}`,
        "DD MMM YYYY hh:mm A"
      )
        .add(appointment.duration.split(" ")[0], "minutes")
        .toDate(),
      joinUrl: appointment.join_url,
      status: appointment?.Status,
    }));

  const EventComponentMonthly = ({ event }) => {
    return (
      <div className="d-flex align-items-center gap-1">
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "#73589b",
          }}
        ></div>
        <p>{event.title}</p>
      </div>
    );
  };

  const EventComponentWeekly = ({ event }) => {
    return (
      <div>
        <p>{event.title}</p>
        <p>
          {moment(event.start).format("h:mm")} -{" "}
          {moment(event.end).format("h:mm a")}
        </p>
      </div>
    );
  };

  const EventComponentDaily = ({ event }) => {
    return (
      <div className="daily-event">
        <p>{event.title}</p>
        <p>
          {moment(event.start).format("h:mm")} -{" "}
          {moment(event.end).format("h:mm a")}
        </p>
      </div>
    );
  };

  const components = {
    month: {
      event: EventComponentMonthly,
    },
    week: {
      event: EventComponentWeekly,
    },
    day: {
      event: EventComponentDaily,
    },
  };

  return (
    <div className="AppointmentCalendarView--Component">
      <div className="CalendarView--Content">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          step={30}
          views={{ month: true, week: true, day: true }}
          components={components}
          style={{ height: 1000 }}
        />
      </div>
    </div>
  );
};

export default AppointmentCalendarView;
