import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 450,
    minWidth: 350,
    bgcolor: '#f5f1fe',
    boxShadow: 16,
    p: 4,
};


const AlertPopup = ({ showModal, data, setShowModal }) => {
    return (
        <Modal
            open={showModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <IconButton onClick={() => setShowModal(false)} style={{ color: "#73589b", position: "absolute", top: "5px", right: "10px" }}><CloseIcon /></IconButton>
                <Typography id="modal-modal-title" variant="h6" style={{ fontStyle: "italic" }} component="h2">
                    {data.title}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {data.desc}
                </Typography>
            </Box>
        </Modal>
    )
}

export default AlertPopup