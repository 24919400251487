import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/Quiz.css";
import { courseInternalContentView, submitQuizAnswers } from "../_request";
import Popup from "./Modal";
import { useNavigate, useParams } from "react-router-dom/dist";
import SidebarMobile from "./SidebarMobile";
import { useAuth } from "../../../auth";
import { Skeleton } from "@mui/material";
import { addBookmarkEntry } from "../_request";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Helmet } from "react-helmet";

const modal_data = [
  {
    title: "Quiz Time",
    desc: "Answer these questions to understand how much of the information disseminated within the program stuck with you.",
  },
];

const Quiz = () => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [quizData, setQuizData] = useState();
  const [currentCustomDataIndex, setCurrentCustomDataIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [quizAnswers, setQuizAnswers] = useState([]);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { id } = useParams();
  const { currentUser } = useAuth();

  const [subLevelId, setSubLevelId] = useState(id);

  const currentData =
    quizData?.content?.quiz_questions &&
    quizData?.content?.quiz_questions[currentCustomDataIndex];

  useEffect(() => {
    const body = {
      userId: currentUser?.UserId,
      subLevelId: subLevelId,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setQuizData(resp?.data);
        setIsBookmarked(resp?.data?.bookmarked);
        setIsLoading(false);
      } else {
        console.error(resp?.ResponseMessage);
      }
    });
  }, [subLevelId]);

  const handleAnswerSelection = (answer, question) => {
    setSelectedAnswer(answer);

    setQuizAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (item) => item.question_id === question
      );

      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex] = {
          question_id: question,
          response: answer,
        };
        return updatedAnswers;
      } else {
        return [
          ...prevAnswers,
          {
            question_id: question,
            response: answer,
          },
        ];
      }
    });

    if (currentData?.answer === answer) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  const iconHandler = (answer) => {
    return currentData?.content?.quiz_questions.answer === answer ? (
      <img
        src="/assets/img/course_internal_journey/quiz/correct-ans.png"
        alt=""
        loading="lazy"
        className="correct-ans"
      />
    ) : (
      <img
        src="/assets/img/course_internal_journey/quiz/wrong-ans.png"
        alt=""
        loading="lazy"
        className="wrong-ans"
      />
    );
  };

  const clickHandler = (answer, question) => {
    handleAnswerSelection(answer, question);
    iconHandler(answer);
  };

  const handleNextQuestionClick = () => {
    if (quizData?.content.quiz_questions && currentData) {
      setCurrentCustomDataIndex(currentCustomDataIndex + 1);
      setIsCorrect(null);
      setSelectedAnswer(null);
    }
  };

  const handleFinishQuiz = () => {
    let body = {
      quiz_data_id: quizData?.content?.quiz_data_id,
      responses: quizAnswers,
    };
    submitQuizAnswers(body).then((resp) => {
      if (resp?.ResponseCode == 200) {
        navigate(`/program-internal/module/quiz-completed/${subLevelId}`);
      }
    });
  };

  const handlePrevButton = () => {
    if (quizData?.previous_id?.type === "video") {
      navigate(`/program-internal/module/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "audio") {
      navigate(`/program-internal/module/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "journal") {
      navigate(
        `/program-internal/module/my-journal/${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "quiz") {
      navigate(`/program-internal/module/quiz/${quizData?.previous_id?.id}`);
      setSubLevelId(quizData?.previous_id?.id);
    } else if (quizData?.previous_id?.type === "html") {
      navigate(`/program-internal/module/article/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${quizData?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (quizData?.next_id?.type === "video") {
      navigate(`/program-internal/module/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "audio") {
      navigate(`/program-internal/module/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${quizData?.next_id?.id}`
      );
    } else if (quizData?.next_id?.type === "journal") {
      navigate(`/program-internal/module/my-journal/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "quiz") {
      navigate(`/program-internal/module/quiz/${quizData?.next_id?.id}`);
      setSubLevelId(quizData?.next_id?.id);
    } else if (quizData?.next_id?.type === "html") {
      navigate(`/program-internal/module/article/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${quizData?.next_id?.id}`
      );
    } else if (quizData?.next_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${quizData?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/program-internal/program-view/${quizData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(`/program-internal/program-view/${quizData?.next_module_id?.id}`);
  };

  const handleBookmarkClick = () => {
    const bBody = { sub_level_id: subLevelId };
    addBookmarkEntry(bBody).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setIsBookmarked(resp?.data?.bookmarked);
      }
    });
  };

  return (
    <section className="quiz">
      <Helmet>
        <title>Program | Quiz</title>
      </Helmet>
      {isLoading ? (
        <>
          <section className="d-none d-md-flex">
            <Skeleton variant="rectangular" width={300} height={1080} />
          </section>
          <section className="modules-section">
            <div>
              <div className="modules-container">
                <Skeleton
                  variant="rounded"
                  width="40%"
                  height={40}
                  className="rounded-3 my-2"
                />
                <Skeleton
                  variant="rounded"
                  width="20%"
                  height={40}
                  className="rounded-3"
                />

                <div className="sections-name d-flex gap-2 align-items-center justify-content-between my-2">
                  <Skeleton
                    variant="rounded"
                    width="40%"
                    height={40}
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width={25}
                    height={25}
                    className=""
                  />
                </div>

                <div className="d-flex flex-column justify-content-center align-content-center">
                  <Skeleton
                    variant="rounded"
                    width="70%"
                    height={30}
                    className="rounded-3 mx-auto mt-3"
                  />

                  <Skeleton
                    variant="rounded"
                    width="40%"
                    height={30}
                    className="rounded-3 mx-auto my-3"
                  />
                  <div
                    className="quiz-question-container p-4 d-flex flex-column gap-2 bg-[rgb(0,0,0,0.07)]"
                  >
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={30}
                      className="rounded-3"
                    />
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={50}
                      className="rounded-pill"
                    />
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={50}
                      className="rounded-pill"
                    />

                    <Skeleton
                      variant="rounded"
                      width="50%"
                      height={30}
                      className="rounded-3 mx-auto"
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <div>
                    <Skeleton
                      variant="circular"
                      width={35}
                      height={35}
                      className="rounded-full"
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="circular"
                      width={35}
                      height={35}
                      className="rounded-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="quiz-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="quiz-section">
            <div>
              <div className="quiz-bg-img">
                <img
                  src="/assets/img/course_internal_journey/module_bg_img"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="quiz-container">
                <div className="sections-heading">
                  {quizData?.course_name}
                  <span>
                    {" > "}
                    {quizData?.content?.name}
                  </span>
                </div>
                <div className="sections-progress">
                  Section {quizData?.current_level_index + 1}/
                  {quizData?.level_count}
                </div>
                <div className="quiz-sections-name">
                  <div style={{ width: "calc(100% - 20px)" }}>
                    {quizData?.content?.name}
                  </div>
                  <div
                    style={{ display: "flex", gap: "10px", lineHeight: "150%" }}
                  >
                    <img
                      src="/assets/img/course_internal_journey/info-icon.png"
                      alt=""
                      loading="lazy"
                      className="quiz-info-icon"
                      onClick={() => setShowModal(true)}
                    />
                    <Popup
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      data={modal_data[0]}
                    />
                    <div
                      className="quiz-save"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleBookmarkClick()}
                    >
                      {isBookmarked ? (
                        <BookmarkIcon color="#541cf5" />
                      ) : (
                        <BookmarkBorderOutlinedIcon />
                      )}
                    </div>
                  </div>
                  {/* <hr style={{ color: "#E0E0E0" }} /> */}
                </div>
                <hr style={{ color: "#E0E0E0" }} />

                <div className="d-flex flex-column justify-content-center align-content-center">
                  <div className="quiz-section-desc">
                    {quizData?.content?.description}
                  </div>
                  <div className="quiz-question-numbers pb-2">
                    Question {currentCustomDataIndex + 1} /{" "}
                    {quizData?.content?.quiz_questions?.length}
                  </div>
                  <div className="quiz-question-container">
                    <div>
                      <div className="quiz-que">
                        {/* <div> */}
                        {currentCustomDataIndex + 1}
                        {". "}
                        {currentData?.name}
                        {/* </div> */}
                      </div>
                      <div className="quiz-btns">
                        <button
                          className="quiz-true-btn"
                          onClick={() => clickHandler("true", currentData?.id)}
                          style={{
                            backgroundColor:
                              isCorrect === null
                                ? "#A68DEF"
                                : selectedAnswer === "true" && !isCorrect
                                ? "#cc0000"
                                : isCorrect && selectedAnswer === "true"
                                ? "#34A001"
                                : "#A68DEF",
                          }}
                        >
                          True
                          {selectedAnswer === "true" && isCorrect !== null && (
                            <img
                              alt=""
                              className="quiz-btn-icons"
                              src={
                                isCorrect === null
                                  ? null
                                  : selectedAnswer === "true" && !isCorrect
                                  ? "/assets/img/course_internal_journey/quiz/wrong-ans.png"
                                  : isCorrect && selectedAnswer === "true"
                                  ? "/assets/img/course_internal_journey/quiz/correct-ans.png"
                                  : null
                              }
                            />
                          )}
                        </button>
                        <button
                          className="quiz-false-btn"
                          onClick={() => clickHandler("false", currentData?.id)}
                          style={{
                            backgroundColor:
                              isCorrect === null
                                ? "#A68DEF"
                                : selectedAnswer === "false" && !isCorrect
                                ? " #cc0000"
                                : isCorrect && selectedAnswer === "false"
                                ? "#34A001"
                                : "#A68DEF",
                          }}
                        >
                          False
                          {selectedAnswer === "false" && isCorrect !== null && (
                            <img
                              alt=""
                              className="quiz-btn-icons"
                              src={
                                isCorrect === null
                                  ? null
                                  : selectedAnswer === "false" && !isCorrect
                                  ? "/assets/img/course_internal_journey/quiz/wrong-ans.png"
                                  : isCorrect && selectedAnswer === "false"
                                  ? "/assets/img/course_internal_journey/quiz/correct-ans.png"
                                  : null
                              }
                            />
                          )}
                        </button>
                      </div>

                      {selectedAnswer !== null && (
                        <div className="quiz-explanation">
                          {isCorrect ? (
                            <p> {currentData?.summary}</p>
                          ) : (
                            <p> {currentData?.summary}</p>
                          )}
                        </div>
                      )}
                    </div>
                    {/* ))} */}

                    {(currentCustomDataIndex + 1 !==
                      quizData?.content?.quiz_questions?.length && (
                      <button
                        disabled={selectedAnswer == null ? true : false}
                        className="quiz-next-btn"
                        onClick={() => handleNextQuestionClick()}
                      >
                        <div>Next Question</div>
                        <img
                          src="/assets/img/course_internal_journey/quiz/next-que-arrow.png"
                          className="quiz-next-arrow"
                          alt=""
                          loading="lazy"
                        />
                      </button>
                    )) || (
                      <button
                        disabled={selectedAnswer == null ? true : false}
                        className="quiz-next-btn"
                        onClick={() => handleFinishQuiz()}
                      >
                        <div>Finish Quiz</div>
                        <img
                          src="/assets/img/course_internal_journey/quiz/next-que-arrow.png"
                          className="quiz-next-arrow"
                          alt=""
                          loading="lazy"
                        />
                      </button>
                    )}
                  </div>
                </div>

                <div className="quiz-footer-btns">
                  <div>
                    {quizData?.previous_id.type && (
                      <div className="prev-btn" onClick={handlePrevButton}>
                        <div className="modules-prev-text">Previous</div>
                        <div className="prev-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                            style={{ transform: "rotate(180deg)" }}
                          />
                        </div>
                      </div>
                    )}
                    {quizData?.previous_module_id?.id &&
                      !quizData?.previous_id?.type && (
                        <div
                          className="prev-btn"
                          onClick={handlePrevModuleButton}
                        >
                          <div className="modules-text">Previous Module</div>
                          <div className="prev-arrow">
                            <img
                              src="/assets/img/course_internal_journey/modules/arrow.png"
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                              style={{ transform: "rotate(180deg)" }}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                  <div>
                    {quizData?.next_id.type && (
                      <div className="next-btn" onClick={handleNextButton}>
                        <div className="modules-text">Next</div>
                        <div className="next-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                    {quizData?.next_module_id?.id &&
                      !quizData?.next_id?.type && (
                        <div
                          className="next-btn"
                          onClick={handleNextModuleButton}
                        >
                          <div className="modules-text">Next Module</div>
                          <div className="next-arrow">
                            <img
                              src="/assets/img/course_internal_journey/modules/arrow.png"
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default Quiz;
