import React, { useEffect, useState } from "react";
import { fetchAssessmentList } from "../components/core/_request";
import AssessmentCard from "../components/assessments/AssessmentCard";
import { Helmet } from "react-helmet";

const AssessmentList = () => {

  const [assessmentList, setAssessmentList] = useState();

  useEffect(() => {
    fetchAssessmentList().then((res) => {
      setAssessmentList(res);
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Mental Wellness Assessments - UNITED WE CARE</title>
      </Helmet>
      <div
        className="d-flex align-items-center gap-2 py-4 px-5"
        style={{
          maxWidth: "1440px",
          margin: "auto",
          flexDirection: "column",
        }}
      >
        <h1 class="font-inter font-w600 fs-2 lh-base text-center">
          Comprehensive Mental Wellness Assessments
        </h1>
        <p class="font-inter fs-5 text-center">Evaluate your well-being now!</p>
      </div>
      <div
        className="AssessmentList--Container px-3 py-4 gap-3"
        style={{ maxWidth: "1440px", margin: "auto" }}
      >
        {assessmentList?.map((item) => {
          return (
            <AssessmentCard
              key={item?.id}
              id={item?.id}
              questionCount={item?.questionsCount}
              title={item?.title}
              usersCount={item?.usersCount}
              author={item?.author}
              backgroundImageUrl={item?.img}
              stellaKey={item?.appParameter?.firstmessage?.message}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AssessmentList;
