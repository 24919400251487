import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Modal,
  Nav,
  ProgressBar,
  Tab,
} from "react-bootstrap";
import CourseWorksheet from "./CourseWorksheet";
import { course_web_lists, getCourseDetail_WebService } from "../core/_request";
import { useParams, Link } from "react-router-dom";
import { useAuth } from "../../auth";
import Iframe from "react-iframe";

const CoursePreview = () => {
  const [data, setData] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [progress, setProgress] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [ttcourseData, setTtcourseData] = useState();
  let { id } = useParams();
  const { currentUser } = useAuth();

  useEffect(() => {
    const body = { userId: currentUser.UserId };

    course_web_lists(body).then((resp) => {
      setTtcourseData(resp?.data?.courses);
    });
  }, []);

  const courseId = ttcourseData?.find((o) => o?.urlTitle === id)?.id;
  console.log("courseId", courseId);

  const Detailbody = {
    courseId: courseId,
    userId: currentUser?.UserId,
  };

  useEffect(() => {
    getCourseDetail_WebService(Detailbody).then((resp) => {
      setData(resp.Data);
      var progress =
        (resp?.Data?.data?.levels.filter((o) => o.completed).length * 100) /
        resp?.Data?.data?.levels?.length;
      setProgress(progress);
      setSelectedItem(resp?.Data?.data && data?.data?.video);
    });
  }, [courseId]);

  return (
    data && (
      <div className="row flex-wrap-reverse align-items-end p-0 m-0">
        <Tab.Container id="left-tabs-example" defaultActiveKey="default">
          <div
            className="col-md-3 p-0"
            style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
              height: "100%",
            }}
          >
            <div className="px-4 py-4">
              <ProgressBar animated variant="success" now={progress} />
              <p className="fs-6" style={{ color: "#24AE63" }}>
                {Math.round(progress)}% Complete
              </p>
            </div>
            <p
              className="fs-5 px-3 py-3"
              style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}
            >
              Course Preview
            </p>
            <Accordion defaultActiveKey="0">
              <Nav className="flex-column">
                {data?.data &&
                  data?.data?.levels?.map((items, index) => (
                    //console.log("items", items) ||
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header
                        className="py-2 px-0"
                        style={{ lineHeight: "30px" }}
                      >
                        {/* Week 1 -  */}
                        <p className="d-flex flex-column ">
                          <span className="fs-5">
                            {items?.name}
                            <br />
                          </span>
                        </p>
                      </Accordion.Header>
                      <Accordion.Body className="px-2">
                        {items?.customLevels?.map((item) => (
                          <Nav.Item className="d-none d-md-block">
                            <Nav.Link
                              onClick={() => setSelectedItem(item)}
                              eventKey="video"
                              style={{
                                boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                              }}
                              className="bg-white text-grey d-flex align-items-center py-3 px-3"
                            >
                              <LeftMenu item={item} />
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                        {items?.customLevels?.map((item) => (
                          <Nav.Item className="d-block d-md-none">
                            <Nav.Link
                              onClick={() => {
                                setSelectedItem(item);
                                setModalShow(true);
                              }}
                              eventKey="video"
                              style={{
                                boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                              }}
                              className="bg-white text-grey d-flex align-items-center py-3 px-3"
                            >
                              <LeftMenu item={item} />
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Nav>
            </Accordion>
          </div>
          <div className="col-md-9">
            <Tab.Content>
              <Tab.Pane eventKey="default">
                <Nav.Item>
                  <Nav.Link href="">
                    {
                      <>
                        <div className="my-2 d-block d-md-none">
                          <h6 className="text-center">Course Preview Video</h6>
                          <p className="text-center">
                            {data?.data?.video?.name}
                          </p>
                        </div>
                        <Iframe
                          styles={{ border: "1px solid black" }}
                          className="coursePreviewVideo my-2"
                          src={data?.data?.video?.video?.player_embed_url}
                          width="100%"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowFullScreen
                          title="Course"
                        ></Iframe>
                        <div className="mt-5 d-none d-md-block">
                          <h6>Course Preview Video</h6>
                          <p>{data?.data?.video?.name}</p>
                        </div>
                      </>
                    }
                  </Nav.Link>
                </Nav.Item>
              </Tab.Pane>
              <Tab.Pane eventKey="video" className="d-none d-md-block">
                <CourseDataView selectedItem={selectedItem} />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
        {modalShow && (
          <MobileVideoPopUp
            selectedItem={selectedItem}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        )}
      </div>
    )
  );
};

const MobileVideoPopUp = (props) => {
  const { selectedItem, show } = props;
  return (
    <Modal
      show={show}
      onHide={() => props.onHide()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>{selectedItem?.name}</Modal.Header>
      <Modal.Body>{<CourseDataView selectedItem={selectedItem} />}</Modal.Body>
    </Modal>
  );
};

const CourseDataView = (props) => {
  const { selectedItem } = props;
  let { id } = useParams();
  const [ttcourseData, setTtcourseData] = useState();
  const { currentUser } = useAuth();

  const courseId = ttcourseData?.find((o) => o.urlTitle === id).id;
  
  useEffect(() => {
    const body = { userId: currentUser.UserId };

    course_web_lists(body).then((resp) => {
      setTtcourseData(resp?.data?.courses);
    });
  }, []);

  return (
    <>
      {(selectedItem &&
        selectedItem.dataType === "video" &&
        selectedItem.video &&
        selectedItem.video.embedHtml && (
          <Iframe
            styles={{ border: "1px solid black" }}
            className="coursePreviewVideo my-2 mx-1"
            src={selectedItem?.video?.playerEmbedUrl}
            width="100%"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Meditation Course Preview.mp4"
          ></Iframe>
        )) ||
        (selectedItem &&
          selectedItem.dataType === "audio" &&
          selectedItem.video &&
          selectedItem.video.link && (
            <div>
              <iframe
                src={selectedItem.video.link}
                title="course video"
                width="100%"
                height="700"
              ></iframe>
            </div>
          )) ||
        (selectedItem &&
          selectedItem.dataType === "worksheet" &&
          selectedItem.worksheet && (
            <CourseWorksheet worksheetData={selectedItem} />
          )) ||
        (selectedItem &&
          selectedItem.dataType === "appointment" &&
          selectedItem.appointment &&
          selectedItem.appointment.booked && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "50vh" }}
            >
              <Link
                to={`/dashboard/my-appointments/${selectedItem.appointment.uwcAppointmentId}`}
                className="mx-auto"
              >
                <Button className="bg-black">
                  Click here to view appointment
                </Button>
              </Link>
            </div>
          )) ||
        (selectedItem &&
          selectedItem.dataType === "appointment" &&
          selectedItem.appointment &&
          !selectedItem.appointment.booked && (
            <div className="d-block h-75 w-100">
              <Link
                to={`/book-an-appointment/${selectedItem.appointment.expertId}?course_id=${courseId}`}
                className="m-auto"
              >
                <Button className="bg-black">
                  Click here to book an appointment
                </Button>
              </Link>
            </div>
          ))}
      <div className="mt-5 d-none d-md-block">
        <div className="d-flex justify-content-between my-5 mx-3">
          <div className="d-flex align-items-center gap-3">
            <img src="/assets/img/logo-light.png" alt="" />
          </div>
          <div className="d-flex align-items-center gap-3">
            <img src="/assets/img/heart_icon_sm_black.png" alt="" />
            <img src="/assets/img/polygon_blue_icon.png" alt="" />
            <p className="fs-4" style={{ color: "#00C4FF" }}>
              Wellness
            </p>
          </div>
        </div>
        <div className="mx-3">
          <p className="fs-5 pt-1">{selectedItem?.name}</p>
        </div>
      </div>
    </>
  );
};

const LeftMenu = (props) => {
  const { item } = props;
  return (
    <>
      <div className="col-1">
        {(item && item?.type === "video" && (
          <img src="/assets/img/play-circle.png" alt="" />
        )) ||
          (item && item?.type === "audio" && (
            <img src="/assets/img/audio.png" alt="" />
          )) ||
          (item && item?.type === "appointment" && (
            <img src="/assets/img/appointment.png" alt="" />
          )) ||
          (item && item?.type === "worksheet" && (
            <img src="/assets/img/worksheet.png" alt="" />
          ))}
      </div>
      <p className="fs-5 col-10">{item?.name}</p>
      {item?.completed === true ? (
        <div className="col-1">
          <img src="/assets/img/green-check.png" alt="" />
        </div>
      ) : null}
      <hr />
    </>
  );
};

export default CoursePreview;
