import React, { useContext, useEffect, useState } from 'react'
import './Success.css'
import { Button } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Get_Appointment_Detailsbyid, getExpertbyId_WebService, isFirstTimeAppointment } from '../../core/_request';
import { useAuth } from '../../../auth';
import { AppContext } from '../../../context';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';

const Success = () => {
    const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];
    let { id } = useParams();
    const search = useLocation().search;
    const [isFirstTime, setIsFirstTime] = useState();
    const [data, setData] = useState();
    const [expertDetailsData, setExpertDetailsData] = useState();
    const appid = new URLSearchParams(search).get("appid");
    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const resp = await getExpertbyId_WebService(id);
            const data1 = resp.data;
            setExpertDetailsData(data1);

            let tdata = undefined;
            if (appid) {
                tdata = await getAppDataByAppId();
            } else {
                tdata = JSON.parse(localStorage.getItem("bookAnAppointmentData"));
            }
            const mainAmount = tdata.callTime === 30 ? data1.countryAmount / 2 : data1.countryAmount;
            tdata["mainAmount"] = mainAmount;
            setData(tdata);
        };

        fetchData();
    }, [id, appid]);

    useEffect(() => {
        isFirstTimeAppointment().then((resp) => {
            if (resp?.data?.Appointment_count === 1) {
                // window.open(`https://stella.unitedwecare.com/?token=${authToken}&message=backendstart_Screener_Intro&appURL=${encodeURI("https://us.unitedwecare.com")}`);
                setIsFirstTime(true);
            } else {
                setIsFirstTime(false);
            }
        });
    }, [])

    const screenerRedirection = () => {
        window.open(`https://stella.unitedwecare.com/?token=${authToken}&message=backendstart_Screener_Intro`);
    }

    const getAppDataByAppId = async () => {
        if (!currentUser) {
            // setShowLloginPopup(true);
            const currentUrl = encodeURIComponent(window.location.href);
            window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
            return;
        }

        const outData = {};
        const appData = await Get_Appointment_Detailsbyid(appid);
        outData.selectedDate = appData?.data?.AppointmentDate;
        outData.selectSlot = { Time_Duration: appData?.data.App_StartTime };
        outData.callTime = appData.data.Minutes;
        return outData;
    };
    console.log("Hello")

    return (
        <div className='appointment--Confiermed-container py-5'>
            <div className='appointment--Confiermed d-flex flex-column align-items-center py-4 px-3 gap-3'>
                <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M47 94C72.9574 94 94 72.9574 94 47C94 21.0426 72.9574 0 47 0C21.0426 0 0 21.0426 0 47C0 72.9574 21.0426 94 47 94Z" fill="#1CB004" />
                    <path d="M46.9997 88.7904C23.9566 88.7904 5.20898 70.0427 5.20898 46.9997C5.20898 23.9555 23.9566 5.20898 46.9997 5.20898C70.0439 5.20898 88.7915 23.9566 88.7915 46.9997C88.7915 70.0439 70.0439 88.7904 46.9997 88.7904Z" fill="#1CB004" stroke="white" stroke-width="4.16721" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M23.627 41.9487L42.067 63.6751L70.373 30.3242" fill="#1CB004" />
                    <path d="M23.627 41.9487L42.067 63.6751L70.373 30.3242" stroke="white" stroke-width="8.63801" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <h3>Appointment Confirmed!</h3>
                <p>
                    Details have been sent to your registered email. To view and access your appointment, go to "Appointments" in Dashboard.
                </p>
                <div className='appointment-details ps-3 mt-3 w-100'>
                    <div className='d-flex justify-content-between detail-header'>
                        <p>Appointment Details</p>
                        <img src='/assets/img/kareify/adayu.png' alt='adayu' />
                    </div>
                    <div className='d-flex gap-3 my-3'>
                        <img src={expertDetailsData?.ProfileImage} alt='' className='rounded-3' style={{ height: 100, width: 100 }} />
                        <div className='expert--detail d-flex flex-column justify-content-around'>
                            <h5>{expertDetailsData?.Name}{" "}
                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.95472 12.1145C9.05582 12.1145 11.5696 9.60074 11.5696 6.49965C11.5696 3.39855 9.05582 0.884766 5.95472 0.884766C2.85363 0.884766 0.339844 3.39855 0.339844 6.49965C0.339844 9.60074 2.85363 12.1145 5.95472 12.1145ZM7.94015 4.51422C8.04558 4.40894 8.18852 4.34985 8.33751 4.34996C8.48651 4.35006 8.62937 4.40935 8.73465 4.51479C8.83993 4.62022 8.89902 4.76316 8.89892 4.91215C8.89881 5.06115 8.83952 5.20401 8.73409 5.30929L5.56 8.48338L5.55775 8.48563C5.50573 8.53795 5.44387 8.57947 5.37575 8.60781C5.30762 8.63614 5.23456 8.65073 5.16078 8.65073C5.087 8.65073 5.01394 8.63614 4.94581 8.60781C4.87769 8.57947 4.81583 8.53795 4.76381 8.48563L4.76156 8.48338L3.17536 6.89718C3.12173 6.84538 3.07895 6.78343 3.04953 6.71492C3.0201 6.64642 3.00461 6.57274 3.00396 6.49819C3.00332 6.42363 3.01752 6.3497 3.04575 6.28069C3.07399 6.21169 3.11568 6.14899 3.1684 6.09627C3.22112 6.04356 3.28381 6.00186 3.35281 5.97363C3.42182 5.9454 3.49576 5.93119 3.57031 5.93184C3.64486 5.93249 3.71854 5.94798 3.78705 5.9774C3.85555 6.00683 3.91751 6.04961 3.9693 6.10323L5.16078 7.29415L7.94015 4.51479V4.51422Z" fill="#74D653" />
                                </svg>
                            </h5>
                            <p>{expertDetailsData?.Experience}+ years of experience</p>
                            <div className='py-1 px-3'>{expertDetailsData?.RegisterYourselfAs}</div>
                        </div>
                    </div>
                    <p>Appointment Date:
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7346 3.73366H10.668V3.20033C10.668 3.05888 10.6118 2.92322 10.5118 2.8232C10.4117 2.72318 10.2761 2.66699 10.1346 2.66699C9.99319 2.66699 9.85753 2.72318 9.75751 2.8232C9.65749 2.92322 9.6013 3.05888 9.6013 3.20033V3.73366H6.4013V3.20033C6.4013 3.05888 6.34511 2.92322 6.24509 2.8232C6.14507 2.72318 6.00942 2.66699 5.86797 2.66699C5.72652 2.66699 5.59086 2.72318 5.49085 2.8232C5.39083 2.92322 5.33464 3.05888 5.33464 3.20033V3.73366H4.26797C3.84362 3.73366 3.43666 3.90223 3.1366 4.20229C2.83654 4.50235 2.66797 4.90931 2.66797 5.33366V11.7337C2.66797 12.158 2.83654 12.565 3.1366 12.865C3.43666 13.1651 3.84362 13.3337 4.26797 13.3337H11.7346C12.159 13.3337 12.5659 13.1651 12.866 12.865C13.1661 12.565 13.3346 12.158 13.3346 11.7337V5.33366C13.3346 4.90931 13.1661 4.50235 12.866 4.20229C12.5659 3.90223 12.159 3.73366 11.7346 3.73366ZM12.268 11.7337C12.268 11.8751 12.2118 12.0108 12.1118 12.1108C12.0117 12.2108 11.8761 12.267 11.7346 12.267H4.26797C4.12652 12.267 3.99086 12.2108 3.89085 12.1108C3.79083 12.0108 3.73464 11.8751 3.73464 11.7337V8.00033H12.268V11.7337ZM12.268 6.93366H3.73464V5.33366C3.73464 5.19221 3.79083 5.05655 3.89085 4.95654C3.99086 4.85652 4.12652 4.80033 4.26797 4.80033H5.33464V5.33366C5.33464 5.47511 5.39083 5.61076 5.49085 5.71078C5.59086 5.8108 5.72652 5.86699 5.86797 5.86699C6.00942 5.86699 6.14507 5.8108 6.24509 5.71078C6.34511 5.61076 6.4013 5.47511 6.4013 5.33366V4.80033H9.6013V5.33366C9.6013 5.47511 9.65749 5.61076 9.75751 5.71078C9.85753 5.8108 9.99319 5.86699 10.1346 5.86699C10.2761 5.86699 10.4117 5.8108 10.5118 5.71078C10.6118 5.61076 10.668 5.47511 10.668 5.33366V4.80033H11.7346C11.8761 4.80033 12.0117 4.85652 12.1118 4.95654C12.2118 5.05655 12.268 5.19221 12.268 5.33366V6.93366Z" fill="#666666" />
                            </svg> {moment(data?.selectedDate)?.format("DD MMMM")}
                        </span>
                    </p>
                    <p>Appointment Time:
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.0013 12.364C8.57434 12.364 9.14177 12.2511 9.67119 12.0318C10.2006 11.8125 10.6817 11.4911 11.0869 11.0859C11.4921 10.6807 11.8135 10.1996 12.0328 9.67022C12.2521 9.1408 12.3649 8.57337 12.3649 8.00033C12.3649 7.42728 12.2521 6.85986 12.0328 6.33043C11.8135 5.80101 11.4921 5.31997 11.0869 4.91477C10.6817 4.50957 10.2006 4.18814 9.67119 3.96885C9.14177 3.74956 8.57434 3.63669 8.0013 3.63669C6.84399 3.63669 5.73409 4.09643 4.91575 4.91477C4.0974 5.73311 3.63767 6.84302 3.63767 8.00033C3.63767 9.15763 4.0974 10.2675 4.91575 11.0859C5.73409 11.9042 6.84399 12.364 8.0013 12.364ZM13.3346 8.00033C13.3346 10.9458 10.9468 13.3337 8.0013 13.3337C5.05585 13.3337 2.66797 10.9458 2.66797 8.00033C2.66797 5.05487 5.05585 2.66699 8.0013 2.66699C10.9468 2.66699 13.3346 5.05487 13.3346 8.00033ZM9.45585 10.1404L7.51645 8.20105V4.84881H8.48615V7.7996L10.1414 9.45487L9.45585 10.1404Z" fill="#666666" />
                            </svg> {data?.selectSlot?.Time_Duration} ({data?.selectSlot?.TimeZone})
                        </span>
                    </p>
                    <p>Session Duration:
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.0013 12.364C8.57434 12.364 9.14177 12.2511 9.67119 12.0318C10.2006 11.8125 10.6817 11.4911 11.0869 11.0859C11.4921 10.6807 11.8135 10.1996 12.0328 9.67022C12.2521 9.1408 12.3649 8.57337 12.3649 8.00033C12.3649 7.42728 12.2521 6.85986 12.0328 6.33043C11.8135 5.80101 11.4921 5.31997 11.0869 4.91477C10.6817 4.50957 10.2006 4.18814 9.67119 3.96885C9.14177 3.74956 8.57434 3.63669 8.0013 3.63669C6.84399 3.63669 5.73409 4.09643 4.91575 4.91477C4.0974 5.73311 3.63767 6.84302 3.63767 8.00033C3.63767 9.15763 4.0974 10.2675 4.91575 11.0859C5.73409 11.9042 6.84399 12.364 8.0013 12.364ZM13.3346 8.00033C13.3346 10.9458 10.9468 13.3337 8.0013 13.3337C5.05585 13.3337 2.66797 10.9458 2.66797 8.00033C2.66797 5.05487 5.05585 2.66699 8.0013 2.66699C10.9468 2.66699 13.3346 5.05487 13.3346 8.00033ZM9.45585 10.1404L7.51645 8.20105V4.84881H8.48615V7.7996L10.1414 9.45487L9.45585 10.1404Z" fill="#666666" />
                            </svg> {data?.callTime} Min
                        </span>
                    </p>
                </div>
                {/* {isFirstTime ?
                    <>
                        <div className='screener--div'>
                            <p>
                                Completing the Screener is important for us to gather the necessary information to ensure the best possible care for your session. Please proceed to complete the Screener (If you have not already).
                            </p>
                        </div>
                        <div className='button--group d-flex flex-column align-items-center gap-3 w-100 my-3'>
                            <Button className='btn-1 w-100' onClick={screenerRedirection}>
                                Proceed
                            </Button>
                            <Button className='btn-2 w-100' onClick={() => navigate('/user-dashboard')}>
                                Go to Dashboard
                            </Button>
                        </div>
                    </>
                    :
                    } */}
                    <div className='button--group d-flex flex-column align-items-center gap-3 w-100 my-3'>
                        <Button className='btn-1 w-100' onClick={() => navigate('/user-dashboard')}>
                            Continue
                        </Button>
                    </div>
            </div>
        </div>
    )
}

export default Success