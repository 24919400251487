import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/DailyPractice.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popup from "./Modal";
import { Skeleton } from "@mui/material";
import {
  editPointToCheckSheet,
  deletePointToCheckSheet,
  addPointToCheckSheet,
  courseInternalContentView,
  addBookmarkEntry,
} from "../_request";
import { useNavigate, useParams } from "react-router-dom";
import SidebarMobile from "./SidebarMobile";
import { useAuth } from "../../../auth";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { changeDailyPracticeItemStatus } from "../_request";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Helmet } from "react-helmet";

const modal_data = [
  {
    title: "Daily Practice",
    desc: "Your hunt for motivation to start healthy practices ends here. Simply click from the list and add to your daily routine. We will help you be on track.",
  },
];

const DailyPractice = () => {
  const [checkSheetData, setCheckSheetData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(null);
  const [clickedItemId, setClickedItemId] = useState(null);
  const [tryList, setTryList] = useState([]);
  const [myList, setMyList] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDailyp_id, setDeleteDailyP_id] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClickOption = (event, daily_p_id) => {
    setDeleteDailyP_id(daily_p_id);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [subLevelId, setSubLevelId] = useState(id);

  useEffect(() => {
    if (clickedItemId !== null) {
      setShowEdit(clickedItemId);
    }
  }, [clickedItemId]);

  useEffect(() => {
    fetchChecksheetData();
  }, [subLevelId]);

  const fetchChecksheetData = () => {
    const body = {
      userId: currentUser?.UserId,
      subLevelId: subLevelId,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCheckSheetData(resp?.data);

        const tryListData = resp?.data?.content?.check_points.filter(
          (item) => item.status == "draft"
        );

        const myListData = resp?.data?.content?.check_points.filter(
          (item) => item.status == "ongoing"
        );

        const myCompletedListData = resp?.data?.content?.check_points.filter(
          (item) => item.status == "completed"
        );

        setMyList(myListData);
        setTryList(tryListData);
        setCompletedList(myCompletedListData);
        setIsBookmarked(resp?.data?.bookmarked);

        setIsLoading(false);
      } else {
        console.error(resp?.ResponseMessage);
      }
    });
  };

  const handleDeleteClick = (item) => {
    const body = {
      checksheet_data_id: checkSheetData?.content?.checksheet_data_id,
      checksheet_point_id: item,
    };
    deletePointToCheckSheet(body).then((res) => {
      if (res.ResponseCode == 200) {
        handleCloseOption();
        fetchChecksheetData();
      }
    });
  };

  const changeStatus = (checksheetID, checksheetPointID) => {
    const body = {
      checksheet_data_id: checksheetID,
      checksheet_point_id: checksheetPointID,
    };
    changeDailyPracticeItemStatus(body).then((res) => {
      if (res?.ResponseCode == 200) {
        fetchChecksheetData();
      }
    });
  };

  const HandleAddtoMydailyList = (item) => {
    changeStatus(checkSheetData?.content?.checksheet_data_id, item?.id);
  };

  const HandleAddtoCompleteList = (item) => {
    changeStatus(checkSheetData?.content?.checksheet_data_id, item?.id);
  };

  const handleAddStep = () => {
    addPointToCheckSheet().then((resp) => {});
  };

  const handleAddStepClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    const body = {
      checksheet_data_id: checkSheetData?.content?.checksheet_data_id,
      point_name: textValue,
    };
    if (textValue.trim() !== "") {
      addPointToCheckSheet(body).then((resp) => {
        if (resp.ResponseCode === 200) {
          fetchChecksheetData();
        }
      });
    }
    setIsEditing(false);
    setTextValue("");
  };

  const handlePrevButton = () => {
    if (checkSheetData?.previous_id?.type === "video") {
      navigate(`/program-internal/module/${checkSheetData?.previous_id?.id}`);
    } else if (checkSheetData?.previous_id?.type === "audio") {
      navigate(`/program-internal/module/${checkSheetData?.previous_id?.id}`);
    } else if (checkSheetData?.previous_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${checkSheetData?.previous_id?.id}`
      );
      setSubLevelId(checkSheetData?.previous_id?.id);
    } else if (checkSheetData?.previous_id?.type === "journal") {
      navigate(
        `/program-internal/module/my-journal/${checkSheetData?.previous_id?.id}`
      );
    } else if (checkSheetData?.previous_id?.type === "quiz") {
      navigate(
        `/program-internal/module/quiz/${checkSheetData?.previous_id?.id}`
      );
    } else if (checkSheetData?.previous_id?.type === "html") {
      navigate(
        `/program-internal/module/article/${checkSheetData?.previous_id?.id}`
      );
    } else if (checkSheetData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${checkSheetData?.previous_id?.id}`
      );
    } else if (checkSheetData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${checkSheetData?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (checkSheetData?.next_id?.type === "video") {
      navigate(`/program-internal/module/${checkSheetData?.next_id?.id}`);
    } else if (checkSheetData?.next_id?.type === "audio") {
      navigate(`/program-internal/module/${checkSheetData?.next_id?.id}`);
    } else if (checkSheetData?.next_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${checkSheetData?.next_id?.id}`
      );
      setSubLevelId(checkSheetData?.next_id?.id);
    } else if (checkSheetData?.next_id?.type === "journal") {
      navigate(
        `/program-internal/module/my-journal/${checkSheetData?.next_id?.id}`
      );
    } else if (checkSheetData?.next_id?.type === "quiz") {
      navigate(`/program-internal/module/quiz/${checkSheetData?.next_id?.id}`);
    } else if (checkSheetData?.next_id?.type === "html") {
      navigate(
        `/program-internal/module/article/${checkSheetData?.next_id?.id}`
      );
    } else if (checkSheetData?.next_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${checkSheetData?.next_id?.id}`
      );
    } else if (checkSheetData?.next_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${checkSheetData?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/program-internal/program-view/${checkSheetData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(
      `/program-internal/program-view/${checkSheetData?.next_module_id?.id}`
    );
  };

  const handleBookmarkClick = () => {
    let body = { sub_level_id: subLevelId };
    addBookmarkEntry(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setIsBookmarked(resp?.data?.bookmarked);
      }
    });
  };

  return (
    <section className="practice">
      <Helmet>
        <title>Program | Daily Practice</title>
      </Helmet>
      {isLoading ? (
        <>
          <section className="d-none d-md-flex">
            <Skeleton variant="rectangular" width={300} height={1080} />
          </section>
          <section className="modules-section">
            <div>
              <div className="modules-container">
              <Skeleton
                  variant="rounded"
                  width="20%"
                  height={40}
                  className="rounded-3"
                />
                <Skeleton
                  variant="rounded"
                  width="40%"
                  height={40}
                  className="rounded-3 my-2"
                />
                <div className="sections-name d-flex gap-2 align-items-center justify-content-between">
                  <Skeleton
                    variant="rounded"
                    width="40%"
                    height={40}
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width={25}
                    height={25}
                    className=""
                  />
                </div>
                <Skeleton
                    variant="rounded"
                    width="90%"
                    height={40}
                    className="rounded-3 my-2"
                  />
                <div className="modules-sections">
                  {
                    Array.from(new Array(3)).map((_, i) => (
                      <Skeleton variant="rounded" width="100%" height={250} className="rounded-3 my-2"  />
                    ))
                  }
                  
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <div>
                    <Skeleton variant="circular" width={35} height={35} className="rounded-full" />
                  </div>
                  <div>
                    <Skeleton variant="circular" width={35} height={35} className="rounded-full" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="daily-practice-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="practice-section">
            <div className="daily-practice-bg-img">
              <img
                src="/assets/img/course_internal_journey/section_bg_img"
                alt=""
                loading="lazy"
              />
            </div>

            <div className="practice-container">
              <div className="sections-heading">
                {checkSheetData?.course_name}
                <span>
                  {" >"} {checkSheetData?.content?.name}
                </span>
              </div>
              <div className="sections-progress">
                Section {checkSheetData?.current_level_index + 1}/
                {checkSheetData?.level_count}
              </div>
              <div className="quiz-sections-name">
                <div style={{ width: "calc(100% - 20px)" }}>Daily Practice</div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src="/assets/img/course_internal_journey/info-icon.png"
                    alt=""
                    loading="lazy"
                    className="quiz-info-icon"
                    onClick={() => setShowModal(true)}
                  />
                  <Popup
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    data={modal_data[0]}
                  />
                  <div
                    className="quiz-save"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleBookmarkClick()}
                  >
                    {isBookmarked ? (
                      <BookmarkIcon color="#541cf5" />
                    ) : (
                      <BookmarkBorderOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
              <hr style={{ color: "#E0E0E0" }} />
              <div className="practice-section-desc">
                {checkSheetData?.content?.description}
              </div>

              <div className="lists">
                <div className="practice-try-list">
                  <div className="list-title try-list-title">Ready for a mindful minute? Complete this daily checklist to prioritize your mental well-being.</div>
                  <div className="list-items">
                    {tryList?.map((item, i) => (
                      <div className="try-list-item" key={i}>
                        <img
                          src="/assets/img/course_internal_journey/daily-practice/practice-plus.png"
                          alt=""
                          loading="lazy"
                          className="plus-icon"
                          onClick={() => HandleAddtoMydailyList(item)}
                        />
                        <div className="try-item-desc">{item.name}</div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="practice-list">
                  <div className="list-title practice-list-title">
                    My Daily Practice List
                  </div>
                  <div className="list-items">
                    {myList?.map((item, i) => (
                      <div
                        key={item.id}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="practice-list-item">
                          <div
                            onClick={() => HandleAddtoCompleteList(item)}
                            className="tick-icon"
                          />
                          <div className="practice-item-desc">{item.name}</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "1rem",
                          }}
                        >
                          <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(e) => handleClickOption(e, item.id)}
                            sx={{ minWidth: "max-content" }}
                          >
                            <MoreVertIcon />
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseOption}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => handleDeleteClick(deleteDailyp_id)}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-3">
                    {isEditing ? (
                      <div className="add-step-textarea">
                        <input
                          className="add-step-text"
                          value={textValue}
                          onChange={(e) => setTextValue(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && textValue.trim() !== "") {
                              handleSaveClick();
                              setTextValue("");
                            }
                          }}
                          rows="1" // Set the number of rows as needed
                          cols="30" // Set the number of columns as needed
                        />
                        <div
                          className="add-step-save-text"
                          onClick={handleSaveClick}
                          style={
                            textValue.trim() === ""
                              ? { color: "#885fff7a" }
                              : { color: "#885fff" }
                          }
                        >
                          Save
                        </div>
                      </div>
                    ) : (
                      <div
                        className="practice-btn"
                        onClick={handleAddStepClick}
                      >
                        <img
                          alt=""
                          src="/assets/img/course_internal_journey/daily-practice/plus-btn-icon.png"
                          className="plus-icon-btn"
                        />
                        <div className="btn-text" onClick={handleAddStep}>
                          Add Step
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {completedList?.length > 0 && (
                  <div className="done-list">
                    <div className="list-title practice-list-title">Done</div>
                    <div className="list-items">
                      {completedList?.map((item, i) => (
                        <div className="done-list-item">
                          <img
                            src="/assets/img/course_internal_journey/daily-practice/green-tick.png"
                            alt=""
                            loading="lazy"
                            className="tick-icon"
                          />
                          <div className="practice-item-desc">{item.name}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="daily-practice-btn d-flex align-items-center justify-content-between">
                <div>
                  {checkSheetData?.previous_id.type && (
                    <div className="prev-btn" onClick={handlePrevButton}>
                      <div className="modules-prev-text">Previous</div>
                      <div className="prev-arrow">
                        <img
                          src="/assets/img/course_internal_journey/modules/arrow.png"
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                          style={{ transform: "rotate(180deg)" }}
                        />
                      </div>
                    </div>
                  )}
                  {checkSheetData?.previous_module_id?.id &&
                    !checkSheetData?.previous_id?.type && (
                      <div
                        className="prev-btn"
                        onClick={handlePrevModuleButton}
                      >
                        <div className="modules-text">Previous Module</div>
                        <div className="prev-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                            style={{ transform: "rotate(180deg)" }}
                          />
                        </div>
                      </div>
                    )}
                </div>

                <div>
                  {checkSheetData?.next_id.type && (
                    <div
                      className="next-btn"
                      style={{ marginLeft: "auto" }}
                      onClick={handleNextButton}
                    >
                      <div className="modules-text">Next</div>
                      <div className="next-arrow">
                        <img
                          src="/assets/img/course_internal_journey/modules/arrow.png"
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {checkSheetData?.next_module_id?.id &&
                    !checkSheetData?.next_id?.type && (
                      <div
                        className="next-btn"
                        onClick={handleNextModuleButton}
                      >
                        <div className="modules-text">Next Module</div>
                        <div className="next-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default DailyPractice;
