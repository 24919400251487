import { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AppointmentDefaultView from "./component/AppointmentDefaultView";
import AppointmentCalendarView from "./component/AppointmentCalendarView";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";


import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from 'react-router-dom';


const ExpertAppointmentDB = () => {
  const [defaultView, setDefaultView] = useState(true);
  const [sortCriteria, setSortCriteria] = useState("date");
  const [selectedTab, setSelectedTab] = useState("Upcoming");
  const [loading, setLoading] = useState(true)


  const navigate = useNavigate();
  const location = useLocation();
  
 // Check if user is logged in
  // useEffect(() => {
  //   const isLoggedIn = isUserLoggedIn();

  //   if (!isLoggedIn) {
  //     window.location.href = "https://www.unitedwecare.com/";
  //   }
  // }, [location, navigate]);

  const handleTabChange = (e) => {
    setSelectedTab(e.target.innerText);
    setLoading(true)
  };
  const handleChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const handleView = () => {
    setDefaultView(!defaultView);
  };
  
  return (
    <div className="my-3 ExpertAppointmentDashboard">
      <div className="ExpertAppointmentDashboard--Header d-flex">
        <div className="d-flex Header--Left">
          <h2>Appointments{!defaultView && " > Calendar"}</h2>
          <Button
            onClick={handleView}
            variant="contained"
            className="ViewCalendar"
          >
            <CalendarMonthOutlinedIcon />
            View {defaultView ? "Calendar" : "Appointments"}
          </Button>
        </div>

        <div className="d-flex Header--Right">
          {defaultView && (
            <div className="d-flex gap-3 Options">
              <div
                className={`Option--Item ${
                  selectedTab == "Upcoming" ? "SelectedItem" : ""
                }`}
                onClick={(e) => handleTabChange(e)}
              >
                Upcoming
              </div>
              <div
                className={`Option--Item ${
                  selectedTab == "Past" ? "SelectedItem" : ""
                }`}
                onClick={(e) => handleTabChange(e)}
              >
                Past
              </div>
            </div>
          )}

          {defaultView && (
            <div className="Selectors d-flex gap-2 d-none">
              <p>Sort by</p>

              <FormControl fullWidth>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={sortCriteria}
                  displayEmpty
                  onChange={handleChange}
                >
                  <MenuItem value={"date"}>Date</MenuItem>
                  <MenuItem value={"time"}>Time</MenuItem>
                  <MenuItem value={"name"}>Name</MenuItem>
                  <MenuItem value={"duration"}>Duration</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      </div>

      <div>
        {defaultView ? <AppointmentDefaultView selectedTab={selectedTab} loading={loading} setLoading={setLoading}/> : <AppointmentCalendarView />}
      </div>
    </div>
  );
};

export default ExpertAppointmentDB;
