import React, { useContext, useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from 'react-bootstrap'
import DisclaimerModal from './DisclaimerModal';
import OTPModal from './OTPModal';
import { CheckReferralCode, CheckUserEmailIsRegister, LoginByOTPService, OODOCreateUserV4Service, OODOGetCountryListV4Service, OODOGetTimezoneV4Service, RegistrationByOTPV4Service, SendRequestOTPService } from './core/_request';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import { useAuth } from '../auth'; import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { AppContext } from '../context';
import jwt_decode from 'jwt-decode';
import { GoogleLogin } from '@react-oauth/google';
import './SignUp.css'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Snackbar from "@mui/material/Snackbar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];

const SignUp2 = () => {

    const [errorMessage, setErrorMessage] = useState("");
    const [successMsg, setSucessMsg] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [otpModalShow, setOtpModalShow] = useState(false);
    const [data, setData] = useState({ OTP: '' });
    const [validationMessage, setValidationMessage] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timezoneList, setTimezoneList] = useState([]);
    const [validReferralCode, setValidReferralCode] = useState();
    const [referralCodeMessage, setReferralCodeMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '']);
    const inputRefs = useRef([]);
    const count_time = process.env.REACT_APP_OTP_TIME;
    const { login, setCurrentUser, currentUser, userType } = useAuth();
    const navigate = useNavigate();
    const params = new URLSearchParams(window ? window.location.search : {});
    const [disclaimer, setDisclaimer] = useState();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const [credentialCode, setCredentialCode] = useState();
    const [decodedResponse, setDecodedResponse] = useState();
    const [detailModalShow, setdetailModalShow] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [resendOtpSent, setResendOtpSent] = useState(false);
    const [counter, setCounter] = useState(30);
    const [resendAllowed, setResendAllowed] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (otpModalShow) {
            inputRefs.current[0].focus();
        }
    }, [otpModalShow]);

    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(counter - 1), 1000);
        } else if (counter === 0) {
            setResendAllowed(true);
        }
        return () => clearTimeout(timer);
    }, [counter]);

    useEffect(() => {
        OODOGetCountryListV4Service().then((resp) =>
            setCountryList(resp.data.countries)
        );
    }, []);

    const handleChange = (index, value) => {
        if (!isNaN(value) && value.length === 1) {
            // Use the callback form of setOtp to ensure you get the latest state
            setOtp(prevOtp => {
                const newOtp = [...prevOtp];
                newOtp[index] = value;
                setData({ ...data, OTP: newOtp.join('') }); // Join the array to form the final OTP
                return newOtp;
            });

            // Move to the next input
            if (index < 3 && inputRefs.current[index + 1]) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, e) => {
        // Move to the previous input on backspace
        if (e.key === 'Backspace') {
            const newOtp = [...otp];

            // If the current input is empty, move to the previous input
            if (index > 0 && !otp[index]) {
                newOtp[index - 1] = '';
                inputRefs.current[index - 1].focus();
            } else {
                newOtp[index] = '';
            }

            setOtp(newOtp);
            setData(prevData => ({
                ...prevData,
                OTP: newOtp.join('')
            })); // Join the array to form the final OTP
        } else if (e.key === 'Enter') {
            e.preventDefault();
            onSubmitOtp();
        }
        else if (!/^\d$/.test(e.key)) {
            // Prevent input for non-numeric keys
            e.preventDefault();
        }
    };

    const HandelReferalCodeChange = (e) => {
        const textWithoutSpaces = e.target.value.replace(/\s/g, ''); // Remove spaces
        setValidReferralCode(textWithoutSpaces);
    }

    const onContinueSignUp = () => {
        setCounter(30);
        setLoading(true);
        if (validReferralCode) {
            CheckReferralCode(validReferralCode).then((resp) => {
                if (resp.ResponseCode === 200) {
                    setReferralCodeMessage('Referral code Applied');
                    setLoading(false);
                    setData({ ...data, referralCode: validReferralCode })
                    setValidationMessage([]);
                    setLoading(true);

                    // Checking Email Pattern
                    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                    if (!pattern.test(data.Email)) {
                        setValidEmail(false);
                        let newValidationMessage = Object.assign(
                            [],
                            [
                                ...validationMessage.filter((o) => o.key != "Email"),
                                { key: "Email", message: "Invalid Email" },
                            ]
                        );
                        setValidationMessage(newValidationMessage);
                        setLoading(false);
                    } else {
                        var req = { Email: data.Email };
                        SendRequestOTPService(req).then((resp) => {
                            setLoading(false);
                            if (resp != null && resp.ResponseCode == 200) {
                                setOtpSent(true);
                                setCounter(count_time);
                                setOtpModalShow(true);
                            } else if (resp != null && resp.ResponseCode == 401) {
                            }
                        });
                    }
                }
                else {
                    setReferralCodeMessage("Referral code doesn't exist");
                    enqueueSnackbar("Referral code doesn't exist", { variant: "error" });
                    setData({ ...data, Referral_Code: null })
                    setLoading(false);
                }
            })
        } else {
            setValidationMessage([]);
            setLoading(true);

            // Checking Email Pattern
            var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
            if (!pattern.test(data.Email)) {
                setValidEmail(false);
                let newValidationMessage = Object.assign(
                    [],
                    [
                        ...validationMessage.filter((o) => o.key != "Email"),
                        { key: "Email", message: "Invalid email" },
                    ]
                );
                setValidationMessage(newValidationMessage);
                setLoading(false);
            } else {
                var req = { Email: data?.Email };
                SendRequestOTPService(req).then((resp) => {
                    setLoading(false);
                    if (resp != null && resp.ResponseCode == 200) {
                        setOtpSent(true);
                        setCounter(count_time);
                        setOtpModalShow(true);
                    } else if (resp != null && resp.ResponseCode == 401) {
                    }
                });
            }
        }
    }

    const onSubmitOtp = () => {
        var req = {
            Email: data.Email,
            OTP: data?.OTP,
        };
        LoginByOTPService(req).then((resp) => {
            // debugger
            if (resp != null && resp.ResponseCode == 200) {
                if (resp.data.IsLogin) {
                    // debugger
                    let UserType = resp.data.UserType;
                    let profile = resp.data.userdetails;
                    profile.UserType = UserType;
                    login(resp.data);
                    setCurrentUser(profile);
                    var retrunurl_local = localStorage.getItem("retrunurl");
                    const retrunurl = params.get("retrunurl") || retrunurl_local;
                    if (retrunurl) {
                        localStorage.removeItem("retrunurl");
                        navigate(retrunurl);
                    } else {
                        enqueueSnackbar("This email is already registered", { variant: "warning" });
                        navigate("/user-dashboard");
                    }
                } else {
                    createRegistration();
                }
            } else {
                setResendOtpSent(false);
                let newValidationMessage = Object.assign(
                    [],
                    [
                        ...validationMessage.filter((o) => o.key != "OTP"),
                        { key: "OTP", message: <span><ErrorOutlineIcon /> Incorrect OTP, Please try again</span> },
                    ]
                );
                setValidationMessage(newValidationMessage)
                setLoading(false);
            }
        });
    }

    const createRegistration = () => {
        // debugger
        setLoading(true);
        const SocialID = null;
        const SocialType = null;

        var MobileNo = "";
        if (data.country == "India") {
            MobileNo = "+91 0000000000";
        } else if (data.country == "Canada") {
            MobileNo = "+1 0000000000";
        } else {
            MobileNo = "+91 0000000000";
        }
        var name = "";
        if (data.firstName != null && data.firstName != "") {
            name = data.firstName;
        } else {
            name = "user";
            setData({ data: { ...data, firstName: "user", lastName: " " } });
        }
        var req = {
            Emailid: data.Email,
            Title: "`",
            Name: name,
            MobileNo: MobileNo,
            ReferralCode: null,
            Referral_Code: data.referralCode,
            Country: "India",
            UserType: 4,
            TimeZone: "Asia/Kolkata",
            SocialID,
            SocialType,
        };
        RegistrationByOTPV4Service(req).then((resp) => {
            // debugger
            if (resp != null && resp.ResponseCode == 200) {

                let UserType = resp.data.UserType;
                let profile = resp.data.userdetails;
                profile.UserType = UserType;

                if (
                    resp.data.userdetails.Country == null &&
                    resp.data.userdetails.MobileNo.includes("+91")
                ) {
                    profile.Country = "India";
                } else if (
                    resp.data.userdetails.Country == null &&
                    resp.data.userdetails.MobileNo.includes("+1")
                ) {
                    profile.Country = "Canada";
                }

                login(resp.data);
                setCurrentUser(profile);

                var oodoCreateUserData = {
                    ...data,
                    email: profile.Emailid,
                    userId: profile.UserId,
                    device_type: "web",
                    timezone: "Asia/Kolkata",
                    country: "India",
                    countryId: 104,
                    firstName: data.firstName,
                    lastName: data.lastName,
                };

                OODOCreateUserV4Service(oodoCreateUserData).then((resp2) => {
                    // debugger
                    console.log("resp2?.data?.disclaimer", resp2);
                    setDisclaimer(resp2?.data?.disclaimer);

                    setLoading(false);
                    const retrunurl = params.get("retrunurl");
                    navigate("/user-dashboard");

                    setOtpModalShow(false);
                    setModalShow(true);
                });
            }
        });
    };

    const resendOTP = () => {
        setResendAllowed(false); // Disable resend button
        setCounter(30);
        let newValidationMessage = Object.assign(
            [],
            [
                ...validationMessage.filter((o) => o.key != "OTP"),
                { key: "OTP", message: "" },
            ]
        );
        setValidationMessage(newValidationMessage);
        setOtpSent(true);
        setCounter(count_time);
        // debugger
        var req = { Email: data.Email };
        SendRequestOTPService(req).then((resp) => {
            setLoading(false);
            if (resp != null && resp.ResponseCode == 200) {
                let newValidationMessage = Object.assign(
                    [],
                    [
                        ...validationMessage.filter((o) => o.key != "OTP"),
                        { key: "OTP", message: <span><CheckCircleOutlineIcon /> An OTP has been resent to you.</span> },
                    ]
                );
                setValidationMessage(newValidationMessage);
                setCounter(count_time);
                setOtpSent(true);
                setResendOtpSent(true);
            } else if (resp != null && resp.ResponseCode == 401) {
            }
        });
    };

    const onGoogleLoginSuccess = (credentialResponse) => {
        setCredentialCode(credentialResponse?.credential);
    };

    useEffect(() => {
        if (credentialCode) {
            try {
                const decoded = jwt_decode(credentialCode);
                console.log(credentialCode)
                setDecodedResponse(decoded);
            } catch (error) {
                console.error('Error decoding token:', error);
            }
        }
    }, [credentialCode]);

    useEffect(() => {
        console.log("decodedResponse", decodedResponse);
        if (decodedResponse) {
            setData((prevData) => ({
                ...prevData,
                Email: decodedResponse?.email,
                firstName: decodedResponse?.given_name,
                lastName: decodedResponse?.family_name,
                Name: decodedResponse?.name,
            }))
            CheckUserEmailIsRegister(decodedResponse?.email).then((resp) => {
                if (resp?.ResponseCode === 400) {
                    toast(resp?.ResponseMessage)
                    setShowLloginPopup(true);
                } else if (resp?.ResponseCode === 200) {
                    setdetailModalShow(true);
                }
            })
        }
    }, [decodedResponse]);

    const onDetailSubmit = () => {
        setLoading(true);
        if (validReferralCode) {
            CheckReferralCode(validReferralCode).then((resp) => {
                if (resp.ResponseCode === 200) {
                    setReferralCodeMessage('Referral code Applied');
                    setLoading(false);
                    setData({ ...data, referralCode: validReferralCode })
                    createRegistration();
                    setValidationMessage([]);
                    setLoading(true);
                }
            })
        } else {
            setReferralCodeMessage("Referral code doesn't exist");
            enqueueSnackbar("Referral code doesn't exist", { variant: "error" });
            setData({ ...data, Referral_Code: null })
            setLoading(false);
        }
    }

    const onSkipSignUp = () => {
        setLoading(true);
        setdetailModalShow(false);
        createRegistration();
        setValidationMessage([]);
        setLoading(false);
    }

    return (
        <>
            <Helmet>
                <title>
                    United We Care Signup
                </title>
            </Helmet>
            <div className='kareify--SignUp'>
                <div className='signUp-container d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-md-between justify-content-center'>
                    <div className='d-flex flex-column gap-3 w-100'>
                        <h5 className='welcome-text-md text-center col-md-5 align-self-center d-none d-md-block'>
                            “You are worthy of happiness and peace of mind.”
                        </h5>
                        <h5 className='welcome-text text-center col-md-5 align-self-center d-block d-md-none'>
                            “You are worthy of happiness and peace of mind.”
                        </h5>
                        <div className='d-none d-md-flex flex-column align-self-start ms-3'>
                            <h2>
                                Compliance
                            </h2>
                            <div className="d-flex gap-3 align-items-center justify-content-center">
                                <img src="/assets/img/kareify/iso.webp.png" alt="" style={{ borderRadius: '8px' }} />
                                <img src="/assets/img/kareify/hippa.webp.png" alt="" style={{ borderRadius: '8px' }} />
                                {/* <img src="/assets/img/kareify/hitrust.png" alt="" style={{ borderRadius: '8px' }} /> */}
                                {/* <img src="/assets/img/aicpa.png" alt="" style={{ borderRadius: '8px' }} /> */}
                            </div>
                        </div>
                    </div>
                    <div className='SignIn--box col-11 col-md-4 col-xxl-3 d-flex flex-column align-items-center gap-2'>
                        <h3>
                            Create Account
                        </h3>

                        <div className='d-flex align-items-center justify-content-around w-75 social--login'>
                            <GoogleLogin
                                onSuccess={onGoogleLoginSuccess}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                        </div>

                        <Modal
                            show={otpModalShow}
                            onHide={() => setOtpModalShow(false)}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className='otp--popup'
                        >
                            <Modal.Body className='d-flex flex-column gap-4'>
                                <div className='otp--header d-flex align-items-center justify-content-between'>
                                    <h3>Confirm OTP</h3>
                                    <CloseButton onClick={() => setOtpModalShow(false)} />
                                </div>

                                <p>
                                    An One Time Password has been sent to your email <span>{data?.Email}</span> Please enter the OTP to verify your email.
                                </p>

                                <form className='otp-container d-flex align-items-center gap-5 justify-content-center px-5'>
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="tel"
                                            onChange={(e) => handleChange(index, e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                            maxLength="1"
                                            ref={(el) => (inputRefs.current[index] = el)}
                                        />
                                    ))}
                                </form>

                                {resendOtpSent && counter !== 0 &&
                                    <span className="error-message px-5 d-flex align-items-center justify-content-center gap-2" style={{ color: "#00AD65" }}>
                                        {validationMessage.find((o) => o.key === "OTP")?.message}
                                    </span>
                                }

                                {!resendOtpSent && validationMessage.some((o) => o.key === "OTP") && (
                                    <span className="error-message px-5 d-flex align-items-center justify-content-center gap-2" style={{ color: "#dc3545" }}>
                                        {validationMessage.find((o) => o.key === "OTP")?.message}
                                    </span>
                                )}

                                <div className='otp--resend d-flex align-items-center justify-content-between px-4'>
                                    {counter === 0 ?
                                        <div className="w-100">
                                            <p className="font-inter fs-6 text-black text-center w-100 d-flex align-items-center justify-content-between">
                                                <span>00:0{counter}</span> <span onClick={resendOTP} style={{ cursor: "pointer", color: "#73589B" }} >Resend OTP</span>
                                            </p>
                                        </div>
                                        :
                                        <div
                                            className="w-100 d-flex align-items-center justify-content-between font-inter fs-6 text-black text-center border-0 bg-white"
                                        >
                                            <span>00:{counter < 10 ? 0 : ''}{counter}</span> <span>Resend OTP</span>
                                        </div>
                                    }
                                </div>

                                <Button
                                    disabled={
                                        loading ||
                                        (data?.OTP.length < 4)
                                    }
                                    className='otp--continue-btn border-0 d-block mx-auto my-3'
                                    onClick={onSubmitOtp}
                                >
                                    Continue
                                </Button>
                            </Modal.Body>
                        </Modal>

                        <div className='d-flex align-items-center w-100 gap-2 px-5 horizontal--line'>
                            <hr />
                            <p>Or</p>
                            <hr />
                        </div>

                        <p>
                            Sign up with your email address
                        </p>

                        <input
                            placeholder="Email Address *"
                            variant="outlined"
                            className='w-75'
                            onChange={(e) => setData((prevData) => ({ ...prevData, Email: e?.target?.value }))}
                        />
                        {
                            !validEmail &&
                            <span
                                className="error-message fs-6"
                                style={{ color: "#dc3545", fontWeight: 400, alignSelf: "flex-start", marginLeft: "14%" }}
                            >
                                {
                                    validationMessage.filter((o) => o.key == "Email")[0]?.message
                                }
                            </span>
                        }
                        <input
                            placeholder="Full Name*"
                            variant="outlined"
                            className='w-75'
                            onChange={(e) => {
                                const trimmedValue = e.target.value.trim(); // Remove leading and trailing spaces
                                if (trimmedValue.length > 0) {
                                    setData({ ...data, firstName: trimmedValue });
                                }
                            }}
                        />
                        <input
                            placeholder="Referral code (Optional)"
                            variant="outlined" className='w-75'
                            value={validReferralCode}
                            onChange={(e) =>
                                HandelReferalCodeChange(e)
                            }
                        />
                        <p className="font-inter font-w500 p-1" style={{ color: referralCodeMessage === "Referral code Applied" ? "green" : "red" }}>
                            {referralCodeMessage}
                        </p>



                        <Button className='border-0' onClick={onContinueSignUp}
                            disabled={loading || !data?.Email || !data?.firstName}>
                            Continue
                        </Button>

                        <DisclaimerModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            disclaimer={disclaimer}
                        />

                        <p>
                            Already have an account? <span className='diff-clr' onClick={() => setShowLloginPopup(true)}>Login</span>
                        </p>
                    </div>
                </div>


                <Modal
                    show={detailModalShow}
                    onHide={() => setdetailModalShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='details--popup show'
                >
                    <Modal.Body>
                        <h5>Please Enter</h5>
                        <div className="d-flex flex-column align-items-center gap-3 input-section">
                            <TextField
                                placeholder="Referral code (Optional)"
                                variant="outlined" className='w-100'
                                value={validReferralCode}
                                onChange={(e) =>
                                    HandelReferalCodeChange(e)
                                }
                            />
                            <p className="font-inter font-w500 p-1" style={{ color: referralCodeMessage === "Referral code Applied" ? "green" : "red" }}>
                                {referralCodeMessage}
                            </p>

                            <div className='d-flex align-items-center gap-3'>
                                <Button className='border-0' onClick={onSkipSignUp}>
                                    Skip
                                </Button>

                                <Button onClick={onDetailSubmit}>
                                    Continue
                                </Button>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default SignUp2