import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  downloadWorksheetData_WebService,
  getWorksheetData_WebService,
  uploadWorksheetData_WebService,
} from "../core/_request";
import { useAuth } from "../../auth";
import { storage } from "../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";
import moment from "moment";
import { useSnackbar } from "notistack";

const CourseWorksheet = (props) => {
  const { worksheetData } = props;
  const [data, setData] = useState();
  const [percent, setPercent] = useState();
  const [downloadData, setDownloadData] = useState();
  const [loading, setLoading] = useState();
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {})
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });

    const body = {
      userId: currentUser.UserId,
      worksheetId: worksheetData.worksheet.id,
    };
    getWorksheetData_WebService(body).then((resp) => {
      setData(resp.data);
    });
    downloadWorksheetData_WebService(body).then((resp) => {
      setDownloadData(resp.data);
    });
  }, []);

  const handleFileUpload = async (event) => {
    setLoading(true);

    var file = event.target.files[0];

    var dirName = moment().format("MMMM_YYYY");
    const storageRef = ref(storage, dirName + "/" + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // update progress
        setPercent(percent);
      },
      (err) => enqueueSnackbar(err, { variant: "error" }),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          var req = {
            userId: currentUser.UserId,
            worksheetId: worksheetData.worksheet.id,
            attachments: [url],
            notes: "This is a sample note",
          };
          uploadWorksheetData_WebService(req);
          enqueueSnackbar("Thanks.", { variant: "success" });
          setLoading(false);
        });
      }
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center py-3">
        <div className=" d-flex align-items-center gap-5">
          <div>
            <h6>Steps to fill the Worksheet:</h6>
          </div>
          <div>
            <p>1. Download and send.</p>
            <p>2. Print and fill.</p>
            <p>3. Upload the worksheet.</p>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <Button variant="outline-warning">Share</Button>
          <Button variant="outline-warning">
            <a
              href={downloadData?.link}
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none"
            >
              Download
            </a>
          </Button>

          <div className="d-flex align-items-center">
            {(loading && <span>Loading....</span>) || (
              <input type="file" onChange={handleFileUpload} />
            )}
          </div>
        </div>
      </div>
      <hr />
      {data &&
        data.images &&
        data.images.map((item) => (
          <div className="col-md-4">
            <img src={item} alt="" />
          </div>
        ))}
    </div>
  );
};

export default CourseWorksheet;
