import { useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import "moment-timezone";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";

const PayoutDetailsTableRow = ({
  id,
  code,
  amount,
  type,
  name,
  image,
  time,
  remarks,
  currency,
}) => {
  const formattedDate = moment(time, "DD-MM-YYYY[T]HH:mm:ss z").format(
    "D MMM YYYY"
  );
  const formattedTime = moment(time, "DD-MM-YYYY[T]HH:mm:ss z").format(
    "HH:mm z"
  );
  const timezone = moment.tz(time).format("z");

  return (
    <TableRow
      className={`PayoutDetails--TableRow`}
      sx={{
        backgroundColor: "#FFF",
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell className="Table--ID">
        <p>{code}</p>
      </TableCell>
      <TableCell className="Table--Amount">
        <div>
          <p className="">
            {currency?.symbol}
            {amount}
          </p>
          {type === "session" ? (
            <span
              className=""
              style={{
                padding: "5px 10px",
                borderRadius: "10px",
                background: "#9DEA6633",
                color: "#55A51D",
                fontSize: "12px",
                fontWeight: "500",
                width: "max-content",
                textTransform: "capitalize",
              }}
            >
              {type}
            </span>
          ) : (
            <span
              className=""
              style={{
                padding: "5px 10px",
                borderRadius: "10px",
                background: "#FBEAEC",
                color: "#D2293D",
                fontSize: "12px",
                fontWeight: "500",
                width: "max-content",
                textTransform: "capitalize",
              }}
            >
              {type}
            </span>
          )}

        </div>
      </TableCell>
      <TableCell className="Table--User">
        {type === "session" ? (
          <div className="d-flex align-items-center gap-3">
            <img
              src={image}
              width={40}
              height={40}
              style={{ borderRadius: "50%", objectFit: "cover" }}
            />
            <p style={{ fontWeight: "600" }}>{name}</p>
          </div>
        ) : (
          <div className="d-flex align-items-center gap-3">
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                background: "#D2293D1A",
                color: "#D2293D",
                position: "relative",
              }}
            >
              <PaymentOutlinedIcon
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
            <p style={{ fontWeight: "600" }}>Payout</p>
          </div>
        )}
      </TableCell>

      <TableCell className="Table--DateTime">
        <div>
          <p>{formattedDate}</p>
        </div>
      </TableCell>

      <TableCell className="Table--Remarks">
        <p>{remarks}</p>
      </TableCell>
    </TableRow>
  );
};

export default PayoutDetailsTableRow;
