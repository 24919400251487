import React from "react";
import { Button, Dropdown, Card } from "react-bootstrap";
import "./ContactCardResponse.css";
import { useNavigate, Link } from "react-router-dom";

const StaticStellaContactCard = ({
  id,
  name,
  image,
  profession,
  expInYears,
  languages,
  type,
  buttonText,
  url,
}) => {
  const navigate = useNavigate();

  return (
    <div
      id={id}
      style={{ width: "100%", maxWidth: "290px", padding: "10px" }}
      className="ContactCard"
    >
      <Link to={`${url}`} target="_blank" style={{ textDecoration: "none" }}>
        <div className="ContactCard--Content">
          <Card.Img style={{ minHeight: "120px" }} variant="top" src={image} />
          <Card.Body>
            <Card.Title style={{ marginLeft: "10px" }}>{name}</Card.Title>
            <div className="ContactCard--desc">
              {type === "Professional" ? (
                <>
                  {" "}
                  {profession !== undefined && (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width={`18px`}
                        height={`18px`}
                      >
                        <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
                      </svg>

                      <p>{profession}</p>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}

              {type === "Professional" ? (
                <>
                  {expInYears !== undefined && (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width={`18px`}
                        height={`18px`}
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clipRule="evenodd"
                        />
                        <path d="M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z" />
                      </svg>

                      <p>{expInYears}</p>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}

              {type === "Professional" ? (
                <>
                  {" "}
                  {languages !== null &&
                    languages !== "" &&
                    languages !== undefined &&
                    languages.length > 0 && (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={`18px`}
                          height={`18px`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                          />
                        </svg>
                        <p>{languages}</p>
                      </div>
                    )}
                </>
              ) : (
                <>
                  {languages !== null &&
                    languages !== "" &&
                    languages !== undefined &&
                    languages.length > 0 && (
                      <div className="mt-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={`18px`}
                          height={`18px`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                          />
                        </svg>
                        <p>English</p>
                      </div>
                    )}
                </>
              )}
            </div>
          </Card.Body>
        </div>
        <button
          style={{ border: "none", width: "100%" }}
          className="bookExpert--Btn"
        >
          {buttonText}
        </button>
      </Link>
    </div>
  );
};

export default StaticStellaContactCard;
