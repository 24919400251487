import React, { useState } from "react";
import BookAnAppointment from "../components/BookAnAppointment";
import { Helmet } from "react-helmet";

const BookAnAppointmentPage = () => {
  return (
    <div style={{ background: "#FFF", padding: 0, minHeight:"60vh" }}>
      <Helmet>
        <title>
          Book Your Mental Wellness Appointment Online | United We Care
        </title>
      </Helmet>
      <BookAnAppointment />
    </div>
  );
};

export default BookAnAppointmentPage;
