import Cookies from 'universal-cookie';

export const getCountryCode = () => {
    const cookies = new Cookies();
    var countryCode = cookies.get('countryCode');
    return countryCode;
}

export const getCurrencyCode =()=> {
    const cookies = new Cookies();
    var countryCode = cookies.get('countryCode');
    if(countryCode == "IN") return "₹";
    else return "$";
}


 