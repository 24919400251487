import axios from 'axios'
import jwtDecode from 'jwt-decode';
import { Config } from '../../../core/_request';

export const getExpertDetailById = (req) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/popupservices/getExpertListWithFilter_Web_V3_Next_Appointment_Odoo`, req, Config())
        .then((resp) => resp.data)
        .then((resp) => {
            if (resp.data) resp.data = jwtDecode(resp.data);
            return resp;
        });
};

export const getSearchedResult = (inputValue) => {
    return axios.get(`https://ms.uwc.world/wellness/search?query=${inputValue}&country=India`, Config())
        .then((resp) => resp?.data)
        .then((resp) => {
            // if (resp.data) resp.data = (resp.data);
            return resp;
        });
};