import React, { useState, useContext, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AppContext } from "../../../context";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const FollowUpTable = ({
  aiGenerated,
  isWebsocketDataRecieved,
  sessionStatus,
}) => {
  const [value, setValue] = useState(dayjs());
  const [followUpIn, setFollowUpIn] = useState("");
  const [followUpUnit, setFollowUpUnit] = useState("");
  const [followUpTable, setFollowUpTable] = useState([{ time: "" }]);
  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);
  const [generated, setGenerated] = useState(false);

  useEffect(() => {
    setValue(dayjs(expertDashboardData?.session_data?.follow_up_date));
  }, [expertDashboardData?.session_data?.follow_up_date]);

  const handleInputChange = (field, inputValue) => {
    if (field === "time") {
      setValue(dayjs(inputValue));
      setFollowUpTable([{ time: inputValue }]);
      setExpertDashboardData((prevData) => ({
        ...prevData,
        session_data: {
          ...prevData.session_data,
          follow_up_date: inputValue,
        },
      }));
    } else if (field === "followUpIn") {
      setFollowUpIn(inputValue);
      calculateFollowUpDate(inputValue, followUpUnit);
    } else if (field === "followUpUnit") {
      setFollowUpUnit(inputValue);
      calculateFollowUpDate(followUpIn, inputValue);
    }
  };

  const calculateFollowUpDate = (followUpInValue, followUpUnitValue) => {
    const newDate = dayjs().add(parseInt(followUpInValue), followUpUnitValue);

    if (newDate.isValid()) {
      const calculatedFollowUpDate = newDate.toISOString();
      setFollowUpTable([{ time: calculatedFollowUpDate }]);
      setExpertDashboardData((prevData) => ({
        ...prevData,
        session_data: {
          ...prevData.session_data,
          follow_up_date: calculatedFollowUpDate,
        },
      }));
    } else {
      console.error("Invalid date");
    }
  };


  useEffect(() => {
    setGenerated(aiGenerated);
  }, [aiGenerated]);

  return (
    <div
      style={{ border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}
      className=" p-3 my-5"
    >
      {generated && !sessionStatus && (
        <div className="my-3">
          <span
            style={{
              color: "#305FB8",
              width: "fit-content",
              border: "1px solid",
              backgroundColor: "rgba(48, 95, 184,0.2)",
            }}
            className="d-flex fw-bold px-3 gap-2 align-items-center   rounded-5 p-1"
          >
            <img
              alt=""
              src="/assets/img/icons/sparkle_blue.svg"
              width={16}
              className=""
              color="#305FB8"
            />
            <span>AI Generated</span>
          </span>
        </div>
      )}
      {!generated ? (
        <div className="Table-span-3 d-flex align-items-center">
          {isWebsocketDataRecieved ? (
            <button
              className="border-0 mx-1"
              style={{ backgroundColor: "white" }}
              onClick={() => setGenerated(true)}
            >
              <AutoFixHighIcon
                sx={{ color: "#6C46A0", width: "30px", height: "30px" }}
              />
            </button>
          ) : (
            <div
              style={{ marginRight: "8px", marginLeft: "5px" }}
              className="d-flex align-items-center justify-content-center my-3"
            >
              <img
                alt=""
                src="/assets/img/icons/sparkling.png"
                width={15}
                className="animate-pulse"
              />
            </div>
          )}
          <h6>Follow Up</h6>
        </div>
      ) : (
        <h6>Follow Up</h6>
      )}
      <div className="Table--Head mt-1 gap-3">
        <div className="Table-span-6">Follow Up In </div>
        <div className="Table-span-6">Follow Up Date</div>
      </div>
      <div className="Table--Body gap-3 my-2">
        <div className="Table-span-3">
          <TextField
            value={followUpIn || ""}
            disabled={sessionStatus}
            fullWidth
            onChange={(e) => handleInputChange("followUpIn", e.target.value)}
            label="Follow Up In"
          />
        </div>
        <div className="Table-span-3">
          <FormControl fullWidth>
            <Select
              disabled={sessionStatus}
              onChange={(e) => handleInputChange("followUpUnit", e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={followUpUnit || ""}
            >
              <MenuItem value={""} disabled>Select an option</MenuItem>
              <MenuItem value={"days"}>Days</MenuItem>
              <MenuItem value={"weeks"}>Weeks</MenuItem>
              <MenuItem value={"months"}>Months</MenuItem>
            </Select>

          </FormControl>
        </div>
        <div className="Table-span-6">
          <LocalizationProvider dateAdapter={AdapterDayjs} className="pt-0">
            <DemoContainer
              components={["DateTimePicker"]}
              sx={{ paddingTop: 0 }}
            >
              <DateTimePicker
                disabled={sessionStatus}
                className="pt-0"
                value={value}
                
                format="DD/MM/YYYY hh:mm A"
                onChange={(newValue) => handleInputChange("time", newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default FollowUpTable;
