import React from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import CountUp from "react-countup";

const PayoutWidgetCard = ({ icon, title, value, currency }) => {
  return (
    <div className="PayoutWidget">
      <div className="PayoutWidgetCard--Header">
        {icon === "wallet" && (
          <AccountBalanceWalletIcon sx={{ fill: "#73589b !important" }} />
        )}
        {icon === "Bank" && (
          <AccountBalanceOutlinedIcon sx={{ fill: "#73589b !important" }} />
        )}
        {icon === "time" && (
          <QueryBuilderOutlinedIcon sx={{ fill: "#73589b !important" }} />
        )}

        <span>{title}</span>
      </div>

      <h6>
        {currency.symbol} <CountUp end={value} />{" "}
      </h6>
    </div>
  );
};

export default PayoutWidgetCard;
