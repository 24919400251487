import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactPlayer from 'react-player'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // maxWidth: 600,
    minWidth: 600,
    minHeight: 500,
    bgcolor: '#f5f1fe',
    boxShadow: 16,
    // p: 4, 
    aspectRatio: 16 / 9,
};

const VideoModal = ({ showModal, data, closeModal }) => {
    const close = (event) => {
        event.preventDefault();
        event.stopPropagation();
        closeModal();
    };
    return (
        <Modal
            open={showModal}
            onClose={close} // Use close function here
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='videoModal--container'
        >
            <Box sx={style} className='d-flex align-items-center justify-content-center boxContainer'>
                <IconButton onClick={close} style={{ color: "#73589b", position: "absolute", top: "5px", right: "10px" }}>
                    <CloseIcon />
                </IconButton>
                {/* <ReactPlayer
                    url={data?.video?.player_embed_url}
                    playIcon={true}
                    controls={true}
                    height={"100%"}
                >
                </ReactPlayer> */}
                <iframe
                    src={data?.video?.player_embed_url}
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    title='Video'
                    autoPlay={true}
                ></iframe>
            </Box>
        </Modal>
    );
}

export default VideoModal;
