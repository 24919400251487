import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const Disclaimer = () => {
    const navigate=useNavigate();
    const[iAgree, setIAgree]=useState();
    const params = new URLSearchParams(
        window ? window.location.search : {}
    );

    const onSubmit = () => {
        const retrunurl = params.get("retrunurl");
        if (retrunurl) {
            navigate(retrunurl)
        }
        else {
            navigate("/")
        }
    }

    const disclaimerText = localStorage.getItem("disclaimer");
    return (
        <div>
            <div className='' style={{ background: "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)", padding: 0, margin: 0 }}>
                <div className='container'>
                    <div className="py-5">
                        <h6 className="text-white font-w600 fs-4">Welcome to United We Care!</h6>
                    </div>
                    <div className='bg-white px-5' style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: "12px" }}>
                        <h6 className='fs-5 pt-5'>Disclaimer</h6>
                        <Form.Group className="mb-3 pb-5 d-flex justify-content-between" controlId="formBasicCheckbox">
                            <Form.Check onClick={()=> setIAgree(!iAgree)} type="checkbox" label="Agree & Continue" /> 
                            <Button disabled={!iAgree} onClick={()=> onSubmit()} color='white' className='border-0 rounded-5' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)" }}>Accept</Button>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer