import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth';
import './VideoCard.css'
import VideoModal from './VideoModal.js';
import SharePopup from '../../Community/Components/SharePopup';
// import Modal from 'react-bootstrap/Modal';
// import ReactPlayer from "react-player";

const VideoCard = ({ item }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const open = Boolean(anchorEl);
    const { currentUser } = useAuth();
    // let userId = currentUser?.UserId
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleVideoModalOpen = () => {
        setVideoModalOpen(true); // Open the modal
    };

    const handleVideoModalClose = () => {
        setVideoModalOpen(false); // Close the modal
    };

    const handleShare = () => {
        setModalShow(true)
        handleClose();
    }

    const timestamp = item?.createDateIST;

    // Parse the timestamp using the Date object
    const dateObject = new Date(timestamp);

    // Get the date components
    const day = String(dateObject.getDate()).padStart(2, '0');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[dateObject.getMonth()];
    const year = dateObject.getFullYear();
    // Create the formatted date string
    const formattedDate = `${day} ${month} ${year}`;

    return (
        <div className='VideoCard--module position-relative gap-2 justify-content-between'>
            <img src={item?.smallThumbnailUrl} alt='' className='w-100' onClick={handleVideoModalOpen} style={{ cursor: 'pointer' }} />
            <div className='my-2 mx-2 d-flex flex-column justify-content-between gap-3 h-100'>
                <div className='d-flex align-items-center justify-content-between'>
                    <h6 onClick={handleVideoModalOpen} className="title-ellipsis" title={item?.title} style={{ cursor: 'pointer' }}>
                        {item?.title}
                    </h6>
                    <a onClick={handleMenuClick} style={{ cursor: 'pointer' }}>
                        <MoreVertIcon />
                    </a>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {/* {userId !== item?.data?.user?.userId ?
                            <MenuItem onClick={handleClose}>Report</MenuItem>
                            :
                            <MenuItem onClick={handleClose}>Delete</MenuItem>
                        } */}
                        <MenuItem onClick={handleShare} style={{ width: "140px" }} className='d-flex gap-3'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.59 12L15 7.41V9.87L14.14 10C9.83 10.61 6.91 12.87 5.24 16.33C7.56 14.69 10.44 13.9 14 13.9H15V16.59M13 14.92C8.53 15.13 5.33 16.74 3 20C4 15 7 10 14 9V5L21 12L14 19V14.9C13.67 14.9 13.34 14.91 13 14.92Z" fill="black" />
                            </svg>
                            Share
                        </MenuItem>
                    </Menu>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                    <p>{formattedDate}</p>
                    {item?.club?.name && <div className='clubDiv'>{item?.club?.name}</div>}
                </div>
            </div>
            <span className='video-duration'>
                {item?.duration}
            </span>
            <img src='/assets/img/kareify/Play.png' alt='' className='video-playBtn' onClick={handleVideoModalOpen} style={{ cursor: 'pointer' }} />
            {/* <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className='video-playBtn'>
                <g filter="url(#filter0_b_4363_1150)">
                    <circle cx="24" cy="24" r="24" fill="#CECECE" fill-opacity="0.35" />
                </g>
                <path d="M18.5454 14.1812L33.8181 23.9993L18.5454 33.8175V14.1812Z" stroke="white" stroke-width="2.18" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <filter id="filter0_b_4363_1150" x="-30" y="-30" width="108" height="108" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4363_1150" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4363_1150" result="shape" />
                    </filter>
                </defs>
            </svg> */}

            <VideoModal
                showModal={videoModalOpen}
                closeModal={handleVideoModalClose}
                data={item}
            />

            <SharePopup
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div >
    )
}

export default VideoCard