import React, { useState, useEffect } from "react";
import "../../Styles/style.css";
import { BsArrowRightCircle } from "react-icons/bs";
import { getAppointments } from "../../_request";
import { useAuth } from "../../../../auth";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Skeleton } from "@mui/material";
import Cookies from "js-cookie";

function Appointments() {
  const navigate = useNavigate();
  const [appointmentFilter, setAppointmentFilter] = useState("Upcoming");
  const { currentUser } = useAuth();
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [calledAppointment, setCalledAppointment] = useState(false)
  const cookie_token = Cookies.get("authToken")


  useEffect(() => {
    if (!currentUser && cookie_token) {
      return;
    } else {
      let body = {
        user_id: currentUser?.UserId,
        datetime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        type: appointmentFilter === "Upcoming" ? "upcoming" : "past",
      };

      const fetchAppointments = async () => {
        try {
          setLoading(true)
          const { appointments } = await getAppointments(JSON.stringify(body));

          setAppointmentsData(appointments);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching random experts:", error);
        } finally {
          setCalledAppointment(true)
        }
      };
      fetchAppointments();
    }
  }, [appointmentFilter]);

  const cancelledAppointments = appointmentsData?.filter(
    (item) => item.Status === "Cancelled"
  );
  const otherAppointments = appointmentsData?.filter(
    (item) => item.Status !== "Cancelled"
  );

  return (
    <>
      {loading ? (
        <>
          <div
            className="mt-3"
            style={{
              backgroundColor: "rgba(0,0,0,0.07)",
              borderRadius: "8px",
            }}
          >
            <div className="d-flex align-items-center justify-content-between p-2">
              <div
                className=""
                style={{
                  marginTop: "0.75rem",
                  "@media (min-width: 768px)": { marginTop: "0rem" },
                }}
              >
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={20}
                  className="mx-2 rounded rounded-3 my-1"
                />

                <div className="d-flex gap-2 mx-2 my-2">
                  <Skeleton
                    variant="rounded"
                    width="120px"
                    height="30px"
                    className="rounded rounded-pill"
                  />

                  <Skeleton
                    variant="rounded"
                    width="120px"
                    height="30px"
                    className="rounded rounded-pill"
                  />
                </div>
              </div>

              <Skeleton
                variant="rounded"
                width="100px"
                height="30px"
                className="rounded rounded-pill mx-2 my-1"
              />
            </div>

            <div className="row main-card  p-md-5 p-4">
              <div className="col-md-12">
                <div className="pt-lg-2 pt-3 pb-lg-2 pb-3">
                  <div
                    className=" w-100 d-flex pb-3 gap-4"
                    style={{ overflow: "hidden" }}
                  >
                    {Array.from(new Array(3)).map((_, index) => (
                      <div
                        key={index}
                        className="card-body w-100 px-4 rounded rounded-3 p-4" // Adjusted class name to specify width in medium and larger screens
                        style={{
                          backgroundColor: "rgb(0, 0, 0, 0.07)",
                          minWidth: "390px",
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <Skeleton
                            variant="circular"
                            width={60}
                            height={60}
                            className="rounded-circle"
                            sx={{ aspectRatio: "1/1", minWidth: "60px" }}
                          />

                          <div className="ps-2 text-start w-100 d-flex flex-column gap-2">
                            <Skeleton
                              variant="rounded"
                              width="100%"
                              height="20px"
                            />
                            <Skeleton
                              variant="rounded"
                              width="100%"
                              height="20px"
                            />
                          </div>
                        </div>

                        <div className="d-flex mt-3 w-100 justify-content-between gap-2">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height="25px"
                          />
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height="25px"
                          />
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height="25px"
                          />
                        </div>

                        <div className="d-flex mb-2 mt-3 w-100 justify-content-between gap-2">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height="40px"
                          />
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height="40px"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="mt-3"
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              boxShadow: " 2px 2px 9px 14px rgba(0,0,0,0.0)",
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div
                style={{
                  marginTop: "0.75rem",
                  "@media (min-width: 768px)": { marginTop: "0rem" },
                }}
              >
                <h5
                  className="px-md-3 ms-3 pt-3"
                  style={{ fontWeight: "bold" }}
                >
                  Appointments
                  {/* <img alt=" " src="/assets/img/dashboard/files.png" className="pb-1 mx-2" /> */}
                </h5>

                <div className="d-flex ms-4">
                  <span
                    className="badge rounded-pill bg-light   px-3 py-2"
                    style={{
                      color:
                        appointmentFilter === "Upcoming" ? "#73589B" : "gray",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      if (appointmentFilter !== "Upcoming")
                        setAppointmentFilter("Upcoming");
                    }}
                  >
                    Upcoming
                  </span>

                  <span
                    className="badge rounded-pill bg-light px-3 py-2 ms-2"
                    style={{
                      color: appointmentFilter === "Past" ? "#73589B" : "gray",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      if (appointmentFilter !== "Past")
                        setAppointmentFilter("Past");
                    }}
                  >
                    Past
                  </span>
                </div>
              </div>

              <div
                className="px-3 d-flex align-items-center "
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/dashboard/my-appointments")}
              >
                <h6 className="text-muted m-0 p-0 me-2">View All</h6>
                <BsArrowRightCircle size={25} className="arrow-hidden" />
              </div>
            </div>

            <div className="row main-card p-md-5">
              <div className="col-md-12">
                <div className=" pt-lg-2 pt-3 pb-lg-2 pb-3">
                  <div className="scroll-container d-flex pb-3 mx-3  ">
                    {otherAppointments?.length > 0 ||
                      cancelledAppointments?.length > 0 ? (
                      <>
                        {otherAppointments?.map((item) => (
                          <div
                            className="scroll-card w-auto border"
                            key={item.ID}
                            style={{ marginRight: "10px" }}
                          >
                            <div
                              className="card-body px-4"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate(
                                  `/dashboard/my-appointments/${item.ID}`
                                );
                              }}
                            >
                              <div className="d-flex">
                                <img
                                  src={item.ProfileImage}
                                  alt="ProfileImage"
                                  style={{
                                    width: " 80px",
                                    height: " 80px",
                                    backgroundColor: "rgba(0,0,0,0.06)",
                                  }}
                                  className="img-fluid rounded-circle mt-3"
                                />

                                <div className="ps-2 text-start">
                                  <div className="d-flex align-items-center mt-3 pt-2">
                                    <h5 className="fw-bold mb-0">
                                      {item.ExpertName}
                                    </h5>
                                    <img
                                      src="/assets/img/dashboard/star1-removebg-preview.png"
                                      alt="Verified"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        objectFit: "contain",
                                      }}
                                      className="img-fluid"
                                    />
                                  </div>

                                  <p
                                    className="text-secondary"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {item.ExpertType}
                                  </p>

                                  {item.Status === "Cancelled" && (
                                    <p
                                      className="rounded-4 w-max px-2 mt-1"
                                      style={{
                                        fontSize: "13px",
                                        backgroundColor: "red",
                                        fontWeight: "500",
                                        color: "#FFF",
                                        width: "max-content",
                                      }}
                                    >
                                      {item.Status}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div
                                className="d-flex mb-2 mt-3"
                                style={{ justifyContent: "space-between" }}
                              >
                                <div className="px-3 w-auto ">
                                  <div className="">
                                    <small className="text-muted">Date</small>
                                    <h6>{item.AppDate}</h6>
                                  </div>
                                </div>

                                <div className="px-3 w-auto border border border-top-0 border-bottom-0">
                                  <div className="">
                                    <small className="text-muted">Time</small>
                                    <h6>{item.AppTime}</h6>
                                  </div>
                                </div>

                                <div className="px-3 w-auto">
                                  <div className="">
                                    <small className="text-muted">
                                      Duration
                                    </small>
                                    <h6>{item.duration}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-around m-2 mb-3">
                              {item.Status !== "Cancelled" ? (
                                <div className="w-100 px-2">
                                  {item.joinButton ? (
                                    <a
                                      href={item.joinUrl}
                                      target="_blank"
                                      type="button"
                                      rel="noreferrer"
                                      className="btn text-light w-100"
                                      style={{
                                        background: item.joinButton
                                          ? "#73589B"
                                          : "#C5B2FC",
                                        fontWeight: 600,
                                      }}
                                      disabled={!item.joinButton}
                                    >
                                      {/***/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-camera-video-fill mx-2 mb-1"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
                                        />
                                      </svg>
                                      Join Now
                                    </a>
                                  ) : (
                                    <button
                                      className="btn text-light w-100"
                                      style={{
                                        background: item.joinButton
                                          ? "#73589B"
                                          : "#C5B2FC",
                                        fontWeight: 600,
                                      }}
                                      disabled
                                    >
                                      {/***/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-camera-video-fill mx-2 mb-1"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
                                        />
                                      </svg>
                                      Join Now
                                    </button>
                                  )}
                                </div>
                              ) : null}

                              <div className="w-100 px-2">
                                <button
                                  type="button"
                                  className="btn w-100"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderColor: "#73589B",
                                    color: "#73589B",
                                    fontWeight: 600,
                                  }}
                                  onClick={() => {
                                    navigate(`/dashboard/chat/${item.ID}`);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-chat-text mx-2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                                    <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z" />
                                  </svg>
                                  Chat
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                        {cancelledAppointments?.map((item) => (
                          <div
                            className="scroll-card w-auto border border"
                            key={item.ID}
                            style={{ marginRight: "10px" }}
                          >
                            <div
                              className="card-body px-4"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate(
                                  `/dashboard/my-appointments/${item.ID}`
                                );
                              }}
                            >
                              <div className="d-flex">
                                <img
                                  src={item.ProfileImage}
                                  alt="ProfileImage"
                                  style={{ width: " 80px", height: " 80px" }}
                                  className="img-fluid rounded-circle mt-3"
                                />

                                <div className="ps-2 text-start">
                                  <div className="d-flex align-items-center mt-3 pt-2">
                                    <h5 className="fw-bold mb-0">
                                      {item.ExpertName}
                                    </h5>
                                    <img
                                      src="/assets/img/dashboard/star1-removebg-preview.png"
                                      alt="Verified"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        objectFit: "contain",
                                      }}
                                      className="img-fluid"
                                    />
                                  </div>

                                  <p
                                    className="text-secondary"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {item.ExpertType}
                                  </p>

                                  {item.Status === "Cancelled" && (
                                    <p
                                      className="rounded-4 w-max px-2 mt-1"
                                      style={{
                                        fontSize: "13px",
                                        backgroundColor: "red",
                                        fontWeight: "500",
                                        color: "#FFF",
                                        width: "max-content",
                                      }}
                                    >
                                      {item.Status}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div
                                className="d-flex mb-2 mt-3"
                                style={{ justifyContent: "space-between" }}
                              >
                                <div className="px-3 w-auto ">
                                  <div className="">
                                    <small className="text-muted">Date</small>
                                    <h6>{item.AppDate}</h6>
                                  </div>
                                </div>

                                <div className="px-3 w-auto border border border-top-0 border-bottom-0">
                                  <div className="">
                                    <small className="text-muted">Time</small>
                                    <h6>{item.AppTime}</h6>
                                  </div>
                                </div>

                                <div className="px-3 w-auto">
                                  <div className="">
                                    <small className="text-muted">
                                      Duration
                                    </small>
                                    <h6>{item.duration}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-around m-2 mb-3">
                              {item.Status !== "Cancelled" ? (
                                <div className="w-100 px-2">
                                  {item.joinButton ? (
                                    <a
                                      href={item.joinUrl}
                                      target="_blank"
                                      type="button"
                                      rel="noreferrer"
                                      className="btn text-light w-100"
                                      style={{
                                        background: item.joinButton
                                          ? "#73589B"
                                          : "#C5B2FC",
                                        fontWeight: 600,
                                      }}
                                      disabled={!item.joinButton}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-camera-video-fill mx-2 mb-1"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
                                        />
                                      </svg>
                                      Join Now
                                    </a>
                                  ) : (
                                    <button
                                      className="btn text-light w-100"
                                      style={{
                                        background: item.joinButton
                                          ? "#73589B"
                                          : "#C5B2FC",
                                        fontWeight: 600,
                                      }}
                                      disabled
                                    >
                                      {/***/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-camera-video-fill mx-2 mb-1"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
                                        />
                                      </svg>
                                      Join Now
                                    </button>
                                  )}
                                </div>
                              ) : null}

                              <div className="w-100 px-2">
                                <button
                                  type="button"
                                  className="btn w-100"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderColor: "#73589B",
                                    color: "#73589B",
                                    fontWeight: 600,
                                  }}
                                  onClick={() => {
                                    navigate(`/dashboard/chat/${item.ID}`);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-chat-text mx-2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                                    <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z" />
                                  </svg>
                                  Chat
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div
                        className="d-flex flex-col gap-4 mx-auto py-4 px-3"
                        style={{
                          flexDirection: "column",
                          border: "1px solid lightblue",
                          borderRadius: "15px",
                        }}
                      >
                        <b style={{ width: "100%", textAlign: "center" }}>
                          No {appointmentFilter} Appointments Found!
                        </b>
                        <button
                          className="px-2 py-2 text-white"
                          style={{
                            backgroundColor: "#73589B",
                            border: "none",
                            borderRadius: "12px",
                          }}
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Book an Appointment
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Appointments;
