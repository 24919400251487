import React from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";

const NotificationRowCard = ({
  image,
  title,
  clientID,
  appointmentID,
  type,
  description,
  day,
  time,
  clientType,
  session,
  joinNow,
}) => {

  const navigate = useNavigate();

  const onProfile = () => {
    navigate(`/expert/clients?id=${clientID}`);
  };

  return (
    <div className="NotificationRowCard">
      {image == false && type !== "review" ? (
        <div className="newAppointmentIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>
        </div>
      ) : type == "review" ? (
        <div className="newAppointmentIcon">
          <TodayIcon />
        </div>
      ) : (
        <img
          alt=""
          src={image}
          width={40}
          height={40}
          style={{ borderRadius: "50%", objectFit: "cover" }}
        />
      )}

      <div className="NotificationRowCard--Content">
        <p className="header">{title}</p>

        {description !== "" ? <p className="review">{description}.</p> : null}

        {type == "appointment" && (
          <>
            <div className="newAppointment--details">
              <p>
                {day} {time}
              </p>
              |<p>{clientType}</p>|<p>{session}</p>
            </div>

            <div className="Content--Actions">
              <button className="ProfileCTA" onClick={onProfile}>
                Client Profile
              </button>
              {joinNow && (
                <Link to="/expert/appointments">
                  <button className="JoinCTA">Join Now</button>
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationRowCard;
