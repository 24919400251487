import React, { Component, useState } from 'react';
import AppRoutes from "./routing/AppRoutes";
import { for_location, getCurrency } from "./components/core/_request";
import Cookies from 'universal-cookie';
import { AppContext } from './context';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default class App extends Component {

  componentDidMount = () => {
    const cookies = new Cookies();
    var countryCode = cookies.get('countryCode');

    if (countryCode == null) {
      for_location().then((resp) => {
        if (resp && resp.countryCode) {
          cookies.set('countryCode', resp.countryCode, { path: '/' });
          this.setState({ countryCode: resp.countryCode })
        }
        else {
          cookies.set('countryCode', "IN", { path: '/' });  //If error
          this.setState({ countryCode: "IN" })
        }
        window.location.reload();
      })
        .catch(() => {
          cookies.set('countryCode', "IN", { path: '/' });
          this.setState({ countryCode: "IN" });
          window.location.reload();
        })
    }
  };

  render() {
    return <AppView />;
  }
}


const AppView = (props) => {
  const [ShowLloginPopup, setShowLloginPopup] = useState(0);
  const [expertDashboardData, setExpertDashboardData] = useState({});
  return (
    <SnackbarProvider maxSnack={3}
      action={(key) => (
        <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      <AppContext.Provider
        value={{
          ShowLloginPopup,
          setShowLloginPopup,
          expertDashboardData,
          setExpertDashboardData,
        }}
      >
        <AppRoutes />
      </AppContext.Provider>
    </SnackbarProvider>
  );
};
