import React, { Component } from 'react';
import ExpertBookingPreview from '../components/ExpertBookingPreview';
import { Helmet } from 'react-helmet';

export default class ExpertBookingPreviewPage extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Complete Your Payment for Wellness Services | United We Care
          </title>
        </Helmet>
        <ExpertBookingPreview />
      </>
    );
  }
}
