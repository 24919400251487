import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowRightCircle } from "react-icons/bs";
import { BsArrowLeftCircle } from "react-icons/bs";
import "../../Styles/style.css";
function Explore_according() {
  const [card, setCard] = useState([
    {
      image: "/assets/img/dashboard/abuse.png",
      link: "https://www.unitedwecare.com/category/abuse/",
    },
    {
      image: "/assets/img/dashboard/chronic c.png",
      link: "https://www.unitedwecare.com/category/chronic-condition/",
    },
    {
      image: "/assets/img/dashboard/chronic syn.png",
      link: "https://www.unitedwecare.com/category/chronic-syndrome/",
    },
    {
      image: "/assets/img/dashboard/diet_&_nutrition.png",
      link: "https://www.unitedwecare.com/category/diet-nutrition/",
    },

    {
      image: "/assets/img/dashboard/emotional_wellness.png",
      link: "https://www.unitedwecare.com/category/emotional-wellness/",
    },
    {
      image: "/assets/img/dashboard/fitness.png",
      link: "https://www.unitedwecare.com/category/fitness/",
    },
    {
      image: "/assets/img/dashboard/focus.png",
      link: "https://www.unitedwecare.com/category/focus/",
    },
    {
      image: "/assets/img/dashboard/lgbt.png",
      link: "https://www.unitedwecare.com/category/lgbtqia/",
    },
    {
      image: "/assets/img/dashboard/meditation.png",
      link: "https://www.unitedwecare.com/category/meditation/",
    },
    {
      image: "/assets/img/dashboard/mindfulnesss.png",
      link: "https://www.unitedwecare.com/category/mindfulness/",
    },
    {
      image: "/assets/img/dashboard/move.png",
      link: "https://www.unitedwecare.com/category/move/",
    },
    {
      image: "/assets/img/dashboard/parenting.png",
      link: "https://www.unitedwecare.com/category/parenting/",
    },
    {
      image: "/assets/img/dashboard/phobia.png",
      link: "https://www.unitedwecare.com/category/phobia/",
    },
    {
      image: "/assets/img/dashboard/psychol d.png",
      link: "https://www.unitedwecare.com/category/psychological-disorder/",
    },
    {
      image: "/assets/img/dashboard/self help.png",
      link: "https://www.unitedwecare.com/category/self-help/",
    },
    {
      image: "/assets/img/dashboard/sexual well.png",
      link: "https://www.unitedwecare.com/category/sexual-wellness/",
    },
    {
      image: "/assets/img/dashboard/sleep.png",
      link: "https://www.unitedwecare.com/category/sleep/",
    },
    {
      image: "/assets/img/dashboard/stress.png",
      link: "https://www.unitedwecare.com/category/stress/ ",
    },
    {
      image: "/assets/img/dashboard/wellness.png",
      link: "https://www.unitedwecare.com/category/wellness/",
    },
    {
      image: "/assets/img/dashboard/work related.png",
      link: "https://www.unitedwecare.com/category/work-related/",
    },
    {
      image: "/assets/img/dashboard/yoga medita.jpg",
      link: "https://www.unitedwecare.com/category/yoga-meditation/",
    },
  ]);

  const handleNavigation = (link) => {
    window.location.href = link;
  };

  const clicked = (a) => {
    alert(a, "clicked...");
  };

  return (
    <div className="mt-2">
      <div
        className=""
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          boxShadow: " 2px 2px 9px 14px rgba(0,0,0,0.06);",
        }}
      >
        <div className="d-flex justify-content-between pe-2">
          <div className="mt-2">
            <h5 className="text-start px-3 ms-3 pt-3">
              Explore according to your goal
            </h5>
            <p className="m-0 p-0 text-start px-3 ms-3 ">
              Start with programs as per your goals
            </p>
          </div>
          <div>
            <div className="d-none d-md-block pt-3">
              <div
                className="px-3 d-flex align-items-center pt-2 "
                style={{ cursor: "pointer" }}
                onClick={() => clicked("data")}
              >
                <h6 className="text-muted m-0 p-0 me-2 d-block d-sm-none">
                  View All
                </h6>

                <div className="d-flex">
                  <BsArrowLeftCircle size={25} className="me-1" />
                  <BsArrowRightCircle size={25} className="ms-1" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row main-card p-md-4">
          <div className="col-md-12">
            <div className="">
              <div className="scroll-container d-flex">
                {card.map((a) => (
                  <div className="">
                    <div className="">
                      <div
                        onClick={() => handleNavigation(a.link)}
                        className="card rounded-4 mx-2"
                        style={{
                          width: "170px",
                          height: "150px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={a.image}
                          alt="card-img"
                          className=""
                          style={{ width: "170px", height: "150px" }}
                        />
                        <div className="card-img-overlay ">
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Explore_according;
