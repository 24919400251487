import { TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import {
  getExpertbyId_WebService,
  subscription_subscribe_odoo,
  UseCouponCodeService,
  SubscriptionPacks,
  updatePhoneNumber,
  AddAppointmentUserService,
  AddUserPackageService,
  UserDetailsService,
  AddTransactionService,
  UpdateTransactionService,
} from "./core/request";
import { useAuth } from "../../auth";
import { useNavigate, useLocation } from "react-router-dom";
import { getCountryCode, getCurrencyCode } from "../core/commonFun";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";

function loadRazorpayScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const SubscriptionOrderSummary = () => {
  const [loading, setLoading] = useState(false);
  const [packsData, setPacksData] = useState({});
  const [optionData, setOptionData] = useState();
  const [couponCode, setCouponCode] = useState();
  const [couponloading, setCouponloading] = useState(false);
  const [expertDetailsData, setExpertDetailsData] = useState();
  const [mainAmountAfterCoupone, setMainAmountAfterCoupone] = useState();
  const [number, setNumber] = useState();
  const [couponCodeMessage, setCouponCodeMessage] = useState();
  const [data, setData] = useState();
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const countryCode = getCountryCode();
  const search = useLocation().search;
  const { enqueueSnackbar } = useSnackbar();

  const [dataLayerJSON, setDataLayerJSON] = useState(null);

  useEffect(() => {
    let dataOptions = {
      event: "purchase",
      ecommerce: {
        value: +optionData?.price_inr,
        currency: "INR",
        items: [
          {
            item_name:
              packsData.packs !== undefined ? packsData?.packs[0]?.name : "",
            price: +optionData?.price_inr,
            item_brand: "United We Care",
            item_category: "Subscription",
            index: 1,
            duration: optionData?.name,
            mobile: "mobile number here",
            quantity: 1,
            paymentSuccess: null,
          },
        ],
      },
    };

    setDataLayerJSON(dataOptions);
  }, [optionData]);

  const subscriptionID = JSON.parse(sessionStorage.getItem("subscriptionID"));
  var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
  var body = { id: subscriptionID };
  useEffect(() => {
    SubscriptionPacks(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        var price = resp?.data?.packs?.find(
          (p) => p?.id === parseInt(subscriptionID)
        )?.available[0];
        setOptionData(price);
        setPacksData(resp?.data);
      }
    });
    var sdata = JSON.parse(localStorage.getItem("bookAnAppointmentData"));
    if (sdata) {
      getExpertbyId_WebService(sdata?.selectSlot?.Expert).then(async (resp) => {
        setData(sdata);
        setExpertDetailsData(resp.data);
      });
    }
  }, []);

  const applyCoupon = () => {
    if (!couponCode) {
      setCouponCodeMessage("Please fill coupon code.");
      return;
    }
    setCouponloading(true);
    var body = {
      CouponCode: couponCode,
      Type: expertDetailsData.RegisterYourselfAs,
      Amount: optionData?.price_inr,
    };
    UseCouponCodeService(body).then((resp) => {
      if (resp.data == "") {
      } else {
        setMainAmountAfterCoupone(resp.data);
      }
      setCouponCodeMessage(resp.ResponseMessage);
      setCouponloading(false);
    });
  };

  const packsNameChange = (event) => {
    var jsonParse = JSON.parse(event.target.value);
    setOptionData(jsonParse);
    setDataLayerJSON((prevState) => ({
      ...prevState,
      duration: jsonParse,
      price: +optionData?.price_inr,
    }));
  };

  console.log("optionData", optionData);
  sessionStorage.setItem("SubscriptionDetails", JSON.stringify(dataLayerJSON));

  const addTrans = (
    response,
    TotalAmount,
    UserDetails,
    Indianpackagewithprice
  ) => {
    let rendId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      "_web";
    var body = {
      ExpertId: data?.selectSlot?.Expert,
      TransactionId: rendId,
      Amount: TotalAmount,
      isMarkAutoRenew: false,
      TransactionType: "Credit Card",
      PaymentGateway_OrderId: "",
      Billing_Address_Master: {
        TransactionId: rendId,
        Name: UserDetails.Name,
        Email: UserDetails.Emailid,
        Mobile: UserDetails.MobileNo,
      },
      DiscountAmount: 0,
      PaymentGateway_OrderId: response?.razorpay_payment_id,
    };

    AddTransactionService(body).then((item) => {
      var tbody = { TransactionId: rendId, Status: "Completed" };
      UpdateTransactionService(tbody).then((trp) => {
        try {
          var subscribe_odoo_body = {
            user_id: user_id,
            plan_id: parseInt(subscriptionID),
            duration_id: optionData?.id,
            payment_method: "card",
            payment_id: response?.razorpay_payment_id,
            order_id: rendId,
            signature: "",
          };
          subscription_subscribe_odoo(subscribe_odoo_body).then((trp) =>
            console.log("subscription_subscribe_odoo resp", trp)
          );
        } catch {}
        setLoading(false);
        setDataLayerJSON((prevData) => ({
          ...prevData,
          paymentSuccess: true,
        }));

        window.dataLayer.push(dataLayerJSON);
        navigate("/payment-success");
      });
    });
  };

  const onCheckout = () => {
    setLoading(true);
    var body = {
      MobileNo: number,
    };
    updatePhoneNumber(body).then((resp) => {
      console.log(resp?.ResponseMessage);
    });
    localStorage.setItem("profile.MobileNo", number);
    let StartJSON = dataLayerJSON;
    StartJSON.event = "begin_checkout";
    window.dataLayer.push(StartJSON);
    console.log("optionData", optionData);
    displayRazorpay(
      countryCode == "IN" ? optionData?.price_inr : optionData?.price_usd
    );
  };

  const displayRazorpay = async (amount) => {
    var UserDetails = await UserDetailsService().then((resp) => resp.data);
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      enqueueSnackbar("Razorpay SDK failed to load. Are you online?", {
        variant: "error",
      });
      return;
    }
    const options = {
      key: "rzp_live_Gft79xyY3ppWNz",
      amount: amount * 100,
      currency: countryCode == "IN" ? "INR" : "USD",
      name: UserDetails.Name,
      handler: async function (response) {
        addTrans(response, amount, UserDetails);
      },
      modal: {
        ondismiss: function () {
          navigate(`/payment-failed`);
        },
      },
      prefill: {
        name: UserDetails.Name,
        email: UserDetails.Emailid,
        contact: number ?? UserDetails.MobileNo,
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <Container>
      <Helmet>
        <title>
          Choose Your Mental Wellness Plan | United We Care Subscription
        </title>
      </Helmet>
      <div className="col-md-9 d-block mx-auto my-5">
        <h6 className="font-inter fs-2">Order Summary</h6>
        <p className="font-inter fs-5 lh-lg">
          You are just one step away from beginning the healing journey.
        </p>
        {packsData?.packs
          ?.filter((p) => p?.id === parseInt(subscriptionID))
          ?.map((item, index) => (
            <div
              className="rounded-3 d-md-flex my-3 py-3"
              style={{ border: "1px solid #E2E2E1" }}
            >
              <div className="py-2 px-3 col-md-6 pb-3">
                <h6 id="plan_name" className="font-inter fs-4 lh-base">
                  {item?.name}
                </h6>
                <p
                  className="font-inter fs-6"
                  dangerouslySetInnerHTML={{ __html: item?.plan_description }}
                ></p>
              </div>
              <div
                class="vr d-none d-md-inline-block"
                style={{ width: "3px" }}
              ></div>
              <hr className="d-block d-md-none" />
              <div className="py-2 px-3 w-100 d-flex flex-column align-items-center pb-5">
                <div className="col-12 lh-lg">
                  <Form.Select
                    id="plan_option"
                    onChange={packsNameChange}
                    size="lg"
                    className="my-3"
                  >
                    {item?.available?.map((option, index) => (
                      <option id={index} value={JSON.stringify(option)}>
                        {option?.name}
                      </option>
                    ))}
                  </Form.Select>
                  {(countryCode === "IN" && (
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="font-inter fs-6 lh-lg">Charges:</p>{" "}
                      <p id="plan_charges" className="font-inter fs-6">
                        INR{" "}
                        {optionData?.price_inr ?? item?.available[0]?.price_inr}
                      </p>
                    </div>
                  )) || (
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="font-inter fs-6 lh-lg">Charges:</p>{" "}
                      <p id="plan_discount" className="font-inter fs-6">
                        USD{" "}
                        {optionData?.price_usd ?? item?.available[0]?.price_usd}
                      </p>
                    </div>
                  )}
                  {(countryCode === "IN" && (
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="font-inter fs-6 lh-lg">Discount:</p>{" "}
                      <p id="plan_discount" className="font-inter fs-6">
                        INR 0
                      </p>
                    </div>
                  )) || (
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="font-inter fs-6 lh-lg">Discount:</p>{" "}
                      <p className="font-inter fs-6">USD 0</p>
                    </div>
                  )}
                  {(countryCode === "IN" && (
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="font-inter fs-6 lh-lg">Final Amount :</h6>{" "}
                      <h6 id="plan_final_amount" className="font-inter fs-6">
                        INR{" "}
                        {mainAmountAfterCoupone ??
                          optionData?.price_inr ??
                          item?.available[0]?.price_inr}
                      </h6>
                    </div>
                  )) || (
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="font-inter fs-6 lh-lg">Final Amount :</h6>{" "}
                      <h6 className="font-inter fs-6">
                        USD{" "}
                        {mainAmountAfterCoupone ??
                          optionData?.price_usd ??
                          item?.available[0]?.price_usd}
                      </h6>
                    </div>
                  )}
                  <div
                    className="d-flex justify-content-between justify-content-md-between rounded-2 my-3"
                    style={{ border: "1px solid #D9D9D9", borderRight: "none" }}
                  >
                    <input
                      className="rounded-2 border-0"
                      id="referal_code"
                      type="text"
                      placeholder="Discount / Referral code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      style={{ height: "45px", paddingLeft: 7 }}
                    />
                    {(couponloading && (
                      <Button className="col-3 py-2">
                        <img
                          src="/assets/img/loading.gif"
                          alt="Kareify"
                          style={{ height: 26, width: 26 }}
                        />
                      </Button>
                    )) || (
                      <Button
                        id="referal_apply_btn"
                        className="font-inter fs-6 text-white border-0 px-md-4 py-2"
                        style={{ background: "var(--primary-color)" }}
                        onClick={() => applyCoupon()}
                      >
                        Apply
                      </Button>
                    )}
                  </div>
                  <p className="mx-md-4 font-inter" style={{ color: "red" }}>
                    {couponCodeMessage}
                  </p>
                  <div className="d-flex align-items-center gap-3 mt-3">
                    <h6
                      className="font-inter fs-5"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Bill To <span>(Optional)</span>
                    </h6>
                    <hr className="w-100" />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <p className="font-inter fs-5">Mobile</p>
                    <TextField
                      id="mobile_input"
                      type="tel"
                      placeholder={currentUser?.MobileNo}
                      value={number}
                      onChange={(e) => {
                        setNumber(e.target.value);
                        setDataLayerJSON((prevData) => ({
                          ...prevData,
                          mobile: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        {(loading && (
          <Button
            disabled
            className="d-block mx-auto border-0 rounded-5 my-5 p-3 text-black"
            style={{ background: "var(--primary-color)", cursor: "no-drop" }}
          >
            Processing.... wait
          </Button>
        )) || (
          <Button
            id="professional_complete_payment"
            className="col-11 col-md-4 px-3 border-0 font-w600 font-inter fs-6 lh-base d-block mx-auto my-5 text-white"
            style={{
              background: "var(--primary-color)",
              borderRadius: "10px",
              height: "48px",
            }}
            onClick={onCheckout}
          >
            Proceed To Payment
          </Button>
        )}
      </div>
    </Container>
  );
};

export default SubscriptionOrderSummary;
