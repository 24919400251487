import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { joinClub } from '../_request';
import { useAuth } from '../../../auth';
import { useSnackbar } from 'notistack';

const ClubCard = ({ clubData }) => {
    const navigate = useNavigate();
    const [wrappedText, setWrappedText] = useState('');
    const { currentUser } = useAuth();
    const userId = currentUser?.UserId;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        function wrapText(text) {
            if (typeof text !== 'string') {
                return ''; 
            }
            const words = text.split(' ');
            if (!words || words.length <= 12) {
                return text; 
            }
            const truncatedText = words.slice(0, 12).join(' '); 
            return truncatedText + '...';
        }

        setWrappedText(wrapText(clubData?.description));
    }, [clubData?.description]);

    const onJoinClub = () => {
        const body = {
            "userId": userId,
            "clubId": clubData?.id
        }
        joinClub(body).then(resp => {
            if (resp?.ResponseCode === 200) {
                enqueueSnackbar("Joined Club.", { variant: "success" });
                window.location.reload();
            } else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        })
    }

    return (
        <div className='clubCardContainer w-100' style={{}}>
            <div className='clubCard position-relative'>
                <img src={clubData.bannerImageUrl} alt='' className='w-100' />
                <div className='p-3 d-flex flex-column gap-3'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <h5 style={{ cursor: "pointer" }} onClick={() => navigate(`/community/club/${clubData?.id}`)}>{clubData?.name}</h5>
                            <span>{clubData?.membersCount} Members</span>
                        </div>
                        {clubData?.following ?
                            <Button variant='contained' onClick={onJoinClub} className='py-3 join-Btn'>Joined</Button>
                            :
                            <Button variant='contained' onClick={onJoinClub} className='py-3 join-Btn'>Join</Button>
                        }
                    </div>
                    <p>
                        {wrappedText}
                    </p>
                </div>
                <span className='categoryName'>
                    {clubData?.category?.name}
                </span>
            </div>
        </div>
    )
}

export default ClubCard