import axios from "axios";
import jwtDecode from "jwt-decode";

const headers = {
  "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
  "Content-Type": "application/json",
};

export const getRandomExperts = async (userID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/user/dashboard/random-experts?user_id=${userID}`,
      {
        method: "GET",
        headers,
      }
    );

    const postResponse = await response.json();
    //console.log("postResponse", postResponse)

    return postResponse.data;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const getAppointments = async (body) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/user/dashboard/appointments`,
      {
        method: "POST",
        headers,
        body,
      }
    );

    const postResponse = await response.json();
    //console.log("postResponse", postResponse)

    return postResponse.data;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const getRecommendedPrograms = async (body) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/user/past/courses`,
      {
        method: "POST",
        headers,
        body,
      }
    );

    const postResponse = await response.json();
    //console.log("postResponse", postResponse)

    return postResponse.data;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const getRecommendedAssessments = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/user/past/assessments`,
      {
        method: "POST",
        headers,
        body: "{}",
      }
    );

    const postResponse = await response.json();
    //console.log("postResponse", postResponse)

    return postResponse.data;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};
