import React from 'react'
import { Helmet } from 'react-helmet'

const TermAndCondition = () => {
    return (
        <div>
            <Helmet>
                <title>
                    Terms and Conditions - United We Care
                </title>
            </Helmet>
            <div className='' style={{ background: "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)", padding: 0, margin: 0 }}>
                <div className='container'>
                    <div className="py-5">
                        <h1 className="text-white font-w600 fs-4">Terms and Condition</h1>
                    </div>
                    <div className='bg-white px-5' style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: "12px" }}>
                        <h2 className='fs-5 pt-5'>TERMS OF USE</h2>
                        <p className='py-3'>This document is an electronic record in terms of the Information Technology Act, 2000 (as may be amended, modified, re-enacted, consolidated or replaced from time to time) and rules thereunder pertaining to electronic records in various statutes as applicable and amended from time to time, and is published in accordance with the provisions of Rule 3 of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 which mandates the publishing of rules and regulations, privacy policy and the terms and conditions for access or usage of the UNITED WE CARE platforms, namely, http://www.unitedwecare.com (“Website”), and UNITED WE CARE mobile application (“Application”) for Android and iOS devices (hereinafter collectively referred to as the “Platforms”) and mobile based and/or web browser based AI chatbot service functioning in the name of ‘Stella’ (“Stella AI Chatbot”). By visiting or accessing the Platforms, you accept the conditions of use. Please read them carefully.

                            For the purpose of these Terms of Use, wherever the context so requires, “You”, “Your” and “User (s)” shall mean any natural or legal person who has agreed to become a service recipient on the Platform by visiting or accessing the Platform(s) and Stella AI Chatbot on the Platform. The terms “UNITED WE CARE”, “We", "Us", "Our" shall have the meaning as United For Her Private Limited, a non-government, private limited company duly incorporated under the Companies Act 2013 and having its registered office at Tower 2-9A, Sector 50, Gurugram, Haryana 122018 India., and its affiliates. (hereinabove & hereinafter referred as “UNITED WE CARE”)

                            By mere use of and/or access to the Platforms, Users understand that they are contracting with UNITED WE CARE. Users also understand that UNITED WE CARE shall remain the sole owner of the Platforms.

                            The Users by using/ accessing the Platforms and by providing any information through the Platforms, impliedly or expressly, hereby accept these Terms of Use and enter into a binding contract with UNITED WE CARE. It is thereby acknowledged that the Users have read, understood these Terms of Use and are bound by them, regardless of how the Users or anyone on the User’s behalf has accessed or used on the Platforms. If the User does not want to be bound by the Terms of Use, they must not retain, access and/or use the Platform in any manner whatsoever. Anything done, caused to be done, whether expressly or impliedly, in contravention of these Terms of Use, may render the User liable for legal action.

                            Downloading Our mobile application from Google Play Store or Apple App Store, the User will also be subject to Google Play Terms of Service or Apple App Store Terms of Service, respectively. If there is any conflict between Google Play Store Terms of Service or Apple App Store Terms of Service and these Terms of Use with respect to a User’s use of the android and/or iOS mobile platform application, then, these Terms of Use shall prevail.

                            DEFINITIONS

                            In these Terms and Conditions, references to:

                            “Platform” means the website(s), apps, tools, platforms and/or other devices of unitedwecare.com and its affiliated companies and business partners on or through which the Service is (made) available.

                            “Services” shall include but not be limited to services made available for Users, for license, for a fee, including any online services and other web-based services identified on the services list. Services also include consulting, counseling, job search/and application, undertaking various psychometric test(s) pertaining to depression, anxiety, stress etc., and other services or advice provided through the Platforms including voice assistance provided through Stella AI Chatbot on the Platform, and any service that the Service Professional provides to UNITED WE CARE on the line reservation system of the Website or Application through which Service Professionals can make their services available for reservation, and through which Users can make reservations for such services, the facilitated payment, customer service and the communication service.

                            “Service Professional(s)” shall mean the professionals listed on Platforms partnered and catered with UNITED WE CARE for providing and/or delivering Services to User (s) from time to time.

                            “Stella AI Chatbot” shall mean an artificial intelligence based chatbot which through comprehension of natural language holds a preliminary conversation with the User according to each User’s relevant specifications. It also facilitates the conducting of various tests, assessments, and screeners for the Users of the Platform.

                            “User(s)” shall mean an individual who are resident in India.

                            “User Account” shall have the meaning ascribed to it under section 3.1 hereinbelow.

                            ELIGIBILITY

                            Legal Capacity and Age of Consent: Apart from the feature/functionality of booking sessions on the Platform, Our Services are accessible by all Users of any age. The feature/functionality of booking sessions is only accessible if You are at least 18 years of age. Accessibility is limited only to those who are not prohibited from entering into a contract of service of this kind by laws applicable in their competent jurisdiction. With this understanding, You specifically agree that at the time of booking sessions on the Platform, you are at least 18 years of age and you are competent under law to enter into a legally binding and enforceable contract.

                            USE OF THE PLATFORM THROUGH STELLA

                            To enable You to provide better Services, We have also included on Our Platform Stella AI Chatbot which will be used to automatically hold a conversation with the User. In this regard, Stella AI Chatbot using artificial intelligence and comprehension of natural language will give answers to phrases (questions) according to the User’s relevant specifications. The conversation with Stella AI Chatbot is deemed to have been commenced from the moment User writes on the window chat or when by opening the window chat the Stella AI Chatbot executes an action, such as bring information through an integration or open an Ad (proactive tool). Please note that User is deemed to have acknowledged and accepted all conditions, warranties, risks, liabilities, disclaimers, etc. with respect to the Stella AI Chatbot at the time of downloading/accessing the Platform.

                            The User can have unlimited interaction with Stella AI Chatbot, however, the interaction will be deemed to be ended in the following cases: (i) inactivity of User for a term of 5 (five) minutes; (ii) rerouting the conversation to another channel such as telephone operator etc.; (iii) active option to end the conversation in the chat window. Further, each time that a User closes the browser/window of the browser wherein an active conversation is ongoing with Stella AI Chatbot, the conversation with Stella AI Chatbot will be deemed to have ended. Each time that a User starts a conversation with Stella AI Chatbot after it has been deemed to have ended for any of the aforementioned reasons, the User shall be deemed to have started a fresh conversation with Stella AI Chatbot. Whenever a User starts a fresh conversation with Stella AI Chatbot, a User will not have access to any previous conversations that the User might have had with Stella AI Chatbot.

                            Your interaction with Stella AI Chatbot is with an artificial intelligence chatbot and not a human. Stella AI Chatbot is restricted in the manner of response and its intended use is for the purpose of providing the firsthand information available pertaining to the concern raised by the User. Stella AI Chatbot is not in any way intended to provide diagnosis, treatment and cure of any issue, disease, disorder and illness. The tests conducted by the Stella AI Chatbot have been clinically approved and tested. Stella AI Chatbot is only a facilitator for the Users to access and undertake the tests, assessments and screeners. Stella AI Chatbot does not guarantee any specific outcome of the tests, assessment and screeners, and is not responsible for any outcome thereof. The results and the analysis of the Stella AI Chatbot are only indicative, and not conclusive in nature. Stella AI Chatbot uses some worksheets and descriptions for conducting the tests, assessment and screeners on the Platform. Stella AI Chatbot shall not be responsible for any claims arising out of the content of these worksheets and descriptions. Stella AI Chatbot cannot and will not offer advise or information on the concerns or issued raised by You that is not recognized by Stella AI Chatbot.

                            YOUR ACCOUNT AND REGISTRATION OBLIGATIONS

                            To enable Your access and use of the Services listed on the Platforms, You are obligated to register on the Platform by providing certain information which shall be verified with a one-time password to Your mobile number. Upon successful registration on the Platforms, the User(s) will be entitled to a virtual space (“User Account”) which shall be utilized solely to browse and book the Services that may be offered by the Service Professionals/Partners to the Users of the Platforms, and is not to be utilized for any other non-permitted purposes. If You use the Platform(s), You shall be responsible for maintaining the confidentiality of Your display name and password and You shall be responsible for all activities that occur under Your display name and password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete, or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with these Terms of Use, We have the right to indefinitely suspend or terminate or block access of Your Account on the Platform and cease Your access to the Platform(s).
                            If You have joined the Platform through a referral program of Your employer, You shall be bound by the applicable terms and conditions of arrangement of the referral program entered into by Us and Your employer. We do not share Your personal data and the results of the tests, assessment and screeners of the Users which have joined through a referral program with Their employers. We only share the anonymized analytical data of the Users with Their employers, which has arrived upon after conducting some tests, assessments and screeners of the Users. We are not responsible for any outcome/consequences of the tests, assessments and screeners which may have a positive or a negative outcome. You hereby agree that any claims with respect to your usage of the Platform (which has been through a referral program) shall lie exclusively with Your employer, and We shall not be liable for any damages and claims arising out of usage of the Platform by You.
                            You agree that You shall not (i) select or use as a username, a name of another person with the intent to impersonate that person; (ii) use as a username, a name that is otherwise offensive, vulgar or obscene. The UNITED WE CARE reserves the right to refuse registration of or cancel a username in its sole discretion. You will be solely responsible for any and all activity that takes place in or through Your account.
                            Your contact number, as made available to Us, is treated as Your primary identification on the Platform. It is Your responsibility to ensure that the contact number provided on the Platform is valid and remains updated at all times. You agree to promptly notify the mobile phone number and/or e-mail address in case of any change therein, by updating the same on the User Account through a onetime password verification.
                            You agree that upon confirmation of a booking or an appointment, the Service Professionals shall be entitled to contact You on the contact number/ email address provided in Your Account, pursuant to your request to avail the Services. You shall not hold UNITED WE CARE liable for non-rendering of requested Services owing to any incorrect or invalid information that is reflected/uploaded on Your Account.
                            You fully acknowledge and understand that You shall be solely liable and responsible for all the activities undertaken under Your Account and any consequences therefrom.

                            REGISTRATION CHARGES

                            UNITED WE CARE does not charge any fee for browsing/ accessing the Platform and is thus free for its User(s), however, UNITED WE CARE reserves the right to change its fee policy, from time to time.

                            In addition to the service fees payable to a Service Professional(s) to enable a booking, Users will also be charged a convenience fee upon booking of an appointment with such Service Professional(s) for the Services booked and obtained through the Platform(s).

                            UNITED WE CARE reserves the right, at its sole discretion, to introduce new Services and/or modify some or all of the existing Services offered on the Platform(s). In such an event, UNITED WE CARE reserves the right to introduce a fee for the new/ existing Service(s) offered or Service(s) modified, as the case may be. Changes to the fee policy shall be posted on the Platform and such changes shall become effective immediately.

                            Unless otherwise stated, all fees shall be quoted and paid in Indian Rupees and all payments shall be subject to deductions/taxes/levies as maybe applicable under law. You shall be solely responsible for compliance of all applicable laws including for making payments to Service Professional (s).

                            ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION

                            The information on the Platforms is not guaranteed to be correct, complete or up to date. The material and information provided on the Platforms are provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on the Platforms are at Your own risk. The Platforms may contain certain historical information. Historical information, necessarily, is not current and is provided for Your reference only. We reserve the right (not being an obligation) to modify the contents of the Platforms at any time and it shall be Your responsibility to monitor changes to the Platform.

                            COMMUNICATIONS

                            When You use the Platform or send emails or other data, information or communication to Us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically or as and when required. We may communicate with You by email or by such other mode of communication, electronic or otherwise.

                            You also acknowledge and agree that the telephonic conversations through the Platforms for the purposes of booking an appointment with any Service Professional, may be recorded solely for quality assurance and training purposes.

                            RENDERING OF SERVICES

                            We partner with third party Service Professionals in order to deliver the Services. Service details of Service Professionals such as approximate time of rendering the Service(s) will be communicated or extended vide text message, notification on the User Account, etc, upon receipt of confirmation from the User Account.
                            You acknowledge and agree that UNITED WE CARE solely acts as enablers or facilitators between the User(s) and the Service Professional(s). The opinions, statements, answers (collectively “consultation”) provided by the Service Professionals via the Platforms are solely individual and independent opinions and statements of such individuals/Service Professionals alone, and in no way reflect the opinions of UNITED WE CARE, its affiliates or any other organizations or institutions of UNITED WE CARE to which such Service Professional is affiliated and/or provides services. We do not recommend or endorse any specific tests, service professionals, products, procedures, opinions, or other information that may be mentioned on the Platforms or by a licensee of UNITED WE CARE.
                            As part of the Services, We also provide psychometric tests pertaining to various mental status including (but not limited to) depression, anxiety, stress, marital advice, etc. that can be used as part of the assessment process as listed in detail on the Platform. These tests have been designed to assess the intelligence, logic, reasoning ability, numerical ability, verbal ability, etc. of the Users. These tests can be used by the User as per their needs and requirements. However, We do not take any responsibility for the results and outcomes generated through these psychometric tests and therefore, UNITED WE CARE does not undertake any sort of liability for the outcomes generated through these psychometric tests.
                            Prior to availing the requisite service(s) listed on the Platforms, the User will be directed to provide relevant information including but not limited to name, mobile number, e-mail address and details of the issue being faced. User assures and affirms that the aforesaid information provided is correct, complete and accurate. Any failure in receiving the necessary service(s) arising out of the User’s failure to provide correct, complete and accurate information shall not be attributable to UNITED WE CARE and consequently, UNITED WE CARE shall not be held liable at any point in time.
                            We at our sole discretion, reserve the right to cancel any and/or all kinds of Service (s). In the event of such cancellation, We shall refund the amount (after any applicable deductions, if any) paid by the User for the Service(s) cancelled or not availed due to such cancellation.
                            While We will make reasonable efforts in ensuring the delivery of Service(s) to the User in a timely manner, the User understands that delivering the same may sometimes be delayed on account of:
                            Reasons and issues beyond Our control;
                            Unsuitable/intermittent connectivity conditions;
                            Disruptions in data or telecom networks by default of third party, etc.;
                            Acts of God such as floods, earthquakes, etc.; and
                            Any other unforeseen circumstances beyond Our control.
                            In such events of delay as laid out in clause 8.6 above, We shall make all reasonable efforts to intimate the User in writing, on her mobile number and/or registered email. We disclaim all liabilities that may arise on account of failure to intimate User of anticipated delays in delivering the Service (s) availed through the Platform. Further, We shall be under no obligation to compensate User for any mental agony or any tortuous claim that may otherwise arise on account of a delay in providing the Service (s).

                            USE OF THIS PLATFORM

                            This Platform is for User’s personal use. User shall not copy, replicate, modify, distribute, display, perform, create derivative works from, transfer or sell any information (including the content hosted on the Platform such as articles, audio clips, videos, etc.), software, products or services obtained from this Platform whether printed, visual, electronic, e-mail forwarding, digital transmission, scanned, website re-cycled or any other form of material except where expressly noted that the material is available for such purpose. As a condition of User’s use of this Platform, User hereby warrant that User will not use this Platform for any purpose that is unlawful or prohibited by these Terms and Conditions of Use or otherwise.

                            User further agree and acknowledge that any unauthorized or unlawful use of this Platform would result in irreparable injury to the UNITED WE CARE for which monetary damages may be inadequate, and in such event UNITED WE CARE, shall have the right, in addition to other remedies available at law and in equity, to obtain immediate injunctive relief against User. Nothing contained in these Terms and Conditions of Use shall be construed to limit remedies available pursuant to statutory or other claims that UNITED WE CARE may have under separate legal authority.

                            GENERAL CONDITIONS

                            UNITED WE CARE reserves the right to refuse Service to anyone for any reason at any time.

                            Users (s) understand that the content (not including credit card information of any User, may be transferred unencrypted and involve (a) transmissions over various networks; (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.

                            UNITED WE CARE reserves the right to add, amend or discontinue, temporarily or permanently, any of its Services (or any part thereof) in future offered through the Platform or the design and functionality of the Platform, with or without notice and/or reason(s). User agrees that UNITED WE CARE shall not be liable either to User or to any third party for any modification, suspension or discontinuance of any of the Services including without limitation any failure of performance, error, omission, interruption, deletion, loss of information, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of records, whether for breach of contract, tortuous actions, negligence, or under any other cause or action.

                            MODE OF PAYMENT

                            You acknowledge that payment made by net banking / debit card / credit card / mobile wallets (if any) would be in accordance with the prevailing guidelines issued by the Reserve Bank of India or the Central Government as the case may be, including guidelines relating to know-your-customer norms / anti-money laundering and combatting of financing of terrorism. Further, We will not be liable for any charges occurring due to dishonour of payment instructions. It is however, clarified that any amount paid by You towards booking of an appointment with a Service Professional and such appointment being cancelled or the Service Professional failing to attend such appointment, shall be refunded to You.
                            UNITED WE CARE does not implicitly or explicitly support or endorse the sale or purchase of any Services on the Platform. UNITED WE CARE accepts no liability for any errors or omissions, whether on behalf of itself or third parties.
                            UNITED WE CARE is not responsible for any non-performance or breach of any contract entered into between User (s) and Service Professional (s). Your correspondence or dealings with Service Professional(s) found on or through the Platform, including payment and delivery of services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such Service Professional(s). UNITED WE CARE cannot and does not guarantee that the concerned User (s) and/or Service Professional (s) will perform any transaction concluded on the Platform. UNITED WE CARE shall not and is not required to mediate or resolve any dispute or disagreement relating to the Services between User (s) or and Service Professional (s).
                            UNITED WE CARE does not at any point of time during any transaction between User (s) and Service Professional (s) on the Platform (s) come into or take possession of any of the Services offered by Service Professional (s)  nor does it at any point gain title to or have any rights or claims over the Services offered by Service Professional (s) and User (s).
                            At no time shall UNITED WE CARE hold any right, title or interest or any obligations or liabilities in respect of such contract entered into between User (s) and Service Professional (s). UNITED WE CARE is not responsible for unsatisfactory or delayed performance of Services.
                            UNITED WE CARE solely provides a platform that can be utilized by User (s) to reach a larger base to avail Services and undertake communication. It is agreed that the contract for sale of any of the Services shall be a strictly bipartite contract between the User (s) and Service Professional (s).
                            You shall independently agree upon the manner and terms and conditions of availing the Service, and the payment towards the Service with the Service Professional (s) You transact with.

                            PROHIBITED ACTIONS FOR USERS

                            We outrightly prohibit You from the following actions:

                            use the Platforms for any purpose other than as expressly permitted by Us.

                            not to hold Us responsible for any links to other websites or application which are beyond Our control.

                            not to hold Us responsible for the third-party advertisements that may be placed/made use of on the Platform. You acknowledge that such advertisements are placed by third parties and We are not directly or indirectly responsible for such advertisement or for any links contained therein.

                            not to unauthorize Us from using the user generated content on the Platform for any of the business purposes, even following the termination of Your registration.

                            post on the Platform any content, whether or not containing links, for any of Our competitor.

                            the Platform or any portion of this Platform may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without the express written consent of UNITED WE CARE. You may not systematically extract and/or re-utilise parts of the contents of the Platform without the express written consent of UNITED WE CARE. In particular, You may not utilize any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilization of any substantial parts of the Platform without the express written consent of UNITED WE CARE.

                            collect, post or submit on this Platform any incomplete, fictitious, false or inaccurate information.

                            aggregate, copy or duplicate in any manner any content or information available from this Platforms.

                            upload, share or otherwise distribute any text, graphics, images, videos or data which victimize, harass, degrade, or intimidate an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age or disability.

                            INTELLECTUAL PROPERTY RIGHTS (IPR) RESTRICTION

                            This site is controlled and operated by UNITED WE CARE and Services rendered by respective Service Professionals. All material on this site and/or the Platforms, including (but not limited to) assistance/service provided by Stella AI Chatbot, articles, posts, blogs, images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks, trade secrets, and other intellectual property rights thereon, all of which vest exclusively with UNITED WE CARE. Material on Platform is solely for Your personal, non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and You must not assist any other person to do so. Without the prior written consent of the owner, modification of the materials, use of the materials on any other Platform or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and other intellectual property rights, and is strictly prohibited. Any use for which You receive any remuneration, whether monetarily or otherwise, is construed as a commercial use for the purposes of this clause and UNITED WE CARE shall hold you accountable and liable for infringement of trademark, copyright, trade secrets and other intellectual property rights under the relevant prevailing laws in India.

                            The User confirms and undertakes not to display or use the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights of any third party on the Platform. The User further agrees to indemnify and hold Us, Our directors, employees, affiliates and assigns harmless against all costs, damages, loss and harm including towards litigation costs and counsel fees, in respect of any third party claims that may be initiated including for infringement of Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the Platform, by User or through the User’s commissions or omissions.

                            REFUND POLICY

                            We may, in certain exceptional circumstances and at our sole and absolute discretion, refund the amount to the User after deducting applicable charges and taxes, if any. Such exceptional circumstances are provided hereinbelow:

                            In the event of any cancellation of appointments/meetings by a Service Professional or rescheduling of meetings by a Service Professional to a time not suitable for the User, then any amount paid for the booking of such appointment/meeting shall be refunded to the User. It is clarified that UNITED WE CARE shall not be liable to refund any amounts in the event of any unsatisfactory services from a Service Professional.
                            In the event of any excess payment towards the Services has been made by the User due to any technical glitch on the Platform or payment gateway.

                            Subject to clause 14.1 above, all monies payable to UNITED WE CARE by the User(s) on consummated transactions, i.e., exchange of Services for consideration, are final and non-refundable. UNITED WE CARE will not be liable to process the refund of any paid amount in the event of any unsatisfactory services obtained from Service Professional(s).

                            In the event that the User fails to attend any appointment/meeting with the Service Professional at the time specified for the appointment/meeting, the User will not be entitled to any refund in respect of the session booked by the User. Subject to clause 14.1 above, UNITED WE CARE will not be liable to refund any amount to the User for its failure to attend the appointment/meeting with the Service Professional at the time specified for the appointment/meeting.

                            RELEASE AND LIMITATIONS OF LIABILITY

                            Users shall access the Platform voluntarily and at their own risk. We shall under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, loss of property) by Users or any other person or entity during the course of access to and/or use of the Platform, including claims based on malpractice.
                            You agree that any claim arising out of Your relationship with Service Professional shall be brought solely against such Service Professional, and neither UNITED WE CARE nor any of its licensors or affiliates shall be included within any such claim.
                            By accessing the Platform, Users hereby release from and agree to indemnify Us, and/or any of Our directors, employees, partners, associates and licensors, against all liability, cost, loss or expense arising out of their access to the Platform including but not limited to personal injury and damage to property, whether direct, indirect, consequential, or foreseeable, caused due to any negligent act or omission on their part, or otherwise.
                            Users shall be solely responsible for any consequence which may arise due to their access and use of the Platform, including conducting an illegal/fraudulent act or non-conformity with these Terms of Use and other rules and regulations in relation to the Platform, including provision of incorrect address or other personal details. Users also undertake to indemnify Us and Our respective officers, directors, employees and agents on the happening of such an event (including without limitation, cost of attorney, etc.) on full indemnity basis for any loss/damage suffered by Us on account of such act on the part of the Users.

                            GOVERNING LAW AND DISPUTE RESOLUTION

                            These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws, principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of courts, tribunals, fora, applicable authorities at Gurugram.

                            TAXES

                            You shall be responsible for payment of all fees/costs/charges associated with availing of Services from Us and You agree to bear any and all applicable taxes including but not limited to GST, duties, cess etc.

                            WAIVER

                            Any failure or delay by a party to enforce or exercise any provision of these Terms of Use, or any related right, shall not constitute a waiver by such party of that provision or right. To clarify, if a User breaches any of these conditions and We do not initiate any action against the same, We will still be entitled to use our rights and remedies in any other situation where You breach these conditions. Any waiver by a party shall only be made in writing and executed by a duly authorized officer of such party.

                            MISCELLANEOUS

                            We may be required under applicable law, to notify Users of certain events. User hereby acknowledges and consents that such notices will be effective upon Our posting them on the Platform or delivering them to the User through the email address provided by the User at the time of registration. User (s) may update their email address by logging into their account on the Platform. If they do not provide Us with accurate information, We shall not be held liable for failure to notify the User.
                            We shall not be liable for any delay or failure to perform resulting from causes outside Our reasonable control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond Our control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.
                            Users agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Platform or these Terms must be filed within 15 (fifteen) days of such claim or cause of action arising or be forever barred from making the claim.
                            If any part of these Terms of Use is determined to be indefinite, invalid, or otherwise unenforceable, the rest of these Terms of Use shall continue in full force.
                            We reserve the right to moderate, restrict or ban the use of the Platform, specifically to any User, or generally, in accordance with Our policy/policies amended from time to time, at Our sole and absolute discretion and without any notice.
                            We may, at Our sole and absolute discretion, permanently close or temporarily suspend any services provided on the Platform.

                            DISCLAIMERS

                            Unless otherwise explicitly specified, We are not affiliated in any way to and claim no association, in any capacity whatsoever, with any organization, association, institution, business, trust, authority, governing bodies and leagues, whether local or national or international.

                            WE ARE NO WAY, DIRECTLY OR INDIRECTLY RESPONSIBLE FOR THE ADVICE AND ASSISTANCE PROVIDED BY THE SERVICE PROFESSIONALS. WE ARE AN INDEPENDENT THIRD PARTY ENABLER WHO HAVE BUILT AND PROVIDED YOU WITH A PLATFORM WHERE USERS CAN COMMUNICATE WITH, AND IF NEED BE, MEET THE SERVICE PROFESSIONALS. WE DO NOT HAVE ANY CONTROL OVER THE SERVICES PROVIDED BY THE SERVICE PROFESSIONALS, AND THUS, CANNOT BE HELD RESPONSIBLE INCASE OF ANY ISSUES/DISCREPANCIES WITH THE SERVICES PROVIDED BY THE SERVICE PROFESSIONALS.

                            The Platform has multiple Service Professionals for the same practice area and there might be instances wherein a same/similar query has been answered in different ways by the Service Professional(s). We take no liability towards these different opinions and answers of the Service Professional(s) and encourage You to take a separate independent opinion/ alternately decide which opinion to go ahead with based on merits and logical conclusions.

                            In no event, UNITED WE CARE shall be liable to any User on account of such User’s use or reliance on Stella AI Chatbot, for any damages whatsoever, including direct, special, punitive, indirect, consequential or incidental damages or damages for tort (including negligence), even if UNITED WE CARE is aware of or has been advised of the possibility of such damage, arising out of or connected with the use or performance of the Stella AI Charbot. UNITED WE CARE does not assume any legal liability or responsibility for the accuracy, completeness or usefulness of any information disclosed or provided by Stella AI Chatbot.

                            All such information is provided “as is” without warranty of any kind. UNITED WE CARE hereby disclaim any warranties and conditions with regard to the information provided by Stella AI Chatbot and in no event shall UNITED WE CARE be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the access or use of Stella AI Chatbot on the Platform.

                            The User assumes all responsibility and risk associated, if any, for the use of the Stella AI Chatbot on the Platform and will independently judge whether the information or advice being provided by Stella AI Chatbot is accurate and will work best for the User or not.

                            To the extent permitted under law, We shall not be responsible for any harm resulting from downloading or accessing any information or material vide the Platform, the quality of servers. We disclaim any responsibility for any technical glitches on the servers or the technology that underlies Our Platforms, failures by service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of Our facilities, acts of nature, war, civil disturbance, or any other cause beyond Our reasonable control, which affect the functioning of the Platform(s). In addition, We do not provide any warranty as to the content on the Platform.

                            Any material accessed, downloaded or otherwise obtained through Us and/or the Platform shall be User's sole discretion, competence, acceptance and risk. The User will be solely responsible for any potential damage to User's computer system/mobile or loss of data that results from a User's download of any such material.

                            We shall make best endeavors to ensure that the Platform and experience through it is error-free and secure, however, neither We nor any of Our partners, Service Professional (s) or associates make any warranty that:

                            the Platforms will meet Users' requirements;
                            The Platforms will be uninterrupted, timely, secure, or error free;
                            the listings on the Platform will be accurate or reliable; and
                            the quality of Services, information, or other material that Users avail or obtain through the Platform will meet Users' expectations.

                            To the extent permitted under law, neither We nor our partners, Service Professional (s) or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use Our website/mobile application, even if We have been advised of the possibility of such damages.

                            No representation is made that the quality of the professional services to be performed is greater than the quality of professional services performed by other Service Professionals. We urge all Users to make their own independent investigation and evaluation of any Service Professional being considered.

                            CONTACT AND COMPLAINT

                            In the event the User has any questions, feedback, suggestions, experience, comments and requests (“Feedback”) regarding these Terms of Use such respective User may address the same to the designated grievance officer in respect of the Platform. Any complaints or concerns with regards to any content on the Platform or any breach of the Privacy Policy or Terms of Use or any other policy can be directed to the designated officer in writing and the details are as follows:

                            United For Her Private Limited, Tower 2-9A,

                            Sector 50, Gurugram, Haryana 122018,

                            A complaint or concern may also be raised through an email to contactus.india@unitedwecare.com.

                            The Feedback, compliant or concern shall be deemed to be non-confidential and non-compensatory in nature. UNITED WE CARE reserves the right, at its sole discretion to use such information in any manner as may be deemed appropriate by UNITED WE CARE and such use shall be entirely unrestricted.

                            User further represent and warrant that while posting any Feedback for the Platforms, User shall not use any offensive, libelous, derogatory, hateful or racially or ethnically objectionable language. Further, User shall not post any content on any part on the Platform that is obscene, pornographic and that all such Feedback will be in accordance with the applicable law.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermAndCondition