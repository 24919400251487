import React, { Component } from 'react';
import { Outlet } from 'react-router-dom'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import Footer2 from './Footer2';

export default class Layout extends Component {
  render() {
    return (
      <div>
        <Header2 />
        <div> <Outlet /> </div>
        <Footer2 />
      </div>
    );
  }
}
