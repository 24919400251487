import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper';

const PlanDetailsReviewSlide = () => {
    return (
        <div className='Reviews--Area'>
            <svg style={{ position: 'absolute', left: 210, top: 50 }} width="1614" height="904" viewBox="0 0 1614 904" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1068 102.5C1077.5 112 1101.98 133 1165.5 133C1234 133 1272.39 77.9999 1259.5 49.0001C1245.5 17.4999 1197.39 33.8763 1173 64.6843C1154 88.6842 1177 94.6842 1189 83.6841C1196 75.6842 1203.85 70.0009 1195 30.4998C1186.15 -9.00178 1121.5 -4.99836 1114.5 41.0015C1107.5 87.0015 1117.01 97.399 1125.5 142.5C1149.5 270 986.542 623.007 1300.5 467.716C1486.5 375.716 1580 467.716 1603.5 575.5C1620.6 653.938 1622.5 813.5 1434 732.5C1358.33 724.167 1165.6 654.2 1098 743C1013.5 854 847.36 907.459 621.5 787C404 671 151.333 818.334 3 901.501" stroke="white" stroke-width="5" stroke-linecap="round" />
            </svg>
            <h6>Because users love us</h6>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>

                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>
                                Really user friendly interface and world class content. Really helpful
                                content, I would definitely recommend it to everyone. I experienced no
                                bugs while using this app. Which is a remarkable feat.
                            </p>

                            <div>
                                Anjali Mishra<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/AnjaliMishra.png' alt='user feedback' />
                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            
                        </div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>
                                It was my first session & after this session i am able to shift my mind
                                or thoughts from my past to present, now m trying to think what i have
                                & how i can move forward gradually. Thanks "Life Coach" for a right
                                direction.
                            </p>
                            <div>
                                Shreya Das<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/ShreyaDas.png' />
                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            
                        </div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>
                                A very well made app. Easy interface and fulfills all my requirements.
                                Great therapists and counselors and an amazing initiative.
                            </p>

                            <div>
                                Abhay Singh<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/AbhaySingh.png' />
                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            
                        </div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>
                                What a brilliant app that lets you meet coaches at the convenience of a
                                click. The choice of coaches is good, experienced and class apart.
                                Highly recommended.
                            </p>

                            <div>
                                Tanya Sachdeva<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/TanyaSachdeva.png' />
                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            <img  src='/assets/img/white_dots_grid.svg'  />
                        </div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>
                                Really makes you feel that you can share anything with the counsellor
                                it's a very convenient app to use. During these times I really needed a
                                counsellor to talk to and I got a very good one all thanks to this app.
                            </p>

                            <div>
                                Dev Sharma<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/DevSharma.png' />
                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            <img  src='/assets/img/white_dots_grid.svg'  />
                        </div>

                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default PlanDetailsReviewSlide