import React, { useEffect, useState } from 'react'
import '.././Plans.css'
import { getCountryCode } from '../../core/commonFun'

const HeroArea = ({ bgType }) => {

    const [isCountryIndia, setIsCountryIndia] = useState();

    var countryCode = getCountryCode();
    console.log("countryCode", countryCode)
    useEffect(() => {
        if (countryCode === "IN") {
            setIsCountryIndia(true)
        } else {
            setIsCountryIndia(false)
        }
    }, [])

    return (
        <div>
            {/* <!------ Hero-area (desktop-display)------> */}
            <div className={`hero-area mobile-display-none desktop-display`}>
                <div className="">
                    <div className="d-flex">
                        <div className="col-lg-7 col-md-7 col-xs-7">
                            <div className="hero-text d-flex flex-column justify-content-center my-5">
                                <h1>Find a plan that’s<br /> right for you </h1>
                                <h4>Plans Starting From <span className="diff-clr">
                                    {isCountryIndia ?
                                        "INR 999 /month" :
                                        "$12.99 /month"
                                    }
                                </span></h4>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-xs-6">
                                        <div className="icon-area">
                                            <div className="icon-bg">
                                                <img src="assets/img/Edit Property.png" />
                                            </div>
                                            <h5>Created by you</h5>
                                            <p>Pick and choose services you need</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-xs-6">
                                        <div className="icon-area">
                                            <div className="icon-bg">
                                                <img src="assets/img/Heart.png" />
                                            </div>
                                            <h5>Always with you</h5>
                                            <p>AI health assistance, at your service, 24*7</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-xs-6">
                                        <div className="icon-area">
                                            <div className="icon-bg">
                                                <img src="assets/img/Tune.png" />
                                            </div>
                                            <h5>Flexible for you</h5>
                                            <p>Evolves with your needs and choices</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="Subscribe-btn mt-5 mb-4">
                                    <button type="button" className="btn"><a href="#Subscribe_Now">Subscribe Now</a></button>
                                </div>
                                <p className="btm-text">Trusted By Over 300K+ Subscribers
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-xs-5">
                            <div className="hero-img col-12">
                                <img src='/assets/img/PlanLandingPage/plan-hero-section-banner.png' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!------ Hero-area (mobile-display)------> */}
            <div className="hero-area mobile-display desktop-display-none">
                <div className="container">
                    <div className="hero-text">
                        <h1 className="mobile-display desktop-display-none text-center mb-3">Find a <span className="diff-clr">plan</span> that’s right for you </h1>
                        <h4>Plans Starting From <span className="diff-clr">
                            {isCountryIndia ?
                                "INR 999 /month" :
                                "$12.99 /month"
                            }
                        </span></h4>
                        <div className="Subscribe-btn mt-md-3">
                            <button type="button border-0" className="btn"><a href="#Subscribe_Now">Subscribe Now</a></button>
                        </div>
                        <p className="btm-text">Trusted By Over <span className="diff-clr">3 Lakh</span> Subscribers
                        </p>
                        <div className="row gap-3 hero--section-feature">
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="icon-area">
                                    <div className="icon-bg">
                                        <img src="assets/img/Edit Property.png" alt='' />
                                    </div>
                                    <h5>Created by you</h5>
                                    <p>Pick and choose services you need</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="icon-area">
                                    <div className="icon-bg">
                                        <img src="assets/img/Heart.png" alt='' />
                                    </div>
                                    <h5>Always with you</h5>
                                    <p>AI health assistance, at your service, 24*7</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 pb-3">
                                <div className="icon-area">
                                    <div className="icon-bg">
                                        <img src="assets/img/Tune.png" alt='' />
                                    </div>
                                    <h5>Flexible for you</h5>
                                    <p>Evolves with your needs and choices</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroArea