import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import "../styles/Library.css";
import { useNavigate } from "react-router-dom";
import { listOfBookmarksEntry } from "../_request";
import { useAuth } from "../../../auth";
import SidebarMobile from "./SidebarMobile";
import { Helmet } from "react-helmet";
import { Skeleton } from "@mui/material";

const Library = () => {
  const navigate = useNavigate();
  const [bookmarkData, setBookmarkData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { currentUser } = useAuth();

  useEffect(() => {
    const body = { user_id: currentUser?.UserId };
    listOfBookmarksEntry(body).then((resp) => {
      if (resp.ResponseCode === 200) {
        setBookmarkData(resp.data);
        setIsLoading(false);
      }
    });
  }, []);

  const handleClickOnLevel = (item) => {
    if (item.content_type === "video") {
      navigate(`/program-internal/module/${item?.id}`);
    } else if (item.content_type === "audio") {
      navigate(`/program-internal/module/${item?.id}`);
    } else if (item.content_type === "checksheet") {
      navigate(`/program-internal/module/daily-practice/${item?.id}`);
    } else if (item.content_type === "journal") {
      navigate(`/program-internal/module/my-journal/${item?.id}`);
    } else if (item.content_type === "quiz") {
      navigate(`/program-internal/module/quiz/${item?.id}`);
    } else if (item.content_type === "stella_assessment") {
      navigate(`/program-internal/talk-to-stella?assessmentID=${item?.id}`);
    } else if (item.content_type === "mood_monitor") {
      navigate(`/program-internal/module/mood-monitor?id=${item?.id}`);
    }
  };

  return (
    <section className="library">
      <Helmet>
        <title>Program | Library</title>
      </Helmet>
      {isLoading ? (
        <>
          <section className="d-none d-md-flex">
            <Skeleton variant="rectangular" width={300} height={1080} />
          </section>
          <section className="modules-section">
            <div>
              <div className="modules-container">
              <Skeleton
                  variant="rounded"
                  width="20%"
                  height={40}
                  className="rounded-3"
                />
                

                <div className="modules-sections">

                  <div className="modules-sections-items my-4">
                  {
                    Array.from(new Array(12)).map((_, i) => (
                      <Skeleton variant="rounded" width="100%" height={80} className="rounded-3 my-3" />  
                    ))
                  }
                  </div>
                </div>

                
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="library-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="library-section">
            <div className="library-bg-img">
              <img
                src="/assets/img/course_internal_journey/module_bg_img"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="library-container">
              <div className="library-mob">
                <h2>My Library</h2>
              </div>

              <div className="library-name">My Library</div>
              <hr style={{ color: "#E0E0E0" }} />
              <div className="library-sections-items">
                {bookmarkData?.bookmarked_data?.map((item, i) => (
                  <div
                    className="library-sections-item"
                    onClick={() => {
                      if (item?.type === "course") {
                        navigate(`/program-internal/${item?.id}/${item?.name}`);
                      } else {
                        handleClickOnLevel(item);
                      }
                    }}
                  >
                    <div className="items">
                      {(item?.content_type === "video" && (
                        <VideocamOutlinedIcon />
                      )) ||
                        (item?.content_type === "audio" && (
                          <HeadphonesOutlinedIcon />
                        )) ||
                        (item?.content_type === "mood monitor" && (
                          <SentimentSatisfiedOutlinedIcon />
                        )) ||
                        (item?.content_type === "checkSheet" && (
                          <MenuBookIcon />
                        )) || <DescriptionOutlinedIcon />}

                      <div className="modules-text">{item.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default Library;
