import React from 'react'

const PlanDetailsStats = () => {
    return (
        <div className='Stats--Area d-flex align-items-center gap-8 relative'>
            <img style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} src='/assets/img/planDetails/StatsBG.png' />
            <div className='container d-flex align-items-center gap-4 relative justify-content-center'>
                <div className='Stat--Item col-md-3'>
                    <h6>80%</h6>
                    <div>
                        <p>of people reported improved mental well-being with us</p>
                    </div>
                </div>

                <div className='Stat--Item col-md-3'>
                    <h6>75%</h6>
                    <div>
                        <p>of people reported less stress with us</p>
                    </div>
                </div>

                <div className='Stat--Item col-md-3'>
                    <h6>75%</h6>
                    <div>
                        <p>of people reported improved sleeping patterns</p>
                    </div>
                </div>

                <div className='Stat--Item Rating'>
                    <h6>4.6
                        <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5624 4.11987C31.4029 2.42832 33.8159 2.42832 34.6563 4.11988L41.5203 17.9348C41.8531 18.6046 42.4927 19.0693 43.2326 19.1788L58.4924 21.4378C60.3609 21.7144 61.1065 24.0093 59.7575 25.3314L48.7398 36.1284C48.2056 36.652 47.9613 37.4037 48.0858 38.1413L50.6529 53.3523C50.9673 55.2148 49.0151 56.6332 47.3409 55.7587L33.6676 48.6167C33.0046 48.2704 32.2141 48.2704 31.5511 48.6167L17.8779 55.7587C16.2037 56.6332 14.2515 55.2148 14.5658 53.3523L17.1329 38.1413C17.2574 37.4037 17.0131 36.652 16.4789 36.1284L5.46125 25.3314C4.1122 24.0093 4.85787 21.7144 6.72635 21.4378L21.9862 19.1788C22.7261 19.0693 23.3656 18.6046 23.6984 17.9348L30.5624 4.11987Z" fill="#FABF35" />
                        </svg>
                    </h6>
                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65" fill="none">
                            <path d="M59.6963 33.1602C59.6963 31.1 59.5114 29.119 59.168 27.2173H31.8042V38.4692H47.4407C46.7539 42.0878 44.6937 45.1517 41.6034 47.2119V54.5283H51.0328C56.5267 49.457 59.6963 42.0085 59.6963 33.1602Z" fill="#4285F4" />
                            <path d="M31.8047 61.5542C39.6494 61.5542 46.2262 58.9657 51.0334 54.5283L41.6039 47.2119C39.0155 48.9552 35.7138 50.0117 31.8047 50.0117C24.2506 50.0117 17.8323 44.914 15.5343 38.0466H5.86719V45.5479C10.6479 55.0302 20.4471 61.5542 31.8047 61.5542Z" fill="#34A853" />
                            <path d="M15.5339 38.0202C14.9528 36.2769 14.6094 34.428 14.6094 32.4999C14.6094 30.5717 14.9528 28.7228 15.5339 26.9796V19.4783H5.86673C3.88576 23.3874 2.75 27.7984 2.75 32.4999C2.75 37.2014 3.88576 41.6123 5.86673 45.5215L13.3944 39.6578L15.5339 38.0202Z" fill="#FBBC05" />
                            <path d="M31.8047 15.0144C36.0836 15.0144 39.8871 16.4936 42.9246 19.3462L51.2447 11.0261C46.1998 6.32457 39.6494 3.44556 31.8047 3.44556C20.4471 3.44556 10.6479 9.96956 5.86719 19.4782L15.5343 26.9795C17.8323 20.1121 24.2506 15.0144 31.8047 15.0144Z" fill="#EA4335" />
                        </svg>

                        <div>
                            <p>Google</p>
                            <span>average rating</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PlanDetailsStats