import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import Details from "./Details";
import WhatToExpect from "./WhatToExpect";
import Testimonials from "./Testimonials";
import Faq from "./Faq";
import RelatedCourses from "./RelatedCourses";
import { getCourseDetailsV2 } from "./_request";
import { useParams } from "react-router-dom";
import { useAuth } from "../../auth";
import { Helmet } from "react-helmet";

const Course = () => {
  const [courseData, setCourseData] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isPaid, setIsPaidCourse] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const { currentUser } = useAuth();

  useEffect(() => {
    const body = { courseId: id, userId: currentUser?.UserId || "" };
    getCourseDetailsV2(body).then((resp) => {
      if (resp.ResponseCode === 200) {
        setCourseData(resp?.data);
        setIsLoading(false);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, [id]);

  useEffect(() => {
    if (
      currentUser !== undefined &&
      currentUser !== null &&
      currentUser !== ""
    ) {
      setIsSubscribed(courseData?.enrolled);

      if (currentUser?.Country === "India") {
        if (courseData?.inr_price !== 0) {
          setIsPaidCourse(true);
        } else {
          setIsPaidCourse(false);
        }
      } else {
        if (courseData?.usd_price !== 0) {
          setIsPaidCourse(true);
        } else {
          setIsPaidCourse(false);
        }
      }
    } else {
      setIsSubscribed(true);
    }
  }, [currentUser]);

  return (
    <div style={{ overflow: "hidden" }}>
      <Helmet>
        <title>Program | {courseData?.course_title || "Description"}</title>
      </Helmet>

      <>
        <Hero
          courseData={courseData}
          courseId={id}
          isSubscribed={isSubscribed}
          isPaid={isPaid}
          loading={isLoading}
        />
        <Details
          courseData={courseData}
          courseId={id}
          isSubscribed={isSubscribed}
          isPaid={isPaid}
          loading={isLoading}
        />
        <WhatToExpect
          courseData={courseData}
          courseId={id}
          isSubscribed={isSubscribed}
          isPaid={isPaid}
          loading={isLoading}
        />
        <Testimonials
          courseData={courseData}
          courseId={id}
          isSubscribed={isSubscribed}
          isPaid={isPaid}
          loading={isLoading}
        />
        <Faq loading={isLoading} />
        <RelatedCourses
          courseData={courseData}
          courseId={id}
          loading={isLoading}
        />
      </>
    </div>
  );
};

export default Course;
