import { useState, useEffect, forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Grid from "@mui/material/Grid";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { useAuth } from "../../../auth";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//Loader spinner
import CircularProgress from "@mui/material/CircularProgress";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

dayjs.extend(localizedFormat);

const AddAppointmentModal = ({ clientDetails }) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [slotsList, setSlotsList] = useState([]);

  const [clientData, setClientData] = useState({
    clientID: 0,
    slotID: 0,
    appointmentDate: dayjs().format("DD/MM/YYYY"),
    appointmentDuration: 30,
    appointmentStartTime: "00:00 AM",
  });

  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isAppointmentAdded, setIsAppointmentAdded] = useState(null);

  const { currentUser } = useAuth();

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const userID = JSON.parse(localStorage.getItem("profile")).UserId;

  const postSlotsData = async () => {
    const payload = {
      user_id: userID,
      date: dayjs(clientData.appointmentDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/slots`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();
      const { slots } = await postResponse.data;

      setSlotsList(slots);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Slots List (Reschedule Dialog)
  useEffect(() => {
    postSlotsData();

    if (clientDetails) {
      setClientData((prevState) => ({
        ...prevState,
        clientID: clientDetails.id,
      }));
    }
  }, [clientData.appointmentDate]);

  //Fetch Clients
  useEffect(() => {
    const payload = {
      expert_id: currentUser?.UserId,
    };

    const postClientsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/clients`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { clients } = await postResponse.data;

        setClientList(clients);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientsData();
  }, []);

  const handleClientSelect = (event) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientID: event.target.value,
    }));
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setClientData({
      clientID: 0,
      slotID: 0,
      appointmentDate: dayjs().format("DD/MM/YYYY"),
      appointmentDuration: 30,
      appointmentStartTime: "00:00 AM",
    });
  };

  const handleSlotChange = (slot) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      slotID: slot.id,
      appointmentStartTime: slot.slot.split(" - ")[0],
    }));
  };

  const onToDateChange = (date) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setClientData((prevClientData) => ({
      ...prevClientData,
      appointmentDate: formattedDate,
    }));
  };

  const handleBookAppointment = async () => {
    setIsAppointmentAdded(false);
    const payload = {
      expert_id: +currentUser?.UserId,
      user_id: +clientData?.clientID,
      date: dayjs(clientData?.appointmentDate, "DD/MM/YYYY").format(
        "YYYY/MM/DD"
      ),
      duration: +clientData?.appointmentDuration,
      start_time: clientData?.appointmentStartTime,
    };

    const addAppointmentHeaders = {
      "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      "Content-Type": "application/json",
      deviceid: "123",
      devicetype: "web",
      lat: "1",
      long: "1",
    };

    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/new-uwc/uwc_institute/create-appointment`,
        {
          method: "POST",
          headers: addAppointmentHeaders,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      if (postResponse?.ResponseCode == 200) {
        setOpenSnackbar(true);
        setSnackbarMessage(postResponse?.data);
        handleClose();
        setIsAppointmentAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const filteredSlots = slotsList?.filter((item) => {
    if (!checked) {
      // Render morning slots (AM)
      return item.slot.split(" - ")[0].includes("AM");
    } else {
      // Render evening slots (PM)
      return item.slot.split(" - ")[0].includes("PM");
    }
  });

  const sortedSlots = filteredSlots?.sort((a, b) => {
    const sortKeyA = a.slot.split(" - ")[0];
    const sortKeyB = b.slot.split(" - ")[0];
    return sortKeyA.localeCompare(sortKeyB);
  });

  const handleSlotTimingChange = (appointmentDuration) => {
    setClientData((prevData) => ({
      ...prevData,
      appointmentDuration,
    }));
  };
  return (
    <>
      <Button
        onClick={handleClickOpen}
        sx={{ flex: "1", minWidth: "0" }}
        variant="contained"
        className="AddAppointment PrimaryCTA"
      >
        <AddCircleIcon />
        Add Appointment
      </Button>

      <Dialog
        className="AddApointment--Dialog"
        open={open}
        onClose={handleClose}
        sx={{
          maxWidth: "610px",
          width: "100%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isAppointmentAdded !== null && !isAppointmentAdded && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle className="pb-2">Add Appointment</DialogTitle>
        <DialogContent className="Dialog--Content pt-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Pick a date"
              format="DD/MM/YYYY"
              value={dayjs(clientData?.appointmentDate, "DD/MM/YYYY")}
              onChange={onToDateChange}
              disablePast={true}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>

          <Grid
            container
            gap={2}
            sx={{ width: "100%", margin: "0" }}
            className="Timings"
          >
            {slotsList?.length > 0 ? (
              slotsList?.map((item) => (
                <Grid
                  item
                  xs={2}
                  key={item.slot}
                  className={`AvailableSlot ${
                    item.id == clientData?.slotID ? "SelectedSlot" : ""
                  }`}
                  onClick={() => handleSlotChange(item)}
                >
                  <p>{item.slot.split(" - ")[0]}</p>
                </Grid>
              ))
            ) : (
              <p className="mx-auto" style={{ fontWeight: "500" }}>
                No slots available for selected dates
              </p>
            )}
          </Grid>

          <div className="SessionDuration--Container">
            <h6>Session Duration</h6>

            <Grid
              container
              gap={2}
              sx={{ width: "100%", margin: "0 0 20px 0" }}
              className="SessionTimings"
            >
              {currentUser?.Country == "India" ? (
                <>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      cursor: "pointer",
                    }}
                    className={`${
                      clientData.appointmentDuration == "30"
                        ? "SelectedSTiming"
                        : ""
                    }`}
                    onClick={() => handleSlotTimingChange(30)}
                  >
                    <p>30 minutes</p>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      cursor: "pointer",
                    }}
                    className={`${
                      clientData.appointmentDuration == "60"
                        ? "SelectedSTiming"
                        : ""
                    }`}
                    onClick={() => handleSlotTimingChange(60)}
                  >
                    <p>60 minutes</p>
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  xs={3}
                  className={`${
                    clientData.appointmentDuration == "30"
                      ? "SelectedSTiming"
                      : ""
                  }`}
                  onClick={() => handleSlotTimingChange(30)}
                >
                  <p>30 minutes</p>
                </Grid>
              )}
            </Grid>
          </div>

          <FormControl fullWidth className="mt-4 ClientSelect--Form">
            <InputLabel id="demo-simple-select-label">Select Client</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={clientData.clientID}
              onChange={handleClientSelect}
              renderValue={(selectedValue) => {
                const selectedItem = clientList.find(
                  (item) => item.ID === selectedValue
                );
                return (
                  <div className="d-flex gap-2 ClientSelect--MenuItem">
                    <img
                      src={selectedItem?.ProfileImage}
                      width={45}
                      height={45}
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                    <div>
                      <h6>{selectedItem?.Name}</h6>
                      <p>{selectedItem?.Country}</p>
                    </div>
                  </div>
                );
              }}
            >
              {clientList?.map((item) => (
                <MenuItem value={item.ID} key={item.ID}>
                  <div
                    id={item.ID}
                    className="d-flex gap-2 ClientSelect--MenuItem"
                  >
                    <img
                      src={item.ProfileImage}
                      width={45}
                      height={45}
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                    <div>
                      <h6>{item.Name}</h6>
                      <p>{item.Country}</p>
                    </div>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="PrimaryCTA"
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "20px",
            }}
            onClick={handleBookAppointment}
            variant="contained"
            disableElevation
            disabled={
              clientData.slotID !== 0 && clientData.clientID !== 0
                ? false
                : true
            }
          >
            Confirm and Book Appointment
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddAppointmentModal;
