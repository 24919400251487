import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Slider from "@mui/material/Slider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PodcastPopup from "./PodcastPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  minHeight: 400,
  bgcolor: "#f5f1fe",
  boxShadow: 16,
  aspectRatio: 16 / 9,
  borderRadius: '10px',
  '@media (max-width: 600px)': {
    width: '95%',
  }
};

const formatTime = (seconds) => {
  const pad = (num, size) => num.toString().padStart(size, '0');
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${pad(minutes, 2)}:${pad(remainingSeconds, 2)}`;
};

const PodcastCard = ({ item }) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(50);
  const [seekPosition, setSeekPosition] = useState(0);
  const [duration, setDuration] = useState(0);
  const timestamp = data?.createDate;
  const dateObject = new Date(timestamp);
  const day = String(dateObject.getDate()).padStart(2, '0');
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[dateObject.getMonth()];
  const year = dateObject.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  const handleVideoModalOpen = () => {
    setVideoModalOpen(true);
  };

  const handleVideoModalClose = () => {
    setVideoModalOpen(false);
  };

  useEffect(() => {
    // Other setup code...

    const audio = audioRef.current; // Capture the current value of the ref in a variable

    if (audio) {
      const setAudioData = () => {
        setDuration(audio.duration); // Set duration as number
        setSeekPosition(audio.currentTime);
      };

      const updateSeekPosition = () => {
        setSeekPosition(audio.currentTime);
      };

      audio.addEventListener("loadedmetadata", setAudioData);
      audio.addEventListener("timeupdate", updateSeekPosition);

      // Return a cleanup function that removes the event listeners
      return () => {
        if (audio) { // Check if audio is not null
          audio.removeEventListener("loadedmetadata", setAudioData);
          audio.removeEventListener("timeupdate", updateSeekPosition);
        }
      };
    }
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
          },
          body: JSON.stringify({
            postId: item?._id,
          }),
        };

        const response = await fetch(
          "https://odoo.unitedwecare.ca/new-uwc/view/public/post",
          requestOptions
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();
        setData(jsonData?.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [item]);

  const togglePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const stopAudio = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    audioRef.current.volume = newValue / 100;
  };

  const handleMuteToggle = () => {
    // Improved mute toggle logic
    if (volume === 0) {
      setVolume(50); // Reset to 50% if previously muted
    } else {
      setVolume(0); // Mute
    }
    audioRef.current.volume = volume === 0 ? 0.5 : 0; // Toggle volume
  };

  const handleVolumeUp = () => {
    // Improved volume up logic
    const newVolume = volume < 100 ? 100 : volume; // Increase to 100 if not already
    setVolume(newVolume);
    audioRef.current.volume = newVolume / 100;
  };

  const handleSeekChange = (event, newValue) => {
    setSeekPosition(newValue);
    audioRef.current.currentTime = newValue;
  };

  const close = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleVideoModalClose();
  };

  return (
    <div className={`w-100 Search--audioCard d-flex p-2 gap-2`} onClick={handleVideoModalOpen} style={{ cursor: 'pointer' }}>
      <div className="my-2 mx-1 d-flex flex-column justify-content-between gap-1 w-100">
        <div className="d-flex gap-4 align-items-center">
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src={item?.thumbnail_url || data?.defaultImageUrl}
              alt=""
              className="program-img position-relative rounded-2"
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#73589B",
                zIndex: "100",
                background: "white",
                width: "60px",
                height: "60px",
              }}
              className=" p-2 rounded-circle"
            >
              <img
                src="/assets/img/play-podcast.png"
                alt=""
                style={{ width: "50px", height: "auto", aspectRatio: "1/1" }}
              />
            </div>
          </div>

          <div className="my-auto w-100">
            <h6>{item?.title}</h6>
            <p className="text-decoration-none">{formattedDate} </p>
            <div className="d-flex gap-2 align-items-center lh-lg my-2">
              <p className="text-decoration-none">{item?.duration} mins </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="12"
                viewBox="0 0 2 12"
                fill="none"
              >
                <path
                  d="M1 11V1"
                  stroke="#D0D5DD"
                  strokeWidth="0.92"
                  strokeLinecap="round"
                />
              </svg>
              <div className="clubDiv">
                <p style={{ color: "#fff", fontSize: "10px" }}>
                  {item?.club_id}
                </p>
              </div>
            </div>

            {/* <div className="d-flex align-items-center gap-2">
              <IconButton onClick={handleMuteToggle}>
                {volume === 0 ? <VolumeOffIcon /> : (volume < 50 ? <VolumeDownIcon /> : <VolumeUpIcon />)}
              </IconButton>
              <Slider
                sx={{
                  color: 'rgba(0,0,0,0.87)',
                  '& .MuiSlider-track': {
                    border: 'none',
                  },
                  '& .MuiSlider-thumb': {
                    width: 24,
                    height: 24,
                    backgroundColor: '#fff',
                    '&::before': {
                      boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                    },
                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                      boxShadow: 'none',
                    },
                  },
                }}
                value={volume}
                onChange={handleVolumeChange}
                aria-labelledby="continuous-slider"
                min={0}
                max={100}
                step={1}
              />
              <IconButton onClick={handleVolumeUp}>
                <VolumeUpIcon />
              </IconButton>
            </div> */}
          </div>
        </div>
        {/* <div className="d-flex align-items-center gap-2">
          <audio ref={audioRef} src={'https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fcourses%2FAnxiety%20Beyond%20The%20Brain%20Meditation.mp3?alt=media&token=c5948a11-a435-4e2a-935a-18713535191c'} />
          <IconButton onClick={togglePlayPause}>
            {
              !isPlaying ? (
                <PlayArrowRounded
                  sx={{ fontSize: '2.5rem' }}
                  htmlColor={'#000'}
                />
              ) : (
                <PauseRounded sx={{ fontSize: '2.5rem' }} htmlColor={'#000'} />)
            }
          </IconButton>
          <Slider
            value={seekPosition}
            max={duration} // Use raw duration for calculations
            onChange={(e, newValue) => {
              setSeekPosition(newValue); // Update current time as number
              audioRef.current.currentTime = newValue;
            }}
            aria-labelledby="continuous-slider"
            min={0}
            step={1}
            sx={{
              color: 'rgba(0,0,0,0.87)',
              height: 4,
              '& .MuiSlider-thumb': {
                width: 8,
                height: 8,
                transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                '&::before': {
                  boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                },
                '&:hover, &.Mui-focusVisible': {
                  boxShadow: `0px 0px 0px 8px 'rgb(0 0 0 / 16%)'
                    }`,
                },
                '&.Mui-active': {
                  width: 20,
                  height: 20,
                },
              },
              '& .MuiSlider-rail': {
                opacity: 0.28,
              },
            }}
          />
          <IconButton onClick={stopAudio}>
            <StopIcon sx={{ fontSize: '2.5rem' }} htmlColor={'#000'} />
          </IconButton>
        </div> */}
        {/* <div className="d-flex align-items-center justify-content-between">
          <p>{formatTime(seekPosition)}</p>
          <p>{formatTime(duration)}</p>
        </div> */}
      </div>

      <Modal
        open={videoModalOpen}
        onClose={handleVideoModalClose} // Use close function here
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="podcastModal--container"
      >
        <Box
          sx={style}
          className="d-flex align-items-center justify-content-center boxContainer rounded-3 position-relative"
        >
          <IconButton className="d-none d-md-block" onClick={close} style={{ color: "#73589b", position: "absolute", top: "5px", right: "10px", zIndex: 1000 }}>
            <CloseIcon />
          </IconButton>
          <IconButton className="d-block d-md-none" onClick={close} style={{ color: "#73589b", position: "absolute", top: "-40px", right: "10px", zIndex: 1000 }}>
            <CloseIcon />
          </IconButton>
          <PodcastPopup data={data} onClose={handleVideoModalClose} />
        </Box>
      </Modal>
    </div>
  );
};

export default PodcastCard;
