import React, { useEffect, useState, useContext, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";

//ICONS
import SearchIcon from "@mui/icons-material/Search";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import StopIcon from "@mui/icons-material/Stop";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import MicIcon from "@mui/icons-material/Mic";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Button from "@mui/material/Button";

//SpeechRecognition
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import SessionMedicalPrescriptionTable from "../../tables/SessionMedicalPrescriptionTable";
import VitalsRichText from "../../richText/sessionDashboard/VitalsRichText";
import DiagnosisRichText from "../../richText/sessionDashboard/DiagnosisRichText";
import SessionLabAdviceTable from "../../tables/SessionLabAdviceTable";
import { set } from "lodash";
import GeneratedSessionMedicationTable from "../../tables/GeneratedSessionMedicationTable";
import ChiefComplaintsRichText from "../../richText/sessionDashboard/ChiefComplaintsRichText";
import { AppContext } from "../../../../context";
import { getTranscript, getTranslation } from "../../APIS/clientAPIS";
import { useAuth } from "../../../../auth";
import AdviseRichText from "../../richText/sessionDashboard/AdviseRichText";

const SessionPrescriptionTabItem = ({
  isWebsocketDataRecieved,
  prescriptionData,
  sessionStatus,
  labtestData,
}) => {
  const [medicineText, setMedicineText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isRecording, setIsRecording] = useState(false);

  const [audioRequestData, setAudioRequestData] = useState(null);
  const [showMic, setShowMic] = useState(true);
  const [showStopMic, setShowStopMic] = useState(false);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [autoGenPopup, setAutoGenPopup] = useState(false);
  const { expertDashboardData } = useContext(AppContext);
  const [base64String, setBase64String] = useState(null);
  const { chief_complaints, vitals, diagnosis, advise, medicines } =
    prescriptionData[0];
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const recorderControls = useAudioRecorder();

  const { currentUser } = useAuth();

  const socketRef = useRef(null);

  useEffect(() => {
    const sendAudioData = async () => {
      const base64String = await convertBlobToBase64(
        recorderControls.recordingBlob
      );

      getTranscript(
        expertDashboardData.session_data.appointment_details.appointment_id,
        expertDashboardData.session_data.appointment_details.user_id,
        expertDashboardData.session_data.appointment_details.expert_id,
        expertDashboardData.session_data.appointment_details.appointment_date,
        expertDashboardData.session_data.appointment_details.booking_date,
        base64String
      ).then((res) => {
        getTranslation(
          expertDashboardData.session_data.appointment_details.appointment_id,
          expertDashboardData.session_data.appointment_details.user_id,
          expertDashboardData.session_data.appointment_details.expert_id,
          expertDashboardData.session_data.appointment_details.appointment_date,
          expertDashboardData.session_data.appointment_details.booking_date,
          res.transcript
        );
      });
    };
    if (
      recorderControls.recordingBlob !== undefined &&
      recorderControls.recordingBlob !== null
    ) {
      sendAudioData();
    }

  }, [recorderControls.recordingBlob]);

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split("data:audio/webm;codecs=opus;base64,")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleRecordingStart = () => {
    recorderControls.startRecording();
    setIsRecording(true);
  };

  const handleRecordingStop = async () => {
    recorderControls.stopRecording();
    setIsRecording(false);
    setIsRecordingStopped(true);
  };

  const handleRecordingComplete = async (blob) => {
    setAudioRequestData(blob);
  }; 

  useEffect(() => {
    setAutoGenPopup(isWebsocketDataRecieved);
  }, [isWebsocketDataRecieved]);

  const element = document.querySelector(".autogenerated-prescription");


  return (
    <div>
      <div className="d-flex justify-content-between">
        {!expertDashboardData?.session_data?.submitted && <div className="d-flex gap-2">
          {isRecording ? (
            <button style={{ maxWidth: "fit-content", right: "30px", bottom: "30px", position: "fixed", zIndex: "1000" }} className=" PrimaryCTA" onClick={handleRecordingStop}>
              <StopIcon color="#FFF" />
              Stop voice recording
            </button>
          ) : (
            <button style={{ maxWidth: "fit-content", right: "30px", bottom: "30px", position: "fixed", zIndex: "1000" }} className="    PrimaryCTA" onClick={handleRecordingStart}>
              <MicIcon color="#FFF" />
              Generate prescription from voice
            </button>
          )}
        </div>}
        <div className="d-none gap-3 align-items-center">
          <button
            style={{
              border: "none",
              borderRadius: "5px",
              background: "#FFF",
              color: "#73589b",
            }}
          >
            <DownloadForOfflineIcon style={{ fontSize: "30px" }} />
          </button>
          <button
            style={{
              border: "none",
              borderRadius: "5px",
              background: "#FFF",
              color: "#73589b",
            }}
          >
            <LocalPrintshopIcon style={{ fontSize: "30px" }} />
          </button>
        </div>
      </div>

      <div style={{ visibility: "hidden", position: "absolute" }}>
        <AudioRecorder
          onRecordingComplete={(blob) => handleRecordingComplete(blob)}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
        />
      </div>

      <div className="MedicinePrescription--Table mt-3 position-relative">
        {autoGenPopup ? (
          <SessionMedicalPrescriptionTable
            medData={medicines}
            aiGenerated={true}
            sessionStatus={sessionStatus}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
          />
        ) : (
          <SessionMedicalPrescriptionTable
            medData={medicines}
            aiGenerated={false}
            sessionStatus={sessionStatus}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
          />
        )}
      </div>

      <div className="PrescriptionOtherData--Container mt-5 ">
        <div className="OtherData--Item position-relative">
          {autoGenPopup ? (
            <ChiefComplaintsRichText
              initialText={
                chief_complaints.map((item) => item.blob).join("\n") || ""
              }
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
              aiGenerated={true}
            />
          ) : (
            <ChiefComplaintsRichText
              aiGenerated={false}
              initialText={
                chief_complaints.map((item) => item.blob).join("\n") || ""
              }
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
            />
          )}
        </div>
        <div className="OtherData--Item position-relative">
          {autoGenPopup ? (
            <VitalsRichText
              initialText={vitals.map((item) => item.blob).join("\n") || ""}
              aiGenerated={true}
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
            />
          ) : (
            <VitalsRichText
              aiGenerated={false}
              initialText={vitals.map((item) => item.blob).join("\n") || ""}
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
            />
          )}
        </div>
        <div className="OtherData--Item position-relative">
          {autoGenPopup ? (
            <DiagnosisRichText
              initialText={diagnosis.map((item) => item.blob).join("\n") || ""}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
              sessionStatus={sessionStatus}
              aiGenerated={true}
            />
          ) : (
            <DiagnosisRichText
              aiGenerated={false}
              initialText={diagnosis.map((item) => item.blob).join("\n") || ""}
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
            />
          )}
        </div>

        <div className="OtherData--Item position-relative">
          {autoGenPopup ? (
            <AdviseRichText
              aiGenerated={true}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
              initialText={advise.map((item) => item.blob).join("\n") || ""}
              sessionStatus={sessionStatus}
            />
          ) : (
            <AdviseRichText
              aiGenerated={false}
              initialText={advise.map((item) => item.blob).join("\n") || ""}
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
            />
          )}
        </div>
      </div>

      <div className="LabAdvice--Table mt-5">
        {autoGenPopup ? (
          <SessionLabAdviceTable
            aiGenerated={true}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
            sessionStatus={sessionStatus}
            genTest={labtestData}
          />
        ) : (
          <SessionLabAdviceTable
            aiGenerated={false}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
            sessionStatus={sessionStatus}
            genTest={labtestData}
          />
        )}
      </div>
    </div>
  );
};

export default SessionPrescriptionTabItem;
