import React, { useState } from 'react'
import AddCommentIcon from '@mui/icons-material/AddComment';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HeadsetIcon from '@mui/icons-material/Headset';
import CreateComponent from './CreateComponent';
import Create from './Create';

const uploadData = [
    {
        title: "Create New Post",
        img: <AddCommentIcon />,
        type: 'Post'
    },
    {
        title: "Upload New Video",
        img: <VideoLibraryIcon />,
        type: 'Video'
    },
    {
        title: "Write New Blogs",
        img: <AssignmentIcon />,
        type: 'Blog'
    },
    {
        title: "Upload New Podcast",
        img: <HeadsetIcon />,
        type: 'Podcast'
    },
]

const followList = [
    {
        imgSrc: '/assets/img/community/Medium.png',
        name: 'Bessie Cooper',
        profileAdd: '@alessandroveronezi',
        following: true,
    },
    {
        imgSrc: '/assets/img/community/Medium.png',
        name: 'Bessie Cooper',
        profileAdd: '@alessandroveronezi',
        following: false,
    },
    {
        imgSrc: '/assets/img/community/Medium.png',
        name: 'Bessie Cooper',
        profileAdd: '@alessandroveronezi',
        following: false,
    },
    {
        imgSrc: '/assets/img/community/Medium.png',
        name: 'Bessie Cooper',
        profileAdd: '@alessandroveronezi',
        following: false,
    },
]

const Rightbar = () => {
    const [selected, setSelected] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('');

    const handleCreateClick = (index, title) => {
        setSelected(index);
        setSelectedTitle(title);
        setModalShow(true);
    };
    return (
        <div className='post--rightSide'>
            <div className='rightSide-createContainer d-none d-md-block'>
                <h5>Upload your Post</h5>
                <hr className='w-100' />
                {uploadData?.map((item, index) =>
                    <div key={index} className={`${selected === index ? 'create-active' : 'create'}`}
                        onClick={() => handleCreateClick(index, item?.type)}
                    >
                        {item?.img}
                        <h6>{item?.title}</h6>
                    </div>
                )}
                <Create
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    type={selectedTitle}
                />
            </div>
            <CreateComponent uploadData={uploadData} />
            <div className='rightSide-followContainer d-none'>
                <h5>Who to follow</h5>
                {/* <hr /> */}
                {followList?.map((item, index) =>
                    <>
                        <div key={index} className='followCard'>
                            <img src={item?.imgSrc} alt='' />
                            <div>
                                <h6>{item?.name}</h6>
                                <p>{item?.profileAdd}</p>
                            </div>
                            {
                                item?.following ?
                                    <button className='followingBtn'>Following</button>
                                    :
                                    <button className='followBtn'>Follow</button>
                            }
                        </div>
                        <hr />
                    </>
                )}
            </div>
        </div >
    )
}

export default Rightbar