import React, { useState, useEffect, useContext } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AutogeneratedReviewBar from "../../component/AutogeneratedReviewBar";
import { AppContext } from "../../../../context";
import { set } from "date-fns";
const VitalsRichText = ({
  isWebsocketDataRecieved,
  initialText,
  aiGenerated,
  sessionStatus,
}) => {
  const [editorState, setEditorState] = useState(() => {
    const contentState = ContentState.createFromText(initialText);
    return EditorState.createWithContent(contentState);
  });
  const [generated, setGenerated] = useState(false);

  useEffect(() => {
    setGenerated(aiGenerated);
  }, [aiGenerated]);
  // Update editor content when the prop changes

  useEffect(() => {
    const contentState = ContentState.createFromText(initialText);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState); // Make sure to update the editor state
  }, [initialText]);

  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const text = contentState.getPlainText("\u0001");

    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        vitals: [
          {
            timestamp: Date.now().toString(),
            blob: text,
          },
        ],
      },
    }));
  };

  const onTickClick = () => {
    setGenerated(false);
  };

  const onCrossClick = () => {
    const emptyEditorState = EditorState.createEmpty();
    setEditorState(emptyEditorState);
    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        vitals: [],
      },
    }));
  };

  return (
    <div
      style={{ border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}
      className="generated-prescription-container  p-0"
    >
      {generated && !sessionStatus && (
        <div className="m-3">
          <span
            style={{
              color: "#305FB8",
              width: "fit-content",
              border: "1px solid",
              backgroundColor: "rgba(48, 95, 184,0.2)",
            }}
            className="d-flex fw-bold px-3 gap-2 align-items-center   rounded-5 p-1"
          >
            <img
              alt=""
              src="/assets/img/icons/sparkle_blue.svg"
              width={16}
              className=""
              color="#305FB8"
            />
            <span>AI Generated</span>
          </span>
        </div>
      )}
      <div className="p-2">
        {!generated ? (
          <div className="Table-span-3 d-flex align-items-center">
            {isWebsocketDataRecieved ? (
              <button
                onClick={() => setGenerated(true)}
                className=" border-0 mx-1"
                style={{ backgroundColor: "white" }}
              >
                <AutoFixHighIcon
                  sx={{ color: "#6C46A0", width: "30px", height: "30px" }}
                />
              </button>
            ) : (
              <div
                style={{ marginRight: "8px", marginLeft: "5px" }}
                className="d-flex align-items-center justify-content-center my-3"
              >
                <img
                  alt=""
                  src="/assets/img/icons/sparkling.png"
                  width={15}
                  className="animate-pulse"
                />
              </div>
            )}
            <h6>Vitals</h6>
          </div>
        ) : (
          <h6>Vitals</h6>
        )}
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          toolbarHidden={true}
          readOnly={expertDashboardData?.session_data?.submitted}
        />
      </div>
      {!expertDashboardData?.session_data?.submitted && generated && (
        <AutogeneratedReviewBar
          tick={() => onTickClick()}
          cross={() => onCrossClick()}
        />
      )}
    </div>
  );
};

export default VitalsRichText;
