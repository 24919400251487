import React from 'react'
import '.././Plans.css'

const TrustArea = ({ bgType }) => {
    return (
        <div>
            {/* <!------ trust-area ------> */}
            {
                bgType !== "pinkGradient" ?
                    <div className={`trust-area ${bgType}`}>
                        <div className="container">
                            <h2 className="heading1">Why place your  <span className="diff-clr">trust</span> in us</h2>
                            <div className="trust-inner">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="trust1">
                                            <img src="assets/img/scale.png" alt='' />
                                            <hr />
                                            <h3>100%</h3>
                                            <p>Client retention renewal rate</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="trust2">
                                            <img src="assets/img/star.png" alt='' />
                                            <h3>4.7</h3>
                                            <p>4.7 star rating on Play Store</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="trust1">
                                            <img src="assets/img/scale.png" alt='' />
                                            <hr />
                                            <h3>75%</h3>
                                            <p>People saw a change in sleeping
                                                <br /> patterns after taking our services</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    :
                    <>
                        <img src='/assets/img/trustArea_PinkGradient.png' alt='trustArea_PinkGradient.png' />
                    </>
            }
        </div>
    )
}

export default TrustArea