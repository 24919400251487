import React from 'react'
import CourseOrderPreview from '../components/course/CourseOrderPreview'

const CourseOrderPreviewPage = () => {
  return (
    <div>
        <CourseOrderPreview />
    </div>
  )
}

export default CourseOrderPreviewPage