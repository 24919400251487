import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../auth';
import Post from './Post';
import Blogs from './Blogs';
import Video from './Video';
import Button from '@mui/material/Button';
import { getTypePostsWithUserIdByClub, joinClub } from '../_request';
import { useSnackbar } from 'notistack';

const tabs = [
    {
        tabId: 0,
        name: 'Post',
        keyword: 'post',
    },
    {
        tabId: 1,
        name: 'Blogs',
        keyword: 'article',
    },
    {
        tabId: 2,
        name: 'Videos',
        keyword: 'video',
    },
    {
        tabId: 3,
        name: 'Podcasts',
        keyword: 'audio',
    },
];

const Club = ({ clubId }) => {
    const [select, setSelect] = useState(0);
    const [postData, setPostData] = useState(null);
    const { currentUser } = useAuth();
    const userId = currentUser?.UserId;
    const [postType, setPostType] = useState('post');

    const { enqueueSnackbar } = useSnackbar();

    const onTabChange = (item) => {
        setPostType(item?.keyword);
        setSelect(item?.tabId); 

        getTypePostsWithUserIdByClub({ userId, clubId, postType: item?.keyword }).then((resp) => {
            if (resp?.ResponseCode === 200) {
                setPostData(resp?.data);
            } else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        });
    };

    useEffect(() => {
        onTabChange(tabs[0])
    }, []); 

    const onJoinClub = () => {
        const body = {
            userId: userId,
            clubId: postData?.club?.id,
        };
        joinClub(body).then((resp) => {
            if (resp?.ResponseCode === 200) {
                enqueueSnackbar("Joined Club.", { variant: "success" });
                window.location.reload();
            } else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        });
    };

    return (
        <div className='categoryDetail-section'>
            <div>
                <div className='module-bg-image'>
                    <img src='/assets/img/community/mother_child.jpg' alt='' />
                </div>
                <div className='module-container d-flex flex-row p-md-4 p-2'>
                    <div className='col-12 pe-md-3'>
                        <div className='categoryDiv'>
                            {postData?.club?.category?.name}
                        </div>
                        <div className='mt-3 mt-md-0'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h3>{postData?.club?.name}</h3>
                                <div className='memberDiv'>{postData?.club?.membersCount} Members</div>
                            </div>
                            <p>{postData?.club?.description}</p>
                        </div>
                        <div className='my-3 d-flex gap-3'>
                            {postData?.club.following ?
                                <Button variant='contained' className='containedBtn'>Joined</Button>
                                :
                                <Button variant='contained' className='containedBtn' onClick={onJoinClub}>Join</Button>
                            }
                            <Button variant='outlined' className='outlinedBtn'>Upload</Button>
                        </div>
                        <div className='tabs-section d-flex align-items-center gap-3 my-4 overflow-auto'>
                            {
                                tabs?.map((item, index) =>
                                    <div key={index} className={`${select == item?.tabId ? "tabs-container-active" : "tabs-container"}`} onClick={() => onTabChange(item, index)}>
                                        {item?.name}
                                    </div>
                                )
                            }
                        </div>
                        {
                            select === 0 &&
                            <div className='d-flex flex-column'>
                                {postData?.feed?.map((item, index) =>
                                    <div key={index} className='my-3 py-md-3 col-12 col-md-9'>
                                        <Post item={item} />
                                    </div>
                                )}
                            </div>
                        }
                        {
                            select === 1 &&
                            <div className='row'>
                                {postData?.feed?.map((item, index) =>
                                    <div className='col-3 p-3'>
                                        <Blogs item={item?.data} />
                                    </div>
                                )}
                            </div>
                        }
                        {
                            select === 2 &&
                            <div className='row'>
                                {postData?.feed?.map((item, index) =>
                                    <div className='col-3 p-3'>
                                        <Video item={item?.data} />
                                    </div>
                                )}
                            </div>
                        }
                        {
                            select === 3 &&
                            <div className='row'>
                                {postData?.feed?.map((item, index) =>
                                    <div className='col-3 p-3'>
                                        <Video item={item?.data} />
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Club