import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/Journal.css";
import Popup from "./Modal";
import {
  addEntrytoJournal,
  courseInternalContentView,
  deleteEntrytoJournal,
  editEntrytoJournal,
} from "../_request";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../auth";
import SidebarMobile from "./SidebarMobile";
import TextField from "@mui/material/TextField";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Skeleton } from "@mui/material";

import { addBookmarkEntry } from "../_request";
import { Helmet } from "react-helmet";
const modal_data = [
  {
    title: "Your personal journal",
    desc: "Dive into your virtual journal space where only your thoughts matter. Type in whatever's on your mind based on the brief provided below.",
  },
];

const Journal = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [journalData, setJournalData] = useState();
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [textValue, setTextValue] = useState("");
  const [addedJournalData, setAddedJournalData] = useState();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  //console.log("kdkjfglsnl---", journalData, title, description);

  useEffect(() => {
    const body = {
      subLevelId: id,
      userId: currentUser?.UserId,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setJournalData(resp?.data);
        setIsBookmarked(resp?.data?.bookmarked);
        setIsLoading(false);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, [addedJournalData]);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmitJournal = () => {
    const body = {
      journal_data_id: journalData?.content?.journal_data_id,
      title: title,
      description: description,
    };
    addEntrytoJournal(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        enqueueSnackbar(resp?.data?.message, { variant: "success" });
        setTitle("");
        setDescription("");
        setAddedJournalData(resp?.data);
      }
    });
  };

  const handlePrevButton = () => {
    if (journalData?.previous_id?.type === "video") {
      navigate(`/program-internal/module/${journalData?.previous_id?.id}`);
    } else if (journalData?.previous_id?.type === "audio") {
      navigate(`/program-internal/module/${journalData?.previous_id?.id}`);
    } else if (journalData?.previous_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${journalData?.previous_id?.id}`
      );
    } else if (journalData?.previous_id?.type === "journal") {
      navigate(
        `/program-internal/module/my-journal/${journalData?.previous_id?.id}`
      );
    } else if (journalData?.previous_id?.type === "quiz") {
      navigate(`/program-internal/module/quiz/${journalData?.previous_id?.id}`);
    } else if (journalData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${journalData?.previous_id?.id}`
      );
    } else if (journalData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${journalData?.previous_id?.id}`
      );
    } else if (journalData?.previous_id?.type === "html") {
      navigate(
        `/program-internal/module/article/${journalData?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (journalData?.next_id?.type === "video") {
      navigate(`/program-internal/module/${journalData?.next_id?.id}`);
    } else if (journalData?.next_id?.type === "audio") {
      navigate(`/program-internal/module/${journalData?.next_id?.id}`);
    } else if (journalData?.next_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${journalData?.next_id?.id}`
      );
    } else if (journalData?.next_id?.type === "journal") {
      navigate(
        `/program-internal/module/my-journal/${journalData?.next_id?.id}`
      );
    } else if (journalData?.next_id?.type === "quiz") {
      navigate(`/program-internal/module/quiz/${journalData?.next_id?.id}`);
    } else if (journalData?.next_id?.type === "html") {
      navigate(`/program-internal/module/article/${journalData?.next_id?.id}`);
    } else if (journalData?.next_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${journalData?.next_id?.id}`
      );
    } else if (journalData?.next_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${journalData?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/program-internal/program-view/${journalData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(
      `/program-internal/program-view/${journalData?.next_module_id?.id}`
    );
  };

  const handleBookmarkClick = () => {
    const bBody = { sub_level_id: id };
    addBookmarkEntry(bBody).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setIsBookmarked(resp?.data?.bookmarked);
      } else {
        //console.log(resp?.ResponseMessage);
      }
    });
  };

  return (
    <section className="journal">
      <Helmet>
        <title>Program | Journal</title>
      </Helmet>
      {isLoading ? (
        <>
          <section className="d-none d-md-flex">
            <Skeleton variant="rectangular" width={300} height={1080} />
          </section>
          <section className="modules-section">
            <div>
              <div className="modules-container">
                <Skeleton
                  variant="rounded"
                  width="20%"
                  height={40}
                  className="rounded-3"
                />
                <Skeleton
                  variant="rounded"
                  width="40%"
                  height={40}
                  className="rounded-3 my-2"
                />
                <div className="sections-name d-flex gap-2 align-items-center justify-content-between">
                  <Skeleton
                    variant="rounded"
                    width="40%"
                    height={40}
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width={25}
                    height={25}
                    className=""
                  />
                </div>

                <div className="modules-sections">
                  <Skeleton
                    variant="rounded"
                    width="40%"
                    height={40}
                    className="rounded-3 mx-auto"
                  />
                  <div className="journal-entries mt-4">
                    <div className="new-entry ">
                      <Skeleton
                        variant="rounded"
                        width="40%"
                        height={40}
                        className="rounded-3 my-2"
                      />
                      <Skeleton
                        variant="rounded"
                        width="100%"
                        height={50}
                        className="rounded-3 mx-auto my-2"
                      />
                      <Skeleton
                        variant="rounded"
                        width="100%"
                        height={220}
                        className="rounded-3 mx-auto my-2"
                      />
                      <Skeleton
                        variant="rounded"
                        width="100%"
                        height={40}
                        className="rounded-3 mx-auto my-2"
                      />
                    </div>

                    <div className="previous-entries">
                        <>
                          <Skeleton
                            variant="rounded"
                            width="40%"
                            height={40}
                            className="rounded-3"
                          />
                          <div className="prev-entries-items ">
                            {Array.from(new Array(12)).map((_, i) => (
                              <Skeleton
                                variant="rounded"
                                width="100%"
                                height={60}
                                className="rounded-3 mx-auto"
                              />
                            ))}
                          </div>
                        </>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <div>
                    <Skeleton
                      variant="circular"
                      width={35}
                      height={35}
                      className="rounded-full"
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="circular"
                      width={35}
                      height={35}
                      className="rounded-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="journal-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="journal-section">
            <div className="journal-bg-img">
              <img
                src="/assets/img/course_internal_journey/section_bg_img"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="journal-container">
              <div className="journal-sections-heading">
                {journalData?.course_name}
                <span>
                  {" > "} {journalData?.content?.name}
                </span>
              </div>
              <div className="sections-progress">
                Section {journalData?.current_level_index + 1}/
                {journalData?.level_count}
              </div>
              <div className="journal-section-name">
                <div style={{ width: "calc(100% - 20px)" }}>
                  {journalData?.content?.name}
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src="/assets/img/course_internal_journey/info-icon.png"
                    alt=""
                    loading="lazy"
                    className="quiz-info-icon"
                    onClick={() => setShowModal(true)}
                  />
                  <Popup
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    data={modal_data[0]}
                  />
                  <div
                    className="quiz-save"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleBookmarkClick()}
                  >
                    {isBookmarked ? (
                      <BookmarkIcon color="#541cf5" />
                    ) : (
                      <BookmarkBorderOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
              <hr style={{ color: "#E0E0E0" }} />
              <div className="sections-desc">{journalData?.description}</div>
              <div className="journal-entries">
                <div className="new-entry">
                  <div className="new-entry-title">Add a new journal entry</div>
                  <div className="new-entry-input-title">
                    <input
                      type="text"
                      className="title-input"
                      name="title"
                      value={title}
                      placeholder="Add title *"
                      onChange={(e) => handleTitleChange(e)}
                    />
                  </div>
                  <div className="new-entry-input-desc">
                    <div
                      style={{
                        height: "13.6875rem",
                        overflow: "hidden",
                      }}
                    >
                      <textarea
                        className="text-input"
                        value={description}
                        name="thoughts"
                        placeholder="Write your thoughts *"
                        rows="13"
                        cols="13"
                        onChange={(e) => handleDescChange(e)}
                      ></textarea>
                    </div>
                  </div>
                  <button
                    className="new-entry-btn"
                    style={
                      title.trim() === "" || description.trim() === ""
                        ? { backgroundColor: "#73589b75" }
                        : {}
                    }
                    onClick={handleSubmitJournal}
                    disabled={title.trim() === "" || description.trim() === ""}
                  >
                    Submit
                  </button>
                </div>

                <div className="previous-entries">
                  {journalData?.content?.entries?.length > 0 && (
                    <>
                      <div className="prev-entries-header">
                        <div className="prev-entries-title">
                          Previous Entries
                        </div>
                      </div>
                      <div className="prev-entries-items ">
                        {journalData?.content?.entries?.map((item, i) => (
                          <div className="prev-entries-item">
                            <div className="prev-item-date">{item.date}</div>
                            <div className="prev-item-content">
                              <div className="prev-item-text">
                                <div className="prev-item-title">
                                  <img
                                    src={
                                      selected === i
                                        ? "/assets/img/course_internal_journey/see-less-icon.png"
                                        : "/assets/img/course_internal_journey/see-more-icon.png"
                                    }
                                    alt=""
                                    loading="lazy"
                                    className="see-more-icon"
                                    onClick={() => toggle(i)}
                                  />
                                  <div className="prev-item-title-text">
                                    {i === selected
                                      ? item.title
                                      : `${item.title.slice(0, 40)}...`}
                                  </div>
                                </div>
                                <div
                                  className={
                                    selected === i
                                      ? "prev-item-desc show"
                                      : "prev-item-desc"
                                  }
                                >
                                  <p>{item?.description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="mood-monitor-btn">
                <div>
                  {journalData?.previous_id.type && (
                    <div className="prev-btn" onClick={handlePrevButton}>
                      <div className="modules-prev-text">Previous</div>
                      <div className="prev-arrow">
                        <img
                          src="/assets/img/course_internal_journey/modules/arrow.png"
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                          style={{ transform: "rotate(180deg)" }}
                        />
                      </div>
                    </div>
                  )}
                  {journalData?.previous_module_id?.id &&
                    !journalData?.previous_id?.type && (
                      <div
                        className="prev-btn"
                        onClick={handlePrevModuleButton}
                      >
                        <div className="modules-text">Previous Module</div>
                        <div className="prev-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                            style={{ transform: "rotate(180deg)" }}
                          />
                        </div>
                      </div>
                    )}
                </div>

                <div>
                  {journalData?.next_id.type && (
                    <div
                      className="next-btn"
                      onClick={handleNextButton}
                      style={{ marginLeft: "auto" }}
                    >
                      <div className="modules-text">Next</div>
                      <div className="next-arrow">
                        <img
                          src="/assets/img/course_internal_journey/modules/arrow.png"
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {journalData?.next_module_id?.id &&
                    !journalData?.next_id?.type && (
                      <div
                        className="next-btn"
                        onClick={handleNextModuleButton}
                      >
                        <div className="modules-text">Next Module</div>
                        <div className="next-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default Journal;
