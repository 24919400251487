import React from 'react';
import ReactPaginate from 'react-paginate';

const PER_PAGE = 16; // Adjust the number of items per page as needed

export default function Pagination({
  count,
  setcurrPage,
  currentPage,
}) {
  function handlePageClick({ selected: selectedPage }) {
    setcurrPage(selectedPage);
  }

  const pageCount = Math.ceil(count / PER_PAGE);

  return (
    <div className="m-3 pb-4">
      
        <div
          style={{
            backgroundColor: "white",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            borderRadius: "20px",
            marginTop: "25px",
          }}
        >
          <div className="text-center col-md-12">
            <ReactPaginate
              previousLabel={<span style={{ fontSize: "14px" }}>← Previous</span>}
              nextLabel={<span style={{ fontSize: "14px" }}>Next →</span>}
              pageCount={pageCount}
              forcePage={currentPage}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </div>
        </div>
    </div>
  );
}
