import axios from 'axios'
import jwtDecode from 'jwt-decode';
import { Config } from '../../core/_request';

export const getPostsWithUserId = (id) => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/feed/${id}?count=99&order=id desc`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                return resp;
        });
}

export const getPostsWithUserIdByCategory = ({ userId, catId }) => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/feed/${userId}?category=${catId}&order=id desc`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                return resp;
        });
}

export const getTypePostsWithUserIdByClub = ({ userId, clubId, postType }) => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/feed/${userId}?club=${clubId}&post-type=${postType}&order=id desc`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                return resp;
        });
}

export const deletePost = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/delete`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const ViewPostDetail = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/view/post`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const getCategoryList = (id) => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/list/category/${id}`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                return resp;
        });
}

export const getCommentsOnPost = ({ id, postId }) => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/view-comments/${id}/${postId}`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const AddCommentToPost = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/create-comment`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const LikePost = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/like`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const removeLikePost = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/remove-like`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const getAllClubs = (id) => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/search/club/${id}?count=99`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                return resp;
        });
}

export const joinClub = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/join-club`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const CreateCommunityPost = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/create-post`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const getAllClubsByCategory = ({ userId, catId }) => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/search/club/${userId}?count=99&category=${catId}`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                return resp;
        });
}

export const getTypePostsWithUserIdByCategory = ({ userId, catId, postType }) => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/feed/${userId}?category=${catId}&post-type=${postType}&order=id desc`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                return resp;
        });
}

// export const getTypePostsWithUserIdByCategory = async (body) => { 
//     if(body?.category)
//     {
//       const resp = await axios
//         .post(
//           `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}&category=${body?.category}`,      
//           {},
//           Config()
//         );
//       const resp_1 = resp.data;
//       if (resp_1.data) resp_1.data = resp_1.data;
//       return resp_1.data;
//     }
//     else if(body?.club){
//       const resp = await axios
//       .post(
//         `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}&club=${body?.club}`,      
//         {},
//         Config()
//       );
//       const resp_1 = resp.data;
//       if (resp_1.data) resp_1.data = resp_1.data;
//       return resp_1.data;
//     }
//     else{
//       const resp = await axios
//       .post(
//         `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}`,      
//         {},
//         Config()
//       );
//       const resp_1 = resp.data;
//       if (resp_1.data) resp_1.data = resp_1.data;
//       return resp_1.data;
//     }
//   };

export const Odoo_community_feed = async (body) => {
    if (body?.category) {
        const resp = await axios
            .post(
                `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}&category=${body?.category}`,
                {},
                Config()
            );
        const resp_1 = resp.data;
        if (resp_1.data) resp_1.data = resp_1.data;
        return resp_1.data;
    }
    else if (body?.club) {
        const resp = await axios
            .post(
                `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}&club=${body?.club}`,
                {},
                Config()
            );
        const resp_1 = resp.data;
        if (resp_1.data) resp_1.data = resp_1.data;
        return resp_1.data;
    } else if (body?.sUserId) {
        const resp = await axios
            .post(
                `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}&s-user=${body?.sUserId}`,
                {},
                Config()
            );
        const resp_1 = resp.data;
        if (resp_1.data) resp_1.data = resp_1.data;
        return resp_1.data;
    }
    else {
        const resp = await axios
            .post(
                `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}`,
                {},
                Config()
            );
        const resp_1 = resp.data;
        if (resp_1.data) resp_1.data = resp_1.data;
        return resp_1.data;
    }
};

export const deletePostComment = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/comment/delete`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const getProfileData = (profileId) => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/view-profile/2/${profileId}`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                return resp;
        });
}

export const getPostsOfSpecificUser = ({ currentUserId, prfileUserId, postType }) => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/feed/${currentUserId}?s-user=${prfileUserId}&post-type=${postType}`, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const reportUser = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/report/v2`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}