import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "../components/Layout";
import Home from "../pages/Home";
import LandingPage from "../pages/LandingPage";
import ExpertDetails from "../pages/ExpertDetails";
import SignIn from "../pages/SignIn";
import BookAnAppointment from "../pages/BookAnAppointment";
import Dashboard from "../pages/dashboard";
import ExpertDashboard from "../pages/expertDashboard";
import About from "../pages/About";
import PaymentStatus from "../pages/PaymentStatus";
import TermAndCondition from "../components/TermAndCondition";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Disclaimer from "../components/Disclaimer";
import CoursePaymentStatus from "../pages/CoursePaymentStatus";
import ExpertBookingPreview from "../pages/ExpertBookingPreview";
import CourseOrderPreviewPage from "../pages/CourseOrderPreviewPage";
import ScrollToTop from "../ScrollToTop";
import Stella from "../pages/Stella";
import SubscriptionUpgrade from "../components/subscriptions/SubscriptionUpgrade";
import SubscriptionPlans from "../components/subscriptions/SubscriptionPlans";
import CancelSubscriptions from "../components/subscriptions/CancelSubscriptions";
import CancelSubscriptionNote from "../components/subscriptions/CancelSubscriptionNote";
import SubscriptionOrderSummary from "../components/subscriptions/SubscriptionOrderSummary";
import MySubscription from "../components/subscriptions/MySubscription";
import OnboardingStella from "../pages/OnboardingStella";
import Listing from "../components/course/Listing";
import ThankYou from "../components/subscriptions/ThankYou";
import TransactionFailed from "../components/subscriptions/TransactionFailed";
import Callback from "../components/Callback";
import NewPlansPage1 from "../components/subscriptions/NewPlansPage-1";
import PlanDetailsPage from "../components/subscriptions/PlanDetailsPage";
import Course from "../components/courseNew/Course";
import Main from "../components/dashboard/Components/Dashboard/Main";
import MyPrograms from "../components/dashboard/Components/My programs/My_programs";
import SignIn2 from "../components/SignIn2";
import SignUp2 from "../components/SignUp2";
import CourseInternalRouting from "./CourseInternalRouting";
import DeleteAccount from "../pages/DeleteAccount";
import DashboardRouter from "../pages/USonBoardingDashboard";
import Community from "../components/Community/Community";
import ClubDetail from "../components/Community/ClubDetail";
import CategoryDetail from "../components/Community/CategoryDetail";
import DetailPage from "../components/Community/DetailPage";
import AssessmentList from "../pages/AssessmentList";
import StellaTestingJourney from "../pages/StellaTestingJourney";
import UserProfile from "../components/Community/UserProfile";
import Search from "../components/Search/Search";
import Success from "../components/BookingJourney/Component/Success";
const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/privacy/request" element={<DeleteAccount />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/landing-page" element={<LandingPage />} />

          {/* Expert appointment Booking route */}
          <Route path="/" element={<Home />} />
          <Route
            path="/profession/:profession/:expertName/:id"
            element={<ExpertDetails />}
          />
          <Route
            path="/book-an-appointment/:id"
            element={<BookAnAppointment />}
          />
          <Route
            path="/payment-order-preview/:id"
            element={<ExpertBookingPreview />}
          />
          <Route path="/appointment-confirmed/:id" element={<Success />} />
          {/* Expert appointment Booking route */}

          <Route path="/payment-status/:id" element={<PaymentStatus />} />
          <Route path="/terms-and-conditions" element={<TermAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />

          <Route path="/program" element={<Listing />} />
          <Route path="/program/:name/:id" element={<Course />} />
          <Route
            path="/program-internal/*"
            element={<CourseInternalRouting />}
          />
          <Route
            path="/program/payment-status/:id"
            element={<CoursePaymentStatus />}
          />

          <Route
            path="/program/program-order-preview/:id"
            element={<CourseOrderPreviewPage />}
          />

          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/expertdashboard/*" element={<ExpertDashboard />} />
          {/* <Route path="/login" element={<SignIn />} />
          <Route path="/login1" element={<SignIn2 />} /> */}
          <Route path="/create-account" element={<SignUp2 />} />
          <Route path="/subscription-plans" element={<SubscriptionPlans />} />
          <Route
            path="/subscription-upgrade"
            element={<SubscriptionUpgrade />}
          />
          <Route
            path="/cancel-subscription"
            element={<CancelSubscriptions />}
          />
          <Route
            path="/cancel-subscription-note"
            element={<CancelSubscriptionNote />}
          />
          <Route
            path="/subscription-order-summary/"
            element={<SubscriptionOrderSummary />}
          />
          <Route path="/my-subscription" element={<MySubscription />} />
          <Route path="/onboarding-stella" element={<OnboardingStella />} />
          <Route path="/stella" element={<Stella />} />
          <Route path="/plans" element={<NewPlansPage1 />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/payment-success" element={<ThankYou />} />
          <Route path="/payment-failed" element={<TransactionFailed />} />
          <Route
            path="/platinum-care-plan"
            element={<PlanDetailsPage type="Platinum" />}
          />
          <Route
            path="/premium-care-plan"
            element={<PlanDetailsPage type="Premium" />}
          />
          <Route
            path="/super-care-plan"
            element={<PlanDetailsPage type="Super" />}
          />
          <Route path="/callback" element={<Callback />} />

          <Route path="/user-dashboard" element={<Main />} />

          <Route path="/My-programs" element={<MyPrograms />} />

          {/* Community Routes */}
          <Route path="/community" element={<Community />} />
          <Route path="/community/club/:id" element={<ClubDetail />} />
          <Route path="/community/category/:id" element={<CategoryDetail />} />
          <Route path="/community/feed/blog/:id" element={<DetailPage />} />
          <Route path="/community/feed/video/:id" element={<DetailPage />} />
          <Route path="/community/feed/podcast/:id" element={<DetailPage />} />
          <Route path="/community/user/:id" element={<UserProfile />} />


          <Route path='/assessment-list' element={<AssessmentList />} />

          {/* Search Routes */}
          <Route path='/search' element={<Search />} />
        </Route>
        {/* Expert Dashboard Route */}
        <Route path="/expert/*" element={<DashboardRouter />} />
        <Route path="/static-stella" element={<StellaTestingJourney />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
