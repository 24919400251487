import React, { useContext, useState } from "react";
import "../../styles/courses/WhatToExpect.css";
import ModuleCard from "./ModuleCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Rating from "react-rating";
import { Pagination, Navigation } from "swiper";
import { AppContext } from "../../context";
import { useAuth } from "../../auth";
import { useNavigate } from "react-router-dom";
import {
  OODOCourseAccessRequestService,
  OODOCoursePaymentService,
} from "../core/_request";

import CircularProgress from "@mui/material/CircularProgress";
import { Skeleton, Grid } from "@mui/material";
import SubscriptionAlertDialougBox from "./SubscriptionAlertDialougBox";

const cards = [
  {
    image: "/assets/img/course_new/module_1.png",
    title: "Developing Your Unique Style and Personality as a Storyteller",
    desc: "Understanding the current situtation of working women with family duties and the impact on their physical and emotional well-being",
  },
  {
    image: "/assets/img/course_new/module_1.png",
    title: "Developing Your Unique Style and Personality as a Storyteller",
    desc: "Understanding the current situtation of working women with family duties and the impact on their physical and emotional well-being",
  },
  {
    image: "/assets/img/course_new/module_1.png",
    title: "Developing Your Unique Style and Personality as a Storyteller",
    desc: "Understanding the current situtation of working women with family duties and the impact on their physical and emotional well-being",
  },
  {
    image: "/assets/img/course_new/module_1.png",
    title: "Developing Your Unique Style and Personality as a Storyteller",
    desc: "Understanding the current situtation of working women with family duties and the impact on their physical and emotional well-being",
  },
];

const course_data = [
  {
    heading: "Dashboard",
    desc: "Track your progress with a personalized dashboard that helps you trace your success.",
    image: "/assets/img/course_new/course_working1.png",
  },
  {
    heading: "Structure Learning Path",
    desc: "Choose your own pace to learn with structured learning created by the best of experts.",
    image: "/assets/img/course_new/course_working2.png",
  },
  {
    heading: "Track your progress",
    desc: "Keep an eye on your journey with regular tracking within the course.",
    image: "/assets/img/course_new/course_working3.png",
  },
  {
    heading: "Community Support",
    desc: "Find a community of people to help you find a healing path even after course completion.",
    image: "/assets/img/course_new/course_working4.png",
  },
];

const WhatToExpect = (props) => {
  const { courseData, isPaid, loading } = props;
  const [selected, setSelected] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [openPaymetAlert, setOpenPaymetAlert] = useState(false);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const handleStartSubscription = () => {
    setIsButtonClicked(true);
    if (!currentUser) {
      localStorage.setItem(
        "retrunurl",
        `/program/${props?.courseData?.course_title}/${props?.courseId}`
      );
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    } else {
      if (courseData?.enrolled) {
        navigate(
          `/program-internal/${courseData?.course_data_id}/${courseData?.course_title}`
        );
        let linksData = [
          {
            name: "Home",
            uri: `/program-internal/${courseData?.course_data_id}/${courseData?.course_title}`,
          },
          {
            name: "My Journal",
            uri: `/program-internal/module/my-journal/:id`,
          },
        ];
        sessionStorage.setItem(
          "courseInternalLinks",
          JSON.stringify(linksData)
        );
      } else if (
        courseData?.inr_price === 0.0 ||
        courseData?.course_type === "free"
      ) {
        let request = {
          userId: currentUser?.UserId,
          courseId: props?.courseId,
        };
        OODOCoursePaymentService(request).then((res) => {
          navigate(
            `/program-internal/${res?.data.data.id}/${courseData?.course_title}`
          );
          let linksData = [
            {
              name: "Home",
              uri: `/program-internal/${res?.data?.data?.id}/${courseData?.course_title}`,
            },
            {
              name: "My Journal",
              uri: `/program-internal/module/my-journal/:id`,
            },
          ];
          sessionStorage.setItem(
            "courseInternalLinks",
            JSON.stringify(linksData)
          );
        });
      } else if (courseData?.course_type === "premium") {
        // localStorage.setItem(
        //   "retrunurl",
        //   `/program/${props?.courseData?.course_title}/${props?.courseId}`
        // );
        // navigate("/plans");
        setOpenPaymetAlert(true);
      }
    }
  };

  return (
    <section>
      {/* expect section */}
      {loading ? (
        <>
          {/* desktop */}
          <div className="expect-header d-none d-md-flex">
            <a href="#discover">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="10vw"
              />
            </a>{" "}
            <div className=""></div>
            <a href="#modules">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="10vw"
              />
            </a>{" "}
            <div className=""></div>
            <a href="#course">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="10vw"
              />
            </a>{" "}
            <div className=""></div>
            <a href="#results">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="10vw"
              />
            </a>{" "}
            <div className=""></div>
          </div>
          {/* mobile */}
          <div className="expect-header d-flex d-md-none">
            <a href="#discover">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="20vw"
              />
            </a>{" "}
            <div className=""></div>
            <a href="#modules">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="20vw"
              />
            </a>{" "}
            <div className=""></div>
            <a href="#course">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="20vw"
              />
            </a>{" "}
            <div className=""></div>
            <a href="#results">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="20vw"
              />
            </a>{" "}
            <div className=""></div>
          </div>
        </>
      ) : (
        <div className="expect-header">
          <a href="#discover">Discover</a> <div className=""></div>
          <a href="#modules">Modules</a> <div className=""></div>
          <a href="#course">How Program Works</a> <div className=""></div>
          <a href="#results">Proven Results</a> <div className=""></div>
        </div>
      )}

      {/* discover section */}
      <section id="discover" className="expect-section">
        {loading ? (
          <div className="expect-main">
            <div className="expect-left-heading d-none d-md-block">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="25vw"
                height={50}
              />
            </div>
            <div className="expect-left-heading d-flex align-items-center justify-content-center d-md-none">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="50%"
                height={40}
              />
            </div>
            <div className="expect-left">
              <div className="expect-left-text">
                <p className="expect-left-para">
                  <Skeleton
                    variant="rounded"
                    sx={{ borderRadius: "10px" }}
                    width="100%"
                    height={140}
                  />
                </p>
              </div>
              <div className="expect-left-blueSection d-xl-block d-none">
                <h4 className="expect-blue-heading">
                  <Skeleton
                    variant="rounded"
                    sx={{ borderRadius: "10px" }}
                    width="100%"
                    height={50}
                  />
                </h4>
                <ul className="expect-blue-list">
                  {courseData?.course_expectations &&
                    courseData?.course_expectations?.map((item) => (
                      <li className="expect-blue-list-items">
                        <Skeleton
                          variant="rounded"
                          sx={{ borderRadius: "10px" }}
                          width="30vw"
                        />
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="expect-main">
            <div className="expect-left-heading">What to Expect</div>
            <div className="expect-left">
              <div className="expect-left-text">
                <p className="expect-left-para">
                  {courseData?.course_expectation_description}
                </p>
              </div>
              <div className="expect-left-blueSection d-xl-block d-none">
                <h4 className="expect-blue-heading">
                  This program is suitable for you if
                </h4>
                <ul className="expect-blue-list">
                  {courseData?.course_expectations &&
                    courseData?.course_expectations?.map((item) => (
                      <li className="expect-blue-list-items">
                        <img
                          src="/assets/img/course_new/white_check_mark.png"
                          alt=""
                          loading="lazy"
                          className="check-list"
                        />
                        {item?.name}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <>
            <div className="d-none d-md-block">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="40vw"
                height={600}
              />
            </div>
            <div
              className="d-block d-md-none"
              style={{ padding: "20px", marginTop: "-80px" }}
            >
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="100%"
                height={294}
              />
            </div>
          </>
        ) : (
          <div className="expect-right">
            <img
              src="/assets/img/course_new/expectNew.png"
              className="expect-right-img"
              alt=""
              loading="lazy"
            />
          </div>
        )}

        <div
          className="expect-left-blueSection d-block d-xl-none"
          style={{ height: "300px", marginTop: "-40px" }}
        >
          {loading ? (
            <>
              <h4 className="expect-blue-heading mt-2">
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="100%"
                  height={40}
                />
              </h4>
              <ul className="expect-blue-list d-flex flex-column gap-4 my-4">
                {courseData?.course_expectations &&
                  courseData?.course_expectations?.map((item) => (
                    <li className="expect-blue-list-items">
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px" }}
                        width="100%"
                      />
                    </li>
                  ))}
              </ul>
            </>
          ) : (
            <>
              <h4 className="expect-blue-heading">
                This course is suitable for you if
              </h4>
              <ul className="expect-blue-list">
                {courseData?.course_expectations &&
                  courseData?.course_expectations?.map((item) => (
                    <li className="expect-blue-list-items">
                      <img
                        src="/assets/img/course_new/white_check_mark.png"
                        alt=""
                        loading="lazy"
                        className="check-list"
                      />
                      {item?.name}
                    </li>
                  ))}
              </ul>
            </>
          )}
        </div>
      </section>

      {/* module section */}
      {loading ? (
        <section
          id="modules"
          className="modules-section"
          style={{ margin: "0 auto" }}
        >
          <div className="module-container">
            <h1 className="module-heading d-flex flex-column justify-content-center align-items-center mx-auto my-2">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="50%"
                height={50}
              />
            </h1>

            <div className="d-flex flex-md-row flex-column gap-4">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="50%"
                height={400}
                className="d-none d-md-block"
              />
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="100%"
                height={400}
                className="d-block d-md-none"
              />
              {/* desktop */}
              <div
                className="d-md-flex d-none  flex-column gap-2"
                style={{ marginTop: "100px" }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="25vw"
                  height={50}
                />
                <Skeleton
                  variant="text"
                  sx={{ borderRadius: "10px" }}
                  width="30vw"
                  height={30}
                />
                <Skeleton
                  variant="text"
                  sx={{ borderRadius: "10px" }}
                  width="30vw"
                  height={30}
                />
                <Skeleton
                  variant="text"
                  sx={{ borderRadius: "10px" }}
                  width="30vw"
                  height={30}
                />
              </div>
              {/* mobile */}
              <div className="d-flex d-md-none flex-column gap-2 p-2">
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="50vw"
                  height={40}
                />
                <Skeleton
                  variant="text"
                  sx={{ borderRadius: "10px" }}
                  width="80%"
                  height={30}
                />
                <Skeleton
                  variant="text"
                  sx={{ borderRadius: "10px" }}
                  width="80%"
                  height={30}
                />
                <Skeleton
                  variant="text"
                  sx={{ borderRadius: "10px" }}
                  width="80%"
                  height={30}
                />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section id="modules" className="modules-section">
          <div className="module-container">
            <h1 className="module-heading">
              <span className="text-highlight">Modules</span> we will cover in
              this program
            </h1>

            <div className="module-cards">
              <div className="card-slider">
                <Swiper
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    1024: {
                      slidesPerView: 1,
                    },
                  }}
                  spaceBetween={30}
                  loop={true}
                  navigation={false}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  <div>
                    {courseData?.modules?.map((item, i) => (
                      <SwiperSlide key={i}>
                        <ModuleCard {...item} />
                      </SwiperSlide>
                    ))}
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* course working */}
      {loading ? (
        <section id="course">
          <div className="course-section">
            <div className="course-container">
              <h1 className="course-heading">
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="50%"
                  height={80}
                  className="d-none d-md-block"
                />
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="70%"
                  height={40}
                  className="d-block d-md-none"
                />
              </h1>
              <div className="course-list">
                {course_data.map((item, i) => (
                  <>
                    <div>
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px" }}
                        width="20vw"
                        height={30}
                        className="d-none d-md-block"
                      />
                    </div>
                    <div>
                      <Skeleton
                        variant="text"
                        sx={{ borderRadius: "10px" }}
                        width="80vw"
                        height={30}
                        className="d-block d-md-none"
                      />
                    </div>
                  </>
                ))}
              </div>

              <div>
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="50%"
                  height={40}
                />
              </div>
            </div>

            <Skeleton
              variant="rounded"
              sx={{ borderRadius: "20px 10px" }}
              width="50%"
              height={400}
              className="d-none d-md-block"
            />
            <div style={{ padding: "10px" }}>
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px" }}
                width="100%"
                height={400}
                className="d-block d-md-none"
              />
            </div>
          </div>
        </section>
      ) : (
        <section id="course">
          <div className="course-section">
            <div className="course-container">
              <h1 className="course-heading">
                How our <span style={{ color: "#73589B" }}>program</span> works
              </h1>
              <div className="course-list">
                {course_data.map((item, i) => (
                  <div className="course-list-items" onClick={() => toggle(i)}>
                    <button
                      className="course-list-check"
                      onClick={() => toggle(i)}
                    >
                      <img
                        src={
                          selected === i
                            ? "/assets/img/course_new/course_tick_purple.png"
                            : "/assets/img/course_new/course-tick-white.png"
                        }
                        className="course-list-img rounded-circle"
                        alt=""
                        loading="lazy"
                      />
                    </button>
                    <div>
                      <h4 className="course-list-heading">{item.heading}</h4>
                      <p
                        className={
                          selected === i
                            ? "course-list-para show"
                            : "course-list-para"
                        }
                      >
                        {item.desc}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className="course-btn"
                style={{ cursor: "pointer" }}
                onClick={handleStartSubscription}
                disabled={isButtonClicked}
              >
                {isButtonClicked && <CircularProgress color="inherit" />}
                {props.isSubscribed
                  ? "Continue"
                  : isPaid
                    ? "Subscribe"
                    : "Start"}
              </div>
            </div>

            <div className="course-img">
              <img
                src="/assets/img/courseDashboard.png"
                loading="lazy"
                alt=""
              />
            </div>
          </div>
        </section>
      )}

      {/* meet your instructor */}
      {loading ? (
        <section className="instructor-section" id="instructor">
          {courseData?.instructer &&
            courseData?.instructer?.map((item) => (
              <div className="instructor-container ">
                <h3 className="instructor-heading d-block d-md-none">
                  <Skeleton
                    variant="rounded"
                    sx={{ borderRadius: "10px", margin: "5px auto" }}
                    width="60%"
                    height={40}
                  />
                </h3>
                <div
                  className="instructor-image d-none d-md-block"
                  style={{ padding: "40px 80px", marginLeft: "20px" }}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ borderRadius: "10px", margin: "5px 0" }}
                    width="80%"
                    height={500}
                  />
                </div>
                <div
                  className="instructor-image d-block d-md-none"
                  style={{ padding: "10px" }}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ borderRadius: "10px", margin: "5px 0" }}
                    width="100%"
                    height={425}
                  />
                </div>
                <div className="instructor-data">
                  <div className="instructor-namee">
                    <h3 className="d-none d-md-block">
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "5px 0" }}
                        width="50%"
                        height={50}
                      />
                    </h3>
                    <div style={{ marginTop: "-30px" }}>
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "5px 0" }}
                        width="50%"
                        height={50}
                        className="d-none d-md-block"
                      />
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "5px 0" }}
                        width="75%"
                        height={40}
                        className="d-block d-md-none"
                      />
                    </div>
                  </div>
                  <div>
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="40%"
                      height={30}
                      className="d-none d-md-block"
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="50%"
                      height={30}
                      className="d-block d-md-none"
                    />
                  </div>
                  <div style={{ marginTop: "-10px" }}>
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="40%"
                      height={30}
                      className="d-none d-md-block"
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="50%"
                      height={30}
                      className="d-block d-md-none"
                    />
                  </div>
                  <div
                    className="instructor-experience"
                    style={{ marginTop: "-10px" }}
                  >
                    <p>
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "5px 0" }}
                        width="40%"
                        height={30}
                        className="d-none d-md-block"
                      />
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "5px 0" }}
                        width="50%"
                        height={30}
                        className="d-block d-md-none"
                      />
                    </p>
                  </div>
                  <div
                    className="instructor-languages"
                    style={{ marginTop: "-30px" }}
                  >
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="40%"
                      height={30}
                      className="d-none d-md-block"
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="50%"
                      height={30}
                      className="d-block d-md-none"
                    />
                  </div>
                  <div
                    className="instructor-desc"
                    style={{ marginTop: "-5px" }}
                  >
                    <p>
                      <Skeleton
                        variant="text"
                        sx={{ borderRadius: "10px", margin: "5px 0" }}
                        width="100%"
                        height={30}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ borderRadius: "10px", margin: "5px 0" }}
                        width="100%"
                        height={30}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ borderRadius: "10px", margin: "5px 0" }}
                        width="100%"
                        height={30}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ borderRadius: "10px", margin: "5px 0" }}
                        width="100%"
                        height={30}
                      />
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </section>
      ) : (
        courseData?.instructer.length > 0 && (
          <section className="instructor-section" id="instructor">
            <div></div>
            <Swiper
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 1,
                },
              }}
              spaceBetween={30}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {courseData?.instructer?.map((item) => (
                <SwiperSlide>
                  <div className="instructor-container ">
                    <h3 className="instructor-heading d-block d-md-none">
                      Meet your{" "}
                      <span style={{ color: "#73589B" }}>Instructor</span>
                    </h3>
                    <div className="instructor-image">
                      <img src={item?.image} loading="lazy" alt="" />
                    </div>
                    <div className="instructor-data">
                      <div className="instructor-namee">
                        <h3 className="instructor-heading d-none d-md-block">
                          Meet your{" "}
                          <span style={{ color: "#73589B" }}>Instructor</span>
                        </h3>
                        <div className="d-flex gap-2">
                          <h5 className="instructor-name">{item?.name} </h5>
                          <img
                            src={item?.flag_url}
                            width={25}
                            height={25}
                            alt="flag"
                            style={{ borderRadius: "100%" }}
                          />
                        </div>
                      </div>
                      <div className="instructor-expertise">
                        <p>{item?.type}</p>
                      </div>
                      {/* <div className="instructor-rating">
                        <p>{item?.rating}</p>
                        <Rating
                          initialRating={item?.rating} //
                          readonly
                          fullSymbol={
                            <img
                              src="/assets/img/course_new/results-star.png"
                              alt=""
                              loading="lazy"
                            />
                          }
                          style={{ fontSize: "30px" }}
                        />
                      </div> */}
                      <div className="instructor-experience">
                        <p>{item?.experience}</p>
                      </div>
                      <div className="instructor-languages">
                        <p>
                          <span style={{ fontWeight: "600" }}>Speaks </span>:{" "}
                          {item?.languages}
                        </p>
                      </div>
                      <div className="instructor-desc">
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        )
      )}

      {/* proven results */}
      {loading ? (
        <section
          id="results"
          className="results-section"
          style={{ margin: "20px 0" }}
        >
          <div className="results-container">
            <h1 className="results-heading">
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px", margin: "5px 0" }}
                width="20vw"
                height={60}
                className="d-none d-md-block"
              />
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "10px", margin: "5px 0" }}
                width="50vw"
                height={50}
                className="d-block d-md-none mx-auto"
              />
            </h1>
            <div
              className="d-md-flex flex-row justify-content-between d-none"
              style={{ padding: "40px 80px" }}
            >
              {Array.from(new Array(4)).map((_, index) => (
                <div>
                  <Skeleton
                    variant="rounded"
                    sx={{ borderRadius: "10px", margin: "5px 0" }}
                    width="10vw"
                    height={40}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ borderRadius: "10px", margin: "10px 0" }}
                    width="20vw"
                    height={80}
                  />
                </div>
              ))}
            </div>
            <div className="d-block d-md-none">
              <Grid container spacing={2}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "15px",
                    width: "100%",
                    margin: "20px",
                  }}
                >
                  {Array.from(new Array(4)).map((_, index) => (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={3}
                      key={index}
                      sx={{ minWidth: "100%", margin: "20px 0" }}
                    >
                      <div>
                        <Skeleton
                          variant="rounded"
                          sx={{ borderRadius: "10px", margin: "5px 0" }}
                          width="25vw"
                          height={30}
                        />
                        <Skeleton
                          variant="rounded"
                          sx={{ borderRadius: "10px", margin: "5px 0" }}
                          width="35vw"
                          height={80}
                        />
                      </div>
                    </Grid>
                  ))}
                </div>
              </Grid>
            </div>
          </div>
        </section>
      ) : (
        <section className="results-section" id="results">
          <div className="results-container">
            <h1 className="results-heading">
              <span style={{ color: "#73589B" }}>Proven</span> Results{" "}
            </h1>
            <div className="results-list">
              {courseData?.proven_results &&
                courseData?.proven_results?.map((item, index) => (
                  <div className="results-list-items">
                    <p
                      className="results-list-heading"
                      style={{
                        fontFamily: "Roboto",
                        color:
                          (item?.id === 6 && "#20AF7C") ||
                          (item?.id === 7 && "#CE0058") ||
                          (item?.id === 8 && "#541CF5"),
                      }}
                    >
                      {item?.percentage}
                    </p>
                    <p className="results-list-desc">{item?.description}</p>
                  </div>
                ))}

              <div className="results-list-items">
                <div className="d-flex align-items-center gap-2">
                  <p
                    className="results-list-heading"
                    style={{ fontFamily: "Roboto", color: "#A601A9" }}
                  >
                    4.7
                  </p>
                  <img
                    src="/assets/img/course_new/results-star.png"
                    className="img-star"
                    style={{ marginTop: "0" }}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="mt-3">
                  <img
                    src="/assets/img/course_new/google_rating.png"
                    className="img-google"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <SubscriptionAlertDialougBox
        description="Thank you for showing interest in our program! It is currently available exclusively for our Enterprise users. If your employer is already partnered with us, you may have access through your company's plan—please check with your HR department. We'll notify you as soon as it becomes available for individual users like you."
        courseData={courseData}
        userId={currentUser?.UserId}
        isOpen={openPaymetAlert}
        setOpenPaymetAlert={(item) => setOpenPaymetAlert(item)}
        setIsButtonClicked={(item) => setIsButtonClicked(item)}
      />
    </section>
  );
};

export default WhatToExpect;
