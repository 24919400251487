import React, { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getExpertRating } from "./core/_request";
import { Skeleton } from "@mui/material";

const DetailAboutTab = (props) => {
  const { data, loading } = props;
  const [readMoreAboutMeText, setReadMoreAboutMeText] = useState();

  var LanguagesArray = data?.Languages?.split(",");
  var userReview =
    data?.ratingData != undefined && data?.ratingData.length > 0
      ? data?.ratingData[0]
      : null;

  return (
    <div>
      {(readMoreAboutMeText && (
        <p className="font-inter fs-6 lh-base align-text-justify my-3">
          {data?.AboutYourProfessionalExperience &&
            data?.AboutYourProfessionalExperience?.length > 200 ? (
            <>
              <span className="font-inter fs-6" style={{ color: "#494747" }}>
                {data?.AboutYourProfessionalExperience}
              </span>
            </>
          ) : (
            data?.AboutYourProfessionalExperience
          )}
        </p>
      )) || (
          <p className="font-inter fs-6 lh-base align-text-justify">
            {(!loading &&
              (data?.AboutYourProfessionalExperience &&
                data?.AboutYourProfessionalExperience.length > 300 ? (
                <>
                  <span className="font-inter fs-6" style={{ color: "#494747" }}>
                    {data?.AboutYourProfessionalExperience?.toString()?.slice(
                      0,
                      300
                    )}{" "}
                  </span>
                  <button
                    className="load-more border-0 py-1 font-w500"
                    style={{ background: "none" }}
                    onClick={() => setReadMoreAboutMeText(true)}
                  >
                    Load More...
                  </button>
                </>
              ) : (
                data?.AboutYourProfessionalExperience
              ))) || (
                <div>
                  <Skeleton variant="text" width="90%" />
                  <Skeleton variant="text" width="90%" />
                  <Skeleton variant="text" width="90%" />
                </div>
              )}
          </p>
        )}
      <p className="font-inter fs-5 font-w500 my-3 text-black">
        Speaks
        <br />
        {(!loading &&
          data?.Languages &&

          <span
            className="font-inter fs-6 lh-base font-w400"
            style={{ color: "#494747" }}
          >
            {data?.Languages.replace(" ", ", ")}

          </span>
        ) || (
            <div className="d-flex flex-row gap-1">
              <Skeleton variant="text" width="10%" />
              <Skeleton variant="text" width="10%" />
              <Skeleton variant="text" width="10%" />
            </div>
          )}
      </p>
      <br />

      {data?.ratingData >= 3 ? (
        <div className="my-3">
          <p className="font-inter fs-3 font-w500 text-black d-flex align-items-center gap-3">
            <img src="/assets/img/star__icon.png" alt="" />
            {data?.Rating}
            <span
              className="font-inter fs-6 font-w400"
              style={{ color: "#494747" }}
            >
              {" "}
              ({data?.ratingData?.length} reviews)
            </span>
          </p>
          <div className="d-flex align-items-center gap-3 my-3">
            <img src="/assets/img/Ellipse 1.png" alt="" />
            <p className="font-inter fs-6 font-w500 text-black text-capitalize lh-sm">
              {userReview?.UserName} <br />
              <span
                className="font-inter fs-6 font-w400"
                style={{ color: "#494747" }}
              >
                {userReview?.UserCountry}
              </span>
            </p>
          </div>
          <p className="font-inter fs-6">
            {userReview?.Message}
          </p>
          <br />
          {data?.ratingData?.length >= 1 ? (
            <Link
              onClick={() => props.changeTab("reviews")}
              className="d-flex align-items-center text-decoration-none gap-3"
            >
              <p
                className="font-inter font-w400 fs-6"
                style={{ color: "#494747" }}
              >
                All Reviews ({data?.ratingData?.length}){" "}
              </p>
              <img src="/assets/img/right_arrow_black.png" alt="" />
            </Link>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default DetailAboutTab;
