import { useEffect } from "react";
import UpcomingAppointment from "./component/UpcomingAppointment";
import Inbox from "./component/Inbox";
import CalendarDays from "./component/CalendarDays";
import ExpertNotificationsDB from "./ExpertNotificationsDB";

import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";

const ExpertDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   // Check if user is logged in
  //   const isLoggedIn = isUserLoggedIn();

  //   if (!isLoggedIn) {
  //     window.location.href = "https://www.unitedwecare.com/";
  //   }
  // }, [location, navigate]);

  return (
    <div>
      <div className="my-3 d-flex gap-2">
        <div className="col-8 d-flex flex-column gap-3">
          <div>
            <UpcomingAppointment />
          </div>
          <div className="bg-white rounded-3 px-3">
            <ExpertNotificationsDB mainPage={true} />
          </div>
        </div>
        <div className="col-4 d-flex flex-column gap-3">
          <Inbox />
          <CalendarDays />
        </div>
      </div>
    </div>
  );
};

export default ExpertDashboard;
