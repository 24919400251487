import React, { useEffect, useState } from "react";
import { useAuth } from "../../../auth";
import Button from "@mui/material/Button";
import Post from "./Post";
import Blogs from "./Blogs";
import Video from "./Video";
import {
  getPostsOfSpecificUser,
  getProfileData,
  reportUser,
} from "../_request";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { useSnackbar } from "notistack";

const tabs = [
  {
    tabId: 0,
    name: "Post",
    keyword: "post",
  },
  {
    tabId: 1,
    name: "Blogs",
    keyword: "article",
  },
  {
    tabId: 2,
    name: "Videos",
    keyword: "video",
  },
  {
    tabId: 3,
    name: "Podcasts",
    keyword: "audio",
  },
];

const Profile = ({ prfileUserId }) => {
  const [select, setSelect] = useState(0);
  const [postData, setPostData] = useState(null);
  const [profileData, setProfileData] = useState();
  const { currentUser } = useAuth();
  const currentUserId = currentUser?.UserId;
  const [postType, setPostType] = useState("post");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getProfileData(prfileUserId).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setProfileData(resp?.data);
      } else {
        console.log(resp.ResponseMessage);
      }
    });

    getPostsOfSpecificUser({ currentUserId, prfileUserId, postType }).then(
      (resp) => {
        if (resp?.ResponseCode === 200) {
          setPostData(resp?.data);
        } else {
          console.log(resp.ResponseMessage);
        }
      }
    );
  }, []);

  const onTabChange = (item) => {
    setPostData(null);
    setPostType(item?.keyword);
    setSelect(item?.tabId);
    getPostsOfSpecificUser({
      currentUserId,
      prfileUserId,
      postType: item?.keyword,
    }).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setPostData(resp?.data);
      } else {
        console.log(resp.ResponseMessage);
      }
    });
  };

  const handleReportPost = () => {
    const body = {
      userId: currentUserId,
      reportedUserId: prfileUserId,
    };
    reportUser(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        enqueueSnackbar("User Reported.", { variant: "success" });
        window.location.reload();
      } else {
        console.log(resp.ResponseMessage);
      }
    });
  };

  return (
    <div className="ProfileDetail--section">
      <div>
        <div className="module-container d-flex flex-row p-md-4 p-2">
          <div className="col-12 pe-md-3">
            <div className="d-flex w-100">
              <div className="w-25">
                <div className="profileImgContainer">
                  <img src={profileData?.user?.image} alt="" />
                </div>
              </div>
              <div className="profileDetail d-flex py-3 justify-content-between w-75">
                <h6>{profileData?.user?.name}</h6>
                {currentUserId !== profileData?.user?.name && (
                  <div>
                    <Button
                      variant="outlined"
                      className="reportBtn"
                      onClick={handleReportPost}
                    >
                      <ReportGmailerrorredIcon sx={{ marginRight: "5px" }} />{" "}
                      {"  "} Report
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="tabs-section d-flex align-items-center gap-3 my-5 overflow-auto">
              {tabs?.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    select == item?.tabId
                      ? "tabs-container-active"
                      : "tabs-container"
                  }`}
                  onClick={() => onTabChange(item, index)}
                >
                  {item?.name}
                </div>
              ))}
            </div>
            {select === 0 && (
              <div className="d-flex flex-column">
                {postData?.feed ? (
                  postData?.feed?.map((item, index) => (
                    <div key={index} className="my-3 py-md-3 col-12 col-md-9">
                      <Post item={item} />
                    </div>
                  ))
                ) : (
                  <div>The user has not posted anything.</div>
                )}
              </div>
            )}
            {select === 1 && (
              <div className="row">
                {postData?.feed ? (
                  postData?.feed?.map((item, index) => (
                    <div className="col-3 p-3">
                      <Blogs item={item?.data} />
                    </div>
                  ))
                ) : (
                  <div>The user has not posted anything.</div>
                )}
              </div>
            )}
            {select === 2 && (
              <div className="row">
                {postData?.feed ? (
                  postData?.feed?.map((item, index) => (
                    <div className="col-3 p-3">
                      <Video item={item?.data} />
                    </div>
                  ))
                ) : (
                  <div>The user has not posted anything.</div>
                )}
              </div>
            )}
            {select === 3 && (
              <div className="row">
                {postData?.feed ? (
                  postData?.feed?.map((item, index) => (
                    <div className="col-3 p-3">
                      <Video item={item?.data} />
                    </div>
                  ))
                ) : (
                  <div>The user has not posted anything.</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
