import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { saveAs } from "file-saver";

//Firebase
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  getStorage,
} from "firebase/storage";

const PrescriptionTabItem = ({ title, note, linkedFile, date }) => {
  const storage = getStorage();

  const handleDownload = async () => {
    fetch(linkedFile)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "myfile.png";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };
  return (
    <div className="PrescriptionItem">
      <div className="d-flex align-items-center gap-2 justify-content-between">
        <h6>{title}</h6>

        <a download href={linkedFile} target="_blank">
          <VisibilityOutlinedIcon sx={{ color: "#73589b " }} />
        </a>
      </div>

      <span>{date}</span>
      <p>{note}</p>
    </div>
  );
};

export default PrescriptionTabItem;
