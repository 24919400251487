import React, { useContext, useEffect, useState } from "react";
import DashboardCards from "./DashboardCards";
import Appointments from "../Dashboard/Appointments";
import RecommendedPrograms from "./RecommendedPrograms";
import ProfessionalHelp from "./ProfessionalHelp";
import Sidebar from "../Dashboard/Sidebar";
import { FeedbackFormCall, getDisclaimerUpdate } from "../../../core/_request";
import OnFeedback from "../../../user/OnFeedback";
import { useAuth } from "../../../../auth";
import { AppContext } from "../../../../context";
import DisclaimerModal from "../../../DisclaimerModal";
import { Helmet } from "react-helmet";

function Main() {
  const [showSimpleModal, setShowSimpleModal] = useState(false);
  const [appointmentId, setAppointmentId] = useState();
  const handleSimpleModal = () => setShowSimpleModal(true);
  const { currentUser } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [disclaimerData, setDisclaimerData] = useState();

  useEffect(() => {
    const body = {
      user_type: "user",
      user_id: currentUser?.UserId,
      current_time: getCurrentTime(),
    };

    FeedbackFormCall(body).then((resp) => {
      if (resp.ResponseCode === 200) {
        setAppointmentId(resp?.data?.appointment_id);
        if (resp?.data?.appointment_id && !resp?.data?.show_popup) {
          handleSimpleModal();
        }
      }
    });
  }, []);

  // useEffect(() => {
  //   if (!currentUser) {
  //     // setShowLloginPopup(true);
  //     const currentUrl = encodeURIComponent(window.location.href);
  //     window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
  //   }
  // }, []);

  useEffect(() => {
    let body = {
      user_id: currentUser?.UserId,
    };
    getDisclaimerUpdate(body).then((resp) => {
      //console.log(resp?.data);
      if (resp?.data?.updated == false) {
        setDisclaimerData(resp?.data?.disclaimer);
        //console.log("resp?.dataresp?.dataresp?.data", resp?.data);
        setModalShow(true);
      }
    });
  }, []);

  const getCurrentTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <Helmet>
        <title>Your Personal Wellness Dashboard | United We Care</title>
      </Helmet>
      <OnFeedback
        handleClose={() => setShowSimpleModal(false)}
        show={showSimpleModal}
        appointmentId={appointmentId}
        feedBackStatusBool={true}
      />
      <main>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2 d-none d-lg-inline">
                <Sidebar />
              </div>

              <div
                className="col-lg-10 px-lg-5 py-md-3 ps-md-3 pt-md-3 pb-0 m-0"
                style={{
                  backgroundColor: "#F4F4F4",
                }}
              >
                <DashboardCards />
                <Appointments />
                <RecommendedPrograms />
                <ProfessionalHelp />
              </div>
            </div>
          </div>
          <DisclaimerModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            disclaimerData={disclaimerData}
          />
        </section>
      </main>
    </>
  );
}

export default Main;
