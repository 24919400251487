import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../styles/Search.css'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IconButton, InputAdornment } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';

const options = [
    {
        value: "all",
        label: 'All Resources',
        icon: <AppsSharpIcon />
    },
    {
        value: "expert",
        label: "Experts",
        icon: <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.98545 16.7763L9.62373 17.1326L9.98545 17.4888L12.4276 19.8939H3.10938V18.2197C3.10938 17.3137 3.86864 16.355 5.41602 15.5931C6.92838 14.8483 9.05184 14.3731 11.4259 14.3712L12.3725 14.4255L9.98545 16.7763ZM11.4401 3.5C12.481 3.5 13.4777 3.90729 14.2114 4.62988C14.9449 5.35222 15.3555 6.33029 15.3555 7.34848C15.3555 8.36668 14.9449 9.34475 14.2114 10.0671C13.4777 10.7897 12.481 11.197 11.4401 11.197C10.3993 11.197 9.40255 10.7897 8.66884 10.0671C7.93539 9.34475 7.52476 8.36668 7.52476 7.34848C7.52476 6.33029 7.93539 5.35222 8.66884 4.62988C9.40255 3.90729 10.3993 3.5 11.4401 3.5Z" stroke="#4C4C4C" />
            <path d="M12.4746 16.9018L14.9066 19.3337C15.2971 19.7242 15.9303 19.7242 16.3208 19.3337L21.8918 13.7627" stroke="#4C4C4C" stroke-width="1.4" />
        </svg>
    },
    {
        value: "video",
        label: "Videos",
        icon: <VideocamOutlinedIcon />
    },
    // {
    //     value: "blog",
    //     label: "Blogs",
    //     icon: <ArticleOutlinedIcon />
    // },
    {
        value: "podcast",
        label: "Podcasts",
        icon: <HeadphonesIcon />
    },
    {
        value: "program",
        label: "Program",
        icon: <SchoolIcon />
    },
]

const SearchHeader = ({ setInputValue, inputValues, setSearchResultText, selectOption, setSelectOption, fetchSearchData, setSearchQuery, searchQuery }) => {
    const [suggestedData, setSuggestedData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [searched, setSearched] = useState(false);
    // const [popularSearch, setPopularSearch] = useState();
    const [category, setCategory] = useState('all');
    const navigate = useNavigate();

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(`https://ms.uwc.world/wellness/popular-searches?count=5`);
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             const jsonData = await response.json();
    //             setPopularSearch(jsonData.data);
    //         } catch (error) {
    //             setError(error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };
    //     fetchData();
    // }, []);

    const handleInputChange = (newValue) => {
        setInputValue(newValue);
        if (newValue?.length > 0) {
            // setSearched(true);
            setInputValue(newValue)
            // setSearchQuery(newValue)
            fetchSuggestedData(newValue); // Fetch new suggestions based on the newValue
        } else {
            // Maybe clear suggestions or handle empty input state
        }
    };

    const fetchSuggestedData = async (searchText) => {
        try {
            const response = await fetch(`https://ms.uwc.world/wellness/search-suggestion?search_str=${searchText}&country=India`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setSuggestedData(jsonData?.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // const handleClearInput = () => {
    //     setInputValue('');
    //     setSearchResultText('');
    // };

    const handleSearch = () => {
        if (inputValues.length > 0) {
            const encodedQuery = encodeURIComponent(inputValues.trim()); // Encode and trim whitespace
            setSearchResultText(inputValues);
            setInputValue(encodedQuery)
            fetchSearchData();
            setSearchQuery(inputValues);
            navigate(`/Search?query=${encodedQuery}`);
        } else {
            alert("Please enter a search term.")
        }
    }

    // const handleOptionChange = (option) => {
    //     setSelectOption(option);
    // }

    const handleSuggestedResultClick = (value) => {
        setSearchResultText(value);
        setInputValue(value);
        fetchSearchData();
        navigate(`/Search?query=${value}`);
        // setSearched(false);
    }

    // const onClickPopularSearch = (item) => {
    //     setSearchResultText(item);

    //     setInputValue(item);
    //     fetchData();
    //     navigate(`/Search?query=${item}`);
    // }

    const handleSearchOptionClick = (item) => {
        setSelectOption(item);
        setCategory(item)
        // setSearchResultText(item);
        // setInputValue(item);
        // fetchData();
        // navigate(`/Search?query=${item}`);
    };

    return (
        <div className='Search--header-container d-flex flex-column align-items-center'>
            <div className='header--div '>
                <div className='d-flex align-items-center gap-3 justify-content-center'>
                    <h3>Showing {selectOption === 'all' ? 'All Results' : selectOption.charAt(0).toUpperCase() + selectOption.slice(1) + 's'} for "{searchQuery}"</h3>
                </div>
                {/* <h3 className='mt-2'>Find Reliable Mental Health Resources</h3> */}
                <p className='mt-2'>Clinically vetted resources on a range of mental health topics to help you cope with your challenges better.</p>
                {/* <div className='d-flex align-items-center my-3 w-100 search--inputDiv'>
                    <input
                        placeholder='Search...'
                        type="text"
                        className="input-field w-100"
                        value={inputValue}
                        onChange={handleInputChange}
                    />
                    {inputValue && (
                        <div className='clearAll px-3' onClick={handleClearInput}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVG">
                                    <path id="Vector" d="M18 6L6 18" stroke="#4E6137" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path id="Vector_2" d="M6 6L18 18" stroke="#4E6137" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                            </svg>
                        </div>
                    )}
                    <div className='search--IconDiv' onClick={handleSearch}><SearchIcon /></div>
                </div>
                {searched && (
                    <div className='d-flex align-items-center w-100 search--suggestedResultDiv'>
                        <ul className='py-2 px-3 mx-2 rounded-3'>
                            {suggestedData?.results?.map((result, index) => (
                                <li className='py-1' key={index} onClick={() => handleSuggestedResultClick(result?.text)}>
                                    {result?.text}
                                </li>
                            ))}
                        </ul>
                    </div>
                )} */}
                <div className='d-flex align-items-center p-0 my-3 w-100 search--inputDiv'>
                    <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        sx={{ background: '#fff', borderRadius: "10px", width: '100%', border: 'none' }}
                        // inputValue={inputValue}
                        onInputChange={(event, newValue) => handleInputChange(newValue)} // Adjust this line
                        options={suggestedData?.results?.map((option) => option?.text) || []} // Ensure options is always an array
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ background: '#fff', borderRadius: 10, border: 'none' }}
                                // label="Search"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="search"
                                                onClick={handleSearch}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                        onChange={(event, newValue) => {
                            handleSuggestedResultClick(newValue); // Ensure this function can handle null newValue
                        }} // This handles selection from the dropdown
                    />
                </div>

                <div>
                    <div className='scrollable-options d-flex align-items-center justify-content-md-center gap-2 p-2 mt-4'>
                        {/* <div value={'all'} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} className={`options--pill ${category === "all" ? 'selected-option' : ''}`} onClick={() => handleSearchOptionClick('all')}>
                            <AppsSharpIcon />
                            All Resources
                        </div> */}
                        {options?.map((option) => (
                            <div key={option.value} value={option.value} style={{ cursor: 'pointer' }} className={`options--pill  ${category === option.value ? 'selected-option' : ''}`} onClick={() => handleSearchOptionClick(option.value)}>
                                {option?.icon} {option.label}
                            </div>
                        ))}
                    </div>
                    {/* <div className='popular--searchContainer scrollable-options d-flex gap-3' style={{ cursor: 'pointer' }}>
                            {popularSearch?.results?.map((item, index) =>
                                <div key={index} className='popularDiv' onClick={() => onClickPopularSearch(item)}>
                                    {item}
                                </div>
                            )}  
                        </div> */}
                </div>
            </div>
        </div>
    )
}

export default SearchHeader