import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const CancelSubscriptionNote = () => {
    const navigate = useNavigate();

    const GotoDashboard = () =>{
        navigate('')
    }

    return (
        <Container>
            <div className='d-flex flex-column align-items-center my-5 gap-3'>
                <img src='/assets/img/rafiki1.png' alt='' className='my-2' />
                <h6 className='font-inter fs-5 font-w700 text-center px-lg-5 lh-base'>
                    Thank you for giving us the opportunity to serve you. We'd love to have you back in future. Wishing you a Happy Mental Health
                </h6>
                <p className='font-inter fs-6 text-center'>You will continue to avail full benefits of the plan till the end of this billing cycle.</p>
                <Button
                    className='font-inter text-black rounded-5 px-4 py-3 border-0 my-5'
                    onClick={GotoDashboard}
                    style={{ background: "#9FE770" }}>
                    Take Me To Dashboard
                </Button>
            </div>
        </Container>
    )
}

export default CancelSubscriptionNote