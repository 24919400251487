import React, { useContext } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../../styles/courses/Hero.css";
import { useState } from "react";
import Iframe from "react-iframe";
import { useAuth } from "../../auth";
import { AppContext } from "../../context";
import {
  OODOCourseAccessRequestService,
  OODOCoursePaymentService,
} from "../core/_request";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Skeleton } from "@mui/material";
import SubscriptionAlertDialougBox from "./SubscriptionAlertDialougBox";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Hero = (props) => {
  const { courseData, isPaid, loading } = props;
  const [readmore, setReadmore] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  function readmoreHandler() {
    setReadmore(!readmore);
  }

  const { name, id } = useParams();
  const info = courseData?.course_description;
  const [openPaymetAlert, setOpenPaymetAlert] = useState(false);
  const description = readmore ? info : `${info?.substring(0, 200)} `;
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const onCLickCopy = async () => {
    try {
      await navigator?.clipboard?.writeText(
        props?.courseData?.course_url.replace(
          "us.unitedwecare.com",
          "my.unitedwecare.com"
        )
      );
      setOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onClickWhatsApp = () => {
    window.open(
      encodeURI(
        `whatsapp://send?text=${props?.courseData?.course_url.replace(
          "us.unitedwecare.com",
          "my.unitedwecare.com"
        )}`
      )
    );
  };

  const onClickWhatsAppOnWeb = () => {
    window.open(
      encodeURI(
        `https://wa.me?text=${props?.courseData?.course_url.replace(
          "us.unitedwecare.com",
          "my.unitedwecare.com"
        )}`
      )
    );
  };

  const handleStartSubscription = () => {
    setIsButtonClicked(true);
    if (!currentUser) {
      localStorage.setItem(
        "retrunurl",
        `/program/${props?.courseData?.course_title}/${props?.courseId}`
      );
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    } else {
      if (courseData?.enrolled) {
        navigate(
          `/program-internal/${courseData?.course_data_id}/${courseData?.course_title}`
        );
        let linksData = [
          {
            name: "Home",
            uri: `/program-internal/${courseData?.course_data_id}/${courseData?.course_title}`,
          },
          {
            name: "My Journal",
            uri: `/program-internal/module/my-journal/:id`,
          },
        ];
        sessionStorage.setItem(
          "courseInternalLinks",
          JSON.stringify(linksData)
        );
      } else if (
        courseData?.inr_price === 0.0 ||
        courseData?.course_type === "free"
      ) {
        let request = {
          userId: currentUser?.UserId,
          courseId: props?.courseId,
        };
        OODOCoursePaymentService(request).then((res) => {
          navigate(
            `/program-internal/${res?.data?.data?.id}/${courseData?.course_title}`
          );
          let linksData = [
            {
              name: "Home",
              uri: `/program-internal/${res?.data?.data?.id}/${courseData?.course_title}`,
            },
            {
              name: "My Journal",
              uri: `/program-internal/module/my-journal/:id`,
            },
          ];
          sessionStorage.setItem(
            "courseInternalLinks",
            JSON.stringify(linksData)
          );
        });
      } else if (courseData?.course_type === "premium") {
        // localStorage.setItem(
        //   "retrunurl",
        //   `/program/${props?.courseData?.course_title}/${props?.courseId}`
        // );
        // navigate("/plans");

        setOpenPaymetAlert(true);
      }
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Share on WhatsApp
    </Tooltip>
  );

  const renderCopyTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Copy the Link
    </Tooltip>
  );

  return (
    <>
      <section className="course--hero">
        {loading ? (
          <>
            <div
              style={{
                width: "50%",
                height: "6.25rem",
                position: "absolute",
                bottom: "0",
                left: "40%",
                transform: "translate(-50%, 52%)",
              }}
            >
              <Skeleton
                variant="rounded"
                width="80vw"
                height={100}
                sx={{ borderRadius: "15px" }}
                className="d-none d-md-block"
              />
            </div>

            <div
              style={{
                width: "50%",
                height: "6.25rem",
                position: "absolute",
                bottom: "0",
                transform: "translate(-50%, 52%)",
              }}
            >
              <Skeleton
                variant="rounded"
                width="100vw"
                height={80}
                sx={{ borderRadius: "15px", marginTop: "15px" }}
                className="d-block d-md-none"
              />
            </div>
          </>
        ) : (
          courseData?.features && (
            <div className="hero-whiteLayout">
              {courseData?.features?.map((item) => (
                <div className="hero-layout-items">
                  <img src={item?.icon} alt="" loading="lazy" />
                  <span className="hero-layout-text anim1">{item?.name}</span>
                </div>
              ))}
            </div>
          )
        )}

        <div className="course--hero__left">
          {loading ? (
            <div className="hero-side-buttons">
              <div className="hero-side-buttons__container">
                <div className="hero-wp-btn d-none d-md-block">
                  <Skeleton
                    variant="rounded"
                    width="3vw"
                    height={50}
                    sx={{ borderRadius: "15px" }}
                  />
                </div>
                <div
                  className="hero-wp-btn d-block d-md-none"
                  onClick={onClickWhatsApp}
                >
                  <Skeleton
                    variant="rounded"
                    width="3vw"
                    height={50}
                    sx={{ borderRadius: "15px" }}
                  />
                </div>
                <div className="hero-wp-btn" onClick={onCLickCopy}>
                  <Skeleton
                    variant="rounded"
                    width="3vw"
                    height={50}
                    sx={{ borderRadius: "15px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="hero-side-buttons">
              <div className="hero-side-buttons__container">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <div
                    className="hero-wp-btn d-none d-md-block"
                    onClick={onClickWhatsAppOnWeb}
                  >
                    <img
                      src="/assets/img/course_new/wp_btn.png"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                </OverlayTrigger>
                <div
                  className="hero-wp-btn d-block d-md-none"
                  onClick={onClickWhatsApp}
                >
                  <img
                    src="/assets/img/course_new/wp_btn.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderCopyTooltip}
                >
                  <div className="hero-wp-btn" onClick={onCLickCopy}>
                    <img
                      src="/assets/img/course_new/copy_btn.png"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          )}
          {loading ? (
            <>
              <div className="course--hero__content d-md-flex flex-column gap-3 d-none">
                <div className="hero-blueHeading">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={40}
                    sx={{ borderRadius: "10px" }}
                  />
                </div>
                <h1 className="hero-heading">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={50}
                    sx={{ borderRadius: "10px" }}
                  />
                </h1>
                <div className="d-flex align-items-center gap-2">
                  <Skeleton
                    variant="rounded"
                    width="5vw"
                    height={50}
                    sx={{ borderRadius: "10px" }}
                  />
                  <div
                    className="d-flex d-md-none align-items-center justify-content-center"
                    style={{
                      border: "1px solid #D0D5DD",
                      height: "35px",
                      width: "35px",
                      cursor: "pointer",
                      background: "#FFF",
                      borderRadius: "8px",
                    }}
                  >
                    <Skeleton
                      variant="rounded"
                      width="1vw"
                      sx={{ borderRadius: "10px" }}
                    />
                  </div>

                  <button
                    className="d-flex d-md-none align-items-center gap-1 justify-content-center px-2 py-1 text-decoration-none"
                    style={{
                      border: "1px solid #D0D5DD",
                      background: "#FFF",
                      borderRadius: "8px",
                      height: "35px",
                      width: "35px",
                    }}
                  >
                    <Skeleton
                      variant="rounded"
                      width="1vw"
                      sx={{ borderRadius: "10px" }}
                    />
                  </button>
                </div>

                <div className="hero-info">
                  <div className="hero-enrollments">
                    <Skeleton
                      variant="rounded"
                      width="5vw"
                      height={50}
                      sx={{ borderRadius: "10px" }}
                    />
                  </div>
                  <div className="hero-rating">
                    <Skeleton
                      variant="rounded"
                      width="7vw"
                      height={50}
                      sx={{ borderRadius: "10px" }}
                    />
                  </div>
                </div>

                <div className="hero-desc">
                  <Skeleton
                    variant="text"
                    width="35vw"
                    height={40}
                    sx={{ borderRadius: "10px" }}
                  />
                  <Skeleton
                    variant="text"
                    width="35vw"
                    height={40}
                    sx={{ borderRadius: "10px" }}
                  />
                  <Skeleton
                    variant="text"
                    width="35vw"
                    height={40}
                    sx={{ borderRadius: "10px" }}
                  />
                </div>

                <Skeleton
                  variant="rounded"
                  width="50%"
                  height={50}
                  sx={{ borderRadius: "10px" }}
                />
              </div>

              <div className="d-flex d-md-none flex-column gap-2 mb-4">
                <h1 className="hero-heading p-0 m-0">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={40}
                    sx={{ borderRadius: "10px", padding: "0" }}
                  />
                </h1>
                <div className="d-flex align-items-center gap-2">
                  <Skeleton
                    variant="rounded"
                    width="15vw"
                    height={40}
                    sx={{ borderRadius: "10px" }}
                  />
                  <div>
                    <Skeleton
                      variant="rounded"
                      width="10vw"
                      height={40}
                      sx={{ borderRadius: "10px" }}
                    />
                  </div>

                  <div>
                    <Skeleton
                      variant="rounded"
                      width="10vw"
                      height={40}
                      sx={{ borderRadius: "10px" }}
                    />
                  </div>
                </div>

                <div className="hero-info">
                  <div className="hero-enrollments">
                    <Skeleton
                      variant="rounded"
                      width="15vw"
                      height={40}
                      sx={{ borderRadius: "10px" }}
                    />
                  </div>
                  <div className="hero-rating">
                    <Skeleton
                      variant="rounded"
                      width="15vw"
                      height={40}
                      sx={{ borderRadius: "10px" }}
                    />
                  </div>
                </div>

                <div className="hero-desc">
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={30}
                    sx={{ borderRadius: "10px" }}
                  />
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={30}
                    sx={{ borderRadius: "10px" }}
                  />
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={30}
                    sx={{ borderRadius: "10px" }}
                  />
                </div>

                <Skeleton
                  variant="rounded"
                  width="50%"
                  height={40}
                  sx={{ borderRadius: "10px", margin: "0 auto" }}
                />
              </div>
            </>
          ) : (
            <div className="course--hero__content">
              <div className="hero-blueHeading">
                Program {">"} {courseData?.club}
              </div>
              <h1 className="hero-heading">{courseData?.course_title}</h1>
              <div className="d-flex align-items-center gap-2">
                <div className="hero-selfPaced">
                  {courseData?.course_duration}
                </div>
                <div
                  className="d-flex d-md-none align-items-center justify-content-center"
                  style={{
                    border: "1px solid #D0D5DD",
                    height: "35px",
                    width: "35px",
                    cursor: "pointer",
                    background: "#FFF",
                    borderRadius: "8px",
                  }}
                >
                  <button
                    className=""
                    style={{
                      cursor: "pointer",
                      border: "none",
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                      background: "#FFF",
                    }}
                    onClick={onClickWhatsApp}
                  >
                    <img
                      src="/assets/img/whatsapp.png"
                      alt=""
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                </div>

                <button
                  onClick={onCLickCopy}
                  className="d-flex d-md-none  align-items-center gap-1 justify-content-center px-2 py-1 text-decoration-none"
                  style={{
                    border: "1px solid #D0D5DD",
                    background: "#FFF",
                    borderRadius: "8px",
                    height: "35px",
                    width: "35px",
                  }}
                >
                  <img src="/assets/img/copy.png" alt="" />
                  <p
                    className="font-inter font-w600 fs-6"
                    style={{ color: "#344054" }}
                  ></p>
                </button>
              </div>

              <div className="hero-info">
                <div className="hero-enrollments">
                  <img
                    src="/assets/img/course_new/bxs-badge-check.png"
                    width={31}
                    height={31}
                    alt=""
                    loading="lazy"
                  />
                  <span className="hero-infoText">
                    {courseData?.people_enrolled} enrollments
                  </span>
                </div>
                <div className="hero-rating">
                  <img
                    src="/assets/img/course_new/bxs-star.png"
                    alt=""
                    loading="lazy"
                  />
                  <span
                    className="hero-infoText"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {courseData?.course_rating} Ratings
                  </span>
                </div>
              </div>

              <div className="hero-desc">
                {description}
                <span className="hero-read-more" onClick={readmoreHandler}>
                  {readmore ? `Show Less` : `Read More`}
                </span>
              </div>
              <button
                className="hero-btn"
                style={{ justifyContent: "center" }}
                onClick={handleStartSubscription}
                disabled={isButtonClicked}
              >
                {isButtonClicked && <CircularProgress color="inherit" />}
                {props.isSubscribed
                  ? "Continue"
                  : isPaid
                    ? "Subscribe"
                    : "Start"}
              </button>
            </div>
          )}
        </div>

        <div className="course--hero__video">
          <div className="hero-blueHeading--mobile">
            {loading ? (
              <Skeleton
                variant="rounded"
                width="70%"
                height={40}
                sx={{ borderRadius: "10px", padding: "0" }}
              />
            ) : (
              <>
                Program{" "}
                <span>
                  {">"} {courseData?.club}
                </span>
              </>
            )}
          </div>
          {loading ? (
            <>
              <div
                style={{ padding: "300px 20px" }}
                className="d-none d-md-block"
              >
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="100%"
                  height={270}
                />
              </div>
              <div className="d-block d-md-none">
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "10px" }}
                  width="100%"
                  height={270}
                />
              </div>
            </>
          ) : (
            <Iframe
              url={courseData?.video?.video?.player_embed_url}
              controls={true}
              className="video"
            ></Iframe>
          )}
        </div>
      </section>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={action}
      >
        <Alert severity="success">Link copied successfully!</Alert>
      </Snackbar>

      <SubscriptionAlertDialougBox
        description="Thank you for showing interest in our program! It is currently available exclusively for our Enterprise users. If your employer is already partnered with us, you may have access through your company's plan—please check with your HR department. We'll notify you as soon as it becomes available for individual users like you."
        courseData={courseData}
        userId={currentUser?.UserId}
        isOpen={openPaymetAlert}
        setOpenPaymetAlert={(item) => setOpenPaymetAlert(item)}
        setIsButtonClicked={(item) => setIsButtonClicked(item)}
      />
    </>
  );
};

export default Hero;
