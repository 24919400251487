import { useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Link,
  Outlet,
  useLocation,
} from "react-router-dom";
import DashboardHeader from "./component/DashboardHeader";

//Icons
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import LockIcon from "@mui/icons-material/Lock";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import { useAuth } from "../../auth";

import { Helmet } from "react-helmet";
import { getToken } from "./APIS/clientAPIS";

// Mapping menu names to their icon components
const iconMap = {
  Dashboard: DashboardOutlinedIcon,
  Appointments: EventAvailableOutlinedIcon,
  "Session Management": EventAvailableIcon,
  Clients: GroupOutlinedIcon,
  Chat: ForumOutlinedIcon,
  "My Schedule": CalendarMonthOutlinedIcon,
  Worksheets: EventAvailableOutlinedIcon, // Assuming a generic icon for demonstration
  Articles: EventAvailableOutlinedIcon, // Assuming a generic icon for demonstration
  Posts: SettingsOutlinedIcon,
  Notifications: NotificationsOutlinedIcon,
  Communities: HeadsetMicOutlinedIcon,
  "Help Center": HeadsetMicOutlinedIcon,
  Payouts: PaymentOutlinedIcon,
  Settings: ManageAccountsOutlinedIcon,
};

const menus = [
  { name: "Dashboard", imageLink: "/assets/img/", link: "dashboard" },
  { name: "Appointments", imageLink: "/assets/img/", link: "appointments" },
  {
    name: "Session Management",
    imageLink: "/assets/img/",
    link: "sessionmanagement",
  },
  { name: "Clients", imageLink: "/assets/img/", link: "clients" },
  { name: "Chat", imageLink: "/assets/img/", link: "chat" },
  { name: "My Schedule", imageLink: "/assets/img/", link: "my-schedule" },
  { name: "Worksheets", imageLink: "/assets/img/", link: "worksheets" },
  { name: "Articles", imageLink: "/assets/img/", link: "articles" },
  { name: "Notifications", imageLink: "/assets/img/", link: "notifications" },
  { name: "Communities", imageLink: "/assets/img/", link: "communities" },
  { name: "Help Center", imageLink: "/assets/img/", link: "help-center" },
  { name: "Payouts", imageLink: "/assets/img/", link: "payouts" },
  { name: "Settings", imageLink: "/assets/img/", link: "settings" },
];

const menusFortis = [
  { name: "Dashboard", imageLink: "/assets/img/", link: "dashboard" },
  { name: "Appointments", imageLink: "/assets/img/", link: "appointments" },
  {
    name: "Session Management",
    imageLink: "/assets/img/",
    link: "sessionmanagement",
  },
  { name: "Clients", imageLink: "/assets/img/", link: "clients" },
  { name: "Chat", imageLink: "/assets/img/", link: "chat" },
  { name: "Worksheets", imageLink: "/assets/img/", link: "worksheets" },
  { name: "Articles", imageLink: "/assets/img/", link: "articles" },
  { name: "Notifications", imageLink: "/assets/img/", link: "notifications" },
  { name: "Communities", imageLink: "/assets/img/", link: "communities" },
  { name: "Help Center", imageLink: "/assets/img/", link: "help-center" },
  { name: "Settings", imageLink: "/assets/img/", link: "settings" },
];
const Dashboard = () => {
  const [isActive, setIsActive] = useState("");
  const [instituteID, setInstituteID] = useState(
    sessionStorage.getItem("InstituteID")
  );
  // const cookie_token = Cookies.get("authToken");
  
  const { currentUser, userType, logout } = useAuth(); 

  const location = useLocation();
  const currentUrl = location.pathname;
  let currentPathName =
    currentUrl?.split("/")[currentUrl.split("/").length - 1];

  useEffect(() => {
    const isInstitudeID = sessionStorage.getItem("InstituteID");
    if (isInstitudeID == null || isInstitudeID == undefined) {
      getToken(currentUser?.Emailid).then((res) => {
        setInstituteID(res.instituteID);
      });
    }
  }, []);

  useEffect(() => {
    let filteredData = menus.filter((item) => item.link == currentPathName);
    setIsActive(filteredData[0]?.name);
  }, [currentUrl]);
  const onClickMenu = (name) => {
    // debugger
    setIsActive(name);
  };
  return (
    <>
      <Helmet>
        <title>Expert Dashboard</title>
      </Helmet>
      <DashboardHeader />
      <Container
        fluid
        style={{ background: "#F5F5F5" }}
        className="ExpertDashboard"
      >
        <Row className="px-2">
          <Col md={2} className="px-0 ExpertDashboard--Col">
            <Nav className="flex-column gap-1 my-3 NavigationRail">
              {instituteID !== "93"
                ? menus.map((menu) => {
                    const IconComponent = iconMap[menu.name]; // Dynamically get the icon component
                    return (
                      <Nav.Link
                        key={menu.name}
                        as={Link}
                        to={menu.link}
                        onClick={() => onClickMenu(menu.name)}
                        className="d-flex align-items-center gap-6"
                        style={{
                          background:
                            isActive === menu.name
                              ? "var(--primary-color)"
                              : "",
                          borderRadius: "24px",
                          fontSize: "14px",
                          color: isActive === menu.name ? "white" : "black",
                        }}
                      >
                        <IconComponent
                          sx={{
                            color: isActive === menu.name ? "#FFF" : "#000",
                          }}
                        />
                        <p className="font-inter">{menu.name}</p>
                        {isActive === menu.name &&
                          (menu.name === "Communities" ||
                          menu.name === "Posts" ||
                          menu.name === "Articles" ||
                          menu.name === "Worksheets" ? (
                            <LockIcon
                              sx={{ color: "#FFF", marginLeft: "auto" }}
                            />
                          ) : (
                            <ArrowRightAltOutlinedIcon
                              sx={{ color: "#FFF", marginLeft: "auto" }}
                            />
                          ))}
                      </Nav.Link>
                    );
                  })
                : menusFortis.map((menu) => {
                    const IconComponent = iconMap[menu.name]; // Dynamically get the icon component
                    return (
                      <Nav.Link
                        key={menu.name}
                        as={Link}
                        to={menu.link}
                        onClick={() => onClickMenu(menu.name)}
                        className="d-flex align-items-center gap-6"
                        style={{
                          background:
                            isActive === menu.name
                              ? "var(--primary-color)"
                              : "",
                          borderRadius: "24px",
                          fontSize: "14px",
                          color: isActive === menu.name ? "white" : "black",
                        }}
                      >
                        <IconComponent
                          sx={{
                            color: isActive === menu.name ? "#FFF" : "#000",
                          }}
                        />
                        <p className="font-inter">{menu.name}</p>
                        {isActive === menu.name &&
                          (menu.name === "Communities" ||
                          menu.name === "Posts" ||
                          menu.name === "Articles" ||
                          menu.name === "Worksheets" ? (
                            <LockIcon
                              sx={{ color: "#FFF", marginLeft: "auto" }}
                            />
                          ) : (
                            <ArrowRightAltOutlinedIcon
                              sx={{ color: "#FFF", marginLeft: "auto" }}
                            />
                          ))}
                      </Nav.Link>
                    );
                  })}
            </Nav>
          </Col>

          {/* Content */}
          <Col md={10} className="mt-3 ExpertDashboard--Col">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
