import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-2016347 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="2016347"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-08880cb"
            data-id="08880cb"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-6433cdc elementor-widget elementor-widget-text-editor"
                data-id="6433cdc"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <div style="text-align: center;">
                    <span style="color: #000000;">
                      United We Care is the world’s #1 mental health &amp;
                      holistic wellness platform.{" "}
                    </span>
                  </div>
                  <div style="text-align: center;">&nbsp;</div>
                  <div style="text-align: center;">
                    <span style="color: #000000;">
                      By leveraging technology and leading global frameworks,
                      UWC provides a wide range of support and interventions
                      directly to individuals, families, and employees.&nbsp;
                    </span>
                  </div>
                  <div style="text-align: center;">
                    <span style="color: #000000;">&nbsp;</span>
                  </div>
                  <div style="text-align: center;">
                    <div>
                      <span style="color: #000000;">
                        Headquartered in Singapore, the company was founded in
                        2020 and has been enabling and delivering complex
                        interventions across cognitive, physiological, and
                        behavioural frameworks.&nbsp;&nbsp;
                      </span>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <span style="color: #000000;">
                        We as a company strive to give the world the ability to
                        attain safe, secure, equal, and inclusive professional
                        guidance and therapeutic support, for overcoming
                        emotional challenges, from the comfort of one’s home.
                        Alongside our fully functional and active website, we
                        also maintain a mobile app, with the same name, which
                        enhances user experience.
                      </span>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <span style="color: #000000;">
                        {" "}
                        Among the resources we share with our global client-base
                        are informative videos and articles, packages and
                        courses designed to help them overcome specific
                        emotional wellness challenges.{" "}
                      </span>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <span style="color: #000000;">
                        We also provide mainstream and alternative
                        therapies(through both recorded and live sessions)
                        ranging from Yoga to Meditation, Art therapy to Dance
                        movement and even music therapy all under one roof.{" "}
                      </span>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <span style="color: #000000;">
                        The idea is to make the best of service providers
                        accessible to a global audience without worrying about
                        affordability.&nbsp;
                      </span>
                    </div>
                  </div>
                  <div style="text-align: center;">
                    <span style="color: #000000;">
                      We believe that with a holistic approach &amp; quality
                      services we can bring in a positive change in people’s
                      lives.{" "}
                    </span>
                  </div>
                  <div style="text-align: center;">&nbsp;</div>
                  <div style="text-align: center;">
                    <span style="color: #000000;">
                      We have already impacted thousands of lives and are hungry
                      to touch many more!
                    </span>
                  </div>
                  <div style="text-align: center;">&nbsp;</div>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-7201e0b elementor-widget elementor-widget-spacer"
                data-id="7201e0b"
                data-element_type="widget"
                data-widget_type="spacer.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
                    <div className="elementor-spacer-inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
