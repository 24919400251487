import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { OODOCoursePaymentService } from "../core/_request";
import { useAuth } from "../../auth";
import Rating from "@mui/material/Rating";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Skeleton, Grid } from "@mui/material";
import "../../styles/courses/RelatedCourses.css";

import { Pagination, Navigation } from "swiper";

const RelatedCourses = (props) => {
  const { courseData, loading } = props;
  const len = courseData?.related_courses?.length;
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [columns, setColumn] = useState(5);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 768) {
        setColumn(2);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="related-course-section">
      {loading ? (
        <div
          className="d-flex flex-column jusitfy-content-center align-items-center"
          style={{ padding: "20px" }}
        >
          <div>
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: "10px", margin: "5px 0" }}
              width="15vw"
              height={60}
              className="d-none d-md-block"
            />
          </div>
          <div>
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: "10px", margin: "5px 0" }}
              width="25vw"
              height={60}
              className="d-none d-md-block"
            />
          </div>
          <div>
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: "10px", margin: "5px 0" }}
              width="30vw"
              height={60}
              className="d-block d-md-none"
            />
          </div>
          <div>
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: "10px", margin: "5px 0" }}
              width="50vw"
              height={60}
              className="d-block d-md-none"
            />
          </div>

          <Grid container spacing={2}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(5,1fr)",
                gap: "15px",
                width: "100%",
                margin: "20px",
                padding: "10px",
              }}
              className="d-none d-md-grid"
            >
              {Array.from(new Array(5)).map((_, index) => (
                <Grid
                  item
                  xs={6}
                  md={4}
                  lg={3}
                  key={index}
                  sx={{ minWidth: "100%" }}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ borderRadius: "10px", margin: "5px 0" }}
                    width={260}
                    height={260}
                  />
                  <div>
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="15vw"
                      height={30}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="15vw"
                      height={30}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="15vw"
                      height={30}
                    />
                  </div>
                </Grid>
              ))}
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr)",
                gap: "15px",
                width: "100%",
                margin: "20px 10px",
                padding: "10px",
              }}
              className="d-grid d-md-none"
            >
              {Array.from(new Array(2)).map((_, index) => (
                <Grid
                  item
                  xs={6}
                  md={4}
                  lg={3}
                  key={index}
                  sx={{ minWidth: "100%" }}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ borderRadius: "10px", margin: "5px 0" }}
                    width={180}
                    height={180}
                  />
                  <div>
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="35vw"
                      height={25}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="35vw"
                      height={25}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "10px", margin: "5px 0" }}
                      width="35vw"
                      height={25}
                    />
                  </div>
                </Grid>
              ))}
            </div>
          </Grid>
        </div>
      ) : (
        <div className="related-course-container">
          <div className="related-course-heading">Related Program</div>
          <div className="related-course-desc">
            Connect with our community and stay inspired on your mental wellness
            journey.
          </div>

          <div className="swiper--container">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={0}
              navigation={len < 2 ? false : true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper relatedCourse--Swiper"
            >
              {courseData?.related_courses?.map((item, i) => (
                <SwiperSlide className="relatedCourse--SwiperItem">
                  <div
                    className="SwiperItem--Card"
                    onClick={() => {
                      if (item.enrolled) {
                        const request = {
                          userId: currentUser?.UserId,
                          courseId: item?.id,
                        };
                        OODOCoursePaymentService(request).then((res) => {
                          navigate(
                            `/program-internal/${res?.data?.data?.id}/${item?.title}`
                          );
                        });
                      } else {
                        navigate(`/program/${item?.title}/${item?.id}`);
                      }
                    }}
                  >
                    <div className="relative SwiperItem--Card__Image">
                      <img src={item?.image} alt="" />
                      {item?.enrolled && (
                        <button className="d-flex align-items-center gap-1 mx-auto justify-content-center">
                          <span>Continue</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_6820_21780)">
                              <path
                                d="M16.51 11H5.5C4.95 11 4.5 11.45 4.5 12C4.5 12.55 4.95 13 5.5 13H16.51V14.79C16.51 15.24 17.05 15.46 17.36 15.14L20.14 12.35C20.33 12.15 20.33 11.84 20.14 11.64L17.36 8.85002C17.05 8.53002 16.51 8.76002 16.51 9.20002V11Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_6820_21780">
                                <rect
                                  width="24"
                                  height="24"
                                  fill="white"
                                  transform="translate(0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      )}
                    </div>

                    <div className="course-card-title">
                      <span>{item?.club}</span>
                      <p>{item?.title}</p>
                      <div className="d-flex align-items-center gap-1">
                        <p>{item?.rating}</p>
                        <Rating
                          name="read-only"
                          value={item?.rating}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </section>
  );
};

export default RelatedCourses;
