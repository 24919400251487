import React, { useState, useEffect } from "react";

const SessionStatsCard = ({
  title,
  titleHighlight,
  expertTime,
  clientTime,
  expertTags,
  clientTags,
  isWebsocketDataRecieved
}) => {
  const [expert_Time, setexpertTime] = useState("");
  const [client_Time, setclientTime] = useState("");

  useEffect(() => {
    setexpertTime(expertTime);
    setclientTime(clientTime);
  }, [expertTime, clientTime]);

  return (
    <div className="StatsCard--Item">
      <h6 className="d-flex justify-content-between">
        {title} <b>{titleHighlight}</b>
      </h6>
      {isWebsocketDataRecieved ? (
        <div className="Item--Timings mt-3">
          <div>
            <p>{expert_Time || ""}</p>
            <div className="d-flex align-items-center gap-2">
              <span className="Item--Tag Expert">Expert</span>
              {expertTags?.map((item, index) => {
                return (
                  <span key={index}
                    className={`Item--Tag ${item ? "OnTime" : "Late"
                      }`}
                  >
                    {item ? "On Time" : "Late"}
                  </span>
                );
              })}
            </div>
          </div>

          <div>
            <p>{client_Time || ""}</p>
            <div className="d-flex align-items-center gap-2">
              <span className="Item--Tag Client">Client</span>
              {clientTags?.map((item, index) => {
                return (
                  <span key={index}
                    className={`Item--Tag ${item ? "OnTime" : "Late"
                      }`}
                  >
                    {item ? "On Time" : "Late"}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center my-3">
          <img
            src="/assets/img/icons/sparkling.png"
            alt=""
            width={15}
            className="animate-pulse"
          />
        </div>
      )}
    </div>
  );
};

export default SessionStatsCard;
