import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { useNavigate } from "react-router-dom";
import { getCourseDetailsV2 } from "../../courseNew/_request";

const ProgramCard = ({ item }) => {
  const [data, setData] = useState();
  // const [error, setError] = useState();
  // const [loading, setLoading] = useState();

  // const timestamp = data?.createDate;
  // const dateObject = new Date(timestamp);
  // const day = String(dateObject.getDate()).padStart(2, '0');
  // const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // const month = monthNames[dateObject.getMonth()];
  // const year = dateObject.getFullYear();

  // const formattedDate = `${day} ${month} ${year}`;

  useEffect(() => {
    const body = {
      courseId: item?._id
    }
    getCourseDetailsV2(body).then((resp) => {
      console.log("resp", resp?.data?.club)
      setData(resp?.data);
    })
    // const fetchData = async () => {
    //   try {
    //     const requestOptions = {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'X-API-Key': '76d802e426a2cb28f3760c8c8f669983f67ed775'
    //       },
    //       body: JSON.stringify({
    //         postId: item?._id
    //       })
    //     };

    //     const response = await fetch('https://odoo.unitedwecare.ca/new-uwc/view/public/post', requestOptions);

    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }

    //     const jsonData = await response.json();
    //     setData(jsonData?.data?.data);
    //   } catch (error) {
    //     setError(error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // fetchData();
  }, [item]);

  const navigate = useNavigate();
  return (
    <div
      className="Search--ProgramCard p-2"
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/program/${item?.name}/${item?._id}`)}
    >
      <div className="my-2 mx-1 d-flex flex-column justify-content-between gap-1">
        <div className="d-flex gap-4 align-items-center ">
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src={item?.website_thumbnail_url}
              alt=""
              className="program-img position-relative"
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#73589B",
                zIndex: "100",
                background: "white",
                width: "60px",
                height: "60px",
              }}
              className="p-2 rounded-circle"
            >
              <img
                src="/assets/img/play-program.png"
                alt=""
                style={{ width: "50px", height: "auto", aspectRatio: "1/1" }}
              />
            </div>
          </div>

          <div className="my-2 h-100">
            <h6>{item?.name}</h6>
            <div className="d-flex flex-wrap gap-1 align-items-center lh-lg my-2">
              <p className="text-decoration-none">{item?.duration} </p>
              {data?.club &&
                <div className="clubDiv">
                  <p style={{ color: "#fff", fontSize: "12px", textDecoration: 'none' }}>
                    {data?.club}
                  </p>
                </div>
              }
            </div>
            <div className="d-flex gap-1 align-items-center lh-lg my-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="68"
                height="12"
                viewBox="0 0 68 12"
                fill="none"
              >
                <path
                  d="M7.03658 0.866507L8.09246 2.99572C8.23645 3.29212 8.62041 3.57642 8.94437 3.63086L10.8582 3.95145C12.082 4.15711 12.37 5.05236 11.4881 5.9355L10.0003 7.43563C9.74828 7.68969 9.6103 8.17965 9.68829 8.53048L10.1142 10.3875C10.4502 11.8574 9.67629 12.426 8.38643 11.6578L6.59263 10.5871C6.26866 10.3935 5.73472 10.3935 5.40476 10.5871L3.61096 11.6578C2.3271 12.426 1.54719 11.8513 1.88315 10.3875L2.30911 8.53048C2.3871 8.17965 2.24911 7.68969 1.99714 7.43563L0.509304 5.9355C-0.3666 5.05236 -0.0846312 4.15711 1.13923 3.95145L3.05302 3.63086C3.37099 3.57642 3.75495 3.29212 3.89893 2.99572L4.95481 0.866507C5.53075 -0.288836 6.46664 -0.288836 7.03658 0.866507Z"
                  fill="#FCAF23"
                />
                <path
                  d="M21.0366 0.866507L22.0925 2.99572C22.2364 3.29212 22.6204 3.57642 22.9444 3.63086L24.8582 3.95145C26.082 4.15711 26.37 5.05236 25.4881 5.9355L24.0003 7.43563C23.7483 7.68969 23.6103 8.17965 23.6883 8.53048L24.1142 10.3875C24.4502 11.8574 23.6763 12.426 22.3864 11.6578L20.5926 10.5871C20.2687 10.3935 19.7347 10.3935 19.4048 10.5871L17.611 11.6578C16.3271 12.426 15.5472 11.8513 15.8832 10.3875L16.3091 8.53048C16.3871 8.17965 16.2491 7.68969 15.9971 7.43563L14.5093 5.9355C13.6334 5.05236 13.9154 4.15711 15.1392 3.95145L17.053 3.63086C17.371 3.57642 17.7549 3.29212 17.8989 2.99572L18.9548 0.866507C19.5307 -0.288836 20.4666 -0.288836 21.0366 0.866507Z"
                  fill="#FCAF23"
                />
                <path
                  d="M35.0366 0.866507L36.0925 2.99572C36.2364 3.29212 36.6204 3.57642 36.9444 3.63086L38.8582 3.95145C40.082 4.15711 40.37 5.05236 39.4881 5.9355L38.0003 7.43563C37.7483 7.68969 37.6103 8.17965 37.6883 8.53048L38.1142 10.3875C38.4502 11.8574 37.6763 12.426 36.3864 11.6578L34.5926 10.5871C34.2687 10.3935 33.7347 10.3935 33.4048 10.5871L31.611 11.6578C30.3271 12.426 29.5472 11.8513 29.8832 10.3875L30.3091 8.53048C30.3871 8.17965 30.2491 7.68969 29.9971 7.43563L28.5093 5.9355C27.6334 5.05236 27.9154 4.15711 29.1392 3.95145L31.053 3.63086C31.371 3.57642 31.7549 3.29212 31.8989 2.99572L32.9548 0.866507C33.5307 -0.288836 34.4666 -0.288836 35.0366 0.866507Z"
                  fill="#FCAF23"
                />
                <path
                  d="M49.0366 0.866507L50.0925 2.99572C50.2364 3.29212 50.6204 3.57642 50.9444 3.63086L52.8582 3.95145C54.082 4.15711 54.37 5.05236 53.4881 5.9355L52.0003 7.43563C51.7483 7.68969 51.6103 8.17965 51.6883 8.53048L52.1142 10.3875C52.4502 11.8574 51.6763 12.426 50.3864 11.6578L48.5926 10.5871C48.2687 10.3935 47.7347 10.3935 47.4048 10.5871L45.611 11.6578C44.3271 12.426 43.5472 11.8513 43.8832 10.3875L44.3091 8.53048C44.3871 8.17965 44.2491 7.68969 43.9971 7.43563L42.5093 5.9355C41.6334 5.05236 41.9154 4.15711 43.1392 3.95145L45.053 3.63086C45.371 3.57642 45.7549 3.29212 45.8989 2.99572L46.9548 0.866507C47.5307 -0.288836 48.4666 -0.288836 49.0366 0.866507Z"
                  fill="#FCAF23"
                />
                <path
                  d="M63.0366 0.866507L64.0925 2.99572C64.2364 3.29212 64.6204 3.57642 64.9444 3.63086L66.8582 3.95145C68.082 4.15711 68.37 5.05236 67.4881 5.9355L66.0003 7.43563C65.7483 7.68969 65.6103 8.17965 65.6883 8.53048L66.1142 10.3875C66.4502 11.8574 65.6763 12.426 64.3864 11.6578L62.5926 10.5871C62.2687 10.3935 61.7347 10.3935 61.4048 10.5871L59.611 11.6578C58.3271 12.426 57.5472 11.8513 57.8832 10.3875L58.3091 8.53048C58.3871 8.17965 58.2491 7.68969 57.9971 7.43563L56.5093 5.9355C55.6334 5.05236 55.9154 4.15711 57.1392 3.95145L59.053 3.63086C59.371 3.57642 59.7549 3.29212 59.8989 2.99572L60.9548 0.866507C61.5307 -0.288836 62.4666 -0.288836 63.0366 0.866507Z"
                  fill="#FCAF23"
                />
              </svg>
              <p className="fw-semibold text-decoration-none" style={{ color: "#000" }}>
                {item?.avg_rating}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramCard;
