import React, { useEffect, useState } from "react";
import "./DeleteAccount.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  LoginByOTPService,
  SendRequestOTPService,
  deleteUserAccount,
} from "../components/core/_request";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useAuth } from "../auth";
import { useSnackbar } from "notistack";

const DeleteAccount = () => {
  const [email, setEmail] = useState();
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState();
  const [validationMessage, setValidationMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [isMailRegistered, setIsMailRegistered] = useState(false);
  const [counter, setCounter] = useState(0);
  const [otpSent, setOtpSent] = useState(false);
  const [otpModalShow, setOtpModalShow] = useState(false);
  const count_time = process.env.REACT_APP_OTP_TIME;
  const [resendOtpSent, setResendOtpSent] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const { login, setCurrentUser, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const onLogin = () => {
    setValidationMessage([]);
    setLoading(true);
    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (!pattern.test(email)) {
      setValidEmail(false);
      let newValidationMessage = Object.assign(
        [],
        [
          ...validationMessage.filter((o) => o.key != "Email"),
          { key: "Email", message: "Invalid email" },
        ]
      );
      setValidationMessage(newValidationMessage);
      setLoading(false);
    } else {
      var req = { Email: email };
      SendRequestOTPService(req).then((resp) => {
        setLoading(false);
        if (resp != null && resp.ResponseCode == 200) {
          setIsMailRegistered(resp?.data?.IsMailRegistered);
          if (resp?.data?.IsMailRegistered) {
            setOtpSent(true);
            setCounter(count_time);
            setOtpModalShow(true);
          } else {
            enqueueSnackbar("Email address does not exist", {
              variant: "error",
            });
          }
        } else if (resp != null && resp.ResponseCode == 401) {
        }
      });
    }
  };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target.form.elements[next]?.focus();
      }
    }
  };

  const resendOTP = () => {
    setOtpSent(true);
    setCounter(count_time);
    // debugger
    var req = { Email: email };
    SendRequestOTPService(req).then((resp) => {
      setLoading(false);
      if (resp != null && resp.ResponseCode == 200) {
        setResendOtpSent(true);
        setOtpSent(true);
        setCounter(count_time);
      } else if (resp != null && resp.ResponseCode == 401) {
      }
    });
  };

  const onSubmitOtp = () => {
    var req = {
      Email: email,
      OTP: otp?.OTP1 + otp?.OTP2 + otp?.OTP3 + otp?.OTP4,
    };
    LoginByOTPService(req).then((resp) => {
      // debugger
      if (resp != null && resp?.ResponseCode == 200) {
        if (resp?.data?.IsLogin) {
          let UserType = resp?.data?.UserType;
          let profile = resp?.data?.userdetails;
          profile.UserType = UserType;
          login(resp?.data);
          setCurrentUser(profile);
          setOtpModalShow(false);
          setStep(2);
        } else {
          enqueueSnackbar("User Not Found!", { variant: "error" });
        }
      } else {
        let newValidationMessage = Object.assign(
          [],
          [
            ...validationMessage.filter((o) => o.key != "OTP"),
            { key: "OTP", message: "Incorrect OTP entered, Please try again" },
          ]
        );
        setValidationMessage(newValidationMessage);
        setLoading(false);
      }
    });
  };

  const onSubmitRequest = () => {
    let req = { additionalInfo: additionalInfo };
    deleteUserAccount(req).then((resp) => {
      if (resp.ResponseCode == 200) {
        enqueueSnackbar("Request Submitted", { variant: "success" });
        logout();
        window.location.reload();
        setEmail("");
      }
    });
  };

  return (
    <div className="deleteAccount--section">
      <div className="deleteAccount-container col-md-9 p-5 my-5">
        <div className="col-md-6 col-12">
          <h3>Privacy Request Form</h3>
          <p>
            Complete this form for inquiries related to deleting your personal
            data. Fill in your information and submit the form.
          </p>
        </div>
        <hr />
        <div className="col-12 col-md-6 d-flex flex-column gap-3">
          <h5>Personal Information Required</h5>
          <p>
            To process your privacy inquiry, we need your email. This
            information is used solely for handling your request and for
            necessary record-keeping as per legal obligations.
            <br />
            <br />
            Post-submission, an One-Time Passcode (OTP) will be sent on your
            email to confirm your identity. Please input the OTP in this email
            to process your request."
            <br />
          </p>
          <form>
            <TextField
              label="Email *"
              disabled={step == 2}
              variant="outlined"
              className="col-12 col-md-6 my-3"
              helperText={
                !validEmail && (
                  <span className="error-message" style={{ color: "#dc3545" }}>
                    {
                      validationMessage.filter((o) => o.key == "Email")[0]
                        ?.message
                    }
                  </span>
                )
              }
              onChange={(e) => setEmail(e.target.value)}
            />

            <div
              className="cf-turnstile"
              data-sitekey="0x4AAAAAAAOoULQqfvKXelo3"
              data-theme="light"
            ></div>

            {step == 1 && (
              <Button
                variant="contained"
                className="col-12 col-md-6"
                onClick={onLogin}
              >
                Request OTP
              </Button>
            )}
          </form>
        </div>
        <hr />
        {step === 2 && (
          <>
            <div className="col-12 col-md-6 d-flex flex-column gap-3">
              <h5>Type of Request</h5>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Delete"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Delete"
                  control={<Radio />}
                  label="Delete"
                />
              </RadioGroup>
              <span>
                <i>
                  * Please note, upon completing this process, you will not be
                  able to use the same email for our services unless you sign up
                  and register again."
                </i>
              </span>
            </div>
            <hr />
            <div className="col-12 col-md-6 d-flex flex-column gap-3">
              <h5>Additional Information</h5>
              <TextField
                id="outlined-multiline-static"
                label="Additional information"
                multiline
                rows={4}
                className="col-md-9 col-12 my-3"
                onChange={(e) => setAdditionalInfo(e.target.value)}
              />
            </div>

            <Button
              variant="contained"
              className="col-12 col-md-3"
              onClick={onSubmitRequest}
            >
              Submit Request
            </Button>
          </>
        )}
      </div>

      <Modal
        show={otpModalShow}
        onHide={() => setOtpModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="otp--popup"
      >
        <Modal.Body className="d-flex flex-column gap-4">
          <div className="otp--header d-flex align-items-center justify-content-between">
            <h3>Confirm OTP</h3>
            <CloseButton onClick={() => setOtpModalShow(false)} />
          </div>

          <p>
            An OTP has been sent to your email <span>{email}</span> Please enter
            the OTP to verify your email.
          </p>

          <form className="d-flex align-items-center gap-5 justify-content-center px-5">
            <TextField
              disabled={loading}
              id="OTP"
              variant="standard"
              inputProps={{ maxLength: 1 }}
              onChange={(e) => {
                setOtp({ ...otp, OTP1: e?.target?.value });
              }}
              onKeyDown={(event) => {
                if (event.key != "Backspace" && !/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              InputProps={{ inputProps: { tabIndex: 1 } }}
              onKeyUp={(e) => inputfocus(e)}
            />
            <TextField
              disabled={loading}
              id="OTP1"
              variant="standard"
              inputProps={{ maxLength: 1 }}
              onChange={(e) => {
                setOtp({ ...otp, OTP2: e?.target?.value });
              }}
              onKeyDown={(event) => {
                if (event.key != "Backspace" && !/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              InputProps={{ inputProps: { tabIndex: 2 } }}
              onKeyUp={(e) => inputfocus(e)}
            />
            <TextField
              id="standard-basic"
              variant="standard"
              inputProps={{ maxLength: 1 }}
              onChange={(e) => {
                setOtp({ ...otp, OTP3: e?.target?.value });
              }}
              onKeyDown={(event) => {
                if (event.key != "Backspace" && !/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              InputProps={{ inputProps: { tabIndex: 3 } }}
              onKeyUp={(e) => inputfocus(e)}
            />
            <TextField
              id="standard-basic"
              variant="standard"
              inputProps={{ maxLength: 1 }}
              onChange={(e) => {
                setOtp({ ...otp, OTP4: e?.target?.value });
              }}
              onKeyDown={(event) => {
                if (event.key != "Backspace" && !/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              InputProps={{ inputProps: { tabIndex: 4 } }}
              onKeyUp={(e) => inputfocus(e)}
            />
          </form>

          {resendOtpSent && (
            <span
              className="error-message px-5 d-flex align-items-center justify-content-center gap-2"
              style={{ color: "#00AD65" }}
            >
              <CheckCircleOutlineIcon /> An OTP has been resend to you
            </span>
          )}

          {validationMessage.some((o) => o.key === "OTP") && !resendOtpSent && (
            <span
              className="error-message px-5 d-flex align-items-center justify-content-center gap-2"
              style={{ color: "#dc3545" }}
            >
              <ErrorOutlineIcon />{" "}
              {validationMessage.find((o) => o.key === "OTP")?.message}
            </span>
          )}

          <div className="otp--resend d-flex align-items-center justify-content-between px-4">
            {(counter === 0 && otpSent && (
              <div className="w-100">
                <p className="font-inter fs-6 text-black text-center w-100 d-flex align-items-center justify-content-between">
                  <span>00:0{counter}</span>{" "}
                  <span onClick={resendOTP} style={{ cursor: "pointer" }}>
                    Resend OTP
                  </span>
                </p>
              </div>
            )) || (
              <div
                className="w-100 d-flex align-items-center justify-content-between font-inter fs-6 text-black text-center border-0 bg-white"
                style={{ color: "red !important" }}
              >
                <span>00:{counter}</span> <span>Resend OTP</span>
              </div>
            )}
          </div>

          <Button
            disabled={
              loading || (!otp?.OTP1 && !otp?.OTP2 && !otp?.OTP3 && !otp?.OTP4)
            }
            className="otp--continue-btn border-0 d-block mx-auto my-3 text-white"
            onClick={onSubmitOtp}
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeleteAccount;
