import React, { useEffect, useState } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import { GetMySubscription, SubscriptionPacks, UpgradeSubscriptionPacks } from './core/request';
import { useSnackbar } from 'notistack';
const SubscriptionUpgrade = () => {

    const [packsData, setPacksData] = useState({});
    const [mySubscriptionData, setMySubscriptionData] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    var body = { "country_id": 233 }
    useEffect(() => {
        SubscriptionPacks(body).then(resp => {
            if (resp.ResponseCode === 200) {
                setPacksData(resp?.data)
            }
        })

        var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
        var myBody = { "user_id": user_id }
        GetMySubscription(myBody).then(resp => {
            if (resp.ResponseCode === 200) {
                setMySubscriptionData(resp.data)
            } else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        })
    }, []);

    return (
        <div className='my-5'>
            <h6 className='text-center font-inter font-w800 fs-1'>Plans & Pricing</h6>
            <div className='row justify-content-center gap-5 my-5'>
                {packsData?.packs?.map((pack, index) =>
                    <div className='col-11 col-md-3 rounded-3 d-flex flex-column justify-content-between px-0' style={{ border: "1px solid #6A6C6A", boxShadow: "#0000002B" }}>
                        <div className='d-flex flex-column justify-content-between'>
                            <div className='rounded-top' style={{ background: pack?.name === "Genesis" ? "#C4E5F9" : "#9FE770", height: "25px" }}></div>
                            <div className='mx-3'>
                                <div className='d-flex justify-content-between align-items-center my-3'>
                                    <h6 className='font-inter lh-lg fs-2'>{pack?.name}</h6>
                                    {pack?.current_plan &&
                                        <div className='rounded-5 d-flex gap-2 align-items-center px-2 py-2' style={{ border: "1px solid #6A994C", color: "#6A994C" }}>
                                            <p className='font-inter '> Active Plan</p>
                                            <img src='/assets/img/green_tick.png' alt='' />
                                        </div>
                                    }
                                </div>
                                <div className='subscriptions-plans'>
                                    <Tabs
                                        defaultActiveKey="tab_0"
                                        id="fill-tab-example"
                                        className="mb-3 rounded-3"
                                        fill
                                        style={{ border: '1px solid #C4E5F9' }}
                                    >
                                        {pack?.available?.map((item, index) =>
                                            <Tab eventKey={"tab_" + index} title={item?.name}>
                                                <div>
                                                    <p className='font-inter fs-5 lh-lg' dangerouslySetInnerHTML={{ __html: pack?.plan_description }}></p>
                                                </div>
                                            </Tab>
                                        )}
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        {pack?.current_plan &&
                            <h6 className='font-inter font-w700 fs-5 text-center my-4 '>You’re already a {packsData?.packs?.find(o => o.current_plan)?.name} Member</h6>
                            ||
                            <Button className='rounded-5 border-0 text-black col-6 d-block mx-auto my-4 font-inter' style={{ background: "#9FE770" }}>Upgrade</Button>
                        }
                    </div>
                )}
            </div>
        </div>
    )
}

export default SubscriptionUpgrade