import React, { useEffect, useState } from "react";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Video from "./Video";
import {
  AddCommentToPost,
  LikePost,
  ViewPostDetail,
  removeLikePost,
} from "../_request";
import { useAuth } from "../../../auth";
import { useParams } from "react-router-dom";
import CommentModal from "./CommentModal";
import ReactPlayer from "react-player";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TextField from "@mui/material/TextField";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import Blogs from "./Blogs";
import SharePopup from "./SharePopup";
import { useSnackbar } from "notistack";
import { handleBrokenImage } from "../../../lib/utils";

const DetailComponent = () => {
  const [commentText, setCommentText] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [postDetail, setPostDetail] = useState();
  const [liked, setLiked] = useState(postDetail?.data?.liked);
  const [commentsModalShow, setCommentsModalShow] = React.useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const postId = useParams();
  let userId = currentUser?.UserId;
  const [modalShow, setModalShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const body = {
      userId: userId,
      postId: postId?.id,
    };
    ViewPostDetail(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setPostDetail(resp?.data);
      } else {
        console.log(resp.ResponseMessage);
      }
    });
  }, []);

  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLike = () => {
    const body = {
      userId: userId,
      postId: postId?.id,
    };
    LikePost(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setLiked(true);
      } else {
        console.log(resp.ResponseMessage);
      }
    });
  };

  const onCommentChange = (e, commentText) => {
    const body = {
      userId: userId,
      postId: postId?.id,
      description: commentText,
    };
    if (e.key === "Enter") {
      e.preventDefault();
      AddCommentToPost(body).then((resp) => {
        if (resp?.ResponseCode === 200 || resp?.ResponseCode === 201) {
          enqueueSnackbar("Comment added.", { variant: "success" });
          window.location.reload();
        } else {
          enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
        }
      });
    } else {
      setCommentText(commentText);
    }
  };

  const onClickRemoveLike = () => {
    const body = {
      userId: userId,
      postId: postId?.id,
    };
    removeLikePost(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setLiked(false);
      } else {
        console.log(resp.ResponseMessage);
      }
    });
  };

  const handleReportPost = () => {
    handleClose();
  };

  const timestamp = postDetail?.data?.createDateIST;

  const dateObject = new Date(timestamp);

  const day = String(dateObject.getDate()).padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateObject.getMonth()];
  const year = dateObject.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  const calculateDuration = (createDate) => {
    const currentDate = new Date();
    const postDate = new Date(createDate);

    const timeDifference = currentDate.getTime() - postDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "a year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "a month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "a day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "an hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
    } else {
      return "just now";
    }
  };

  const duration = calculateDuration(timestamp);

  return (
    <>
      {(postDetail?.type === "video" || postDetail?.type === "audio") && (
        <div className="detailPage-Module mx-3">
          <section className={`videoPlayer videoPlayer${postDetail?.type}`}>
            <ReactPlayer
              url={
                postDetail?.type === "video"
                  ? postDetail?.data?.video?.player_embed_url ||
                  postDetail?.data?.video?.link
                  : postDetail?.data?.audioUrl
              }
              playIcon={true}
              controls={true}
              height={postDetail?.type === "audio" ? "340px" : "auto"}
            ></ReactPlayer>
          </section>
          <div className="contentContainer d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between my-3">
            <div className="contentClubDiv">
              <span>{postDetail?.data?.category?.name}</span>
            </div>
            <div className="contentContainer d-flex align-items-center justify-content-between gap-3 mx-1 mx-md-3 my-3 col-12 col-md-4">
              <div className="contentActionDiv">
                <MoreTimeOutlinedIcon fontSize="small" />{" "}
                <span>Add Reminder</span>
              </div>
              <div className="contentActionDiv">
                <AddCircleOutlineOutlinedIcon fontSize="small" />{" "}
                <span>Save to playlist</span>
              </div>
              <a onClick={handleMenuClick}>
                <MoreVertIcon />
              </a>
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {userId !== postDetail?.data?.user?.userId ? (
                <MenuItem onClick={handleClose}>
                  <ReportGmailerrorredOutlinedIcon />
                  Report
                </MenuItem>
              ) : (
                <MenuItem onClick={handleClose}>Delete</MenuItem>
              )}
            </Menu>
          </div>
          <h6>{postDetail?.data?.title}</h6>
          <p>{formattedDate}</p>

          <div
            className="d-flex flex-column gap-3 post--footer my-3"
            style={{ width: "fit-content" }}
          >
            <div className="d-flex align-items-center gap-3">
              {liked ? (
                <svg
                  className="post--footer-img"
                  width="42"
                  height="41"
                  viewBox="0 0 42 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={onClickRemoveLike}
                >
                  <path
                    d="M33.4537 10.8136C32.7593 10.1166 31.9348 9.56378 31.0274 9.18658C30.12 8.80938 29.1473 8.61523 28.1651 8.61523C27.1828 8.61523 26.2102 8.80938 25.3028 9.18658C24.3954 9.56378 23.5709 10.1166 22.8765 10.8136L21.4354 12.2593L19.9943 10.8136C18.5917 9.40647 16.6893 8.61596 14.7057 8.61596C12.7221 8.61596 10.8198 9.40647 9.41716 10.8136C8.01454 12.2207 7.22656 14.1291 7.22656 16.1191C7.22656 18.1091 8.01454 20.0175 9.41716 21.4246L10.8583 22.8703L21.4354 33.4814L32.0126 22.8703L33.4537 21.4246C34.1484 20.728 34.6995 19.9009 35.0755 18.9906C35.4515 18.0802 35.645 17.1045 35.645 16.1191C35.645 15.1337 35.4515 14.158 35.0755 13.2476C34.6995 12.3373 34.1484 11.5102 33.4537 10.8136Z"
                    fill="#DD1E1E"
                    stroke="#DD1E1E"
                    stroke-width="2.16293"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  className="post--footer-img"
                  width="42"
                  height="41"
                  viewBox="0 0 42 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={onClickLike}
                >
                  <path
                    d="M33.5831 10.7706C32.8887 10.0737 32.0642 9.52081 31.1568 9.14361C30.2493 8.76641 29.2767 8.57227 28.2945 8.57227C27.3122 8.57227 26.3396 8.76641 25.4322 9.14361C24.5248 9.52081 23.7003 10.0737 23.0059 10.7706L21.5648 12.2163L20.1237 10.7706C18.7211 9.3635 16.8187 8.57299 14.8351 8.57299C12.8515 8.573 10.9492 9.3635 9.54656 10.7706C8.14394 12.1777 7.35596 14.0862 7.35596 16.0761C7.35596 18.0661 8.14394 19.9745 9.54656 21.3816L10.9877 22.8274L21.5648 33.4384L32.142 22.8274L33.5831 21.3816C34.2778 20.685 34.8289 19.8579 35.2049 18.9476C35.5809 18.0373 35.7744 17.0615 35.7744 16.0761C35.7744 15.0907 35.5809 14.115 35.2049 13.2047C34.8289 12.2943 34.2778 11.4672 33.5831 10.7706V10.7706Z"
                    stroke="black"
                    stroke-width="2.16293"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              <svg
                className="post--footer-img"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setCommentsModalShow(true)}
              >
                <mask id="path-1-inside-1_182_2312" fill="white">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M29.1189 23.9236C30.4199 21.7448 31.1675 19.1972 31.1675 16.4751C31.1675 8.43974 24.6536 1.92578 16.6182 1.92578C8.58281 1.92578 2.06885 8.43974 2.06885 16.4751C2.06885 24.5105 8.58281 31.0244 16.6182 31.0244C19.3455 31.0244 21.8975 30.274 24.0791 28.9684L30.9651 30.8135L29.1189 23.9236Z"
                  />
                </mask>
                <path
                  d="M29.1189 23.9236L27.0748 22.703C26.7449 23.2555 26.6527 23.9181 26.8192 24.5398L29.1189 23.9236ZM24.0791 28.9684L24.6952 26.6688C24.0728 26.502 23.4094 26.5946 22.8564 26.9255L24.0791 28.9684ZM30.9651 30.8135L30.3489 33.1132C31.1705 33.3334 32.0471 33.0985 32.6486 32.497C33.25 31.8956 33.4849 31.0189 33.2648 30.1973L30.9651 30.8135ZM28.7867 16.4751C28.7867 18.7556 28.1616 20.8829 27.0748 22.703L31.163 25.1442C32.6783 22.6066 33.5483 19.6388 33.5483 16.4751H28.7867ZM16.6182 4.30658C23.3387 4.30658 28.7867 9.75462 28.7867 16.4751H33.5483C33.5483 7.12486 25.9684 -0.455019 16.6182 -0.455019V4.30658ZM4.44965 16.4751C4.44965 9.75462 9.89768 4.30658 16.6182 4.30658V-0.455019C7.26793 -0.455019 -0.311952 7.12486 -0.311952 16.4751H4.44965ZM16.6182 28.6436C9.89768 28.6436 4.44965 23.1956 4.44965 16.4751H-0.311952C-0.311952 25.8254 7.26793 33.4052 16.6182 33.4052V28.6436ZM22.8564 26.9255C21.0341 28.0162 18.903 28.6436 16.6182 28.6436V33.4052C19.7879 33.4052 22.7609 32.5319 25.3017 31.0113L22.8564 26.9255ZM23.4629 31.2681L30.3489 33.1132L31.5813 28.5139L24.6952 26.6688L23.4629 31.2681ZM33.2648 30.1973L31.4186 23.3074L26.8192 24.5398L28.6654 31.4297L33.2648 30.1973Z"
                  fill="black"
                  mask="url(#path-1-inside-1_182_2312)"
                />
              </svg>
              <svg
                className="post--footer-img"
                width="40"
                height="33"
                viewBox="0 0 40 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setModalShow(true)}
              >
                <g clip-path="url(#clip0_182_2313)">
                  <path
                    d="M35.914 15.3881L26.2004 5.67442V10.8804L24.3804 11.1556C15.2593 12.4465 9.07978 17.2292 5.54561 24.5515C10.4554 21.0808 16.5502 19.409 24.0841 19.409H26.2004V25.1018M21.9678 21.5676C12.5081 22.012 5.73608 25.4192 0.805176 32.3182C2.92144 21.7369 9.27024 11.1556 24.0841 9.03929V0.574219L38.898 15.3881L24.0841 30.202V21.5253C23.3857 21.5253 22.6874 21.5464 21.9678 21.5676Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_182_2313">
                    <rect
                      width="39.68"
                      height="31.744"
                      fill="white"
                      transform="translate(0.0708008 0.603516)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h5>
              {liked
                ? parseInt(postDetail?.data?.likeCount, 10) + 1
                : postDetail?.data?.likeCount}{" "}
              Likes
            </h5>
            {postDetail?.data?.description && (
              <h6>{postDetail?.data?.description}</h6>
            )}
            <span
              onClick={() => setCommentsModalShow(true)}
              style={{ cursor: "pointer" }}
            >
              View all {postDetail?.data?.commentCount} comments
            </span>
            <CommentModal
              postData={postDetail?.data}
              show={commentsModalShow}
              onHide={() => setCommentsModalShow(false)}
              userId={userId}
              postId={postId?.id}
            />
            <div className="addComment-Container d-flex gap-2">
              <img
                src={currentUser?.ProfileImage}
                onError={(e) => {
                  const newLink = handleBrokenImage(currentUser?.ProfileImage);
                  e.target.src = newLink;
                }}
                alt=""
                style={{ height: 32, width: 32, borderRadius: "50%" }}
              />
              <input
                type="text"
                id="commentInput"
                style={{ border: "none" }}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                onKeyDown={(e) => onCommentChange(e, commentText)}
                placeholder="Add a comment..."
              />
            </div>
            <p>{duration}</p>
          </div>
          <div className="similarVideos my-5">
            <h5 style={{ textTransform: "capitalize" }}>
              Similar {(postDetail?.type === "audio" && "Podcasts") || "Videos"}
            </h5>
            <div className="row">
              {postDetail?.data?.relatedPosts?.feed?.map((item, index) => (
                <div key={index} className="col-md-3 col-12 p-3">
                  <Video item={item?.data} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {postDetail?.type === "article" && (
        <div className="detailPage-Blogs mx-3">
          <div className="d-flex">
            <div className="col-md-8 blog-LeftSide">
              <h2 className="my-3">{postDetail?.data?.title}</h2>
              <h6>
                {formattedDate} - {postDetail?.data?.duration}
              </h6>
              <div className="d-flex justify-content-between my-3">
                <div className="d-flex gap-md-5 gap-2 align-items-center">
                  <img
                    className="profileImage"
                    src={postDetail?.data?.user?.image}
                    alt=""
                  />
                  <div className="postByDetail">
                    <h6>{postDetail?.data?.user?.name}</h6>
                    <p></p>
                  </div>
                </div>
                <div className="d-none d-md-flex align-items-center gap-3">
                  {liked ? (
                    <svg
                      width="68"
                      height="67"
                      viewBox="0 0 68 67"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={onClickRemoveLike}
                    >
                      <rect
                        x="0.961914"
                        y="0.347656"
                        width="66.64"
                        height="66.64"
                        rx="9.996"
                        fill="#7B7B7B"
                        fill-opacity="0.09"
                      />
                      <path
                        d="M46.9863 19.4752C46.1243 18.61 45.1008 17.9237 43.9742 17.4554C42.8477 16.9871 41.6403 16.7461 40.4209 16.7461C39.2014 16.7461 37.994 16.9871 36.8675 17.4554C35.7409 17.9237 34.7174 18.61 33.8554 19.4752L32.0663 21.27L30.2773 19.4752C28.536 17.7284 26.1743 16.747 23.7118 16.747C21.2492 16.747 18.8875 17.7284 17.1463 19.4752C15.405 21.2221 14.4268 23.5913 14.4268 26.0617C14.4268 28.5322 15.405 30.9014 17.1463 32.6483L18.9353 34.4431L32.0663 47.6161L45.1973 34.4431L46.9863 32.6483C47.8488 31.7835 48.5329 30.7567 48.9997 29.6265C49.4665 28.4964 49.7068 27.2851 49.7068 26.0617C49.7068 24.8384 49.4665 23.6271 48.9997 22.497C48.5329 21.3668 47.8488 20.34 46.9863 19.4752V19.4752Z"
                        stroke="black"
                        stroke-width="3.07446"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="68"
                      height="67"
                      viewBox="0 0 68 67"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={onClickLike}
                    >
                      <rect
                        x="0.961914"
                        y="0.347656"
                        width="66.64"
                        height="66.64"
                        rx="9.996"
                        fill="#7B7B7B"
                        fill-opacity="0.09"
                      />
                      <path
                        d="M46.9863 19.4752C46.1243 18.61 45.1008 17.9237 43.9742 17.4554C42.8477 16.9871 41.6403 16.7461 40.4209 16.7461C39.2014 16.7461 37.994 16.9871 36.8675 17.4554C35.7409 17.9237 34.7174 18.61 33.8554 19.4752L32.0663 21.27L30.2773 19.4752C28.536 17.7284 26.1743 16.747 23.7118 16.747C21.2492 16.747 18.8875 17.7284 17.1463 19.4752C15.405 21.2221 14.4268 23.5913 14.4268 26.0617C14.4268 28.5322 15.405 30.9014 17.1463 32.6483L18.9353 34.4431L32.0663 47.6161L45.1973 34.4431L46.9863 32.6483C47.8488 31.7835 48.5329 30.7567 48.9997 29.6265C49.4665 28.4964 49.7068 27.2851 49.7068 26.0617C49.7068 24.8384 49.4665 23.6271 48.9997 22.497C48.5329 21.3668 47.8488 20.34 46.9863 19.4752V19.4752Z"
                        stroke="black"
                        stroke-width="3.07446"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                  <svg
                    width="67"
                    height="67"
                    viewBox="0 0 67 67"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setCommentsModalShow(true)}
                  >
                    <rect
                      x="0.487305"
                      y="0.347656"
                      width="66"
                      height="66"
                      rx="9.996"
                      fill="#7B7B7B"
                      fill-opacity="0.09"
                    />
                    <mask id="path-2-inside-1_390_15570" fill="white">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M48.6464 42.3735C50.2223 39.7328 51.1277 36.6456 51.1277 33.347C51.1277 23.6047 43.23 15.707 33.4877 15.707C23.7454 15.707 15.8477 23.6047 15.8477 33.347C15.8477 43.0893 23.7454 50.987 33.4877 50.987C36.7949 50.987 39.8895 50.0769 42.5348 48.4934L50.8858 50.7311L48.6464 42.3735Z"
                      />
                    </mask>
                    <path
                      d="M48.6464 42.3735L46.004 40.7966C45.5779 41.5107 45.4589 42.3668 45.6741 43.1699L48.6464 42.3735ZM42.5348 48.4934L43.3312 45.5211C42.5266 45.3055 41.669 45.4253 40.9543 45.8531L42.5348 48.4934ZM50.8858 50.7311L50.0894 53.7034C51.1513 53.988 52.2844 53.6844 53.0618 52.907C53.8391 52.1296 54.1427 50.9966 53.8582 49.9347L50.8858 50.7311ZM48.0505 33.347C48.0505 36.0752 47.3032 38.6194 46.004 40.7966L51.2889 43.9504C53.1413 40.8461 54.2049 37.2161 54.2049 33.347H48.0505ZM33.4877 18.7842C41.5305 18.7842 48.0505 25.3042 48.0505 33.347H54.2049C54.2049 21.9052 44.9295 12.6298 33.4877 12.6298V18.7842ZM18.9249 33.347C18.9249 25.3042 25.4448 18.7842 33.4877 18.7842V12.6298C22.0459 12.6298 12.7705 21.9052 12.7705 33.347H18.9249ZM33.4877 47.9098C25.4448 47.9098 18.9249 41.3898 18.9249 33.347H12.7705C12.7705 44.7888 22.0459 54.0642 33.4877 54.0642V47.9098ZM40.9543 45.8531C38.7733 47.1587 36.2229 47.9098 33.4877 47.9098V54.0642C37.3668 54.0642 41.0057 52.9951 44.1153 51.1338L40.9543 45.8531ZM41.7384 51.4658L50.0894 53.7034L51.6823 47.7588L43.3312 45.5211L41.7384 51.4658ZM53.8582 49.9347L51.6188 41.5771L45.6741 43.1699L47.9135 51.5275L53.8582 49.9347Z"
                      fill="black"
                      mask="url(#path-2-inside-1_390_15570)"
                    />
                  </svg>
                  <svg
                    width="67"
                    height="67"
                    viewBox="0 0 67 67"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setModalShow(true)}
                  >
                    <rect
                      x="0.161133"
                      y="0.347656"
                      width="66.64"
                      height="66.64"
                      rx="9.996"
                      fill="#7B7B7B"
                      fill-opacity="0.09"
                    />
                    <g clip-path="url(#clip0_390_15574)">
                      <path
                        d="M53.1934 32.3275L41.227 20.3611V26.7745L38.9849 27.1134C27.7485 28.7037 20.136 34.5956 15.7822 43.616C21.8305 39.3404 29.3388 37.2809 38.6199 37.2809H41.227V44.2938M36.0129 39.9401C24.3594 40.4875 16.0168 44.6849 9.94238 53.1839C12.5494 40.1486 20.3706 27.1134 38.6199 24.5063V14.0781L56.8693 32.3275L38.6199 50.5768V39.8879C37.7596 39.8879 36.8993 39.914 36.0129 39.9401Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_390_15574">
                        <rect
                          width="48.8822"
                          height="39.1058"
                          fill="white"
                          transform="translate(9.04004 14.1152)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="d-flex d-md-none align-items-center gap-2">
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.744385"
                      y="0.101562"
                      width="34"
                      height="34"
                      rx="5.1"
                      fill="#7B7B7B"
                      fill-opacity="0.09"
                    />
                    <path
                      d="M24.2273 9.86092C23.7874 9.41948 23.2652 9.06931 22.6905 8.83039C22.1157 8.59148 21.4997 8.46851 20.8775 8.46851C20.2554 8.46851 19.6393 8.59148 19.0646 8.83039C18.4898 9.06931 17.9676 9.41948 17.5278 9.86092L16.615 10.7766L15.7022 9.86092C14.8138 8.96967 13.6089 8.46897 12.3525 8.46897C11.0961 8.46897 9.89115 8.96967 9.00274 9.86092C8.11434 10.7522 7.61523 11.961 7.61523 13.2214C7.61523 14.4818 8.11434 15.6906 9.00274 16.5819L9.91552 17.4976L16.615 24.2185L23.3145 17.4976L24.2273 16.5819C24.6673 16.1406 25.0164 15.6168 25.2545 15.0402C25.4927 14.4636 25.6152 13.8455 25.6152 13.2214C25.6152 12.5973 25.4927 11.9792 25.2545 11.4026C25.0164 10.826 24.6673 10.3021 24.2273 9.86092V9.86092Z"
                      stroke="black"
                      stroke-width="1.5686"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.461426"
                      y="0.101562"
                      width="34"
                      height="34"
                      rx="5.1"
                      fill="#7B7B7B"
                      fill-opacity="0.09"
                    />
                    <mask id="path-2-inside-1_37_2123" fill="white">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M25.1947 21.7083C25.9992 20.3607 26.4614 18.7851 26.4614 17.1016C26.4614 12.131 22.432 8.10156 17.4614 8.10156C12.4909 8.10156 8.46143 12.131 8.46143 17.1016C8.46143 22.0721 12.4909 26.1016 17.4614 26.1016C19.1486 26.1016 20.7274 25.6373 22.077 24.8295L26.3368 25.971L25.1947 21.7083Z"
                      />
                    </mask>
                    <path
                      d="M25.1947 21.7083L23.8466 20.9035C23.6291 21.2679 23.5683 21.7047 23.6782 22.1146L25.1947 21.7083ZM22.077 24.8295L22.4833 23.313C22.0728 23.203 21.6353 23.2641 21.2707 23.4824L22.077 24.8295ZM26.3368 25.971L25.9305 27.4875C26.4723 27.6326 27.0504 27.4777 27.447 27.0811C27.8436 26.6845 27.9985 26.1064 27.8534 25.5646L26.3368 25.971ZM24.8914 17.1016C24.8914 18.494 24.5099 19.7925 23.8466 20.9035L26.5427 22.5131C27.4884 20.9289 28.0314 19.0762 28.0314 17.1016H24.8914ZM17.4614 9.67156C21.5649 9.67156 24.8914 12.9981 24.8914 17.1016H28.0314C28.0314 11.2639 23.2991 6.53156 17.4614 6.53156V9.67156ZM10.0314 17.1016C10.0314 12.9981 13.3579 9.67156 17.4614 9.67156V6.53156C11.6238 6.53156 6.89143 11.2639 6.89143 17.1016H10.0314ZM17.4614 24.5316C13.3579 24.5316 10.0314 21.205 10.0314 17.1016H6.89143C6.89143 22.9392 11.6238 27.6716 17.4614 27.6716V24.5316ZM21.2707 23.4824C20.158 24.1484 18.8568 24.5316 17.4614 24.5316V27.6716C19.4404 27.6716 21.2969 27.1262 22.8833 26.1767L21.2707 23.4824ZM21.6706 26.346L25.9305 27.4875L26.7432 24.4545L22.4833 23.313L21.6706 26.346ZM27.8534 25.5646L26.7112 21.3019L23.6782 22.1146L24.8203 26.3773L27.8534 25.5646Z"
                      fill="black"
                      mask="url(#path-2-inside-1_37_2123)"
                    />
                  </svg>
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setModalShow(true)}
                  >
                    <rect
                      x="0.744385"
                      y="0.101562"
                      width="34"
                      height="34"
                      rx="5.1"
                      fill="#7B7B7B"
                      fill-opacity="0.09"
                    />
                    <g clip-path="url(#clip0_37_2126)">
                      <path
                        d="M27.8029 16.4181L21.6976 10.3128V13.5849L20.5537 13.7578C14.8209 14.5692 10.9369 17.5753 8.71557 22.1775C11.8015 19.9961 15.6322 18.9453 20.3675 18.9453H21.6976V22.5234M19.0374 20.302C13.0917 20.5814 8.83528 22.7229 5.73608 27.0591C7.06621 20.4085 11.0566 13.7578 20.3675 12.4277V7.10718L29.6784 16.4181L20.3675 25.729V20.2754C19.9285 20.2754 19.4896 20.2887 19.0374 20.302Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_37_2126">
                        <rect
                          width="24.9399"
                          height="19.9519"
                          fill="white"
                          transform="translate(5.27466 7.12573)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <img
                src={postDetail?.data?.coverImageUrl}
                alt=""
                className="my-3 w-100"
              />
              <div
                dangerouslySetInnerHTML={{ __html: postDetail?.data?.content }}
                className="my-3"
              ></div>
              <TextField label="Add a comment.." className="w-100 my-2" />
            </div>
            <div className="col-md-4 blog-RightSide mx-3 d-none d-md-block">
              <h3>Recent Blogs</h3>
              <h3>Top CATEGORIES</h3>
              <a>
                <h3>ALL CATEGORIES</h3>
              </a>
            </div>
          </div>
          <div className="my-3 blog-relatedContainer">
            <h3 className="text-center mb-3">Related Articles</h3>
            <div className="d-flex overflow-auto">
              {postDetail?.data?.relatedPosts?.feed?.map((item, index) => (
                <div className="col-md-3 col-12 p-3">
                  <Blogs item={item?.data} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <SharePopup show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default DetailComponent;
