import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  AddCommentToPost,
  LikePost,
  deletePost,
  removeLikePost,
} from "../_request";
import { useAuth } from "../../../auth";
import CommentModal from "./CommentModal";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import SharePopup from "./SharePopup";

import { useSnackbar } from "notistack";
import { handleBrokenImage } from "../../../lib/utils";

const Post = ({ item }) => {
  const [commentText, setCommentText] = useState();
  const [commentsModalShow, setCommentsModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [liked, setLiked] = useState(item?.data?.liked);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { currentUser } = useAuth();

  const userId = currentUser?.UserId;
  const postId = item?.data?.id;

  const { enqueueSnackbar } = useSnackbar();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeletePost = () => {
    const body = {
      userId: userId,
      type: item?.type,
      id: item?.data?.id,
    };
    deletePost(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        enqueueSnackbar("Post Deleted.", { variant: "success" });
        window.location.reload();
      } else {
        console.log(resp.ResponseMessage);
      }
    });
    handleClose();
  };

  const handleReportPost = () => {
    handleClose();
  };

  const calculateDuration = (createDate) => {
    const currentDate = new Date();
    const postDate = new Date(createDate);

    const timeDifference = currentDate.getTime() - postDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "a year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "a month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "a day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "an hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
    } else {
      return "just now";
    }
  };

  const createDate = item?.data?.createDateIST;
  const duration = calculateDuration(createDate);

  const onCommentChange = (e, commentText) => {
    const body = {
      userId: userId,
      postId: postId,
      description: commentText,
    };
    if (e.key === "Enter") {
      e.preventDefault();
      AddCommentToPost(body).then((resp) => {
        if (resp?.ResponseCode === 200) {
          enqueueSnackbar("Comment added.", { variant: "success" });
          window.location.reload();
        } else {
          enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
        }
      });
    } else {
      setCommentText(commentText);
    }
  };

  const onClickLike = () => {
    const body = {
      userId: userId,
      postId: item.data.id,
    };
    LikePost(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setLiked(true);
      } else {
        console.log(resp.ResponseMessage);
      }
    });
  };
  const onClickRemoveLike = () => {
    const body = {
      userId: userId,
      postId: postId,
    };
    removeLikePost(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setLiked(false);
      } else {
        console.log(resp.ResponseMessage);
      }
    });
  }; 

  return (
    <div className="post--container px-1 pt-3">
      <div className="post--header">
        <div className="headerLeft">
          <a href={`/community/user/${29621}`}>
            <img src={item?.data?.user?.image} alt="" />
          </a>
          <h3>{item?.data?.user?.user_name}</h3>
        </div>
        <div className="headerRight">
          <div>
            <p>{item?.data?.category?.name}</p>
          </div>
          <a onClick={handleMenuClick} style={{ cursor: "pointer" }}>
            <MoreVertIcon />
          </a>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {userId == item?.data?.user?.userId ? (
              <MenuItem onClick={handleDeletePost}>Delete</MenuItem>
            ) : (
              <MenuItem onClick={handleReportPost}>Report</MenuItem>
            )}
          </Menu>
        </div>
      </div>
      <div className="w-100 postBody">
        {(item?.type === "post" && (
          <div className="w-100 my-3 postMedia">
            {(item?.data?.imageUrl && (
              <img
                src={item?.data?.imageUrl}
                alt=""
                className="w-100 postImage"
              />
            )) || (
                <ReactPlayer
                  url={item?.data?.video?.player_embed_url}
                  playIcon={true}
                  controls={true}
                  height={"340px"}
                ></ReactPlayer>
              )}
          </div>
        )) ||
          (item?.type === "article" && (
            <div
              className={`w-100 my-3 postBody-${item?.type}`}
              style={{
                borderRadius: "22.88px",
                border: "2px solid #EEE",
                background: "#FFF",
              }}
            >
              <img src={item?.data?.imageUrl} alt="" className="w-100" />
              <h2 className="my-4 ms-2">{item?.data?.title}</h2>
              <span>{item?.data?.duration}</span>
            </div>
          )) ||
          (item?.type === "audio" && (
            <div
              className={`w-100 my-3 postBody-${item?.type} d-flex p-2 p-md-5 gap-3`}
              style={{
                borderRadius: "22.88px",
                border: "2px solid #EEE",
                background: "#FFF",
              }}
              onClick={() =>
                navigate(`/community/feed/podcast/${item?.data?.id}`)
              }
            >
              <img src={item?.data?.imageUrl} alt="" className="col-6" />
              <div className="col-6 d-flex flex-column justify-content-between my-2">
                <h2 className="ms-2">{item?.data?.title}</h2>
                <div>
                  <img
                    src="/assets/img/community/audioPlayer.png"
                    alt=""
                    className="col-10 col-md-12"
                  />
                </div>
              </div>
            </div>
          )) ||
          (item?.type === "video" && (
            <div
              className={`w-100 my-3 postBody-${item?.type}`}
              style={{
                borderRadius: "22.88px",
                border: "2px solid #EEE",
                background: "#FFF",
              }}
            >
              <img src={item?.data?.imageUrl} alt="" className="w-100" />
              <h2 className="my-4 ms-2">{item?.data?.title}</h2>
              <span>{item?.data?.duration}</span>
              <svg
                width="70"
                height="69"
                viewBox="0 0 70 69"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.525391"
                  y="0.0859375"
                  width="68.64"
                  height="68.64"
                  rx="34.32"
                  fill="#73589B"
                />
                <path
                  d="M45.5233 34.7862C45.5239 35.0551 45.456 35.3196 45.3262 35.554C45.1963 35.7883 45.0089 35.9846 44.7823 36.1237L30.7345 44.8491C30.4976 44.9964 30.2264 45.0767 29.9487 45.082C29.671 45.0872 29.397 45.017 29.155 44.8788C28.9152 44.7427 28.7155 44.5442 28.5764 44.3038C28.4372 44.0633 28.3637 43.7896 28.3633 43.5107V26.0618C28.3637 25.7829 28.4372 25.5092 28.5764 25.2687C28.7155 25.0283 28.9152 24.8298 29.155 24.6937C29.397 24.5554 29.671 24.4853 29.9487 24.4905C30.2264 24.4957 30.4976 24.5761 30.7345 24.7234L44.7823 33.4488C45.0089 33.5879 45.1963 33.7841 45.3262 34.0185C45.456 34.2528 45.5239 34.5173 45.5233 34.7862Z"
                  fill="white"
                />
              </svg>
            </div>
          ))}
      </div>
      <div className="d-flex flex-column gap-3 post--footer">
        <div className="d-flex align-items-center gap-3">
          {liked ? (
            <svg
              className="post--footer-img"
              width="42"
              height="41"
              viewBox="0 0 42 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={onClickRemoveLike}
            >
              <path
                d="M33.4537 10.8136C32.7593 10.1166 31.9348 9.56378 31.0274 9.18658C30.12 8.80938 29.1473 8.61523 28.1651 8.61523C27.1828 8.61523 26.2102 8.80938 25.3028 9.18658C24.3954 9.56378 23.5709 10.1166 22.8765 10.8136L21.4354 12.2593L19.9943 10.8136C18.5917 9.40647 16.6893 8.61596 14.7057 8.61596C12.7221 8.61596 10.8198 9.40647 9.41716 10.8136C8.01454 12.2207 7.22656 14.1291 7.22656 16.1191C7.22656 18.1091 8.01454 20.0175 9.41716 21.4246L10.8583 22.8703L21.4354 33.4814L32.0126 22.8703L33.4537 21.4246C34.1484 20.728 34.6995 19.9009 35.0755 18.9906C35.4515 18.0802 35.645 17.1045 35.645 16.1191C35.645 15.1337 35.4515 14.158 35.0755 13.2476C34.6995 12.3373 34.1484 11.5102 33.4537 10.8136Z"
                fill="#DD1E1E"
                stroke="#DD1E1E"
                stroke-width="2.16293"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              className="post--footer-img"
              width="42"
              height="41"
              viewBox="0 0 42 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={onClickLike}
            >
              <path
                d="M33.5831 10.7706C32.8887 10.0737 32.0642 9.52081 31.1568 9.14361C30.2493 8.76641 29.2767 8.57227 28.2945 8.57227C27.3122 8.57227 26.3396 8.76641 25.4322 9.14361C24.5248 9.52081 23.7003 10.0737 23.0059 10.7706L21.5648 12.2163L20.1237 10.7706C18.7211 9.3635 16.8187 8.57299 14.8351 8.57299C12.8515 8.573 10.9492 9.3635 9.54656 10.7706C8.14394 12.1777 7.35596 14.0862 7.35596 16.0761C7.35596 18.0661 8.14394 19.9745 9.54656 21.3816L10.9877 22.8274L21.5648 33.4384L32.142 22.8274L33.5831 21.3816C34.2778 20.685 34.8289 19.8579 35.2049 18.9476C35.5809 18.0373 35.7744 17.0615 35.7744 16.0761C35.7744 15.0907 35.5809 14.115 35.2049 13.2047C34.8289 12.2943 34.2778 11.4672 33.5831 10.7706V10.7706Z"
                stroke="black"
                stroke-width="2.16293"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          <svg
            className="post--footer-img"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setCommentsModalShow(true)}
          >
            <mask id="path-1-inside-1_182_2312" fill="white">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.1189 23.9236C30.4199 21.7448 31.1675 19.1972 31.1675 16.4751C31.1675 8.43974 24.6536 1.92578 16.6182 1.92578C8.58281 1.92578 2.06885 8.43974 2.06885 16.4751C2.06885 24.5105 8.58281 31.0244 16.6182 31.0244C19.3455 31.0244 21.8975 30.274 24.0791 28.9684L30.9651 30.8135L29.1189 23.9236Z"
              />
            </mask>
            <path
              d="M29.1189 23.9236L27.0748 22.703C26.7449 23.2555 26.6527 23.9181 26.8192 24.5398L29.1189 23.9236ZM24.0791 28.9684L24.6952 26.6688C24.0728 26.502 23.4094 26.5946 22.8564 26.9255L24.0791 28.9684ZM30.9651 30.8135L30.3489 33.1132C31.1705 33.3334 32.0471 33.0985 32.6486 32.497C33.25 31.8956 33.4849 31.0189 33.2648 30.1973L30.9651 30.8135ZM28.7867 16.4751C28.7867 18.7556 28.1616 20.8829 27.0748 22.703L31.163 25.1442C32.6783 22.6066 33.5483 19.6388 33.5483 16.4751H28.7867ZM16.6182 4.30658C23.3387 4.30658 28.7867 9.75462 28.7867 16.4751H33.5483C33.5483 7.12486 25.9684 -0.455019 16.6182 -0.455019V4.30658ZM4.44965 16.4751C4.44965 9.75462 9.89768 4.30658 16.6182 4.30658V-0.455019C7.26793 -0.455019 -0.311952 7.12486 -0.311952 16.4751H4.44965ZM16.6182 28.6436C9.89768 28.6436 4.44965 23.1956 4.44965 16.4751H-0.311952C-0.311952 25.8254 7.26793 33.4052 16.6182 33.4052V28.6436ZM22.8564 26.9255C21.0341 28.0162 18.903 28.6436 16.6182 28.6436V33.4052C19.7879 33.4052 22.7609 32.5319 25.3017 31.0113L22.8564 26.9255ZM23.4629 31.2681L30.3489 33.1132L31.5813 28.5139L24.6952 26.6688L23.4629 31.2681ZM33.2648 30.1973L31.4186 23.3074L26.8192 24.5398L28.6654 31.4297L33.2648 30.1973Z"
              fill="black"
              mask="url(#path-1-inside-1_182_2312)"
            />
          </svg>
          <svg
            className="post--footer-img"
            width="40"
            height="33"
            viewBox="0 0 40 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setModalShow(true)}
          >
            <g clip-path="url(#clip0_182_2313)">
              <path
                d="M35.914 15.3881L26.2004 5.67442V10.8804L24.3804 11.1556C15.2593 12.4465 9.07978 17.2292 5.54561 24.5515C10.4554 21.0808 16.5502 19.409 24.0841 19.409H26.2004V25.1018M21.9678 21.5676C12.5081 22.012 5.73608 25.4192 0.805176 32.3182C2.92144 21.7369 9.27024 11.1556 24.0841 9.03929V0.574219L38.898 15.3881L24.0841 30.202V21.5253C23.3857 21.5253 22.6874 21.5464 21.9678 21.5676Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_182_2313">
                <rect
                  width="39.68"
                  height="31.744"
                  fill="white"
                  transform="translate(0.0708008 0.603516)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <h5>
          {liked
            ? parseInt(item?.data?.likeCount, 10) + 1
            : item?.data?.likeCount}{" "}
          Likes
        </h5>
        {item?.data?.title && <h6>{item?.data?.title}</h6>}
        <a
          onClick={() => setCommentsModalShow(true)}
          style={{ cursor: "pointer" }}
        >
          View all {item?.data?.commentCount} comments
        </a>
        <CommentModal
          postData={item}
          show={commentsModalShow}
          onHide={() => setCommentsModalShow(false)}
          userId={userId}
          postId={postId}
          commentCount={item?.data?.commentCount}
        />
        <div className="addComment-Container d-flex gap-2">
          <img
            src={currentUser?.ProfileImage}
            onError={(e) => {
              const newLink = handleBrokenImage(currentUser?.ProfileImage);
              e.target.src = newLink;
            }}
            height={32}
            width={32}
            style={{ borderRadius: "50%" }}
            alt=""
          />
          <input
            type="text"
            id="commentInput"
            style={{ border: "none" }}
            value={commentText}
            onClick={() => setCommentsModalShow(true)}
            placeholder="Add a comment..."
          />
        </div>
        <p>{duration}</p>
      </div>

      <SharePopup show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default Post;
