import React, { useEffect, useState } from "react";
import ".././Plans.css";
import { useAuth } from "../../../auth";
import { useContext } from "react";
import { AppContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import { getCountryCode } from "../../core/commonFun";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PickUpPlans = () => {
  const { currentUser } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const navigate = useNavigate();
  const [isCountryIndia, setIsCountryIndia] = useState();
  const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];

  var countryCode = getCountryCode();
  console.log("countryCode", countryCode);
  useEffect(() => {
    if (countryCode === "IN") {
      setIsCountryIndia(true);
    } else {
      setIsCountryIndia(false);
    }
  }, []);

  const onFree = () => {
    if (!currentUser) {
      localStorage.setItem("retrunurl", "/");
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    } else {
      window.open(`https://stella.unitedwecare.com/?token=${authToken}`);
      navigate("/");
    }
  };

  const onPaid = (item) => {
    sessionStorage.setItem("subscriptionID", item);
    if (!currentUser) {
      localStorage.setItem("retrunurl", `/subscription-order-summary`);
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    } else {
      navigate(`/subscription-order-summary`);
    }
  };

  const onLearnMore = (item) => {
    if (item === 5) {
      navigate("/platinum-care-plan");
    } else if (item === 7) {
      navigate("/premium-care-plan");
    } else if (item === 6) {
      navigate("/super-care-plan");
    }
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="pick-plan-area">
      {/* <!------ pick-plan-area ------> */}
      <div id="Subscribe_Now">
        <div className="">
          <h2 className="heading mb-md-4">
            Pick Your <span className="diff-clr">Plan</span>
          </h2>
          <div className="pick-plan-inner">
            <div
              className="row justify-content-center px-0 mx-0"
              style={{ float: "left" }}
            >
              <div className="col-lg-4 col-xxl-3 px-md-3">
                <div className="plan-bg m-t">
                  <div className="plan h-100 d-flex flex-column justify-content-around">
                    <div>
                      <span className="d-flex align-items-center justify-content-center">
                        <h3>
                          Care{" "}
                          <img
                            src="/assets/img/PlanLandingPage/PurpleHearts.png"
                            className="w-auto"
                          />
                        </h3>
                      </span>
                      <h6>Available to you 24x7!</h6>
                      <p>
                        <span className="price">
                          {isCountryIndia ? "₹ 0" : "$0"}
                        </span>{" "}
                        /month
                      </p>
                      <div className="d-flex align-items-center gap-2 my-3 plan-benefits-container">
                        <hr />
                        <h4>Benefits</h4>
                        <hr />
                      </div>
                      <ul>
                        <li>
                          <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{ alignItems: "center" }}
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5>Chat with STELLA</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p>
                                Unlock self-improvement potential with Stella's
                                comprehensive assessment
                              </p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                        <li>
                          <Accordion
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="d-flex align-items-center"
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5>Basic Wellness Courses</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p>
                                Basic Wellness Courses to elevate your
                                well-being
                              </p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                        <li>
                          <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3")}
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="d-flex align-items-center"
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5>Exclusive enriching content</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p>Videos, audio, and blogs</p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                      </ul>
                    </div>
                    <div className="plan-btn">
                      <button
                        type="button"
                        onClick={onFree}
                        className="btn border-0"
                      >
                        <a href="#">Start Now</a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 px-md-3">
                <div className="plan-bg1">
                  <div className="plan1 d-flex flex-column justify-content-between h-100 pb-5">
                    <div>
                      <h3 className="text-white">Premium Care</h3>
                      <h6 className="text-white">
                        A plan that actually takes CARE of you
                      </h6>
                      <p className="my-3 text-white">
                        <span className="price text-white">
                          {isCountryIndia ? "₹ 999" : "$12.99"}
                        </span>{" "}
                        /month
                      </p>
                      <div className="d-flex align-items-center gap-2 my-3 plan-benefits-container">
                        <hr style={{ borderColor: "#fff" }} />
                        <h4 className="text-white">Benefits</h4>
                        <hr style={{ borderColor: "#fff" }} />
                      </div>
                      <ul>
                        <li>
                          <Accordion
                            expanded={expanded === "panel11"}
                            onChange={handleChange("panel11")}
                          >
                            <AccordionSummary
                              expandIcon={
                                <KeyboardArrowDownIcon
                                  sx={{ color: "white" }}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{ alignItems: "center" }}
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5 className="text-white">Chat with STELLA</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p className="text-white">
                                Unlock self-improvement potential with Stella's
                                comprehensive assessment
                              </p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                        <li>
                          <Accordion
                            expanded={expanded === "panel22"}
                            onChange={handleChange("panel22")}
                          >
                            <AccordionSummary
                              expandIcon={
                                <KeyboardArrowDownIcon
                                  sx={{ color: "white" }}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="d-flex align-items-center"
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5 className="text-white">Premium Course</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p className="text-white">
                                Exclusive access to courses aimed at enhancing
                                your mental wellness
                              </p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                        <li>
                          <Accordion
                            expanded={expanded === "panel33"}
                            onChange={handleChange("panel33")}
                          >
                            <AccordionSummary
                              expandIcon={
                                <KeyboardArrowDownIcon
                                  sx={{ color: "white" }}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="d-flex align-items-center"
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5 className="text-white">
                                Exclusive enriching content
                              </h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p className="text-white">
                                Videos, audio, and blogs
                              </p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div>
                        <div className="plan1-btn">
                          <button
                            type="button"
                            onClick={() => {
                              isCountryIndia ? onPaid(7) : onPaid(9);
                            }}
                            className="btn px-2 border-0"
                          >
                            <a href="#" style={{ whiteSpace: "nowrap" }}>
                              Subscribe Now
                            </a>
                          </button>
                        </div>
                        <a
                          className="plan1-learnMore text-white"
                          onClick={() => {
                            isCountryIndia ? onLearnMore(7) : onLearnMore(9);
                          }}
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="plan1-para">
                  <p>
                    And the best part, our plans come with 7 days cooling-off
                    period{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 px-md-3">
                <div className="plan-bg2 m-t">
                  <div className="plan1 d-flex flex-column justify-content-between h-100">
                    <div>
                      <h3>Super Care</h3>
                      <h6>The only mental health subscription you will need</h6>
                      <p>
                        <span className="price">
                          {isCountryIndia ? "₹ 2999 " : "$38"}
                        </span>
                        /month
                      </p>
                      <div className="d-flex align-items-center gap-2 my-3 plan-benefits-container">
                        <hr />
                        <h4>Benefits</h4>
                        <hr />
                      </div>
                      <ul>
                        <li>
                          <Accordion
                            expanded={expanded === "panel12"}
                            onChange={handleChange("panel12")}
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{ alignItems: "center" }}
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5>Chat with STELLA</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p>
                                Unlock self-improvement potential with Stella's
                                comprehensive assessment
                              </p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                        <li>
                          <Accordion
                            expanded={expanded === "panel72"}
                            onChange={handleChange("panel72")}
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="d-flex align-items-center"
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5>Premium Courses</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p>
                                Exclusive access to courses aimed at enhancing
                                your mental wellness
                              </p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                        <li>
                          <Accordion
                            expanded={expanded === "panel32"}
                            onChange={handleChange("panel32")}
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="d-flex align-items-center"
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5>Exclusive enriching content</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p>Videos, audio, and blogs</p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                        <li>
                          <Accordion
                            expanded={expanded === "panel92"}
                            onChange={handleChange("panel92")}
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="d-flex align-items-center"
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5>Customized care plans</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p>Designed specifically to meet your needs</p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                        <li>
                          <Accordion
                            expanded={expanded === "panel52"}
                            onChange={handleChange("panel52")}
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="d-flex align-items-center"
                            >
                              <img src="assets/img/check-bx.png" />
                              <h5>Personalised 3 Hour sessions</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p>3 hours live session with experts</p>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <div className="plan1-btn">
                        <button
                          type="button"
                          onClick={() => {
                            isCountryIndia ? onPaid(6) : onPaid(10);
                          }}
                          className="btn col-12 new border-0 px-2"
                        >
                          <a href="#" style={{ whiteSpace: "nowrap" }}>
                            Subscribe Now
                          </a>
                        </button>
                      </div>
                      <a
                        className="plan1-learnMore"
                        onClick={() => {
                          isCountryIndia ? onLearnMore(6) : onLearnMore(10);
                        }}
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {isCountryIndia ? (
                <div className="col-lg-4 col-xxl-3 px-md-3">
                  <div className="plan-bg3 m-t">
                    <div className="plan1 d-flex flex-column justify-content-between h-100">
                      <div>
                        <h3>Platinum Care</h3>
                        <h6>
                          The mental health subscription for all your needs will
                          need
                        </h6>
                        <p>
                          <span className="price">₹ 9999</span> /month
                        </p>
                        <div className="d-flex align-items-center gap-2 my-3 plan-benefits-container">
                          <hr />
                          <h4>Benefits</h4>
                          <hr />
                        </div>
                        <ul>
                          <li>
                            <Accordion
                              expanded={expanded === "panel41"}
                              onChange={handleChange("panel41")}
                            >
                              <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="d-flex align-items-center"
                              >
                                <img src="assets/img/check-bx.png" />
                                <h5>Chat with STELLA</h5>
                              </AccordionSummary>
                              <AccordionDetails>
                                <p>
                                  Unlock self-improvement potential with
                                  Stella's comprehensive assessment
                                </p>
                              </AccordionDetails>
                            </Accordion>
                          </li>
                          <li>
                            <Accordion
                              expanded={expanded === "panel42"}
                              onChange={handleChange("panel42")}
                            >
                              <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="d-flex align-items-center"
                              >
                                <img src="assets/img/check-bx.png" />
                                <h5>Premium Courses</h5>
                              </AccordionSummary>
                              <AccordionDetails>
                                <p>
                                  Exclusive access to courses aimed at enhancing
                                  your mental wellness
                                </p>
                              </AccordionDetails>
                            </Accordion>
                          </li>
                          <li>
                            <Accordion
                              expanded={expanded === "panel43"}
                              onChange={handleChange("panel43")}
                            >
                              <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="d-flex align-items-center"
                              >
                                <img src="assets/img/check-bx.png" />
                                <h5>Exclusive enriching content</h5>
                              </AccordionSummary>
                              <AccordionDetails>
                                <p>Videos, audio, and blogs</p>
                              </AccordionDetails>
                            </Accordion>
                          </li>
                          <li>
                            <Accordion
                              expanded={expanded === "panel84"}
                              onChange={handleChange("panel84")}
                            >
                              <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="d-flex align-items-center"
                              >
                                <img src="assets/img/check-bx.png" />
                                <h5>Customized care plans</h5>
                              </AccordionSummary>
                              <AccordionDetails>
                                <p>Designed specifically to meet your needs</p>
                              </AccordionDetails>
                            </Accordion>
                          </li>
                          <li>
                            <Accordion
                              expanded={expanded === "panel45"}
                              onChange={handleChange("panel45")}
                            >
                              <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="d-flex align-items-center"
                              >
                                <img src="assets/img/check-bx.png" />
                                <h5>Personalised 1-1 sessions</h5>
                              </AccordionSummary>
                              <AccordionDetails>
                                <p>
                                  Unlimited live sessions with professional
                                  experts
                                </p>
                              </AccordionDetails>
                            </Accordion>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <div>
                          <div className="plan1-btn">
                            <button
                              type="button"
                              onClick={() => onPaid(5)}
                              className="btn col-12 new border-0"
                            >
                              <a href="#" style={{ whiteSpace: "nowrap" }}>
                                Subscribe Now
                              </a>
                            </button>
                          </div>
                        </div>
                        <a
                          className="plan1-learnMore"
                          onClick={() => onLearnMore(5)}
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickUpPlans;
