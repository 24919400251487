import { Routes, Route } from 'react-router-dom';
import MyAppointmentPage from './MyAppointmentPage';

const DashRouting = () => {
    return (
        <Routes>
            <Route path='my-appointments' element={<MyAppointmentPage />} /> 
        </Routes>
    )
}

export default DashRouting;