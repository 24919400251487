import { useState } from "react";

import Button from "@mui/material/Button";

//Icons
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import LockIcon from "@mui/icons-material/Lock";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import moment from "moment";

const CalendarViewAppointmentCard = ({
  id,
  name,
  image,
  type,
  status,
  sessionType,
  date,
  day,
  time,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      className={`Appointment--Item ${status == "Completed" ? "Completed--Item" : ""}`}
    >
      <img src={image} width={45} height={45} style={{ borderRadius: '50%', objectFit: 'cover' }} />
      <div className="Item--Details">
        <h6>
          { name }{" "}
          {!status == "Completed" && <LockIcon style={{ marginLeft: "10px" }} />}
        </h6>
        {status == "Completed" ? (
          <span>
            <CheckCircleOutlineOutlinedIcon /> Completed
          </span>
        ) : (
          <p>{day} {time} | {type} | {sessionType}</p>
        )}
      </div>
      {!status == "Completed" && (
        <div className="CTA--Container">
          <Button>
            <VisibilityIcon />
            <p>View</p>
          </Button>

          <Button>
            <ChatBubbleOutlineOutlinedIcon />
            <p>Chat</p>
          </Button>

          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <ArrowDropDownOutlinedIcon />
          </Button>

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            className="DarkDropdown"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }}>Add Note</MenuItem>
            <Divider />
            <MenuItem sx={{ fontSize: "14px" }}>Book Appointment</MenuItem>
            <Divider />
            <MenuItem sx={{ fontSize: "14px" }}>Reschedule</MenuItem>
            <Divider />
            <MenuItem sx={{ fontSize: "14px" }}>Add Presciptions</MenuItem>
            <Divider />
            <MenuItem sx={{ fontSize: "14px" }}>Courses Undertaken</MenuItem>

            <Divider />
            <MenuItem sx={{ fontSize: "14px", color: "#f57e8c !important" }}>
              Cancel Appointment
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default CalendarViewAppointmentCard;
