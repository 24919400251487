import { useState, useEffect } from "react";

const TermsOfService = () => {
  const [termsData, setTermsData] = useState("");

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const getTerms = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/expert/dashboard/terms`,
          {
            method: "GET",
            headers,
          }
        );
        const postResponse = await response.json();

        setTermsData(postResponse?.data.terms);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getTerms();
  }, []);
  return (
    <div
      className="px-3 py-4"
      style={{ fontSize: "18px" }}
      dangerouslySetInnerHTML={{ __html: termsData }}
    ></div>
  );
};

export default TermsOfService;
