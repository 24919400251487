import React from 'react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/Edit';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const SessionNoteTabItem = ({id, name, date, time, onView}) => {
    return (
        <div className='SessionNoteItem p-3 d-flex align-items-center justify-items-between'>
            <div>
                <h6>{name}</h6>
                <span>Last update on {date}</span>
            </div>

            <div className='d-flex align-items-center gap-2 ml-auto Actions'>
                <div onClick={() => {
                    onView(id)
                }}>
                    <VisibilityOutlinedIcon />
                    View
                </div>
            </div>
        </div>
    )
}

export default SessionNoteTabItem