import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { useAuth } from '../../../auth';
import { AddCommentToPost, deletePostComment, getCommentsOnPost } from '../_request';
import { useSnackbar } from 'notistack';
import { handleBrokenImage } from '../../../lib/utils';

const CommentModal = ({ postData, ...props }) => {
    const [commentsData, setCommentsData] = useState();
    const [commentText, setCommentText] = useState();
    const [loading, setLoading] = useState();
    const { currentUser } = useAuth();

    const id = currentUser?.UserId;
    const postId = props?.postId;

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (props?.commentCount > 0) {
            getCommentsOnPost({ id, postId }).then(resp => {
                if (resp?.ResponseCode === 200) {
                    setCommentsData(resp?.data)
                } else {
                    console.log(resp.ResponseMessage)
                }
            })
        }
    }, []);

    const onCommentChange = (e, commentText) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            commentOnPost();
        } else {
            setCommentText(commentText);
        }
    }

    const commentOnPost = () => {
        setLoading(true);
        const body = {
            "userId": props?.userId,
            "postId": props?.postId,
            "description": commentText
        }
        AddCommentToPost(body).then(resp => {
            if (resp?.ResponseCode === 200 || resp?.ResponseCode === 201) {
                setLoading(false);
                enqueueSnackbar("Comment added.", { variant: "success" });
                window.location.reload();
                props?.show();
            } else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        })
    }

    const handleDeleteComment = (id) => {
        let body = {
            "commentId": id,
            "userId": props?.userId
        }
        deletePostComment(body).then((resp) => {
            if (resp?.ResponseCode === 200) {
                enqueueSnackbar("Comment Deleted.", { variant: "success" });
                window.location.reload()
                props?.show();
            } else {
                console.log(resp.ResponseMessage)
            }
        })
    }

    return (
        <Modal
            {...props}
            size=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='viewComments'
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className='d-flex align-items-center gap-2 my-3'>
                        <img
                            src={currentUser?.ProfileImage}
                            onError={(e) => {
                                const newLink = handleBrokenImage(currentUser?.ProfileImage);
                                e.target.src = newLink
                            }} alt='' height={58} width={58}
                        />
                        <InputGroup className='lh-1 rounded-5 d-flex gap-3'>
                            <Form.Control className="rounded-5" style={{ height: "50px" }} type="text" placeholder="Add a comment..."
                                onChange={(e) => setCommentText(e.target.value)}
                                onKeyDown={(e) => onCommentChange(e, commentText)}
                                value={commentText}
                            />
                            <Button disabled={loading} className='font-inter fs-6 text-white rounded-5 border-0' style={{ background: "var(--primary-color)", height: "50px" }} onClick={commentOnPost} >Submit</Button>
                        </InputGroup>
                    </div>
                    <hr />
                    {commentsData?.comments && commentsData?.comments?.map((item, index) =>
                        <div className='d-flex px-2 commentDiv py-3' key={index}>
                            <div className='col-2'>
                                <img src={item?.user?.image} alt='' className='' height={58} width={58} style={{ borderRadius: '50%' }} />
                            </div>
                            <div className='col-9'>
                                <h3>{item?.user?.name}</h3>
                                <p>
                                    {item?.description}
                                </p>
                            </div>
                            {props?.userId == item?.user?.userId &&
                                <div className='col-1'>
                                    <DeleteOutlineOutlinedIcon onClick={() => handleDeleteComment(item?.id)} style={{ cursor: 'pointer' }} />
                                </div>
                            }
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CommentModal