import React, { useEffect, useState } from "react";
import { getCourseDashboardDetails_WebService } from "../core/_request";
import { useAuth } from "../../auth";

const DashboardStats = () => {
  const [data, setData] = useState();
  const { currentUser } = useAuth();

  useEffect(() => {
    const body = { userId: currentUser?.UserId, courseId: "1" };
    getCourseDashboardDetails_WebService(body).then((resp) => {
      setData(resp.data);
    });
  }, []);
  return (
    <div>
      <div
        className="row py-5 mx-1"
        style={{ boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.15)" }}
      >
        <div className="col-6 col-md-3 py-3 py-md-0">
          <h5 className="fs-3 text-center">{data?.data?.counts?.calls}</h5>
          <h3 className="text-center fs-6">No. of Calls</h3>
        </div>
        <div className="col-6 col-md-3 py-3 py-md-0">
          <h5 className="fs-3 text-center">{data?.data?.counts?.worksheets}</h5>
          <h3 className="text-center fs-6">Worksheets</h3>
        </div>
        <div className="col-6 col-md-3 py-3 py-md-0">
          <h5 className="fs-3 text-center">{data?.data?.counts?.videos}</h5>
          <h3 className="text-center fs-6">Video Watched</h3>
        </div>
        <div className="col-6 col-md-3 py-3 py-md-0">
          <h5 className="fs-3 text-center">
            {data?.data?.counts?.subscriptions}
          </h5>
          <h3 className="text-center fs-6">Subscriptions</h3>
        </div>
      </div>
    </div>
  );
};

export default DashboardStats;
